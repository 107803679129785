
import {
  updateMyUserAttribute,
  patchUserSetting,
  updateUser,
  deleteMyUserAttribute,
} from '@/api/core/user.api';
import { UpdateUserObject, UserAttributes } from '@/domain/User';
import { Component, Vue, Prop } from 'vue-property-decorator';
import UnsavedChangesDialog from '@/components/Settings/UnsavedChangesDialog.vue';
import { Settings } from '@/domain/Settings';

@Component({
  components: {
    UnsavedChangesDialog,
  },
})
export default class ActionButtons extends Vue {
  @Prop() data: UpdateUserObject;
  @Prop() value: boolean;

  showUnsavedChangesDialog = false;
  savingChanges = false;

  get dataChanged(): boolean {
    return this.value;
  }

  set dataChanged(val: boolean) {
    this.$emit('input', val);
  }

  async saveChanges(): Promise<void> {
    try {
      if (this.dataChanged) {
        // Update the user object
        if (this.data.user) {
          this.savingChanges = true;
          await updateUser(this.data.user);
          // Update store upon success
          const updatedUser = {
            ...this.getCurrentUser,
            ...this.data.user,
          };
          this.$store.commit('auth/setUser', updatedUser);
        }

        // Update the settings
        if (this.data.settings) {
          this.savingChanges = true;
          const settingsPairs: Array<{
            settingName: string;
            value: boolean | string | number;
          }> = Object.keys(this.data.settings as Settings).map((key) => ({
            settingName: key,
            value: (this.data.settings as Settings)[key],
          }));

          settingsPairs.forEach(
            async ({ settingName, value }) =>
              await patchUserSetting(settingName, value)
          );
          // Update store if all settings were successfully updated
        }

        // Update attributes
        if (this.data.attributes) {
          this.savingChanges = true;

          const data = this.data.attributes as UserAttributes;

          Object.keys(data).forEach(async (key: string) => {
            // eslint-disable-next-line
            // @ts-ignore
            let value = data[key];
            // Empty/array check
            if (value || (Array.isArray(value) && value.length > 0)) {
              await updateMyUserAttribute(key, value);
            } else {
              await deleteMyUserAttribute(key);
            }
          });
        }

        this.$notify('Settings updated successfully.');
        this.dataChanged = false;
        this.savingChanges = false;
      }
    } catch (error) {
      this.$notify('An error has occured.');
      this.savingChanges = false;
    }
  }

  discardChanges(): void {
    this.dataChanged = false;
    this.$emit('discardChanges');
  }
}
