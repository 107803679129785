var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pb-2 white interactable",on:{"click":function($event){_vm.isExpanded = !_vm.isExpanded}}},[_c('v-row',{staticClass:"px-6 py-4",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-medium neutral--text text--darken-3"},[_c('span',[_vm._v(_vm._s(_vm.problemRow.problem.xref))]),(_vm.problemRow.problem.partLetter !== null)?_c('span',[_vm._v(": "+_vm._s(_vm.problemRow.problem.partLetter.toUpperCase())+" ")]):_vm._e()]),_c('ProblemView',{attrs:{"problem":_vm.problemRow.problem,"collapsed":!_vm.isExpanded,"disableDivider":"","canBeCollapsed":""}})],1),_c('v-col',[_c('v-row',{staticClass:"text-center",attrs:{"dense":"","align":"center"}},_vm._l((_vm.customHeaders),function(header,index){return _c('v-col',{key:index,staticClass:"d-flex",class:index === _vm.customHeaders.length - 1
              ? 'justify-end'
              : 'justify-start',attrs:{"cols":_vm.customHeaderCols
              ? _vm.customHeaderCols
              : header.cols
              ? header.cols
              : false}},[(header.value === _vm.ProblemRowHeaderValue.CLASS_AVERAGE)?_c('div',[_vm._v(" "+_vm._s(_vm.getDisplayScore(_vm.problemRow.problemData.averageScore))+" ")]):(
              _vm.problemRow.studentData[header.value] &&
              _vm.problemRow.studentData[header.value].score !== null
            )?_c('ScoreChip',{attrs:{"score":_vm.getScore(_vm.problemRow.studentData[header.value].score),"studentActions":_vm.getStudentActions(_vm.problemRow.studentData[header.value]),"sawAnswer":_vm.getSawAnswer(_vm.problemRow.studentData[header.value]),"includesCorrectAnswer":""}}):_c('div',{staticClass:"primary--text text--darken-3"},[_vm._v("-")])],1)}),1)],1)],1),(_vm.isExpanded)?_c('v-row',[_c('v-col',[_vm._l((_vm.customHeaders),function(header){return [(_vm.problemRow.studentData[header.value])?_c('StudentProblemView',{key:header.value,attrs:{"studentXref":header.value,"problemAverageTime":_vm.problemRow.problemData.averageTime,"problemAnswers":_vm.problemRow.problem.answers,"studentData":_vm.problemRow.studentData[header.value]}}):_vm._e()]})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }