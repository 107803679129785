export enum GroupType {
  PRINCIPALS = 'PRINCIPALS',
  AST_COURSE = 'AST_COURSE',
  ASSIGNEE = 'ASSIGNEE',
  MENTEE = 'MENTEE',
}

export interface GroupDefinition {
  groupXref: string;
  name: string;
  ownerXref: string;
  groupType: GroupType;
  isActive: boolean;
  createdAt: number;
}
