import { ActionTree } from 'vuex';
import { AuthState } from './types';
import { RootState } from '../types';
import { UserAttributes } from '@/domain/User';

export const actions: ActionTree<AuthState, RootState> = {
  updateUserAttributes(context, attributes: Partial<UserAttributes>): void {
    context.commit('updateAttributes', attributes);
  },
};
