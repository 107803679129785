export interface AttributeDefinition {
  name: string;
  description: string;
  dataType: string;
  targetTypes: AttributeTargetType[];
  attrType: AttributeType;
  isActive: boolean;
  values: AttributeDefinitionValue[];
}

export interface AttributeDefinitionValue {
  display: string;
  value: AttributeValueType;
}

export enum AttributeTargetType {
  GROUP = 'GROUP',
  USER = 'USER',
  ASSIGNMENT = 'ASSIGNMENT',
  PARTNER = 'PARTNER',
  LEGACY_PROBLEM = 'LEGACY_PROBLEM',
  LEGACY_PROBLEM_SET = 'LEGACY_PROBLEM_SET',
  SDK3_PROBLEM = 'SDK3_PROBLEM',
  SDK3_PROBLEM_SET = 'SDK3_PROBLEM_SET',
  LEGACY_FOLDER = 'LEGACY_FOLDER',
}

export enum AttributeType {
  UNBOUNDED_SINGLE_VALUED = 'UNBOUNDED_SINGLE_VALUED',
  DISCRETE_SINGLE_VALUED = 'DISCRETE_SINGLE_VALUED',
  DISCRETE_MULTI_VALUED = 'DISCRETE_MULTI_VALUED',
  UNBOUNDED_MULTI_VALUED = 'UNBOUNDED_MULTI_VALUED',
}

export enum AttributeNames {
  COURSE_END_TIME = 'courseEndTime',
  CURRICULUM = 'curriculum',
  GRADE_LEVEL = 'gradeLevel',
  NCES_PUBLIC_SCHOOL = 'ncesPublicSchool',
  QUICK_COMMENTS_TEACHER = 'quickCommentsTeacher',
  ATTRIBUTION = 'attribution',
}

export type AttributeValueType =
  | number
  | string
  | boolean
  | Record<string, unknown>;
