import store from '@/store';
import { AttributeValueType } from '@/domain/Attributes';
import { Settings } from '@/domain/Settings';
import { User } from '@/domain/User';
import { coreAxios } from '@/plugins/axios';
import { transformToArray } from '@/utils/arrays.util';
import { transformUserProfileToUser } from '@/utils/user.util';

const END_POINT = `/users/me`;

//////////
// DTOs //
//////////

export interface SettingsDTO {
  name: string;
  value: boolean | string | number;
  hasBeenSet: boolean;
}

const getMyUser = (roles: boolean, attributes: boolean): Promise<User> => {
  return coreAxios
    .get(`${END_POINT}/?roles=${roles}&attributes=${attributes}`)
    .then((res) => {
      return transformUserProfileToUser(res.data);
    });
};

const updateUser = (data: Partial<User>): Promise<void> => {
  return coreAxios.patch(`${END_POINT}`, data).then((res) => {
    return res.data;
  });
};

const getUserSettings = (): Promise<Settings> => {
  return coreAxios.get(`${END_POINT}/settings`).then((res) => {
    return buildSettings(res.data);
  });
};

const _updateUserSettingInStore = (
  settingName: string,
  value: unknown
): void => {
  store.commit('auth/updateSettings', {
    [settingName]: value,
  });
};

const patchUserSetting = (
  settingName: string,
  value: unknown
): Promise<number> => {
  // ${value} forces axios to send data in case value = false
  return coreAxios
    .patch(`${END_POINT}/settings/${settingName}`, `${value}`)
    .then((res) => {
      _updateUserSettingInStore(settingName, value);
      return res.status;
    });
};

const resetUserSetting = (settingName: string): Promise<number> => {
  return coreAxios
    .delete(`${END_POINT}/settings/${settingName}/`)
    .then((res) => {
      return res.status;
    });
};

function buildSettings(data: Array<SettingsDTO>): Settings {
  let payload: Partial<Settings> = {};

  data.forEach((d) => {
    payload = Object.assign(payload, {
      [d.name]: d.value,
    });
  });

  return payload as Settings;
}

//Slightly unneccessary but what I have is a retry loop in here to try and update a user's attributes.
// If we think we know that the attribute already exists then we start with the put, otherwise post
// Example follows using we don't know it exists:
// If we post to the endpoint we will attempt to add a attribute. If that fails with a 409 then it is assumed that
// the attribute exists already and we should run a put. We then attempt a put and hope that it works.
// This is called promise chaining and what it means is that the catch will run before any other catches in other code blocks
// and by returning a new promise (if we are in status code 409) then that promise runs and resolves/rejects before ANY other catch/then
// blocks are run.
const updateMyUserAttribute = (
  attributeName: string,
  value: AttributeValueType | Array<AttributeValueType>,
  exists = false
): Promise<unknown> => {
  let runArr = [_postMyUserAttribute, _putMyUserAttribute];
  if (exists) {
    runArr = [_putMyUserAttribute, _postMyUserAttribute];
  }
  return runArr[0](attributeName, value).catch((err) => {
    if (err.statusCode === 409) {
      return runArr[1](attributeName, value);
    } else {
      throw err;
    }
  });
};

const _updateUserAttributeInStore = (
  attributeName: string,
  value: AttributeValueType | Array<AttributeValueType>
): void => {
  store.commit('auth/updateAttributes', {
    [attributeName]: value,
  });
};

const _putMyUserAttribute = (
  attributeName: string,
  value: AttributeValueType | Array<AttributeValueType>
): Promise<void> => {
  return coreAxios
    .put(`${END_POINT}/attributes/${attributeName}`, {
      value: transformToArray(value),
    })
    .then(() => {
      _updateUserAttributeInStore(attributeName, value);
    });
};
const _postMyUserAttribute = (
  attributeName: string,
  value: AttributeValueType | Array<AttributeValueType>
): Promise<void> => {
  return coreAxios
    .post(`${END_POINT}/attributes/${attributeName}`, {
      value: transformToArray(value),
    })
    .then(() => {
      _updateUserAttributeInStore(attributeName, value);
    });
};
//END slightly unncessary update user attributes code.

const deleteMyUserAttribute = (attributeName: string): Promise<void> => {
  return coreAxios.delete(`${END_POINT}/attributes/${attributeName}`);
};

export {
  getMyUser,
  updateUser,
  getUserSettings,
  patchUserSetting,
  resetUserSetting,
  updateMyUserAttribute,
  deleteMyUserAttribute,
};
