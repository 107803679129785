import { User } from '@/domain/User';

/**
 * User Profile sent from server
 */
export interface UserProfileDTO {
  userXref: string;
  firstName: string;
  lastName: string;
  userName: string;
  displayName: string;
  roles: Array<string>;
  timeZone: string;
}

const transformUserProfileToUser = (userDTO: UserProfileDTO): User => {
  const { userXref, ...rest } = userDTO;
  return {
    xref: userXref,
    ...rest,
  };
};

export { transformUserProfileToUser };
