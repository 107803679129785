import { MutationTree } from 'vuex';
import { cloneDeep } from 'lodash';
import { User } from '@/domain/User';
import { InsightsHubState } from './types';
import { TeacherCurriculumStats } from '@/domain/ReportData/InsightsHub';
import { CurriculumGrade } from '@/domain/Curriculum';
import { CourseDefinition } from '@/domain/Class';
import { School } from '@/domain/School';
import { DataOptions } from 'vuetify';
import { TimeSelector } from '@/domain/Time';
import { CancelTokenSource } from 'axios';
import { HybridFolderDefinition } from '@/domain/Folder';

export const mutations: MutationTree<InsightsHubState> = {
  setGradeFolders(state: InsightsHubState, folders: HybridFolderDefinition[]) {
    state.gradeFolders = [...folders];
  },
  setMenteeTeachers(state: InsightsHubState, menteeTeachers: Array<User>) {
    state.menteeTeachers = cloneDeep(menteeTeachers);
  },
  setMenteeCourses(
    state: InsightsHubState,
    menteeCourses: Array<CourseDefinition>
  ) {
    state.menteeCourses = cloneDeep(menteeCourses);
  },
  setMenteeSchools(state: InsightsHubState, menteeSchools: Array<School>) {
    state.menteeSchools = cloneDeep(menteeSchools);
  },
  setSelectedCurriculumXref(state: InsightsHubState, xref: string) {
    state.selectedCurriculumXref = xref;
  },
  setSelectedGradeId(state: InsightsHubState, gradeId: number) {
    state.selectedGradeId = gradeId;
  },
  setSelectedModuleId(state: InsightsHubState, moduleId: number) {
    state.selectedModuleId = moduleId;
  },
  setTeacherCurriculumStats(
    state: InsightsHubState,
    statsPerCurriculumGrade: Array<TeacherCurriculumStats>
  ) {
    state.teacherCurriculumStats = cloneDeep(statsPerCurriculumGrade);
  },
  setCurriculumGrade(
    state: InsightsHubState,
    curriculumGrade: CurriculumGrade | null
  ) {
    state.curriculumGrade = cloneDeep(curriculumGrade);
  },
  setSelectedMode(state: InsightsHubState, selectedMode: string) {
    state.selectedMode = selectedMode;
  },
  setSelectedPsTypes(state: InsightsHubState, selectedPsTypes: string[]) {
    state.selectedPsTypes = selectedPsTypes;
  },
  setSelectedAssessmentType(
    state: InsightsHubState,
    selectedAssessmentType: string | null
  ) {
    state.selectedAssessmentType = selectedAssessmentType;
  },
  setAnonymized(state: InsightsHubState, anonymized: boolean) {
    state.anonymized = anonymized;
  },
  setSelectedTab(state: InsightsHubState, selectedTab: number | null) {
    state.selectedTab = selectedTab;
  },
  setCollapsedPaths(state: InsightsHubState, collapsedPaths: string[]) {
    state.collapsedPaths = collapsedPaths;
  },
  setOptions(state: InsightsHubState, options: DataOptions) {
    state.options = options;
  },
  setTimeSelector(state: InsightsHubState, timeSelector: TimeSelector | null) {
    state.timeSelector = timeSelector;
  },
  setDashboardLoading(state: InsightsHubState, dashboardLoading: boolean) {
    state.dashboardLoading = dashboardLoading;
  },
  setDashboardSource(
    state: InsightsHubState,
    dashboardSource: CancelTokenSource | null
  ) {
    state.dashboardSource = dashboardSource;
  },
};
