
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { orderBy, shuffle } from 'lodash';
import { Problem } from '@/domain/Problem';
import {
  AssignmentReportData,
  ProblemLogAndActions,
  StudentLog,
  ProblemLog,
} from '@/domain/ReportData/AssignmentData';
import { Skill } from '@/domain/Skill';
import { User } from '@/domain/User';
import { getSkills } from '@/api/skills.api';
import { scoreProblemForStandardsReport } from '@/utils/problem.util';
import { appendGlobalHeaderOptions } from '@/utils/dataTables.utils';
import ScoreChip from './ScoreChip.vue';
import sortBySortableName from '@/utils/sortBySortableName.util';
import StandardsScoringDialog from './StandardsScoringDialog.vue';

interface SkillColumn {
  skillCode: string;
  value: number | null;
  totalProblemsCount: number;
  completedProblemsCount: number;
}

interface StandardsReportTableRow {
  student: User;
  averageScore: number;
  skills: Array<SkillColumn>;
}

@Component({
  components: {
    ScoreChip,
    StandardsScoringDialog,
  },
})
export default class StandardsReportTable extends Vue {
  @Prop({ default: null }) assignmentReportData: AssignmentReportData | null;
  @Prop({ default: () => [] }) problems: Array<Problem>;
  @Prop({ default: () => [] }) assignees: Array<User>;

  rowsGenerated = false;
  problemCountsMap: Map<string, number> = new Map<string, number>();
  showScoringDialog = false;
  reportReady = false;

  anonymizeNames = false;

  prependStaticHeaders: Array<DataTableHeader> = [
    {
      text: 'Student Name/Problem',
      value: 'student',
      align: 'start',
      class: ['text-no-wrap', 'sticky-row', 'sticky-row-1'],
      cellClass: ['text-no-wrap'],
      sortable: this.anonymizeNames ? false : true,
      sort: sortBySortableName,
    },
    {
      text: 'Average Score',
      value: 'averageScore',
      align: 'center',
      class: ['text-no-wrap', 'sticky-row', 'sticky-row-1'],
    },
  ];

  /**
   * Maps problems ids to the problem objects
   * Used to check problem types when scoring problems
   */
  get problemIdToProblemMap(): Map<number, Problem> {
    const res = new Map();
    for (const prob of this.problems) {
      res.set(prob.id, prob);
    }
    return res;
  }

  /**
   * Score an individual problem log based on problem type
   * Assign partial credit to open response questions
   * All other questions are 1 if correct 0 otherwise
   * @param prLog: ProblemLog - the log to score
   * @returns score 0-1
   */
  scoreProblem(prLog: ProblemLog): number {
    const problem = this.problemIdToProblemMap.get(prLog.problemDbid);
    return scoreProblemForStandardsReport(prLog, problem);
  }

  get skillIdToSkillMap(): Map<number, Skill> {
    return this.$store.getters['skillList/getSkillsMap'];
  }

  get skillIdToProblemIdsMap(): Map<number, Array<number>> {
    const res: Map<number, Array<number>> = new Map<number, Array<number>>();

    for (let i = 0; i < this.uniqueSkillIds.length; i++) {
      const skillId = this.uniqueSkillIds[i];
      const problemIds = this.problems
        .filter((problem) => problem.skillIds?.includes(skillId))
        .map((problem) => Number(problem.id));

      if (problemIds.length > 0) {
        res.set(skillId, problemIds);
      }
    }

    return res;
  }

  get studentXrefToStudentLogMap(): Map<string, StudentLog> {
    const res = new Map<string, StudentLog>();

    if (this.assignmentReportData) {
      this.assignmentReportData.studentLogs.forEach((log) => {
        res.set(log.studentXref, log);
      });
    }

    return res;
  }

  get headers(): Array<DataTableHeader> {
    return [
      ...this.prependStaticHeaders,
      ...this.skillCodeHeaders.map((header) => {
        const transformedHeader = appendGlobalHeaderOptions(header);
        return {
          ...transformedHeader,
          sortable: true,
        };
      }),
    ];
  }

  get uniqueSkillIds(): Array<number> {
    const skillIds: Array<number> = [];

    this.problems.forEach((problem) =>
      problem.skillIds?.forEach((skillId) => skillIds.push(skillId))
    );

    return Array.from(new Set(skillIds)); // remove duplicates
  }

  get skillCodeHeaders(): Array<DataTableHeader> {
    const headers: Array<DataTableHeader> = [];

    this.uniqueSkillIds.forEach((id) => {
      const skill = this.skillIdToSkillMap.get(id);

      if (skill) {
        headers.push({
          text: skill.code,
          value: skill.code,
          align: 'center',
        });
      }
    });

    return headers;
  }

  getProblemCountPerStandard(skillCode: string): number | null {
    return this.problemCountsMap.get(skillCode) || null;
  }

  generateSkillColumns(studentLog?: StudentLog): Array<SkillColumn> {
    const res: Array<SkillColumn> = [];

    this.uniqueSkillIds.forEach((id) => {
      const skill = this.skillIdToSkillMap.get(id) as Skill;
      const problemIds = this.skillIdToProblemIdsMap.get(id) as Array<number>;
      const logs =
        studentLog?.problemLogAndActions || ([] as Array<ProblemLogAndActions>);

      const filteredLogs = logs.filter(
        (log) =>
          problemIds.includes(log.prLog.problemDbid) &&
          log.prLog.endTime &&
          (typeof log.prLog.discreteScore === 'number' ||
            typeof log.prLog.continuousScore === 'number')
      );

      let value: number | null = null;

      if (filteredLogs.length === 0) {
        value = 0;
      } else {
        const sum = filteredLogs.reduce(
          (acc, curr) => (acc += this.scoreProblem(curr.prLog)),
          0
        );

        value = Math.round((sum / filteredLogs.length) * 100);

        this.problemCountsMap.set(skill.code, problemIds.length);
      }
      res.push({
        skillCode: skill.code || '',
        value: value,
        totalProblemsCount: problemIds.length,
        completedProblemsCount: filteredLogs.length,
      });
    });

    return res;
  }

  get rows(): Array<StandardsReportTableRow> {
    const rows: Array<StandardsReportTableRow> = [];

    for (let i = 0; i < this.assignees.length; i++) {
      // Get the average score **per standard**
      const student = this.assignees[i];

      const studentLog = this.studentXrefToStudentLogMap.get(
        student.xref
      ) as StudentLog;

      const skills: Array<SkillColumn> = this.generateSkillColumns(studentLog);

      const scores: Array<number> = (studentLog?.problemLogAndActions || [])
        .filter(
          (log) =>
            log.prLog.endTime &&
            (typeof log.prLog.discreteScore === 'number' ||
              typeof log.prLog.continuousScore === 'number')
        )
        .map((log) => {
          return this.scoreProblem(log.prLog);
        });

      const sum = scores.reduce((acc, curr) => (acc += curr), 0);
      const averageScore =
        scores.length > 0 ? Math.round((sum / scores.length) * 100) : 0;

      rows.push({
        student,
        averageScore: Math.round(averageScore),
        skills,
      });
    }

    rows.forEach((row) => {
      const { skills } = row;
      skills.forEach((skill) => {
        row = Object.assign(row, { [skill.skillCode]: skill.value });
      });
    });

    this.rowsGenerated = true;

    // Shuffle rows when anonymizing
    if (this.anonymizeNames) {
      return shuffle(rows);
    } else {
      return orderBy(
        rows,
        [
          (row) => {
            const student = row.student as User;
            return student.lastName;
          },
          (row) => {
            const student = row.student as User;
            return student.firstName;
          },
        ],
        ['asc', 'asc']
      );
    }
  }

  generateCsv(): string {
    let csv = '';

    // Header row
    csv += 'Student Name/Problem, Average Score, ';
    this.skillCodeHeaders.forEach(({ text }) => (csv += `${text}, `));
    csv += '\n';

    // Number of problems row
    csv += 'Number Of Problems, -, ';
    this.rows[0].skills.forEach(
      ({ totalProblemsCount }) => (csv += `${totalProblemsCount}, `)
    );
    csv += '\n';

    // Value rows
    this.rows.forEach((row) => {
      csv += `${row.student.displayName}, ${
        isNaN(row.averageScore) ? 0 : row.averageScore
      }, `;
      row.skills.forEach(({ value }) => (csv += `${value}, `));
      csv += '\n';
    });

    return csv;
  }

  downloadCsv(): void {
    const csv = this.generateCsv();

    // Create a hidden anchor
    const hiddenAnchor = document.createElement('a');

    // Set anchor attributes
    hiddenAnchor.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenAnchor.target = '_blank';
    hiddenAnchor.download = `${this.assignmentReportData?.contentInfo.xref}.csv`;

    // Click on the anchor to initiate the download
    hiddenAnchor.click();

    // Delete the anchor
    hiddenAnchor.remove();
  }

  created(): void {
    this.anonymizeNames =
      this.getCurrentUser.settings?.anonymizeReportsByDefault || false;

    if (this.skillIdToSkillMap.size === 0) {
      getSkills().then((skills) => {
        this.$store.commit('skillList/setSkillList', skills);
        this.reportReady = true;
      });
    } else {
      this.reportReady = true;
    }
  }
}
