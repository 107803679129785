
import { Component, Vue } from 'vue-property-decorator';
import { orderBy } from 'lodash';
import { ProblemSet } from '@/domain/ProblemSet';
import { Skill } from '@/domain/Skill';
import ProblemSetView from '@/components/MyProblemSets/ProblemSetView.vue';
import { ProblemSetFilterAndPagingParams } from '@/api/pspr.api';
import { CancelTokenSource } from 'axios';
import ScrollObserver from '@/components/base/ScrollObserver.vue';

@Component({
  components: {
    ProblemSetView,
    ScrollObserver,
  },
})
export default class MyProblemSetsPage extends Vue {
  downloading = false;
  selectedSkillId: number | null = null;
  downloadingSkills = false;

  // Filtering/paging
  limit = 20;
  source: CancelTokenSource | null;

  get params(): ProblemSetFilterAndPagingParams {
    const params: ProblemSetFilterAndPagingParams = {
      limit: this.limit,
    };
    return params;
  }

  get myProblemSets(): ProblemSet[] {
    return this.$store.getters['myps/getProblemSets'];
  }

  get filteredProblemSets(): ProblemSet[] {
    if (this.selectedSkillId) {
      const skillId = Number(this.selectedSkillId); //typescript yells if we omit this temp var
      return this.myProblemSets.filter((ps: ProblemSet) => {
        return ps.skillIds && ps.skillIds.includes(skillId);
      });
    }
    return this.myProblemSets;
  }
  get skills(): Skill[] {
    return this.$store.state.skillList.skills;
  }
  get skillsAreDownloaded(): boolean {
    return this.$store.state.skillList.skills.length > 0;
  }
  downloadSkills(): void {
    if (!this.skillsAreDownloaded && !this.downloadingSkills) {
      this.downloadingSkills = true;
      this.$store.dispatch('skillList/requestSkills').then(() => {
        this.downloadingSkills = false;
      });
    }
  }
  /**
   * Toggling Skeleton Loader for new PS when duplicating
   */
  duplicateInProgress = false;
  setDuplicating(val: boolean): void {
    this.duplicateInProgress = val;
  }
  // Currently selected sort order (defaults to created date descending)
  selectedSort: { attribute: keyof ProblemSet; direction: 'asc' | 'desc' } = {
    attribute: 'created',
    direction: 'desc',
  };
  // List of ways to sort
  sortOptions = [
    {
      text: 'Date Created (Descending)',
      value: { attribute: 'created', direction: 'desc' },
    },
    {
      text: 'Date Created (Ascending)',
      value: { attribute: 'created', direction: 'asc' },
    },
    // {
    //   text: 'Last Modified (Descending)',
    //   value: { attribute: 'lastModifiedByTeacher', direction: 'desc' },
    // },
    // {
    //   text: 'Last Modified (Ascending)',
    //   value: { attribute: 'lastModifiedByTeacher', direction: 'asc' },
    // },
    {
      text: 'Name (Descending)',
      value: { attribute: 'name', direction: 'desc' },
    },
    {
      text: 'Name (Ascending)',
      value: { attribute: 'name', direction: 'asc' },
    },
  ];

  /**
   * Returns new sorted array of the problem sets,
   * sorted acording to the selection made in the menu
   */
  get sortedProblemSets(): ProblemSet[] {
    return orderBy(
      this.filteredProblemSets,
      [
        (ps) => {
          const sortTarget = ps[this.selectedSort.attribute];
          return typeof sortTarget !== 'string'
            ? sortTarget
            : sortTarget.toLowerCase();
        },
      ],
      this.selectedSort.direction
    );
  }
  /**
   * Whether to show created date or last modified date
   * Shows whichever you're sorting by, if applicable
   * Defaults to created date when sorting by name etc
   */
  get dateToShow(): 'created' | 'lastModifiedByTeacher' {
    switch (this.selectedSort.attribute) {
      case 'created':
      case 'lastModifiedByTeacher':
        return this.selectedSort.attribute;
      default:
        return 'created';
    }
  }

  get nextPageToken(): string {
    return this.$store.state.myps.nextPageToken;
  }

  /**
   * Downloads My Problem Sets
   * saves them into this component's myProblemSets
   */
  fetchProblemSets(): void {
    if (this.downloading) {
      return;
    }

    this.downloading = true;
    this.$store
      .dispatch('myps/requestMyProblemSets', {
        params: this.params,
        cancelToken: this.source?.token,
      })
      .then(() => {
        this.downloading = false;
      });
  }
  created(): void {
    // Should already be logged in to be here
    this.fetchProblemSets();
  }
}
