
import dayjs from 'dayjs';
import { Component, Vue } from 'vue-property-decorator';
import {
  getAssignmentDefinition,
  getAssignmentReportData,
  deleteAssignmentProgress,
  getAssignmentAssignees,
} from '@/api/core/assignment.api';
import AssignmentBarChartViewForReport, {
  ProblemStats,
} from '@/components/Report/AssignmentBarChartViewForReport.vue';
import AssignmentProgressRow from '@/components/Report/AssignmentProgressRow.vue';
import ProblemSideSheetForReport from '@/components/Report/ProblemSideSheetForReport.vue';
import ProgressCard, {
  ProgressState,
} from '@/components/Report/ProgressCard.vue';
import AssignmentReportTable from '@/components/Report/AssignmentReportTable.vue';
import StandardsReportTable from '@/components/Report/StandardsReportTable.vue';
import NoDataView from '@/components/Report/NoDataView.vue';
import {
  AssignmentReportData,
  ProblemLogAndActions,
  StudentLog,
} from '@/domain/ReportData/AssignmentData';
import { AssignmentDefinition } from '@/domain/Assignment';
import { Problem, ProblemType } from '@/domain/Problem';
import { getProblemsByPsXref, getProblemSetByXref } from '@/api/pspr.api';
import { User } from '@/domain/User';
import { LmsProviderType } from '@/domain/LmsProviderType';
import { uploadAssignmentScores } from '@/api/assignment.api';
import { ProblemSet } from '@/domain/ProblemSet';
import SkillBuilderTable from '@/components/Report/SkillBuilderTable.vue';
import { mixpanel, AssignmentReportType, EventType } from '@/plugins/mixpanel';
import { isEmpty } from 'lodash';

@Component({
  components: {
    AssignmentBarChartViewForReport,
    AssignmentProgressRow,
    AssignmentReportTable,
    NoDataView,
    ProblemSideSheetForReport,
    ProgressCard,
    StandardsReportTable,
    SkillBuilderTable,
  },
})
export default class ReportLandingPage extends Vue {
  assignmentXref = '';
  assignmentDefinition: AssignmentDefinition | null = null;
  problems: Array<Problem> = [];
  assignees: Array<User> = [];
  assignmentReportData: AssignmentReportData | null = null;
  reportReady = false;
  problemSideSheet = false;
  selectedProblemIdIndex: number | null = null;
  uploadDialog = false;
  tabs = ['PROBLEMS', 'STANDARDS'];
  problemSet: ProblemSet | null = null;

  // Allows us access to the enum in the template.
  ProgressState = ProgressState;

  ///////////////
  // Return To //
  ///////////////
  returnToName = this.$route.query.returnToName;
  returnToPath = this.$route.query.returnToPath;

  ////////////////////
  // Loading States //
  ////////////////////
  uploadingScores = false;

  /////////////////////////////////////
  // What table should be rendered? //
  ///////////////////////////////////
  reportToBeRendered = 'PROBLEMS';

  get lmsProviderType(): LmsProviderType | null {
    if (this.assignmentDefinition) {
      return this.assignmentDefinition.lmsProviderType;
    }

    return null;
  }

  get isInTestMode(): boolean {
    let ret = false;

    if (this.assignmentDefinition?.properties) {
      const correctnessFeedback = this.assignmentDefinition.properties.find(
        (p) => p.propertyKey === 'correctnessFeedback'
      );
      const tutoringAccessible = this.assignmentDefinition.properties.find(
        (p) => p.propertyKey === 'tutoringAccessible'
      );

      //if the properties exist then make sure they are set to false. If they do not exist or are not set to false then they are not in test mode
      if (correctnessFeedback && tutoringAccessible) {
        ret =
          correctnessFeedback.propertyValue === 'false' &&
          tutoringAccessible.propertyValue === 'false';
      }
    }
    return ret;
  }

  get idToProblemMap(): Map<number, Problem> {
    const res = new Map();
    for (const problem of this.problems) {
      res.set(problem.id, problem);
    }
    return res;
  }

  get xrefToStudentMap(): Map<string, User> {
    const res = new Map();
    for (const student of this.assignees) {
      res.set(student.xref, student);
    }
    return res;
  }

  get beyondDaysMap(): Map<string, string> {
    const res = new Map();
    for (const student of this.assignees) {
      res.set(student.xref, this.calculateOverdue(student.xref));
    }
    return res;
  }

  get isSkillBuilder(): boolean {
    return this.problemSet?.isSkillBuilder || false;
  }

  get isCourseAssignment(): boolean {
    return (
      this.assignmentDefinition?.assigneeXref ==
      this.assignmentDefinition?.groupContextXref
    );
  }

  get downloadCSVUrl(): string {
    return `${process.env.VUE_APP_TNG_URL}/getReportCSV/${this.assignmentXref}`;
  }

  get problemIdToProblemStats(): Map<number, ProblemStats> {
    const res = new Map();

    if (this.assignmentReportData) {
      this.assignmentReportData?.studentLogs.forEach(
        (studentLog: StudentLog) => {
          if (studentLog.problemLogAndActions) {
            const problemLogAndActions: Array<ProblemLogAndActions> =
              studentLog.problemLogAndActions;
            problemLogAndActions.forEach((plas: ProblemLogAndActions) => {
              const problemId: number = plas.prLog.problemDbid;
              let problemStats: ProblemStats = res.get(problemId);
              if (!problemStats) {
                // Initialize
                problemStats = {
                  numCorrect: 0,
                  numCorrectEventually: 0,
                  numIncorrect: 0,
                };
              }
              const problem = this.idToProblemMap.get(problemId);
              // Not an open-ended response so we have a score that can
              // be used to determine category
              if (problem && problem.type != ProblemType.OPEN_RESPONSE) {
                const score = plas.prLog.continuousScore;
                // Only count student if their score is given
                if (score || score == 0) {
                  // Perfect Score
                  if (score >= 1) {
                    problemStats.numCorrect += 1;
                  }
                  // Correct Eventually (partial credit)
                  else if (!plas.prLog.sawAnswer) {
                    problemStats.numCorrectEventually += 1;
                  }
                  // Incorrect (answer requested)
                  else {
                    problemStats.numIncorrect += 1;
                  }
                }
              }
              res.set(problemId, problemStats);
            });
          }
        }
      );
    }
    return res;
  }

  get previewLink(): string | null {
    const usr = this.getCurrentUser;
    if (!this.assignmentXref) {
      return null;
    }
    return `${this.$store.state.urls.tutorTemplateUrl}?assignmentRef=${this.assignmentXref}&preview=true&pr=TNG&ut=${usr.userToken}&laId=${usr.loginAliasId}`;
  }

  /////////////
  // Methods //
  /////////////
  getTimeStamp(unixTimeInMs: number): string {
    // Parse UNIX timestamp in ms
    const date = dayjs(unixTimeInMs);
    return date.format('MMM D, h:mma');
  }

  uploadGradesToLms(): void {
    if (this.lmsProviderType) {
      this.uploadingScores = true;
      uploadAssignmentScores(this.assignmentXref, this.lmsProviderType)
        .then(() => {
          this.uploadDialog = false;
          this.$notify(
            `Uploaded scores to ${this.lmsProviderType?.displayName} successfully.`
          );
        })
        .catch((error) => {
          if (error.statusCode === 417) {
            // Needs login
            error.handleGlobally &&
              error
                .handleGlobally()
                .then(() => {
                  this.$notify('Authorization updated. Please try again.');
                })
                .catch(() => {
                  // Something wrong going to the LP
                  this.$notify(
                    'Failed authentication and authorization in login portal.'
                  );
                });
          } else {
            // Cannot be handled by authentication
            this.$notify(
              `Failed to upload scores to ${this.lmsProviderType?.displayName}.` +
                ' This will occur if no students have completed the assignment.' +
                ' Please make sure at least one student has scores to upload and try again.'
            );
          }
        })
        .finally(() => {
          this.uploadingScores = false;
        });
    }
  }

  openProblemSideSheet(problemId: number): void {
    // Set selected problem id index
    this.selectedProblemIdIndex = this.findIndexOfProblemId(problemId);
    // Open problem side sheet with updated problem data
    this.problemSideSheet = true;
  }

  setPreviousProblem(): void {
    // Set selected problem id to previous problem id
    if (
      this.selectedProblemIdIndex != null &&
      this.selectedProblemIdIndex > 0
    ) {
      this.selectedProblemIdIndex -= 1;
    }
  }

  setNextProblem(): void {
    // Set selected problem id to next problem id
    if (
      this.selectedProblemIdIndex != null &&
      this.selectedProblemIdIndex < this.problems.length - 1
    ) {
      this.selectedProblemIdIndex += 1;
    }
  }

  findIndexOfProblemId(problemId: number): number {
    return this.problems.findIndex((problem: Problem) => {
      return problem.id == problemId;
    });
  }

  deleteStudentProgress(studentXref: string): void {
    deleteAssignmentProgress(this.assignmentXref, studentXref).then(() => {
      this.reportReady = false;
      getAssignmentReportData(this.assignmentXref)
        .then((assignmentReportData: AssignmentReportData) => {
          if (!isEmpty(assignmentReportData)) {
            this.assignmentReportData = assignmentReportData;
          }
        })
        .finally(() => {
          this.reportReady = true;
        });
    });
  }

  async created(): Promise<void> {
    this.assignmentXref = this.$route.params.xref;
    const outerPromises: Promise<void>[] = [];
    const innerPromises: Promise<void>[] = [];

    // Load assignment definition
    const assignmentDefinitionPromise = getAssignmentDefinition(
      this.assignmentXref,
      {
        details: true,
      }
    ).then((assignmentDefinition: AssignmentDefinition) => {
      this.assignmentDefinition = assignmentDefinition;
      const problemSetXref = this.assignmentDefinition.problemSetCeri;
      // Problem set
      const problemSetPromise = getProblemSetByXref(problemSetXref).then(
        (problemSet) => {
          this.problemSet = problemSet;
        }
      );
      innerPromises.push(problemSetPromise);
      // Problems
      const problemsPromise = getProblemsByPsXref(problemSetXref, [
        'ANSWERS',
        'ATTRIBUTIONS',
        'SKILLS',
      ]).then((problems: Array<Problem>) => {
        this.problems = problems;
      });
      innerPromises.push(problemsPromise);

      // Load assignees
      const assigneePromise = getAssignmentAssignees(
        this.assignmentDefinition.xref
      )
        .then((assignees: Array<User>) => {
          this.assignees = assignees;
        })
        .catch(() => {
          // 404 - No assignees found
          this.assignees = [];
        });

      innerPromises.push(assigneePromise);
    });
    outerPromises.push(assignmentDefinitionPromise);
    // Load report data
    const reportDataPromise = getAssignmentReportData(this.assignmentXref)
      .then((assignmentReportData: AssignmentReportData) => {
        if (!isEmpty(assignmentReportData)) {
          this.assignmentReportData = assignmentReportData;
        }
      })
      .catch((error) => {
        if (error.statusCode === 404) {
          this.assignmentReportData = null;
        }
      });
    outerPromises.push(reportDataPromise);

    try {
      await Promise.all(outerPromises);
      await Promise.all(innerPromises);
      this.reportReady = true;
      this.reportLoaded(this.assignmentXref);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      error.handleGlobally && error.handleGlobally();
    }
  }

  reportLoaded(clickedAssignmentXref: string): void {
    let reportType = '';
    if (this.problemSet?.isSkillBuilder) {
      reportType = AssignmentReportType.masteryReport;
    } else {
      reportType = AssignmentReportType.assignmentReport;
    }
    mixpanel.track(EventType.reportViewed, {
      distinct_id: this.getCurrentUser.xref,
      assignmentXref: clickedAssignmentXref,
      reportType: reportType,
    });
  }

  calculateOverdue(studentXref: string) {
    if (!this.assignmentDefinition?.dueDate) {
      return '';
    }
    let endDate = +new Date();
    if (this.assignmentReportData && this.assignmentDefinition?.dueDate) {
      for (const studentLog of this.assignmentReportData.studentLogs) {
        if (
          studentXref === studentLog.studentXref &&
          studentLog.problemLogAndActions
        ) {
          endDate = studentLog.asEndTime ? studentLog.asEndTime : +new Date();
          break;
        }
      }
    }
    const overdueMs = dayjs(endDate).diff(
      dayjs(this.assignmentDefinition?.dueDate),
      'millisecond'
    );
    // Get 1 day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;
    const overdueDays = Math.round(overdueMs / oneDay);
    if (overdueMs < 0) {
      return '';
    } else if (overdueDays === 0) {
      return 'Less than 1 day late';
    } else if (overdueDays === 1) {
      return overdueDays.toString() + ' day overdue';
    } else {
      return overdueDays.toString() + ' days overdue';
    }
  }
}
