
import { Component, Vue, Prop } from 'vue-property-decorator';
import { TimeFrame } from '@/domain/Time';
import dayjs from 'dayjs';

interface TimeOption {
  text: string;
  timeFrame: TimeFrame;
}

@Component
export default class WeeklySelect extends Vue {
  @Prop() numWeeksBack!: number;
  @Prop({ default: false }) filled: boolean;
  @Prop({ default: null }) value: TimeFrame | null;

  dateTimeFormat = 'DD MMM, YYYY';

  set timeOptionSelected(option: TimeOption) {
    this.selectedTimeFrame = option;
  }

  get timeOptionSelected(): TimeOption {
    if (this.selectedTimeFrame) {
      return this.selectedTimeFrame;
    }

    // Default to recent week
    this.selectedTimeFrame = this.weekOptions[0];

    return this.selectedTimeFrame;
  }

  set selectedTimeFrame(option: TimeOption | null) {
    const timeFrame = option ? option.timeFrame : null;

    this.$emit('input', timeFrame);
  }

  get selectedTimeFrame(): TimeOption | null {
    return this.getWeekOption(this.value);
  }

  get selectLabel(): string {
    let label = 'Weekly View';

    if (
      this.selectedTimeFrame &&
      dayjs().diff(
        dayjs(this.selectedTimeFrame.timeFrame.startDateTime),
        'day'
      ) === 7
    ) {
      return label + ' (Last 7 days)';
    }

    return label;
  }

  get weekOptions(): Array<TimeOption> {
    const weeks: Array<TimeOption> = [];

    let endAt = dayjs(); // Now

    while (weeks.length < this.numWeeksBack) {
      let startAt = endAt.subtract(7, 'day'); // 7 days ago

      weeks.push({
        text: this.getDisplayDateRange(startAt, endAt),
        timeFrame: {
          // When we run intervals, we just do inclusive start and exclusive end.
          // [start, end)
          startDateTime: this.getDateTimeAtMidnight(startAt),
          //Adding a day here will mean that we display the end day as Feb 25th but use Feb 26 12AM as the actual end marker (We will include all of Feb 25th)
          endDateTime: this.getDateTimeAtMidnight(endAt.add(1, 'day')),
        },
      });

      endAt = startAt.subtract(1, 'day');
    }

    return weeks;
  }

  getWeekOption(timeFrame: TimeFrame | null): TimeOption | null {
    if (timeFrame) {
      const targetOption = this.weekOptions.find((option) => {
        return (
          option.timeFrame.startDateTime === timeFrame.startDateTime &&
          option.timeFrame.endDateTime === timeFrame.endDateTime
        );
      });

      if (targetOption) {
        return targetOption;
      }

      return null;
    }

    return null;
  }

  getDateTimeAtMidnight(startAt: dayjs.Dayjs): number {
    // Start at 12:00 AM
    let startDateTime = dayjs(`${startAt.format('YYYY-MM-DD')} 00:00`);

    return startDateTime.valueOf();
  }

  getDisplayDateRange(startAt: dayjs.Dayjs, endAt: dayjs.Dayjs): string {
    return (
      startAt.format(this.dateTimeFormat) +
      ' - ' +
      endAt.format(this.dateTimeFormat)
    );
  }
}
