
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AssignmentDefinition, AssignmentDetail } from '@/domain/Assignment';
import { Skill } from '@/domain/Skill';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { ProblemSet } from '@/domain/ProblemSet';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);

@Component
export default class AssignmentDetailView extends Vue {
  @Prop({ required: true }) assignment: AssignmentDefinition;
  @Prop({ required: true }) problemSet: ProblemSet;
  @Prop({ required: true }) details: AssignmentDetail;

  placeholder = '-----';

  //////////////
  // Computed //
  //////////////

  /**
   * Store
   */
  get skills(): Array<Skill> {
    return this.$store.state.skillList.skills;
  }
  /**
   * Display & Rendering
   */
  get matchedSkills(): Array<Skill> {
    const skillIds = this.problemSet.skillIds;

    if (skillIds) {
      return this.skills.filter((skill) => {
        return skillIds.includes(Number(skill.id));
      });
    }

    return [];
  }
  get releaseDate(): string {
    const releaseDate = this.assignment.releaseDate;

    return this.getDate(releaseDate);
  }
  get dueDate(): string {
    const dueDate = this.assignment.dueDate;

    if (dueDate) {
      return this.getDate(dueDate);
    }

    return this.placeholder;
  }
  /**
   * Navigation
   */
  get skillBuilderReportUrl(): string {
    return `${process.env.VUE_APP_TNG_URL}/masteryreport/${this.assignment.xref}?hideNav=true`;
  }

  /////////////
  // Methods //
  /////////////

  getDate(ms: number): string {
    // Parse UNIX timestamp in ms
    const date = dayjs(ms);
    const formattedDate = date.format('dddd, MMM DD');

    var now = dayjs();
    var reminderNote = '';

    if (date.isToday()) {
      reminderNote = '(Today)';
    } else if (date.isTomorrow()) {
      reminderNote = '(Tomorrow)';
    } else if (date.isAfter(now)) {
      // In the future
      var distant = date.diff(now, 'day');

      reminderNote = '(in ' + distant + ' Days)';
    }

    return formattedDate + ' ' + reminderNote;
  }
  created(): void {
    // Download skills if not already
    // if (this.$store.state.skillList.skills.length === 0) {}
    // if (!this.$store.state.skillList.hasDownloaded && !this.$store.state.skillList.isDownloading) {}
    // If a list of all classes is missing from store, be the first instance to request it
    // Will be prevented to download again in store if already
    this.$store.dispatch('skillList/requestSkills');
  }
}
