import { Settings } from './../../domain/Settings';
import { MutationTree } from 'vuex';
import { AuthenticatedUser, UserAttributes } from '@/domain/User';
import { AuthState } from './types';

export const mutations: MutationTree<AuthState> = {
  setUser(state, user: AuthenticatedUser) {
    state.user = user;
  },
  setSettings(state, settings: Settings) {
    if (state.user) {
      state.user.settings = settings;
    }
  },
  updateSettings(state, settings: Partial<Settings>) {
    if (state.user) {
      let userSettings = state.user.settings || {};
      // Combine them by overriding updated settings only
      // Also to create a new copy so that we don't save the reference
      // to the given new settings
      userSettings = {
        ...userSettings,
        ...settings,
      };
      state.user.settings = userSettings as unknown as Settings;
    }
  },
  updateAttributes(state, attributes: Partial<UserAttributes>) {
    if (state.user) {
      let attr = state.user.attributes || {};
      //combine them. Use attributes to override attr
      attr = {
        ...attr,
        ...attributes,
      };
      state.user.attributes = attr;
    }
  },

  setIsInitializing(state, isInitializing: boolean) {
    state.isInitializing = isInitializing;
  },
  setHasInitialized(state, hasInitialized: boolean) {
    state.hasInitialized = hasInitialized;
  },
};
