
//Please refactor all of this code because a TON of it is copy-pasted from lessonPage.
import ProblemListView from '@/components/base/ProblemListView.vue';
import { ParentPage } from '@/components/base/ProblemViewMenu.vue';
import AssignBottomBar from '@/components/FindProblems/AssignBottomBar.vue';
import ProblemSetMenu from '@/components/MyProblemSets/ProblemSetMenu.vue';
import { ProblemSet } from '@/domain/ProblemSet';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  getMyProblemSetById,
  getProblemsByPsIdGroupedByAssistmentId,
  updateProblemSet,
} from '@/api/pspr.api';
import { Problem } from '@/domain/Problem';

@Component({
  components: {
    ProblemListView,
    AssignBottomBar,
    ProblemSetMenu,
  },
})
export default class EditMyProblemSetPage extends Vue {
  //If we can figure out how to get reactive objects that we don't need to mark as Object | null
  // Then we can get away from this "as unknown as Object" garbage.
  psId = 0;

  problems: Problem[][] = [];
  problemListReady = false;

  selectedProblemIds: number[] = [];

  removeProblemIndices: number[] = [];
  removeProblemXref = '';
  removeDialog = false;
  parentPage: ParentPage = ParentPage.MY_PROBLEM_SETS;

  get problemSet(): ProblemSet {
    return this.$store.getters['myps/getProblemSets'].find(
      (ps: ProblemSet) => ps.id === this.psId
    );
  }

  moveUp(astId: number): void {
    this.move(astId, -1);
  }

  moveDown(astId: number): void {
    this.move(astId, 1);
  }

  move(astId: number, indexChange: number): void {
    const index = this.findIndexOfAssistmentId(astId);

    if (index === -1) {
      return;
    }

    const target = index + indexChange;
    if (target < 0 || target >= this.problems.length) {
      //do nothing again.
      return;
    }

    this.problems.splice(target, 0, this.problems.splice(index, 1)[0]);
    updateProblemSet(this.problemSet.id, {
      assistmentIds: this.problems.map((pr) => {
        return pr[0].assistmentId;
      }),
    });
  }

  showRemoveDialog(astId: number | null): void {
    this.removeProblemIndices = [];
    this.removeProblemXref = '';
    if (astId) {
      const index = this.findIndexOfAssistmentId(astId);
      this.removeProblemIndices = [index];
      this.removeProblemXref = this.problems[index][0].xref;
    } else {
      //No astid means use the selected problem ids
      let index;
      for (let numId of this.selectedProblemIds) {
        index = this.findIndexOfAssistmentId(numId);
        this.removeProblemIndices.push(index);
      }
    }

    this.removeDialog = true;
  }

  get removeProblemText(): string {
    if (this.removeProblemXref) {
      return this.removeProblemXref;
    } else {
      let addS = this.removeProblemIndices.length > 1 ? 's' : '';
      return `${this.removeProblemIndices.length} Problem${addS}`;
    }
  }

  remove(): void {
    if (this.removeProblemIndices.length === 0) {
      return;
    }

    if (this.removeProblemIndices.length === 1) {
      this.problems.splice(this.removeProblemIndices[0], 1);
    } else {
      this.problems = this.problems.filter((prArr, index) => {
        return !this.removeProblemIndices.includes(index);
      });
    }
    this.removeDialog = false;
    updateProblemSet(this.problemSet.id, {
      assistmentIds: this.problems.map((pr) => {
        return pr[0].assistmentId;
      }),
    });
  }

  findIndexOfAssistmentId(assistmentId: number): number {
    return this.problems.findIndex((prArr: Problem[]) => {
      return prArr[0].assistmentId == assistmentId;
    });
  }

  get numTotalProblems(): number {
    return this.problems.length;
  }

  psDeleted(): void {
    this.$router.push({
      name: 'myProblemSets',
    });
  }

  psDuplicated(psXref: string): void {
    const ps = this.$store.getters['myps/getProblemSets'].find(
      (ps: ProblemSet) => {
        return ps.xref === psXref;
      }
    );
    if (ps) {
      this.$router.push({
        name: 'editMyPS',
        params: {
          id: ps.id,
        },
      });
    } else {
      this.$router.push({ name: 'myProblemSets' });
    }
  }

  @Watch('$route.params.id')
  onPSIDChange(value: string, oldValue: string): void {
    if (value === oldValue) {
      return;
    }
    this.psId = 0;
    this.problems = [];
    this.problemListReady = false;

    this.selectedProblemIds = [];

    this.removeProblemIndices = [];
    this.removeProblemXref = '';
    this.removeDialog = false;
    this.initialize();
  }

  initialize(): void {
    this.psId = Number(this.$route.params.id);

    if (!this.problemSet) {
      /**
       * If the problem set cannot be found in the store,
       * attempt downloading the problem set.
       *
       * If the problem set cannot be found at all,
       * redirect the user to the my problem sets page.
       */
      getMyProblemSetById(this.psId)
        .then((ps) => {
          this.$store.commit('myps/addProblemSet', ps);
        })
        .catch(() => {
          this.$router.push({ name: 'myProblemSets' });
        });
    }
    getProblemsByPsIdGroupedByAssistmentId(this.psId, [
      'ANSWERS',
      'PARENT_PROBLEM_SETS',
      'ATTRIBUTIONS',
      'SKILLS',
    ]).then((problems) => {
      this.problems = problems;
      this.problemListReady = true;
    });
  }

  created(): void {
    this.initialize();
  }
}
