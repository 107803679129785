
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import NotFoundView from '@/components/base/NotFoundView.vue';
import ProblemSideSheet, {
  ProblemDetail,
} from '@/components/LDOE/TutoringProblemSets/ProblemSideSheet.vue';
import ScoreChip from '@/components/Report/ScoreChip.vue';
import ScoringKeyDialog from '@/components/Report/ScoringKeyDialog.vue';
import StudentActionsMenu from '@/components/LDOE/TutoringProblemSets/StudentActionsMenu.vue';
import {
  ProblemData,
  StudentDataPerAssignmentProblem,
  StudentDataPerAssignmentWithProblemData,
  TargetAssignmentStudentAndClassDataWithProblems,
} from '@/domain/ReportData/Cignition';
import { Problem } from '@/domain/Problem';
import { User } from '@/domain/User';
import { appendGlobalHeaderOptions } from '@/utils/dataTables.utils';

export interface DynamicTableCols {
  [problemId: number]: number | null;
}

export interface StaticTableCols {
  name: string;
  averageScore: number | null;
}

type TableRow = { xref?: string } & StaticTableCols & DynamicTableCols;

@Component({
  components: {
    NotFoundView,
    ProblemSideSheet,
    ScoreChip,
    ScoringKeyDialog,
    StudentActionsMenu,
  },
})
export default class AssignmentTable extends Vue {
  @Prop({ required: true })
  canonicalAssignmentReportData: TargetAssignmentStudentAndClassDataWithProblems;

  //////////
  // Data //
  //////////
  displaySideSheet = false;
  targetProblemId = 0;

  get hasData(): boolean {
    return this.canonicalAssignmentReportData ? true : false;
  }

  get problemDetails(): { [problemId: number]: ProblemDetail } {
    const problemIdToDetails: { [problemId: number]: ProblemDetail } = {};

    if (this.canonicalAssignmentReportData) {
      this.canonicalAssignmentReportData.problems.forEach(
        (problem: Problem) => {
          // Problem content
          problemIdToDetails[problem.id] = {
            problem: problem,
            studentData: {},
          };
        }
      );

      // Class data
      this.canonicalAssignmentReportData.reportData.classProblemData.forEach(
        (classData: ProblemData) => {
          problemIdToDetails[classData.problemId].classData = classData;
        }
      );

      // Student data
      this.canonicalAssignmentReportData.reportData.studentData.forEach(
        (studentData: StudentDataPerAssignmentWithProblemData) => {
          studentData.studentProblemData.forEach(
            // Problem data
            (problemData: StudentDataPerAssignmentProblem) => {
              problemIdToDetails[problemData.problemId].studentData[
                studentData.studentXref
              ] = problemData;
            }
          );
        }
      );
    }

    return problemIdToDetails;
  }

  ///////////
  // Store //
  ///////////

  get selectedTutees(): Array<User> {
    return this.$store.getters['ldoe/getSelectedTutees'];
  }

  /////////////////////////////
  // Assignment Report Table //
  /////////////////////////////
  staticHeaders: Array<DataTableHeader> = [
    { text: 'Student Name/Problem', value: 'name', align: 'start' },
    { text: 'Average Score', value: 'averageScore' },
  ];

  get problemHeaders(): Array<DataTableHeader> {
    if (this.canonicalAssignmentReportData) {
      // Order of problem set
      return this.canonicalAssignmentReportData.problems.map(
        (problem: Problem) => {
          return {
            text: this.getProblemText(problem),
            value: String(problem.id),
          };
        }
      );
    }

    return [];
  }

  get headers(): Array<DataTableHeader> {
    return [
      ...this.staticHeaders.map(appendGlobalHeaderOptions),
      ...this.problemHeaders.map(appendGlobalHeaderOptions),
    ];
  }

  get problemAverages(): TableRow {
    const classAverageScores: TableRow = {
      name: 'Problem Average',
      averageScore: null,
    };

    if (this.canonicalAssignmentReportData) {
      // Assignment average
      classAverageScores.averageScore = this.getScore(
        this.canonicalAssignmentReportData.reportData.classAverage
      );

      this.canonicalAssignmentReportData.reportData.classProblemData.forEach(
        // Problem class average
        (problemData: ProblemData) => {
          // Can be null
          classAverageScores[problemData.problemId] = this.getScore(
            problemData.averageScore
          );
        }
      );
    }

    return classAverageScores;
  }

  get studentData(): Array<TableRow> {
    const data: Array<TableRow> = [];

    if (this.canonicalAssignmentReportData) {
      this.canonicalAssignmentReportData.reportData.studentData.forEach(
        (studentData: StudentDataPerAssignmentWithProblemData) => {
          const student = this.findStudentUserByXref(studentData.studentXref);

          if (student) {
            const tableRow: TableRow = {
              xref: student?.xref,
              name: student ? student.displayName : '',
              // Can be null
              averageScore: this.getScore(studentData.averageScore),
            };

            studentData.studentProblemData.forEach(
              (problemData: StudentDataPerAssignmentProblem) => {
                // Can be null
                tableRow[problemData.problemId] = this.getScore(
                  problemData.score
                );
              }
            );

            data.push(tableRow);
          }
        }
      );
    }

    return data;
  }

  /////////////
  // Methods //
  /////////////

  getProblemText(problem: Problem): string {
    // FIXME: How do we determine it is part 1 or part 2?
    const problemXref = problem.xref;

    if (problem.partLetter != null) {
      // Is a subproblem
      return problemXref + ': ' + problem.partLetter;
    }

    return problemXref;
  }

  getScore(averageScore?: number): number | null {
    return averageScore != undefined ? Math.floor(averageScore * 100) : null;
  }

  getDisplayScore(score: number | null): string {
    return score != null ? score + '%' : '-';
  }

  findStudentUserByXref(xref: string): User | null {
    const userFound = this.selectedTutees.find((tutee) => tutee.xref === xref);

    if (userFound) {
      return userFound;
    }

    return null;
  }

  getProblemMap(problems: Array<Problem>): { [id: number]: Problem } {
    let problemMap: { [id: number]: Problem } = {};

    problems.forEach((problem: Problem) => {
      problemMap[problem.id] = problem;
    });

    return problemMap;
  }

  openProblemSideSheet(problemId?: number): void {
    if (problemId) {
      this.targetProblemId = problemId;
    }

    this.displaySideSheet = true;
  }
}
