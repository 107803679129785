
import { Skill } from '@/domain/Skill';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AccelerateInformationView extends Vue {
  @Prop({ required: true }) assignmentLessonDetails: string;

  ///////////
  // Store //
  ///////////

  get skills(): Array<Skill> {
    return this.$store.state.skillList.skills;
  }

  /////////////
  // Methods //
  /////////////

  findSkills(skillIds: Array<number>): Array<Skill> {
    return this.skills.filter((skill) => {
      return skillIds.includes(Number(skill.id));
    });
  }

  created(): void {
    // Download skills if not already
    // Will be prevented to download again in store if already
    this.$store.dispatch('skillList/requestSkills');
  }
}
