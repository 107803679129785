
import { Component, Vue } from 'vue-property-decorator';
import { Skill } from '@/domain/Skill';
import SearchPsByPsaDialog from '@/components/FindProblems/FindProblemsPage/SearchPsByPsaDialog.vue';

@Component({
  components: {
    SearchPsByPsaDialog,
  },
})
export default class FindProblemsTopBar extends Vue {
  dense = false;
  isDownloading = false;
  isDownloaded = false;
  get skills(): Array<Skill> {
    return this.$store.state.skillList.skills;
  }
  get selectedSkillId(): number | null {
    const skillId = this.$store.getters['skillList/getSelectedSkill'];
    return skillId ? skillId : null;
  }

  /**
   * Request skills from server and store them in this.skills
   * Does nothing if run a second time (once downloaded)
   */
  showSkills(): void {
    if (!this.isDownloaded && !this.isDownloading) {
      this.isDownloading = true;
      this.$store.dispatch('skillList/requestSkills').then(() => {
        this.isDownloaded = true;
        this.isDownloading = false;
      });
    }
  }

  /**
   * Navigates to search-skills problem view with id of chosen skill
   */
  selectSkill(skillId: string): void {
    // Does nothing if skill was cleared
    if (skillId) {
      this.$store.commit('search/resetProblemSearch');
      this.$store.commit('skillList/setSelectedSkill', skillId);
      this.$router.push({
        name: 'searchProblems',
        params: {
          skillId,
        },
      });
    }
  }

  openAndFocus(): void {
    this.dense = false;
    //I'd rather this be marked "as VTextField" but that doesn't work apparently.
    //So for now this comment will do.
    this.$nextTick(() => (this.$refs.searchBar as HTMLElement).focus());
  }

  mounted(): void {
    this.$store.commit('skillList/setSelectedSkill', null);
  }
}
