import { tngAxios } from '@/plugins/axios';
import { Class, LmsClass, ClassWrapper, ImportFilter } from '@/domain/Class';
import { LmsProviderType } from '@/domain/LmsProviderType';

const END_POINT = '/classes';

//////////
// DTOs //
//////////

// Imported CAS Course
export interface ClassDTO {
  id: string;
  name: string;
  lmsPtype: number;
}

// Unimported LMS Course
export interface LmsClassDTO {
  id: string;
  name: string;
  lmsPtype: number;
}

interface getClassesInterface {
  lmsid?: number;
  importFilter?: ImportFilter;
}

const getClasses = ({
  lmsid,
  importFilter,
}: getClassesInterface): Promise<ClassWrapper> => {
  return tngAxios
    .get(`${END_POINT}`, {
      params: { lmsPtype: lmsid, importFilter: importFilter },
      timeout: 10000,
    })
    .then((result) => {
      const ret: ClassWrapper = {};
      if (result.data.importedClasses) {
        ret.importedClasses = result.data.importedClasses.map((dto: ClassDTO) =>
          buildClass(dto)
        );
      }
      if (result.data.unimportedClasses) {
        ret.unimportedClasses = result.data.unimportedClasses.map(
          (dto: LmsClassDTO) => buildLmsClass(dto)
        );
      }
      return ret;
    });
};

const importClasses = (
  lmsClasses: LmsClass[],
  lmsPtype: LmsProviderType
): Promise<void> => {
  const lmsClassDTOs = lmsClasses.map(transformLmsClassToDTO);
  return tngAxios.post(
    `${END_POINT}/import?lmsPtype=${lmsPtype.id}`,
    lmsClassDTOs
  );
};

const synchronize = (lmsid: number): Promise<void> => {
  return tngAxios.post(`${END_POINT}/sync`, null, {
    params: { lmsPtype: lmsid },
  });
};

function buildClass(classDto: ClassDTO): Class {
  return {
    id: classDto.id,
    name: classDto.name,
    lmsType: LmsProviderType.find(classDto.lmsPtype),
  };
}

function buildLmsClass(lmsClassDto: LmsClassDTO): LmsClass {
  return {
    id: lmsClassDto.id,
    name: lmsClassDto.name,
    lmsType: LmsProviderType.find(lmsClassDto.lmsPtype),
  };
}

function transformLmsClassToDTO(lmsClass: LmsClass): LmsClassDTO {
  const { lmsType, ...rest } = lmsClass;
  return {
    lmsPtype: lmsType ? lmsType.id : 0,
    ...rest,
  };
}

export { getClasses, importClasses, synchronize };
