import baseAxios, { AxiosError } from 'axios';
import qs from 'qs';
import router from '@/router/index';

//Axios retry stuff.  https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/

// import {notifier} from './util';

interface ComposedError {
  readonly message: string;
  readonly error: AxiosError;
  handleGlobally(): void | Promise<void>;
  getError(): AxiosError;
}

class ComposedAjaxError implements ComposedError {
  name = 'ComposedAjaxError';
  //Message to display
  public message: string;
  //The original error
  public readonly error: AxiosError;
  //The status code. Null if there wasn't a response (we error'd before the response got to us)
  public statusCode: number | null;

  private _globallyHandled = false;

  constructor(error: AxiosError) {
    this.error = error;
    this.statusCode = this.error.response ? this.error.response.status : null;

    switch (this.statusCode) {
      case 401: {
        this.message = 'Please login to access this resource';
        break;
      }
      case 403: {
        this.message = 'Unauthorized to access this resource';
        break;
      }
      case 404: {
        this.message =
          'The requested resource does not exist or has been deleted';
        break;
      }
      case 417: {
        //expectation failed
        this.message =
          'We need to return to the loginPortal to reconnect with the LMS';
        break;
      }
      default: {
        this.message = 'Something went wrong and request was not completed';
      }
    }
  }

  public getError(): AxiosError {
    return this.error;
  }

  public async handleGlobally(): Promise<void> {
    if (this._globallyHandled) return;
    this._globallyHandled = true;

    //Promise to represent the new LP popup window.
    let LPPromise: Promise<void> | undefined = undefined;

    //Given a 417 we need to go the LP to re-login.
    if (this.statusCode == 417) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errorResponse = (this.error as AxiosError<any, any>).response;
      if (errorResponse?.data.destination) {
        LPPromise = openLP(errorResponse?.data.destination);
      }
    }

    // Given a 403 we need to go to the unauthorized page (since we have router guard in place
    // to check for unauthentication)
    else if (this.statusCode == 403) {
      router.push('/403');
    }
    // Given a 404 we need to go to the not found page
    else if (this.statusCode == 404) {
      router.push('/404');
    }

    if (LPPromise) {
      await LPPromise;
    }
    // notifier.error(this.message);
  }
}

const openLP = (url: string): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const destination = new URL(url);
    const callbackURL = `${destination.origin}/close`;
    destination.searchParams.set('callbackURL', callbackURL);
    const win = window.open(
      destination.toString(),
      '_blank',
      'location=no,height=600,width=600,status=no'
    );
    let timerRunning = true;
    const timer = setInterval(() => {
      // do not use interceptors for this stuff.use the interceptor to add a global handler method to errors that we want to handle globally and have each catch method call these global handlers in the right spot
      // read about vue global error handling
      if (timerRunning && (!win || win.closed)) {
        clearInterval(timer);
        timerRunning = false;
        if (_determineWindowBlocked(win)) {
          reject();
        } else {
          resolve();
        }
      }
    }, 100);
  });
};

const _determineWindowBlocked = (win: Window | null): boolean => {
  let result = false;

  try {
    if (win == null || typeof win == 'undefined') {
      result = true;
    } else if (win && win.closed) {
      result = false;
    } else {
      // Else we'll assume the window is not OK
      result = true;
    }
  } catch (err) {
    result = true;
  }

  return result;
};

baseAxios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, {
    arrayFormat: 'repeat',
  });
};

const tngAxios = baseAxios.create({
  baseURL: process.env.VUE_APP_TNG_API,
  // timeout: 5000, // 5 second timeout. Figure out a good/better one
  headers: {
    'Content-Type': 'application/json',
    // anything you want to add to the headers
  },
  withCredentials: true,
});

const cignitionAxios = baseAxios.create({
  baseURL: process.env.VUE_APP_CIGNITION_API,
  // timeout: 5000, // 5 second timeout. Figure out a good/better one
  headers: {
    'Content-Type': 'application/json',
    // anything you want to add to the headers
  },
  withCredentials: true,
});

const coreAxios = baseAxios.create({
  baseURL: process.env.VUE_APP_ASSISTMENTS_API,
  // timeout: 5000, // 5 second timeout. Figure out a good/better one
  headers: {
    'Content-Type': 'application/json',
    // anything you want to add to the headers
  },
  withCredentials: true,
});

const loginPortalAxios = baseAxios.create({
  baseURL: process.env.VUE_APP_LOGIN_PORTAL_API,
  // timeout: 5000, // 5 second timeout. Figure out a good/better one
  headers: {
    'Content-Type': 'application/json',
    // anything you want to add to the headers
  },
  withCredentials: true,
});

const ldoeAxios = baseAxios.create({
  baseURL: process.env.VUE_APP_LDOE_API,
  // timeout: 5000, // 5 second timeout. Figure out a good/better one
  headers: {
    'Content-Type': 'application/json',
    // anything you want to add to the headers
  },
  withCredentials: true,
});

const quickCommentsAxios = baseAxios.create({
  baseURL: process.env.VUE_APP_QUICK_COMMENTS_API,
  headers: {
    'Content-Type': 'text/plain',
  },
});

const ssasAxios = baseAxios.create({
  baseURL: process.env.VUE_APP_SSAS_URL,
  // timeout: 5000, // 5 second timeout. Figure out a good/better one
  headers: {
    'Content-Type': 'application/json',
    // anything you want to add to the headers
  },
  withCredentials: true,
});
/**
 * [WIP] implementation of global request error handling
 */
tngAxios.interceptors.response.use(undefined, function (error: AxiosError) {
  const composedError = new ComposedAjaxError(error);
  return Promise.reject(composedError);
});
coreAxios.interceptors.response.use(undefined, function (error: AxiosError) {
  const composedError = new ComposedAjaxError(error);
  return Promise.reject(composedError);
});

export {
  tngAxios,
  cignitionAxios,
  coreAxios,
  loginPortalAxios,
  ldoeAxios,
  quickCommentsAxios,
  ssasAxios,
  openLP,
  ComposedError,
};
