
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProblemView from '@/components/base/ProblemView.vue';
import StudentProblemView from '@/components/LDOE/base/StudentProblemView.vue';
import { Problem } from '@/domain/Problem';
import {
  ProblemData,
  StudentDataPerAssignmentProblem,
} from '@/domain/ReportData/Cignition';
import { Skill } from '@/domain/Skill';

export interface ProblemDetail {
  problem: Problem;
  classData?: ProblemData;
  studentData: { [xref: string]: StudentDataPerAssignmentProblem };
}

@Component({
  components: {
    ProblemView,
    StudentProblemView,
  },
})
export default class ProblemSideSheet extends Vue {
  @Prop({ required: true }) problemDetail: ProblemDetail;
  @Prop({ default: true }) value: boolean;

  //////////
  // Data //
  //////////
  isExpanded = false;

  get showDrawer(): boolean {
    return this.value;
  }

  set showDrawer(val: boolean) {
    this.$emit('input', val);
  }

  get problemName(): string {
    const name = this.problemDetail.problem.xref;

    if (this.problemDetail.problem.partLetter != null) {
      // A subproblem
      return name + ' ' + this.problemDetail.problem.partLetter;
    }

    return name;
  }

  get matchedSkills(): Array<Skill> {
    return this.skills.filter((skill) => {
      if (this.problemDetail.problem.skillIds) {
        return this.problemDetail.problem.skillIds.includes(Number(skill.id));
      }

      return false;
    });
  }

  get drawerOption(): string {
    return this.isExpanded ? '>> Collapse' : '<< Expand';
  }

  ///////////
  // Store //
  ///////////

  get skills(): Array<Skill> {
    return this.$store.state.skillList.skills;
  }

  get selectedTuteeXrefs(): string[] {
    return this.$store.state.ldoe.selectedTuteeXrefs;
  }

  /////////////
  // Methods //
  /////////////

  expand(): void {
    this.isExpanded = !this.isExpanded;
  }

  created(): void {
    // Download skills if not already
    // Will be prevented to download again in store if already
    this.$store.dispatch('skillList/requestSkills');
  }
}
