
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Problem } from '@/domain/Problem';
import ProblemAnswers from './ProblemAnswers.vue';

export enum ParentPage {
  LESSON_VIEW = 1,
  SEARCH_RESULTS = 2,
  MY_PROBLEM_SETS = 3,
}

@Component({
  components: {
    Attribution: () => import('./Attribution.vue'),
    ProblemAnswers,
  },
})
export default class ProblemView extends Vue {
  @Prop({ required: true }) problem: Problem;
  @Prop({ type: Boolean }) disableDivider: boolean;
  @Prop({ type: Boolean }) canBeCollapsed: boolean;
  @Prop({ type: Boolean }) collapsed: boolean;

  collapsedBody = '';

  get problemBody(): string {
    if (this.collapsed) {
      return this.collapsedBody;
    }
    return this.problem.body;
  }

  mounted(): void {
    if (this.canBeCollapsed) {
      const el = document.createElement('div');
      el.innerHTML = this.problem.body;
      if (el.textContent) {
        this.collapsedBody = el.textContent;
      }
    }
  }
}
