import { VnextLegacyHint } from '@/domain/StudentSupport';
import { coreAxios } from '@/plugins/axios';

const END_POINT = '/content/student-supports';

const getHint = (hintId: number): Promise<VnextLegacyHint> => {
  return coreAxios.get(`${END_POINT}/hints/${hintId}`).then((res) => res.data);
};

export { getHint };
