
import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class OpenResponseImageModal extends Vue {
  @Prop({ required: true }) imageSource: string;
  @Prop() value: boolean;

  get showModal(): boolean {
    return this.value;
  }

  set showModal(val: boolean) {
    this.$emit('input', val);
  }
}
