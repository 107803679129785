import { GetterTree } from 'vuex';
import { Skill } from '@/domain/Skill';
import { SkillState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<SkillState, RootState> = {
  getHasDownloaded(state: SkillState): boolean {
    return state.hasDownloaded;
  },
  getIsDownloading(state: SkillState): boolean {
    return state.isDownloading;
  },
  getSelectedSkill(state: SkillState): Skill | null {
    if (!state.selectedSkillId) {
      return null;
    }
    const selectedSkill = state.skills.find(
      (skill) => Number(skill.id) === Number(state.selectedSkillId)
    );
    return selectedSkill ? selectedSkill : null;
  },
  getSelectedId(state: SkillState): number | null {
    if (!state.selectedSkillId) {
      return null;
    }
    const selectedSkill = state.skills.find(
      (skill) => Number(skill.id) === Number(state.selectedSkillId)
    );
    return selectedSkill ? Number(selectedSkill.id) : null;
  },
  getSkillsMap(state: SkillState): Map<number, Skill> {
    const res = new Map<number, Skill>();

    if (state.skills) {
      state.skills.forEach((skill) => res.set(Number(skill.id), skill));
    }

    return res;
  },
};
