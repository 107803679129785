import { coreAxios } from '@/plugins/axios';
import { AssignmentReportData } from '@/domain/ReportData/AssignmentData';
import {
  AssignmentDefinition,
  AssignmentSortByField,
  AssignmentScope,
  AssigneeType,
  AssignmentProperty,
  AssignmentList,
} from '@/domain/Assignment';
import { LmsProviderType } from '@/domain/LmsProviderType';
import { CancelToken } from 'axios';
import { IncludeAttrSettings } from '@/domain/IncludeAttrSettings';
import { User } from '@/domain/User';
import { transformUserProfileToUser } from '@/utils/user.util';
import { IsActiveFilter } from '@/domain/State';

const END_POINT = '/assignments';

//////////
// DTOs //
//////////

export interface AssignmentDefinitionDTO {
  scope: AssignmentScope;
  assigneeType: AssigneeType;
  xref: string;
  assigneeXref: string;
  ownerXref: string;
  groupContextXref: string;
  problemSetCeri: string;
  lmsProviderType: string;
  name: string;
  assignDate: number;
  releaseDate: number;
  dueDate: number;
  active: boolean;
  properties?: Array<AssignmentProperty>;
}

/////////////////
// CAS Web API //
/////////////////

export interface AssignmentFilterAndSortParams {
  userXref?: string;
  courseXrefs?: Array<string>;
  isActive?: IsActiveFilter;
  limit?: number;
  nextPageToken?: string;
  sortBy?: AssignmentSortByField;
  include?: Array<IncludeAttrSettings>;
  dueDate?: string;
  releaseDate?: string;
  assignDate?: string;
}

const getAssignmentDefinitions = (
  params: AssignmentFilterAndSortParams,
  cancelToken?: CancelToken
): Promise<AssignmentList> => {
  return coreAxios
    .get(`${END_POINT}`, {
      params,
      cancelToken,
    })
    .then((result) => {
      return {
        assignments: result.data.assignments.map(buildAssignmentDefinition),
        nextPageToken: result.data.nextPageToken,
      };
    });
};

export interface AssignmentDefinitionParams {
  details?: boolean;
  isActive?: IsActiveFilter;
}

// Active filters:    'ENABLED', 'DISABLED', or 'IGNORE'
// Details option:    true returns assignment's properties, defaults to false
const getAssignmentDefinition = (
  assignmentXref: string,
  params?: AssignmentDefinitionParams,
  cancelToken?: CancelToken
): Promise<AssignmentDefinition> => {
  return coreAxios
    .get(`${END_POINT}/${assignmentXref}`, {
      params: params,
      cancelToken,
    })
    .then((result) => {
      return buildAssignmentDefinition(result.data);
    });
};

// Data Types:             'ASSIGNMENT_LOGS', 'PROBLEM_LOGS', 'ACTION_LOGS', 'STATISTICS'
// Action filters:    'ASSIGNMENT_ACTIONS', 'PROBLEM_SET_ACTIONS', 'PROBLEM_ACTIONS', 'RESPONSE_ACTIONS'
//                    'TUTORING_REQUEST_ACTIONS', 'TIMER_ACTIONS', 'TUTORING_SET_ACTIONS'
// Statistic filters: 'ALL_STUDENTS', 'SPECIFIED_STUDENTS'
// State filters:     'ENABLED', 'DISABLED', 'IGNORE'
type ActionFilter =
  | 'ASSIGNMENT_ACTIONS'
  | 'PROBLEM_ACTIONS'
  | 'RESPONSE_ACTIONS'
  | 'TUTORING_REQUEST_ACTIONS';

type DataType =
  | 'ASSIGNMENT_LOGS'
  | 'PROBLEM_LOGS'
  | 'ACTION_LOGS'
  | 'STATISTICS';

type StatisticsFilter = 'ALL_STUDENTS' | 'SPECIFIED_STUDENTS';

interface AssignmentReportDataParams {
  dTypes?: Array<DataType>;
  aFilters?: Array<ActionFilter>;
  usersFilter?: Array<string>;
  statisticsFor?: StatisticsFilter;
  isActiveFilter?: IsActiveFilter;
}

const getAssignmentReportData = (
  assignmentXref: string,
  // FIXME: Are these filters what we want here?
  params: AssignmentReportDataParams = {}
): Promise<AssignmentReportData> => {
  const queryParams = setDefaultAssignmentReportDataParams(params);

  return coreAxios
    .get(`${END_POINT}/${assignmentXref}/sdata`, {
      params: {
        ...queryParams,
      },
    })
    .then((result) => {
      return result.data;
    });
};

const deleteAssignmentProgress = (
  assignmentXref: string,
  studentXref: string
): Promise<void> => {
  return coreAxios.delete(
    `${END_POINT}/${assignmentXref}/sdata/${studentXref}`
  );
};

const getAssignmentAssignees = (
  assignmentXref: string
): Promise<Array<User>> => {
  return coreAxios
    .get(`${END_POINT}/${assignmentXref}/assignees`)
    .then((res) => {
      return res.data.map(transformUserProfileToUser);
    });
};

export interface UpdateStudentProblemLogPayload {
  continuousScore?: number;
  teacherComment?: string;
}

const updateStudentProblemLog = (
  assignmentXref: string,
  studentXref: string,
  problemLogId: number,
  payload: UpdateStudentProblemLogPayload
): Promise<void> => {
  return coreAxios.patch(
    `${END_POINT}/${assignmentXref}/sdata/${studentXref}/pLogs/${problemLogId}`,
    payload
  );
};

/////////////
// Helpers //
/////////////
function buildAssignmentDefinition(
  adDTO: AssignmentDefinitionDTO
): AssignmentDefinition {
  return {
    ...adDTO,
    dueDate: adDTO.dueDate || null,
    lmsProviderType: LmsProviderType.findByName(adDTO.lmsProviderType),
  };
}

function setDefaultAssignmentReportDataParams(
  params: AssignmentReportDataParams
): AssignmentReportDataParams {
  if (!params.dTypes) {
    params.dTypes = [
      'ASSIGNMENT_LOGS',
      'ACTION_LOGS',
      'PROBLEM_LOGS',
      'STATISTICS',
    ];
  }

  if (!params.aFilters) {
    params.aFilters = [
      'ASSIGNMENT_ACTIONS',
      'PROBLEM_ACTIONS',
      'RESPONSE_ACTIONS',
      'TUTORING_REQUEST_ACTIONS',
    ];
  }

  if (!params.statisticsFor) {
    params.statisticsFor = 'ALL_STUDENTS';
  }

  return params;
}

export {
  getAssignmentDefinitions,
  getAssignmentDefinition,
  getAssignmentReportData,
  deleteAssignmentProgress,
  updateStudentProblemLog,
  getAssignmentAssignees,
};
