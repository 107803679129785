
import {
  AssignmentReportData,
  ProblemLog,
  ProblemSetAction,
  StudentLog,
} from '@/domain/ReportData/AssignmentData';
import { User } from '@/domain/User';
import { appendGlobalHeaderOptions } from '@/utils/dataTables.utils';
import { sortBySortableUserName } from '@/utils/table.utils';
import dayjs from 'dayjs';
import { orderBy, shuffle } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import DeleteStudentProgressDialog from './DeleteStudentProgressDialog.vue';

interface StudentData {
  timeSpent: number;
  score: number;
}

interface SkillBuilderTableRow {
  student: User;
  studentLog?: StudentLog;
  studentStats?: StudentData;
  sortedProblemLogs?: Array<ProblemLog[]>;
  sortedProblemSetActions?: Array<ProblemSetAction[]>;
  problemSetLimitExceededActions?: Array<boolean>;
  completed: boolean;
}

@Component({
  components: {
    DeleteStudentProgressDialog,
  },
})
export default class SkillBuilderTable extends Vue {
  @Prop() assignees: Array<User>;
  @Prop() reportData: AssignmentReportData;

  hideNames = false;
  dailyLimitReached = false;

  studentToDeleteProgress: User | null = null;
  showDeleteProgressDialog = false;

  get assigneeXrefToStudentLogMap(): Map<string, StudentLog> {
    const res = new Map();
    if (this.reportData) {
      for (const studentLog of this.reportData.studentLogs) {
        res.set(studentLog.studentXref, studentLog);
      }
    }
    return res;
  }

  get assigneeXrefToStudentStatsMap(): Map<string, StudentData> {
    const res = new Map();
    if (this.reportData && this.reportData.summaryStatsAll) {
      for (const studentStats of this.reportData.summaryStatsAll.studentStats) {
        res.set(studentStats.studentXref, {
          timeSpent: studentStats.timeSpent,
          score: studentStats.score,
        });
      }
    }
    return res;
  }

  get prependStaticHeaders(): Array<DataTableHeader> {
    return [
      {
        text: 'Student Name/Problem',
        value: 'student',
        align: 'start',
        class: [
          'text-no-wrap',
          'sticky-row',
          'sticky-row-1',
          'text-subtitle-2',
        ],
        sortable: true,
        sort: sortBySortableUserName,
      },
      {
        text: 'Skill Builder Completion',
        value: 'skillBuilderCompletion',
        align: 'start',
        class: [
          'text-no-wrap',
          'sticky-row',
          'sticky-row-1',
          'text-subtitle-2',
        ],
        sortable: false,
      },
      {
        text: 'Total Time',
        value: 'totalTime',
        align: 'start',
        class: [
          'text-no-wrap',
          'sticky-row',
          'sticky-row-1',
          'text-subtitle-2',
        ],
        sortable: false,
      },
    ];
  }

  get headers(): Array<DataTableHeader> {
    return [...this.prependStaticHeaders.map(appendGlobalHeaderOptions)];
  }

  get rows(): Array<SkillBuilderTableRow> {
    const rows = this.generateRows();

    this.getProblemSetLimitExceededActionsForRows(rows);

    // Shuffle student rows if anonymizing
    if (this.hideNames) {
      return shuffle(rows);
      // Otherwise sort alphabetically by lastname, firstname
    } else {
      return orderBy(
        rows,
        [
          (row) => {
            return row.student.lastName;
          },
          (row) => {
            return row.student.firstName;
          },
        ],
        ['asc', 'asc']
      );
    }
  }

  get hideNamesButtonText(): string {
    return this.hideNames ? 'Show Names' : 'Hide Names';
  }

  toggleNames(): void {
    this.hideNames = !this.hideNames;
  }

  getTimeSpent(ms: number): string {
    return dayjs.duration(ms).format('HH:mm:ss');
  }

  generateRows(): Array<SkillBuilderTableRow> {
    const rows: Array<SkillBuilderTableRow> = [];

    this.assignees.forEach((assignee) => {
      const studentLog = this.assigneeXrefToStudentLogMap.get(assignee.xref);
      let completed = false;

      if (studentLog) {
        completed = !!studentLog.asEndTime;
      }

      rows.push({
        completed,
        studentLog,
        student: assignee,
        studentStats: this.assigneeXrefToStudentStatsMap.get(assignee.xref),
      });
    });

    return rows;
  }

  created(): void {
    this.hideNames =
      this.$store.state.auth.user.settings.anonymizeReportsByDefault || false;
  }

  sortProblemLogsPerDay(studentLog: StudentLog): Map<string, ProblemLog[]> {
    const sortAscending = (a: ProblemLog, b: ProblemLog): number =>
      a.startTime > b.startTime ? 1 : -1;

    const res = new Map<string, ProblemLog[]>();

    const logs = studentLog.problemLogAndActions
      ?.map((log) => log.prLog)
      .sort(sortAscending);

    if (logs) {
      logs.forEach((log) => {
        if (log.endTime) {
          const formattedStartTime = dayjs(log.startTime).format('YYYY-MM-DD');
          if (res.has(formattedStartTime)) {
            res.set(formattedStartTime, [
              ...(res.get(formattedStartTime) as ProblemLog[]),
              log,
            ]);
          } else {
            res.set(formattedStartTime, [log]);
          }
        }
      });
    }

    return res;
  }

  showStudentDeleteProgressDialog(studentXref: string): void {
    this.studentToDeleteProgress =
      this.assignees.find((assignee) => assignee.xref === studentXref) || null;

    if (this.studentToDeleteProgress) {
      this.showDeleteProgressDialog = true;
    }
  }

  deleteStudentProgress(): void {
    if (this.studentToDeleteProgress) {
      this.$emit('deleteStudentProgress', this.studentToDeleteProgress?.xref);
      this.studentToDeleteProgress = null;
    }
    //close dialog
    this.showDeleteProgressDialog = false;
  }

  sortProblemSetActionsPerDay(
    studentActions: Array<ProblemSetAction>
  ): Map<string, ProblemSetAction[]> {
    const res = new Map<string, ProblemSetAction[]>();

    studentActions.forEach((action) => {
      const formattedStartTime = dayjs(action.timestamp).format('YYYY-MM-DD');
      if (res.has(formattedStartTime)) {
        res.set(formattedStartTime, [
          ...(res.get(formattedStartTime) as ProblemSetAction[]),
          action,
        ]);
      } else {
        res.set(formattedStartTime, [action]);
      }
    });

    return res;
  }

  navigateToStudentDetailsReport(studentXref: string): void {
    this.$router.push({
      name: 'studentDetailsPage',
      params: {
        studentXref: studentXref,
      },
      query: this.$route.query,
    });
  }

  private getProblemSetLimitExceededActionsForRows(
    rows: Array<SkillBuilderTableRow>
  ) {
    rows.forEach((row) => {
      row.sortedProblemLogs = [];
      row.sortedProblemSetActions = [];
      row.problemSetLimitExceededActions = [];

      if (row.studentLog) {
        const sortedProblemLogs = this.sortProblemLogsPerDay(row.studentLog);
        sortedProblemLogs.forEach((log) => row.sortedProblemLogs?.push(log));

        const sortedProblemSetActions = this.sortProblemSetActionsPerDay(
          row.studentLog.psActions || []
        );
        sortedProblemSetActions.forEach((action) =>
          row.sortedProblemSetActions?.push(action)
        );

        // For each of the output row's timestamps, determine if a problem limit exceeded action exists
        const timestamps = Array.from(sortedProblemLogs.keys());
        timestamps.forEach((timestamp) => {
          let value = false;

          const actions = sortedProblemSetActions.get(timestamp);
          if (actions) {
            const problemSetLimitExceededAction = actions.find(
              (action) => action.actionType === 'PROBLEM_LIMIT_EXCEEDED_ACTION'
            );

            if (problemSetLimitExceededAction) {
              value = true;
            }
          }

          row.problemSetLimitExceededActions?.push(value);
        });
      }
    });
  }
}
