
import {
  getAssignmentAssignees,
  getAssignmentDefinition,
  getAssignmentReportData,
} from '@/api/core/assignment.api';
import { getProblemsByPsXref } from '@/api/pspr.api';
import { AssignmentDefinition } from '@/domain/Assignment';
import { Problem, ProblemType } from '@/domain/Problem';
import {
  AssignmentReportData,
  ProblemLog,
} from '@/domain/ReportData/AssignmentData';
import { User } from '@/domain/User';
import { getQuickComments, RequestParams } from '@/api/quickcomments.api';
import { QuickComment } from '@/domain/QuickComment';
import { Component, Vue, Watch } from 'vue-property-decorator';
import EssayScoringTable from '@/components/Report/EssayScoringTable.vue';
import { isEmpty } from 'lodash';
import OpenResponseImageModal from '../../components/Report/OpenResponseImageModal.vue';

enum ProblemDirectionType {
  NEXT = 1,
  PREVIOUS = -1,
}

@Component({
  components: {
    EssayScoringTable,
    OpenResponseImageModal,
  },
})
export default class EssayScoringPage extends Vue {
  ProblemDirection = ProblemDirectionType;

  assignment: AssignmentDefinition;
  problemSetXref = '';

  outerPromisesReady = false;
  innerPromisesReady = false;

  assignmentReportData: AssignmentReportData;
  problems: Array<Problem> = [];

  students: Array<User> = [];

  quickComments: Array<QuickComment> = [];
  numberOfRetrievedComments = 3;
  quickCommentsReady = false;

  showQuestion = false;

  showImageModal = false;
  imageSource: string | null = null;

  problemLogIdToLog: Map<number, ProblemLog> = new Map();

  created(): void {
    const assignmentXref = this.$route.params.xref;
    // Load report data
    const assignmentReportDataPromise = getAssignmentReportData(
      assignmentXref,
      { dTypes: ['ASSIGNMENT_LOGS', 'PROBLEM_LOGS'] }
    ).then((assignmentReportData: AssignmentReportData) => {
      if (!isEmpty(assignmentReportData)) {
        this.assignmentReportData = assignmentReportData;
      }
    });

    // Load assignment definition
    const assignmentDefinitionPromise = getAssignmentDefinition(
      assignmentXref
    ).then((assignmentDefinition: AssignmentDefinition) => {
      this.assignment = assignmentDefinition;
      this.problemSetXref = assignmentDefinition.problemSetCeri;

      const assigneePromise = getAssignmentAssignees(assignmentDefinition.xref)
        .then((students: Array<User>) => {
          if (students) {
            this.students = students;
          }
        })
        .catch(() => {
          // 404 - No assignees found
          this.students = [];
        });

      const problemPromise = getProblemsByPsXref(
        this.problemSetXref as string,
        ['ANSWERS', 'CURRICULA', 'SKILLS']
      ).then((problems: Array<Problem>) => {
        this.problems = problems.filter(
          (problem) => problem.type == ProblemType.OPEN_RESPONSE
        );
      });

      Promise.all([assigneePromise, problemPromise])
        .then(() => {
          this.fetchQuickComments();
        })
        .finally(() => {
          this.innerPromisesReady = true;
        });
    });

    Promise.all([
      assignmentReportDataPromise,
      assignmentDefinitionPromise,
    ]).finally(() => {
      this.outerPromisesReady = true;
    });
  }

  get reportReady(): boolean {
    return (
      this.innerPromisesReady &&
      this.outerPromisesReady &&
      this.quickCommentsReady
    );
  }

  get questionCardText(): string {
    return this.showQuestion ? 'Hide Question' : 'Show Question';
  }

  get currentProblemIndex(): number {
    return (
      this.problems.findIndex((problem) => problem.id === this.problemId) ?? 0
    );
  }

  get currentProblem(): Problem {
    return this.problems[this.currentProblemIndex];
  }

  get headerText(): string {
    let title = `${this.currentProblem.position}`;

    if (this.currentProblem.partLetter) {
      title += ` - ${this.currentProblem.partLetter}`;
    }

    return title;
  }

  get problemId(): number {
    return Number(this.$route.params.problemId);
  }

  get isQuickCommentsTeacher(): boolean {
    return (
      this.$store.getters['auth/getCurrentUser']?.attributes
        ?.quickCommentsTeacher ?? false
    );
  }

  get quickCommentsRequestParams(): RequestParams {
    const params: RequestParams = {
      body: [],
    };
    if (this.students.length > 0) {
      this.students.forEach((student: User) => {
        const problemLog = this.studentXrefToProblemLogMap.get(student.xref);
        const answerText = problemLog?.answerText;

        if (answerText) {
          params.body.push({
            userId: student.xref,
            problemId: this.problemId,
            teacherId: this.$store.getters['auth/getCurrentUser'].xref,
            answerText,
            numOfComments: this.numberOfRetrievedComments,
          });
        }
      });
    }
    return params;
  }

  get studentXrefToProblemLogMap(): Map<string, ProblemLog> {
    const res: Map<string, ProblemLog> = new Map<string, ProblemLog>();

    this.assignmentReportData?.studentLogs.forEach((studentLog) => {
      if (studentLog.problemLogAndActions) {
        studentLog.problemLogAndActions?.forEach((log) => {
          const { prLog } = log;
          this.problemLogIdToLog.set(prLog.id, prLog);

          if (prLog.problemDbid === this.problemId) {
            res.set(studentLog.studentXref, log.prLog);
          }
        });
      }
    });

    return res;
  }

  changeProblem(problemDirection: ProblemDirectionType): void {
    let nextProblem: Problem;

    nextProblem = this.problems[this.currentProblemIndex + problemDirection];

    this.redirectUser(nextProblem.id);
  }

  redirectUser(id: number): void {
    this.$router.push({
      name: 'essayScoringPage',
      params: {
        problemId: id.toString(),
      },
    });
  }

  toggleQuestion(): void {
    this.showQuestion = !this.showQuestion;
  }

  updateLogs(event: {
    id: number;
    continuousScore?: number;
    teacherComment?: string;
  }): void {
    const pLog = this.problemLogIdToLog.get(event.id);
    if (!pLog) {
      return;
    }

    if (event.continuousScore) {
      pLog.continuousScore = event.continuousScore;
    }

    if (event.teacherComment) {
      pLog.teacherComment = event.teacherComment;
    }
  }

  fetchQuickComments(): void {
    this.quickCommentsReady = false;

    if (this.isQuickCommentsTeacher) {
      getQuickComments(this.quickCommentsRequestParams)
        .then((data) => {
          this.quickComments = data.flat();
        })
        .finally(() => {
          this.quickCommentsReady = true;
        });
    } else {
      this.quickComments = [];
      this.quickCommentsReady = true;
    }
  }

  openImageModal(imageSource: string): void {
    this.imageSource = imageSource;
    this.showImageModal = true;
  }

  @Watch('this.problemId')
  onProblemIdChange(): void {
    this.fetchQuickComments();
  }
}
