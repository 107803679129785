import { ActionTree } from 'vuex';
import { LDOEState } from './types';
import { RootState } from '../types';
import { User } from '@/domain/User';
import { getStudentsInAllClasses } from '@/api/ldoe/student.api';

export const actions: ActionTree<LDOEState, RootState> = {
  /**
   * Downloads this users' tutees (students)
   * only if not already downloaded/downloading
   */
  requestTutees(context): Promise<Array<User>> {
    if (
      !context.state.hasDownloadedTutees &&
      !context.state.isDownloadingTutees
    ) {
      context.commit('setIsDownloadingTutees', true);

      getStudentsInAllClasses().then((students) => {
        context.commit('setTutees', students);
        context.commit('setHasDownloadedTutees', true);
        context.commit('setIsDownloadingTutees', false);
      });

      return Promise.resolve(context.state.tutees);
    }

    return Promise.resolve(context.state.tutees);
  },
};
