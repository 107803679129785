import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { AuthState } from './types';
import { RootState } from '../types';

export const state: AuthState = {
  user: undefined,
  isInitializing: false,
  hasInitialized: false,
};

const namespaced = true;

export const auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
