
import {
  BarChartData,
  CustomDataSetLabel,
  DataSets,
  XTickLabel,
} from '@/components/Report/BarChartView.vue';
import BarChartViewForReport from '@/components/Report/BarChartViewForReport.vue';
import { Problem, ProblemType } from '@/domain/Problem';
import { Component, Prop, Vue } from 'vue-property-decorator';

interface CustomFilterOption {
  text: string;
  customChartLabels: Array<CustomDataSetLabel>;
}

export interface ProblemStats {
  numCorrect: number;
  numCorrectEventually: number;
  numIncorrect: number;
}

@Component({
  components: {
    BarChartViewForReport,
  },
})
export default class AssignmentBarChartViewForReport extends Vue {
  @Prop({ required: true }) problems: Array<Problem>;
  @Prop({ required: true }) problemIdToProblemStats: Map<number, ProblemStats>;
  @Prop({ default: false }) isLoading: boolean;
  @Prop({ default: false }) isInTestMode: boolean;
  selectedFilterOption = 0;

  //////////
  // Data //
  //////////

  ////////////////////
  // Loading States //
  ////////////////////

  ///////////
  // Store //
  ///////////

  ////////////////
  // Graph Data //
  ////////////////
  get problemChartLabels(): Array<XTickLabel> {
    const problemTickLabels: Array<XTickLabel> = [];
    let problemText: string;

    // Based on given order in list of problems (NOT problem logs)
    for (const problem of this.problems) {
      // Do not show open-ended responses in performance chart
      if (problem.type != ProblemType.OPEN_RESPONSE) {
        problemText = 'P' + problem.assistmentPosition;

        if (problem.partLetter) {
          problemText += ': ' + problem.partLetter;
        }

        problemTickLabels.push({
          text: problemText,
          value: problem.id,
        });
      }
    }

    return problemTickLabels;
  }

  get problemChartData(): DataSets {
    const problemDataSets: DataSets = {
      correct: [],
      correctEventually: [],
      incorrect: [],
    };

    for (const problem of this.problems) {
      // Do not include open-ended responses in performance chart
      if (problem.type != ProblemType.OPEN_RESPONSE) {
        const problemStats = this.problemIdToProblemStats.get(problem.id);
        if (problemStats) {
          problemDataSets.correct.push({ value: problemStats.numCorrect });
          problemDataSets.correctEventually.push({
            value: this.isInTestMode ? 0 : problemStats.numCorrectEventually,
          });
          problemDataSets.incorrect.push({
            value: this.isInTestMode
              ? problemStats.numIncorrect + problemStats.numCorrectEventually
              : problemStats.numIncorrect,
          });
        } else {
          // No stats for this problem?
          // Add placeholder to indicate no data for this problem so that we
          // don't mess up the display for the next problem
          problemDataSets.correct.push({ value: 0 });
          problemDataSets.correctEventually.push({
            value: 0,
          });
          problemDataSets.incorrect.push({ value: 0 });
        }
      }
    }

    return problemDataSets;
  }

  get correctColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.correct;
  }

  get correctEventuallyColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.correctEventually;
  }

  get incorrectColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.incorrect;
  }

  get correctChartLabel(): CustomDataSetLabel {
    return {
      xref: 'correct',
      label: 'Correct',
      backgroundColor: this.correctColor,
    };
  }

  get correctEventuallyChartLabel(): CustomDataSetLabel {
    return {
      xref: 'correctEventually',
      label: 'Correct Eventually',
      backgroundColor: this.correctEventuallyColor,
    };
  }

  get incorrectChartLabel(): CustomDataSetLabel {
    return {
      xref: 'incorrect',
      label: 'Incorrect',
      backgroundColor: this.incorrectColor,
    };
  }

  get customFilterOptions(): Array<CustomFilterOption> {
    const labels = {
      text: 'ALL',
      customChartLabels: [this.correctChartLabel, this.incorrectChartLabel],
    };

    const options = [
      {
        text: 'CORRECT',
        customChartLabels: [this.correctChartLabel],
      },
      {
        text: 'INCORRECT',
        customChartLabels: [this.incorrectChartLabel],
      },
    ];

    if (!this.isInTestMode) {
      // Insert correctEventually label and filter option after the correct ones if test mode is disabled
      labels.customChartLabels.splice(1, 0, this.correctEventuallyChartLabel);

      options.splice(1, 0, {
        text: 'CORRECT EVENTUALLY',
        customChartLabels: [this.correctEventuallyChartLabel],
      });
    }

    return [labels, ...options];
  }

  get customChartLabels(): Array<CustomDataSetLabel> {
    if (this.selectedFilterOption) {
      return this.customFilterOptions[this.selectedFilterOption]
        .customChartLabels;
    }

    // Undefined? Deselected filter option?
    this.selectedFilterOption = 0;
    return this.customFilterOptions[0].customChartLabels;
  }

  get customChartData(): BarChartData {
    return {
      xTickLabels: this.problemChartLabels,
      datasets: this.problemChartData,
    };
  }

  /////////////
  // Methods //
  /////////////
}
