import { VnextLegacyHint } from '@/domain/StudentSupport';
import { ssasAxios, coreAxios } from '@/plugins/axios';

const END_POINT = `/content/student-supports`;

const getHint = (hintId: number): Promise<VnextLegacyHint> => {
  return coreAxios.get(`${END_POINT}/hints/${hintId}`).then((res) => res.data);
};

const getStudentSupportsForProblems = (problemIds: any): Promise<Response> => {
  return ssasAxios
    .get(`${END_POINT}`, {
      params: { problemIds },
    })
    .then((res) => {
      return res.data;
    });
};

const createStudentSupportAuthoring = (requestBody: any): Promise<Response> => {
  return ssasAxios
    .put(`${END_POINT}`, requestBody, {
      params: { publish: 'true' },
    })
    .then((res) => {
      return res.data;
    });
};

const updateStudentSupportAuthoring = (requestBody: any): Promise<Response> => {
  return ssasAxios
    .post(`${END_POINT}/update`, requestBody, {
      params: { publish: 'true' },
    })
    .then((res) => {
      return res.data;
    });
};

const deleteStudentSupportAuthoring = (
  contentKey: string,
  problemId: number
): Promise<Response> => {
  return ssasAxios.delete(`${END_POINT}/${contentKey}`, {
    params: { problemId },
  });
};

export {
  getHint,
  createStudentSupportAuthoring,
  updateStudentSupportAuthoring,
  deleteStudentSupportAuthoring,
  getStudentSupportsForProblems,
};
