import { tngAxios } from '@/plugins/axios';
import { FolderInfo } from '@/domain/FolderInfo';

const END_POINT = '/hierarchy/folders';

//////////
// DTOs //
//////////

export interface FolderInfoDTO {
  legacyFolderItemsItemId: number;
  itemName: string;
  folderMemberType: string;
  title: string;
  parentFolderId: number;
  curriculumXrefs?: Array<string>;
  attributionXrefs?: Array<string>;
}

const getFolderChildren = (folder_id: number): Promise<FolderInfo[]> => {
  return tngAxios
    .get(`${END_POINT}`, { params: { parentFolderId: folder_id } })
    .then((result) => {
      const arr = result.data;
      const retArr: FolderInfo[] = [];
      let child: FolderInfo;

      arr.forEach((element: FolderInfoDTO) => {
        child = transformDataToFolderInfo(element);
        retArr.push(child);
      });
      return retArr;
    });
};

const getFolder = (folder_id: number): Promise<FolderInfo> => {
  return tngAxios
    .get(`${END_POINT}`, { params: { ids: folder_id } })
    .then((result) => {
      return transformDataToFolderInfo(result.data[0]);
    });
};

const getFolderPathWithFolder = (folder_id: number): Promise<FolderInfo[]> => {
  return tngAxios
    .get(`${END_POINT}/path`, {
      params: {
        folderId: folder_id,
      },
    })
    .then((result) => {
      const arr = result.data;
      const retArr: FolderInfo[] = [];
      let child: FolderInfo;

      arr.forEach((element: FolderInfoDTO) => {
        child = transformDataToFolderInfo(element);
        retArr.push(child);
      });

      retArr.shift();
      return retArr;
    });
};

const getFolderPathWithPS = (ps_id: number): Promise<FolderInfo[]> => {
  return tngAxios
    .get(`${END_POINT}/path`, {
      params: {
        problemSetId: ps_id,
      },
    })
    .then((result) => {
      const arr = result.data;
      const retArr: FolderInfo[] = [];
      let child: FolderInfo;

      arr.forEach((element: FolderInfoDTO) => {
        child = transformDataToFolderInfo(element);
        retArr.push(child);
      });

      retArr.shift();
      return retArr;
    });
};

function transformDataToFolderInfo(data: FolderInfoDTO): FolderInfo {
  let name = data.itemName;
  let title = data.title || '';
  let type = data.folderMemberType.toLowerCase();
  if (!title && name.includes('---')) {
    const arr = name.split('---');
    name = arr[1].trim();
    title = arr[0].trim();
    if (type == 'folder') {
      type = 'lesson';
    }
  }

  return {
    id: data.legacyFolderItemsItemId,
    name: name,
    type: type,
    children: [],
    open: false,
    title: title,
    parent_id: data.parentFolderId,
    curriculumXrefs: data.curriculumXrefs,
    attributionXrefs: data.attributionXrefs,
  };
}

export {
  getFolder,
  getFolderChildren,
  getFolderPathWithFolder,
  getFolderPathWithPS,
};
