import { ActionTree } from 'vuex';
import { MyPsState } from './types';
import { RootState } from '../types';
import { ProblemSet, ProblemSetList } from '@/domain/ProblemSet';
import {
  getMyProblemSets,
  renameProblemSet,
  duplicateProblemSet,
  deleteProblemSet,
  ProblemSetFilterAndPagingParams,
  getMyProblemSetsByName,
} from '@/api/pspr.api';
import { CancelToken } from 'axios';

export const actions: ActionTree<MyPsState, RootState> = {
  requestMyProblemSets(
    { commit, state },
    payload: {
      params: ProblemSetFilterAndPagingParams;
      cancelToken?: CancelToken;
      name?: string;
    },
    reDownload = false
  ): Promise<void> {
    // If the name's being passed, assume that we are calling off to the search endpoint
    if (payload.name) {
      return getMyProblemSetsByName(
        payload.name,
        payload.params,
        payload.cancelToken
      )
        .then((list) => {
          list.problemSets.forEach((ps) => commit('addProblemSet', ps));
        })
        .catch(() => {
          //do nothing
        });
    }
    //If there is no more to download. Or we are already downloading (and you don't want to start over)
    // then leave
    if (
      ((state.originalDownloadDone && !state.nextPageToken) ||
        state.downloading) &&
      !reDownload
    ) {
      return Promise.resolve();
    }

    //start over
    if (reDownload) {
      commit('resetProblemSets');
    }

    if (state.nextPageToken) {
      payload.params.nextPageToken = state.nextPageToken;
    }

    commit('setDownloading', true);
    return getMyProblemSets(payload.params, payload.cancelToken)
      .then((data: ProblemSetList) => {
        if (state.problemSets.length === 0) {
          commit('setProblemSets', data.problemSets);
        } else {
          data.problemSets.forEach((ps) => commit('addProblemSet', ps));
        }
        commit('setNextPageToken', data.nextPageToken);
      })
      .finally(() => {
        if (!state.originalDownloadDone) {
          commit('setOriginalDownloadDone', true);
        }
        commit('setDownloading', false);
      });
  },
  requestRenameProblemSet(context, psData): Promise<void> {
    return renameProblemSet(psData.id, psData.name).then(() => {
      context.commit('updateProblemSet', psData);
      return Promise.resolve();
    });
  },
  requestDuplicateProblemSet(context, psId: number): Promise<ProblemSet> {
    return duplicateProblemSet(psId).then((ps: ProblemSet) => {
      context.commit('addProblemSet', ps);
      return ps;
    });
  },
  requestDeleteProblemSet(context, psId: string): Promise<void> {
    return deleteProblemSet(psId).then(() => {
      context.commit('deleteProblemSet', psId);
    });
  },
};
