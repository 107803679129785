
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router/types/router';
import {
  getAssignmentLessonDetails,
  getAssignmentStudentAndClassData,
} from '@/api/cignition/assignment.api';
import NotFoundView from '@/components/base/NotFoundView.vue';
import AssignmentTable from '@/components/Cignition/TutoringAssignments/AssignmentTable.vue';
import LessonDetailsNotFoundView from '@/components/Cignition/TutoringAssignments/LessonDetailsNotFoundView.vue';
import LessonInformationView from '@/components/Cignition/TutoringAssignments/LessonInformationView.vue';
import {
  AssignmentLessonDetails,
  TargetAssignmentStudentAndClassDataWithProblems,
} from '@/domain/ReportData/Cignition';
import { addPartLetters } from '@/utils/problem.util';

@Component({
  components: {
    NotFoundView,
    AssignmentTable,
    LessonDetailsNotFoundView,
    LessonInformationView,
  },
})
export default class TutoringAssignmentPage extends Vue {
  //////////
  // Data //
  //////////
  assignmentXref: string | null = null;
  lessonData: AssignmentLessonDetails | null = null;
  assignmentData: TargetAssignmentStudentAndClassDataWithProblems | null = null;
  assignmentDataNotFound = false;
  lessonDataNotFound = false;
  tab = null;

  get assignmentName(): string {
    if (this.assignmentData) {
      return this.assignmentData.reportData.name;
    }

    return '';
  }

  get dashboardPage(): RawLocation {
    return {
      name: 'tutoringDashboard',
      query: this.$route.query,
    };
  }

  ///////////
  // Store //
  ///////////

  get selectedTuteeXrefs(): Array<string> {
    return this.$store.state.cignition.selectedTuteeXrefs;
  }

  ////////////////////
  // Loading States //
  ////////////////////

  isDownloadingAssignmentData = false;
  hasDownloadedAssignmentData = false;
  isDownloadingLessonDetails = false;
  hasDownloadedLessonDetails = false;

  //////////////
  // Watchers //
  //////////////

  @Watch('isAuthenticated')
  handleAuthChange(): void {
    if (!this.isAuthenticated) {
      // FIXME:
      // this.goToLoginPortal();
    }
  }

  /////////////
  // Methods //
  /////////////

  ///////////////////////////
  // Report Data & Helpers //
  ///////////////////////////

  created(): void {
    // Get assignment xref from path
    this.assignmentXref = this.$route.params.xref;

    this.initializeSelectedTutees();

    // Download data
    this.getAssignmentLessonDetails();
    this.getAssignmentStudentAndClassData();
  }

  getAssignmentLessonDetails(): void {
    if (this.assignmentXref) {
      this.isDownloadingLessonDetails = true;
      this.hasDownloadedLessonDetails = false;

      getAssignmentLessonDetails(this.assignmentXref)
        .then((data) => {
          this.lessonData = data;
          this.hasDownloadedLessonDetails = true;
        })
        .catch(() => {
          // Any errors
          this.lessonDataNotFound = true;
        })
        .finally(() => {
          this.isDownloadingLessonDetails = false;
        });
    }
  }

  getAssignmentStudentAndClassData(): void {
    if (this.assignmentXref && this.selectedTuteeXrefs.length > 0) {
      this.isDownloadingAssignmentData = true;
      this.hasDownloadedAssignmentData = false;

      getAssignmentStudentAndClassData(this.assignmentXref)
        .then((data) => {
          addPartLetters(data.problems);
          this.assignmentData = data;
          this.hasDownloadedAssignmentData = true;
        })
        .catch(() => {
          // Any errors
          this.assignmentDataNotFound = true;
        })
        .finally(() => {
          this.isDownloadingAssignmentData = false;
        });
    }
  }

  // Download helpers
  initializeSelectedTutees(): void {
    if (this.selectedTuteeXrefs.length === 0) {
      // Go to dashboard to select tutees
      this.$router.push(this.dashboardPage);
    }
  }
}
