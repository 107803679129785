const config = {
  relative_urls: false,
  browser_spellcheck: true,
  invalid_elements: 'script',
  image_advtab: true,
  automatic_uploads: false,
  default_link_target: '_blank',
  toolbar:
    'fontsizeselect bold italic underline strikethrough | bullist numlist forecolor backcolor | link unlink image media | undo redo | tiny_mce_wiris_formulaEditor table | charmap subscript superscript | preview',
  plugins:
    'advlist lists paste media table charmap link code preview astImagePlugins image',
  file_picker_types: 'image background_image',
  images_upload_credentials: true,
  imagetools_cors_hosts: [],
  imagetools_credentials_hosts: [],
  image_caption: true,
  paste_data_images: true,
  assistments_upload_type: 'C',
  assistments_upload_url: process.env.VUE_APP_RESOURCES_URL + '/upload/C',
  ast_base_plugin_url:
    '/assets/js/common/tinymce/5.0/tinymce/plugins/astImagePlugins/',
  assistments_image_data: { width: '200' },
  // In Tiny5.0, resize is set to true by default if autoresize plugin is NOT enabled,
  // false if enabled.
  resize: 'both',
  height: 450,
  image_uploadtab: false,
  external_plugins: {
    assistcompress:
      process.env.VUE_APP_RESOURCES_URL +
      '/assets/js/common/tinymce/5.0/tinymce/plugins/assistcompress/plugin.js',
    astImagePlugins:
      process.env.VUE_APP_RESOURCES_URL +
      '/assets/js/common/tinymce/5.0/tinymce/plugins/astImagePlugins/plugin.js',
    //add wiris
    tiny_mce_wiris:
      process.env.VUE_APP_RESOURCES_URL +
      '/assets/js/common/tinymce/5.0/tinymce/plugins/tiny_mce_wiris/plugin.min.js',
  },
  mathTypeParameters: {
    serviceProviderProperties: {
      URI: 'https://wiris.assistments.org/pluginwiris_engine/app/configurationjs',
      server: 'java',
    },
  },
};

export default config;
