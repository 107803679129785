
import { isEqual, orderBy } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataOptions, DataTableHeader } from 'vuetify';
import { convertHexToRGBA } from '@/utils/color.util';

export enum StaticHeader {
  NAME = 'name',
}

export enum RowType {
  SCHOOL = 'school',
  TEACHER = 'teacher',
  COURSE = 'course',
}

interface Node {
  xref: string;
  parents?: string[] | null;
  children?: string[] | null;
}

export interface Fraction {
  numerator: number;
  denominator: number;
}

export interface Row extends Node {
  type: RowType;
  sortOverride?: { [header: string]: string };
  [header: string]: unknown;
}

export interface GradientHeaders {
  averageHeader: DataTableHeader;
  itemHeaders: DataTableHeader[];
}

export interface GradientRows {
  averageRow: Row;
  itemRows: Map<string, Row>;
}

type OrderFunction = (rows: Row[]) => Row[];

@Component
export default class TreeGradientTable extends Vue {
  // Table Configuration
  @Prop({ required: true }) color: string;
  @Prop({ default: false }) anonymized: boolean;

  // Header Configurations
  @Prop({ required: true }) headerTitle: string;

  // Table Data
  @Prop({ required: true }) rowHeaders: GradientHeaders;
  @Prop({ required: true }) rows: GradientRows;
  @Prop({ default: [] }) collapsedPaths: string[];
  @Prop({ required: false }) options: DataOptions;

  // Loading State
  @Prop({ default: false }) loading: boolean;

  StaticHeader = StaticHeader;
  RowType = RowType;

  convertHexToRGBA = convertHexToRGBA;

  rowPaths: string[] = [];

  prependStaticHeaders = [
    {
      text: 'Teacher',
      value: StaticHeader.NAME,
    },
  ];

  get tableHeaders(): DataTableHeader[] {
    return [
      ...this.prependStaticHeaders,
      this.rowHeaders.averageHeader,
      ...this.rowHeaders.itemHeaders,
    ];
  }

  get averageRow(): Row {
    return this.rows.averageRow ?? {};
  }

  get itemRows(): Row[] {
    const items = this.rows?.itemRows ?? new Map();

    return [...items.values()];
  }

  // Hidden rows are decedents of collapsed rows
  get hiddenPaths(): string[] {
    return this.rowPaths.filter((rowPath) => {
      return this.collapsedPaths.some((collapsedPath) =>
        rowPath.startsWith(collapsedPath + '.')
      );
    });
  }

  togglePath(path: string): void {
    const res = this.collapsedPaths.filter(
      (collapsedPath) => collapsedPath != path
    );
    // Add path if it wasn't already present
    if (res.length === this.collapsedPaths.length) {
      res.push(path);
    }

    this.$emit('updateCollapsedPaths', res);
  }

  updateOptions(options: DataOptions) {
    if (!isEqual(options, this.options)) {
      this.$emit('updateOptions', options);
    }
  }

  // Recursively ordering the rows of each level applying the order by function if given
  orderRows(items: Row[], orderFunction?: OrderFunction): Row[] {
    const res = [];
    let rows = [];
    // List of nodes at this level ordered by given function if any
    if (orderFunction) {
      rows = orderFunction(items);
    } else {
      // Walk the tree
      rows = items;
    }
    for (const row of rows) {
      row.path = row.path ?? row.xref;
      res.push(row);

      // Do the same to its children if subtree is expanded
      if (row.children) {
        const children = row.children as string[];

        const childRows = children.map((child) => {
          const childRow = {
            ...(this.rows.itemRows.get(child) ?? ({} as Row)),
          };
          childRow.path = `${row.path}.${childRow.xref}`;
          return childRow;
        }) as Row[];

        res.push(...this.orderRows(childRows, orderFunction));
      }
    }
    return res;
  }
  customSort(items: Row[], sortBy: string[], sortDesc: boolean[]): Row[] {
    let res: Row[] = [];
    const rootRows = items.filter(
      (row: Row) => (row.parents ?? []).length === 0
    );
    // Not default
    if (sortBy.length > 0) {
      // A column and order to sort by
      const order = sortDesc[0] ? 'desc' : 'asc';
      res = this.orderRows(rootRows, (rows: Row[]) => {
        return orderBy(
          rows,
          (row) => {
            // Override column values in sorting if specified
            if (row.sortOverride && row.sortOverride[sortBy[0]]) {
              return row.sortOverride[sortBy[0]];
            }
            // Otherwise default to using column value
            if (sortBy[0] === StaticHeader.NAME) {
              // Name Column
              return row[StaticHeader.NAME];
            } else {
              // Data Column
              const fraction = (row[sortBy[0]] as Fraction) ?? null;
              return fraction ? fraction.numerator / fraction.denominator : 0;
            }
          },
          [order]
        );
      });
    } else {
      // Walk the tree
      res = this.orderRows(rootRows);
    }

    this.rowPaths = res.map((row) => row.path) as string[];
    return res;
  }
}
