import { Problem, ProblemType } from '@/domain/Problem';
import { ProblemLog } from '@/domain/ReportData/AssignmentData';

const getPartLetter = (index: number): string => {
  let mainLetter = -1;
  let secondLetter = -1;

  //If we need to use the second letter
  if (index >= 26) {
    while (index >= 26) {
      secondLetter++;
      index -= 26;
    }
  }

  //we have another problem if we somehow use all the double letter parts.
  // Someone would have to be crazy to get all the way through ZZ though...

  mainLetter = index;

  let part = 'Part ';

  if (secondLetter >= 0) {
    part += String.fromCharCode(64 + secondLetter);
  }

  part += String.fromCharCode(64 + mainLetter);

  return part;
};

/**
 * Updates problems in-place by adding partLetter attribute
 * (or null if not multipart)
 *
 * FAILS TO DETECT MULTIPART IF ONLY PART A IS INCLUDED
 * @param problems: Array<Problem>
 */
const addPartLetters = (problems: Array<Problem>): void => {
  // Determine which problems are multipart
  const assistmentIdToMultiPartMap = new Map<number, boolean>();
  for (const prob of problems) {
    if (!assistmentIdToMultiPartMap.has(prob.assistmentId)) {
      // Assign true if position > 1 (must be multipart)
      assistmentIdToMultiPartMap.set(prob.assistmentId, prob.position > 1);
    } else {
      // Assign true if find
      assistmentIdToMultiPartMap.set(prob.assistmentId, true);
    }
  }
  // Add part letters
  for (const prob of problems) {
    prob.partLetter = null; // Null for single part problems
    if (assistmentIdToMultiPartMap.get(prob.assistmentId)) {
      prob.partLetter = getPartLetter(prob.position);
    }
  }
};

/**
 * Updates problems in-place by adding psIndex attribute
 *
 * POSITION IS COMPUTED BASED ON THE CURRENT POSITION IN GIVEN ORDER OF PROBLEMS
 * PROBLEMS WITH THE SAME ASSISTMENTS ID WILL HAVE THE SAME PROBLEM GROUP
 * POSITION INDEX NUMBER STARTING AT 1
 *
 * @param problems: Array<Problem>
 */
const addProblemNumber = (problems: Array<Problem>): void => {
  let problemNumber = 0;
  let assistmentId = 0;

  // Add index attribute
  for (const problem of problems) {
    // Not a subproblem
    if (problem.assistmentId !== assistmentId) {
      assistmentId = problem.assistmentId;
      problemNumber += 1;
    }

    problem.assistmentPosition = problemNumber;
  }
};

/**
 * Score an individual problem log based on problem type
 * Assign partial credit to open response questions
 * All other questions are 1 if correct 0 otherwise
 * @param prLog: ProblemLog - the log to score
 * @returns score 0-1
 */
const scoreProblemForStandardsReport = (
  prLog: ProblemLog,
  problem: Problem | undefined // if no problem provided, no partial credit
): number => {
  switch (problem?.type) {
    // Count partial credit for open response questions
    case ProblemType.OPEN_RESPONSE:
      return prLog.continuousScore;
    // All other problem types are 1 or 0 only
    default:
      return prLog.discreteScore || 0;
  }
};

export {
  getPartLetter,
  addPartLetters,
  addProblemNumber,
  scoreProblemForStandardsReport,
};
