
import dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProgressCard, {
  ProgressState,
  StudentProgressRecord,
} from '@/components/Report/ProgressCard.vue';
import {
  ProblemLogAndActions,
  StudentLog,
} from '@/domain/ReportData/AssignmentData';
import { User } from '@/domain/User';
import { VNextAction } from '@/domain/StudentProblemAction';

interface AssignmentLogSummary {
  studentXrefs: Array<string>;
  studentsInProgress: Array<StudentProgressRecord>;
  studentsCompleted: Array<StudentProgressRecord>;
}

@Component({
  components: {
    ProgressCard,
  },
})
export default class ReportLandingPage extends Vue {
  @Prop({ required: true }) assignDate: number;
  @Prop({ required: true }) assignees: Array<User>;
  @Prop({ default: [] }) assignmentStudentLogs: Array<StudentLog>;

  // Allows us access to the enum in the template.
  ProgressState = ProgressState;

  //////////
  // Data //
  //////////

  get xrefToStudentMap(): Map<string, User> {
    const xrefToStudent = new Map();
    for (const student of this.assignees) {
      xrefToStudent.set(student.xref, student);
    }
    return xrefToStudent;
  }

  get assignmentLogSummary(): AssignmentLogSummary {
    let summary: AssignmentLogSummary = {
      studentXrefs: [],
      studentsInProgress: [],
      studentsCompleted: [],
    };

    for (const studentLog of this.assignmentStudentLogs) {
      summary.studentXrefs.push(studentLog.studentXref);

      // Find assignee user
      const assigneeFound = this.xrefToStudentMap.get(studentLog.studentXref);

      if (assigneeFound) {
        const studentProgress: StudentProgressRecord = {
          name: assigneeFound.displayName,
        };

        if (studentLog.asEndTime) {
          // With an end time means they finished
          studentProgress.timestamp = this.getTimeStamp(studentLog.asEndTime);
          summary.studentsCompleted.push(studentProgress);
        } else {
          // No end time means they have not finish the assignment yet
          const lastWorkedOn: number | null = this.getLastWorkedOn(studentLog);

          if (lastWorkedOn) {
            studentProgress.timestamp = this.getTimeStamp(lastWorkedOn);
          } else {
            // No valid end times in problem logs?
            studentProgress.timestamp = '-';
          }

          summary.studentsInProgress.push(studentProgress);
        }
      }
    }

    return summary;
  }

  get studentsNotStarted(): Array<StudentProgressRecord> {
    //  Students who we have assignment logs for
    const studentXrefsInAssignmentLogs: Array<string> =
      this.assignmentLogSummary.studentXrefs;

    // No assignment logs
    const missingStudents: Array<User> = this.assignees.filter(
      (student: User) => {
        return !studentXrefsInAssignmentLogs.includes(student.xref);
      }
    );

    // Only those students that have started the assignment are included in the returned data
    return missingStudents.map((student: User): StudentProgressRecord => {
      return { name: student.displayName };
    });
  }

  get studentsAssigned(): Array<StudentProgressRecord> {
    const assignedOn = this.getTimeStamp(this.assignDate);

    return this.assignees.map((student: User): StudentProgressRecord => {
      return {
        name: student.displayName,
        timestamp: assignedOn,
      };
    });
  }

  /////////////
  // Methods //
  /////////////

  getTimeStamp(unixTimeInMs: number): string {
    // Parse UNIX timestamp in ms
    const date = dayjs(unixTimeInMs);
    return date.format('MMM D, h:mma');
  }

  getLastWorkedOn(studentLog: StudentLog): number | null {
    let lastWorkedOn: number | null = null;

    // Cannot assume problem logs are ordered by end time
    if (studentLog.problemLogAndActions) {
      studentLog.problemLogAndActions.forEach(
        (problemLogAndAction: ProblemLogAndActions) => {
          if (
            problemLogAndAction.actions &&
            problemLogAndAction.actions.length > 0
          ) {
            // Actions are ordered!
            const lastActionIndex: number =
              problemLogAndAction.actions.length - 1;
            const lastAction: VNextAction =
              problemLogAndAction.actions[lastActionIndex];
            if (lastAction.timestamp) {
              if (lastWorkedOn == null) {
                // Not set
                lastWorkedOn = lastAction.timestamp;
              } else if (lastAction.timestamp > lastWorkedOn) {
                // More recent
                lastWorkedOn = lastAction.timestamp;
              }
            }
          }
        }
      );
    }

    return lastWorkedOn;
  }
}
