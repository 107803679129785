
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LmsProviderType } from '@/domain/LmsProviderType';
import { getClasses, importClasses, synchronize } from '@/api/class.api';
import dayjs from 'dayjs';
import { Class, ImportFilter, LmsClass } from '@/domain/Class';
import { ProblemSet, PsType } from '@/domain/ProblemSet';
import { AssignmentDefinition } from '@/domain/Assignment';
import { ModifiedAssignData, VnextAssignData } from '@/domain/AssignData';
import { updateAssignment } from '@/api/assignment.api';
import { createProblemSet, ProblemSetOwnershipType } from '@/api/pspr.api';
import { createCourseAssignment } from '@/api/core/course.api';
import { ComposedError } from '@/plugins/axios';
import PopupBlockedDialog from '@/components/base/PopupBlockedDialog.vue';
import AssigneeSelector, {
  AssigneeSummary,
} from '@/components/FindProblems/AssigneeSelector.vue';
import TestModeDialog from './TestModeDialog.vue';
import { getDeepLinkResponse } from '@/api/core/lti.api';

export enum Mode {
  ASSIGN = 1,
  EDIT = 2,
  REASSIGN = 3,
}

@Component({
  components: {
    AssigneeSelector,
    PopupBlockedDialog,
    TestModeDialog,
  },
})
export default class AssignDialog extends Vue {
  @Prop({ default: '' }) defaultName: string;
  @Prop() problems: number[];
  @Prop() value: boolean;
  @Prop({ default: null }) numTotalProblems: number | null; // number of problems in set/search results
  @Prop({ default: 'Assign to Class' }) title: string;
  @Prop({ default: Mode.ASSIGN }) mode: Mode;

  // Prepopulate dialog
  @Prop() assignment: AssignmentDefinition;
  @Prop() problemSet: ProblemSet;

  // Allows us access to the enum in the template.
  Mode = Mode;

  // Loading states
  assigning = false;
  updating = false;

  assignmentMade = false;

  lmsArr = [LmsProviderType.GOOGLE_CLASSROOM, LmsProviderType.CANVAS];
  problemOrder = [
    PsType.LINEAR_COMPLETE_ALL,
    PsType.RANDOM_COMPLETE_ALL,
    PsType.STUDENT_CHOICE,
  ];

  assignmentName = '';
  chosenLms = 0;
  chosenClasses: string[] = [];
  testMode = false;
  chosenProblemOrder: string | null = null;

  importSyncDialog = false;
  lmsClassList: LmsClass[] = [];
  downloadingLmsClassList = false;
  synchronizing = false;
  importDialog = false;
  importing = false;
  selectedImportClasses: string[] = [];

  alert = {
    show: false,
    type: 'success',
    msg: '',
  };

  popupBlockedDialog = false;
  assignError: ComposedError | null = null;

  //actual release and due date dayjs objects
  displayDateFormat = 'M/D/YY';
  displayTimeFormat = 'h:mm A';
  pickerDateFormat = 'YYYY-MM-DD';
  pickerTimeFormat = 'HH:mm';

  //objects for pickers
  releaseDate = '';
  releaseTime = '';
  dueDate = '';
  dueTime = '';

  //handles menus
  releaseDateMenu = false;
  releaseTimeMenu = false;

  dueDateMenu = false;
  dueTimeMenu = false;

  dialog = false;

  assignees: Map<string, AssigneeSummary> = new Map();
  showTestModeDialog = false;

  get showDialog(): boolean {
    // return this.value;
    return this.value ? this.value : this.dialog;
  }

  set showDialog(val: boolean) {
    this.dialog = val;

    this.$emit('input', val);
  }

  @Watch('chosenLms')
  onLmsChange(): void {
    // Clear any prior selections
    this.chosenClasses = [];
    this.assignees = new Map();
  }

  /**
   * Computed props: classes from store
   */
  get lmsPtypes(): Array<LmsProviderType> {
    if (this.isLtiUser) {
      return [LmsProviderType.LTI_ENABLED];
    }

    return this.lmsArr;
  }

  get importedClasses(): Array<Class> {
    const imported: Class[] = this.$store.state.classList.classes;

    const lms = LmsProviderType.find(this.chosenLms);

    switch (lms) {
      case LmsProviderType.LTI_ENABLED: {
        // Find launched course context
        const courseXref = this.$store.state.lti.currentlyLaunchedCourseXref;
        const courseFound = imported.find(({ id }) => id === courseXref);

        return courseFound ? [courseFound] : [];
      }
      default:
        // Filter imported classes for only those of chosen LMS
        return imported.filter((c: Class) => {
          if (c.lmsType) {
            return c.lmsType.id === this.chosenLms;
          }

          return false;
        });
    }
  }

  get isProcessing(): boolean {
    return this.assigning || this.updating;
  }

  get hasReleased(): boolean {
    var now = dayjs();

    if (this.assignment) {
      const releaseDate = dayjs(this.assignment.releaseDate);

      if (releaseDate.isBefore(now)) {
        return true;
      }
    }

    return false;
  }

  get isPastDate(): boolean {
    if (this.releaseDate) {
      const now = dayjs();
      const releaseDate = dayjs(this.releaseDate);
      return releaseDate.isBefore(now);
    }
    return false;
  }

  get importSyncDialogButtonText(): string {
    if (this.isLtiUser) {
      return 'Import';
    } else {
      return 'Import/Sync';
    }
  }

  // ----------
  // Computed props: Defaults
  // ----------

  get testModeEnabled(): boolean {
    return this.$store.state.auth.user.settings.testModeEnabled || false;
  }

  get selectedLms(): number {
    if (!this.chosenLms) {
      // Default to LMS of the assignment if given
      if (this.assignment && this.assignment.lmsProviderType) {
        this.chosenLms = this.assignment.lmsProviderType.id;
      } else {
        this.chosenLms = this.getCurrentUser.lmsProviderType?.id ?? 0;
      }
    }

    return this.chosenLms;
  }

  set selectedLms(id: number) {
    this.chosenLms = id;
  }

  get selectedClasses(): string[] {
    // No classes selected
    if (this.chosenClasses.length === 0) {
      // Default to class/assignee of the assignment if LMS matches
      if (this.assignment && this.assignment.lmsProviderType) {
        // LMS matches user selection
        if (this.chosenLms === this.assignment.lmsProviderType.id) {
          // Default to assignee (should be imported and active?)
          // Just to make sure:
          const defaultClass = this.importedClasses.find(
            ({ id }) => id === this.assignment.assigneeXref
          );
          if (defaultClass) {
            // Found.
            this.chosenClasses.push(this.assignment.groupContextXref);
          }
        }
      }
    }

    return this.chosenClasses;
  }

  set selectedClasses(ids: string[]) {
    this.chosenClasses = ids;
  }

  get matchedCourses(): Class[] {
    return this.importedClasses.filter((c: Class) => {
      return this.chosenClasses.includes(c.id);
    });
  }

  get isSkillBuilder(): boolean {
    // Figure out the default problem order.
    if (this.problemSet && this.problemSet.isSkillBuilder) {
      // Do nothing, we can't change it.
      return true;
    }

    return false;
  }

  get selectedProblemOrder(): string | null {
    // FIXME: Differs from assignments?
    if (!this.chosenProblemOrder) {
      // Figure out the default problem order.
      if (!this.isSkillBuilder) {
        if (this.problemSet) {
          // can't call "includes" because they are all objects.
          // intsead filter on the id being equal to the problem set id and see
          // if it was found
          if (
            this.problemOrder.filter((t) => t.id === this.problemSet.type.id)
              .length > 0
          ) {
            this.chosenProblemOrder = this.problemSet.type.id;
          }
        } else {
          // Default to linear
          this.chosenProblemOrder = PsType.LINEAR_COMPLETE_ALL.id;
        }
      } else {
        // Default to random for skill builders
        this.chosenProblemOrder = PsType.SKILL_BUILDER_RANDOM.id;
      }
    }

    return this.chosenProblemOrder;
  }

  set selectedProblemOrder(id: string | null) {
    this.chosenProblemOrder = id;
  }

  get todayDate(): string {
    return dayjs().format(this.pickerDateFormat);
  }

  @Watch('showDialog')
  onShowDialog(): void {
    if (this.showDialog && !this.isProcessing) {
      // Start fresh
      // Clear prior data
      this.clearAndReset();

      // Default to assignment properties if given
      if (this.assignment) {
        this.defaultToAssignmentDates();
      }
    }
  }

  clearAndReset(): void {
    // This is here to get past the stupid warning from typescript/vetur about
    // assigning the assignmentName before defaultName has been intialized
    this.assignmentName = this.defaultName;

    // Reset all fields
    this.chosenLms = 0;
    this.chosenClasses = [];
    this.assignees = new Map();

    this.testMode = false;

    this.chosenProblemOrder = null;

    this.selectedImportClasses = [];

    // Clear any alerts from before
    this.alert = {
      show: false,
      type: 'success',
      msg: '',
    };

    this.assignError = null;
    this.popupBlockedDialog = false;

    this.releaseDate = '';
    this.releaseTime = '';
    this.dueDate = '';
    this.dueTime = '';

    // Return from reassignment?
    this.assignmentMade = false;
  }

  defaultToAssignmentDates(): void {
    var assignmentReleaseDate = dayjs(this.assignment.releaseDate);

    this.releaseDate = assignmentReleaseDate.format(this.pickerDateFormat);
    this.releaseTime = assignmentReleaseDate.format(this.pickerTimeFormat);

    if (this.assignment.dueDate) {
      let assignmentDueDate = dayjs(this.assignment.dueDate);

      this.dueDate = assignmentDueDate.format(this.pickerDateFormat);
      this.dueTime = assignmentDueDate.format(this.pickerTimeFormat);
    }
  }

  getImportClasses(): void {
    if (!this.chosenLms) {
      return;
    }
    this.lmsClassList = [];
    this.downloadingLmsClassList = true;
    getClasses({ lmsid: this.chosenLms, importFilter: ImportFilter.UNIMPORTED })
      //rename unimportedClasses to classes
      .then(({ unimportedClasses: classes }) => {
        if (classes && classes.length > 0) {
          this.lmsClassList = classes;
          this.importDialog = true;
        } else {
          this.showAlert('warning', 'No classes found to import');
        }
        this.downloadingLmsClassList = false;
        this.importSyncDialog = false;
      })
      .catch((error) => {
        error.handleGlobally &&
          error
            .handleGlobally()
            .then(() => {
              this.downloadingLmsClassList = false;
              this.$notify('Authorization updated. Please try again');
            })
            .catch(() => {
              this.showAlert('error', 'Import Failed');
              //our popup was blocked
              this.assignError = error;
              this.popupBlockedDialog = true;
            });
      });
  }

  importClasses(): void {
    if (!this.selectedImportClasses) {
      return;
    }
    const classesToImport = this.lmsClassList.filter((lmsClass) => {
      return this.selectedImportClasses.includes(lmsClass.id);
    });
    const lmsPtype = LmsProviderType.find(this.chosenLms);
    if (lmsPtype) {
      this.importing = true;
      importClasses(classesToImport, lmsPtype)
        .then(() => {
          if (this.isLtiUser) {
            this.$store.dispatch('lti/requestLaunchedCourseXref');
          }
          // FIXME: Make another request for list of imported classes or
          // trust that what we have sent to the server is the truthy?
          // Update cached classes in store
          this.$store.dispatch('classList/requestClasses', true).then(() => {
            this.showAlert('success', 'Class(es) successfully imported.');

            this.importing = false;
            this.importDialog = false;
          });
        })
        .catch((error) => {
          error.handleGlobally &&
            error
              .handleGlobally()
              .then(() => {
                this.importing = false;
                this.$notify('Authorization updated. Please try again.');
              })
              .catch(() => {
                this.showAlert('error', 'Import failed.');
                //our popup was blocked
                this.assignError = error;
                this.popupBlockedDialog = true;
              });
        });
    }
  }

  showAlert(type: string, msg: string): void {
    this.alert = {
      show: true,
      type: type,
      msg: msg,
    };
  }

  synchronize(): void {
    if (!this.chosenLms) {
      return;
    }
    this.synchronizing = true;

    synchronize(this.chosenLms)
      .then(() => {
        // this.showAlert('success', 'Synchronization was successful.');

        // FIXME: Make another request for list of imported classes or
        // trust that what we have sent to the server is the truthy?
        // Update cached classes in store
        this.$store.dispatch('classList/requestClasses', true).then(() => {
          this.showAlert('success', 'Synchronization was successful.');

          this.synchronizing = false;
          this.importSyncDialog = false;
        });
      })
      .catch((error) => {
        error.handleGlobally &&
          error
            .handleGlobally()
            .then(() => {
              this.synchronizing = false;
              this.$notify('Authorization updated. Please try again');
            })
            .catch(() => {
              this.showAlert('error', 'Synchronization failed.');
              //our popup was blocked
              this.assignError = error;
              this.popupBlockedDialog = true;
            });
      });
  }

  get assignButtonText(): string {
    let numClasses = this.chosenClasses.length;
    if (numClasses === 0) {
      //leave the text alone
      return 'Assign';
    }
    let assignText = `Assign to ${numClasses} Class`;

    if (numClasses > 1) {
      assignText += 'es';
    }

    return assignText;
  }

  get chosenLmsName(): string {
    const lms = LmsProviderType.find(this.chosenLms);
    if (lms) {
      return lms.displayName;
    } else {
      return '';
    }
  }

  async assign(): Promise<void> {
    this.assigning = true;
    const data: Partial<VnextAssignData> = {
      name: this.assignmentName,
    };
    // Make sure canonical assignments does NOT affect Cignition / LDOE DSDR?
    if (this.problemSet) {
      // Always send up the parent problem set if it is from a known problem set
      data.parentProblemSetCeri = this.problemSet.xref;
      data.problemSetCeri = this.problemSet.xref;
    }
    if (
      !this.problemSet ||
      (!this.isSkillBuilder &&
        ((this.numTotalProblems &&
          this.problems.length !== this.numTotalProblems) ||
          (this.problemSet &&
            // For E-TRIALS: Choose-condition sections don't create new PS
            this.problemSet.type.id !== PsType.RANDOM_COMPLETE_ONE.id &&
            this.chosenProblemOrder !== this.problemSet.type.id)))
    ) {
      // Create new PS
      const ps = await createProblemSet({
        name: this.assignmentName,
        assistmentIds: this.problems,
        type: this.chosenProblemOrder,
        ownership: ProblemSetOwnershipType.SYSTEM,
      });
      data.problemSetCeri = ps.xref;
    }

    // Release and due date
    if (this.releaseDate) {
      // Not empty
      const releaseDateObj = dayjs(`${this.releaseDate} ${this.releaseTime}`);
      data.releaseDate = releaseDateObj.valueOf();
    } // If empty, exclude from request to set release date to 'now' in the server

    if (this.dueDate) {
      // Not empty
      const dueDateObj = dayjs(`${this.dueDate} ${this.dueTime}`);
      data.dueDate = dueDateObj.valueOf();
    } // If empty, exclude from request to not set any due date

    // TODO/FIXME: Check due date is after release date and alert user otherwise
    if (this.releaseDate && this.dueDate) {
      const releaseDate = dayjs(`${this.releaseDate} ${this.releaseTime}`);
      const dueDate = dayjs(`${this.dueDate} ${this.dueTime}`);

      if (dueDate.isSame(releaseDate) || dueDate.isBefore(releaseDate)) {
        this.$notify('Due date must be a date/time after the release date.');
        this.assigning = false;
        return;
      }
    }
    // before sending request to server?

    // Enabled test mode on assignment
    if (this.testModeEnabled && this.testMode) {
      data.settings = {
        correctnessFeedback: false,
        tutoringAccessible: false,
      };
    }

    const lmsPtype = LmsProviderType.find(this.chosenLms);

    if (lmsPtype) {
      //reset checkers
      this.assignError = null;
      this.popupBlockedDialog = false;

      const promises: Array<Promise<unknown>> = [];
      let promise;

      for (const courseXref of this.chosenClasses) {
        const assignees = this.assignees.get(courseXref);
        let assignData: VnextAssignData | null = null;

        if (assignees?.class) {
          // Course assignment
          assignData = data as unknown as VnextAssignData;
        } else if (
          assignees?.studentXrefs &&
          assignees?.studentXrefs.length > 0
        ) {
          // Individual/Subgroup assignment
          const assignDataExt: VnextAssignData = {
            ...data,
          } as unknown as VnextAssignData;

          assignDataExt.userXrefs = assignees.studentXrefs;
          assignData = assignDataExt;
        }

        if (assignData) {
          if (this.isLtiUser) {
            // Should only be ONE assignment
            promise = getDeepLinkResponse(assignData).then(
              ({ jwt, redirectUrl }) => {
                this.$router.push({
                  name: 'LtiDeepLinkResponsePage',
                  query: {
                    jwt,
                    redirectUrl,
                  },
                });
              }
            );
          } else {
            promise = createCourseAssignment(
              courseXref,
              lmsPtype,
              assignData as unknown as VnextAssignData
            );
          }

          promises.push(promise);
        }
      }

      // FIXME: What if one assignment failed to be created, but the others did?
      Promise.all(promises)
        .then((responses) => {
          this.assignmentMade = true;
          this.$emit('created', responses); // Assignment xrefs
          this.assigning = false;
        })
        .catch((error) => {
          this.assigning = false;

          if (error.statusCode) {
            switch (error.statusCode) {
              case 401:
                if (this.isLtiUser) {
                  // Go to unauthenticated page
                  this.$router.push({
                    path: '/401',
                  });
                }
                break;
              case 404:
                this.$notify(
                  'Resource not found. Unable to create the assignment(s).'
                );
                break;
              case 403:
                if (this.isLtiUser) {
                  // Not a deep link request?
                  this.$notify(
                    'You do not have the authority to create the assignment. ' +
                      'Please make sure you are performing assignment selection in the LMS,' +
                      ' and that the LMS expects you to be sending back an assignment.'
                  );
                } else {
                  this.$notify(
                    'You do not have the authority to create the assignment(s).'
                  );
                }
                break;
              case 417:
                error.handleGlobally &&
                  error
                    .handleGlobally()
                    .then(() => {
                      //if the popup was not blocked we can continue as we have been
                      this.$notify('Authorization updated. Please try again.');
                    })
                    .catch(() => {
                      //our popup was blocked
                      this.assignError = error;
                      this.popupBlockedDialog = true;
                    });
                break;
              default:
                this.$notify('Fail to create the assignment(s).');
            }
          }
        });
    } else {
      // FIXME: Better way to do this?
      this.$notify('Unrecognized LMS to assign to?');
    }
  }

  update(): void {
    const modifiedAssignData = this.modifiedFields;

    // TODO/FIXME: Check whether it is empty before sending and alert
    // user otherwise before sending the request to server?
    // TODO/FIXME: Check due date is after release date and alert user otherwise
    const { releaseDate, dueDate } = modifiedAssignData;
    if (releaseDate && dueDate) {
      const formattedReleaseDate = dayjs(releaseDate);
      const formattedDueDate = dayjs(dueDate);

      if (
        formattedDueDate.isSame(formattedReleaseDate) ||
        formattedDueDate.isBefore(formattedReleaseDate)
      ) {
        this.$notify('Due date must be a date/time after the release date.');
        return;
      }
    }

    // before sending request to server?
    if (this.assignment) {
      this.updating = true;

      updateAssignment(
        this.assignment.xref,
        this.assignment.lmsProviderType,
        modifiedAssignData
      )
        .then(() => {
          this.$emit('updated', modifiedAssignData);
          this.$notify('Successfully updated assignment.');
          this.showDialog = false;
        })
        .catch((error) => {
          if (error.statusCode === 417) {
            // Authorization needed
            error.handleGlobally &&
              error
                .handleGlobally()
                .then(() => {
                  this.$notify('Authorization updated. Please try again.');
                })
                .catch(() => {
                  //our popup was blocked
                  this.assignError = error;
                  this.popupBlockedDialog = true;
                });
          } else {
            // Going to LP will not fix this issue.
            this.$notify('Failed to update assignment.');
          }
        })
        .finally(() => {
          this.updating = false;
        });
    }
  }

  get hasModifiedFields(): boolean {
    return Object.keys(this.modifiedFields).length > 0;
  }

  get modifiedFields(): ModifiedAssignData {
    const data: ModifiedAssignData = {};

    if (this.assignment) {
      // Changed assignment name
      if (this.assignmentName !== this.assignment.name) {
        data.name = this.assignmentName;
      }

      // TODO/FIXME: Under the assumption that the assignment MUST have a set release date
      // FIXME: Do we care about s/ms when editing an assignment as long as they are the same date and time?
      if (this.releaseDate) {
        const selectedReleaseDate = dayjs(
          `${this.releaseDate} ${this.releaseTime}`
        );
        const assignmentReleaseDate = dayjs(this.assignment.releaseDate);

        // Compare to minutes. Ignore s and ms.
        if (!selectedReleaseDate.isSame(assignmentReleaseDate, 'm')) {
          // Different release date
          data.releaseDate = selectedReleaseDate.valueOf();
        } else {
          // No changes made. Do not include as an updated field.
        }
      } else {
        // No release date selected. Set to NOW.
        data.releaseDate = null;
      }

      // FIXME: Should we disable due date (not allow such edit option) if it is already due?
      if (this.dueDate) {
        const selectedDueDate = dayjs(`${this.dueDate} ${this.dueTime}`);

        if (this.assignment.dueDate) {
          const assignmentDueDate = dayjs(this.assignment.dueDate);

          // Compare to minutes. Ignore s and ms.
          if (!selectedDueDate.isSame(assignmentDueDate, 'm')) {
            // Different due date
            data.dueDate = selectedDueDate.valueOf();
          } else {
            // No changes made. Do not include as an updated field.
          }
        } else {
          // No due date assigned originally. Added one.
          data.dueDate = selectedDueDate.valueOf();
        }
      } else {
        if (this.assignment.dueDate) {
          // Has a due date originally. Removed it. Reset to NO due date.
          data.dueDate = null;
        }
      }
    }

    return data;
  }

  mounted(): void {
    // Download classes if not already
    // if (this.$store.state.classList.classes.length === 0) {}
    // if (!this.$store.state.classList.hasDownloaded && !this.$store.state.classList.isDownloading) {}
    // If a list of all classes is missing from store, be the first instance to request it
    // Will be prevented to download again in store if already
    this.$store.dispatch('classList/requestClasses');
  }

  //Handle release and due date/time
  get formattedReleaseDate(): string {
    return this.releaseDate
      ? dayjs(this.releaseDate).format(this.displayDateFormat)
      : '';
  }
  set formattedReleaseDate(val: string) {
    if (val) {
      let dayjsDate = dayjs(val);
      if (dayjsDate.isValid()) {
        this.releaseDate = dayjsDate.format(this.pickerDateFormat);
      } else {
        //do nothing. releaseDate will stay what it was.
      }
    } else {
      this.releaseDate = '';
    }
  }

  get formattedReleaseTime(): string {
    if (this.releaseTime) {
      const timeArr = this.releaseTime.split(':');
      return dayjs()
        .hour(parseInt(timeArr[0]))
        .minute(parseInt(timeArr[1]))
        .format(this.displayTimeFormat);
    } else {
      return '';
    }
  }
  set formattedReleaseTime(val: string) {
    if (val) {
      let newVal = this.parseTime(val);
      if (newVal) {
        this.releaseTime = newVal;
      }
    } else {
      this.releaseTime = val;
    }
  }

  //Fixme the fact that this is copy paste...
  get formattedDueDate(): string {
    return this.dueDate
      ? dayjs(this.dueDate).format(this.displayDateFormat)
      : '';
  }
  set formattedDueDate(val: string) {
    if (val) {
      let dayjsDate = dayjs(val);
      if (dayjsDate.isValid()) {
        this.dueDate = dayjsDate.format(this.pickerDateFormat);
      } else {
        //do nothing. dueDate will stay what it was.
      }
    } else {
      this.dueDate = '';
    }
  }

  get formattedDueTime(): string {
    if (this.dueTime) {
      const timeArr = this.dueTime.split(':');
      return dayjs()
        .hour(parseInt(timeArr[0]))
        .minute(parseInt(timeArr[1]))
        .format(this.displayTimeFormat);
    } else {
      return '';
    }
  }
  set formattedDueTime(val: string) {
    if (val) {
      let newVal = this.parseTime(val);
      if (newVal) {
        this.dueTime = newVal;
      }
    } else {
      this.dueTime = val;
    }
  }

  //Returns empty string if not correctly parsed
  parseTime(time: string): string {
    //Make sure there is a space before AM/PM
    time = time.replace(/ *([AP]M)/i, ' $1');
    let dayjsTime = dayjs(`${this.todayDate} ${time}`);
    if (dayjsTime.isValid()) {
      return dayjsTime.format(this.pickerTimeFormat);
    }
    return '';
  }

  get hideTestModeDialog(): boolean {
    return this.$store.state.auth.user.settings.hideTestModeDialog || false;
  }

  @Watch('testMode')
  onTestModeChange(value: boolean): void {
    if (!this.hideTestModeDialog) {
      this.showTestModeDialog = value;
    }
  }
}
