import { tngAxios } from '@/plugins/axios';
import { ModifiedAssignData } from '@/domain/AssignData';
import { AssignmentDetail } from '@/domain/Assignment';
import { LmsProviderType } from '@/domain/LmsProviderType';

const END_POINT = '/classes/assignments';

const getAssignmentDetails = (xref: string): Promise<AssignmentDetail> => {
  return tngAxios.get(`${END_POINT}/${xref}/details`).then((result) => {
    return result.data;
  });
};

const deleteAssignment = (
  xref: string,
  lmsPtype: LmsProviderType | null
): Promise<void> => {
  const lmsPtypeId = lmsPtype ? lmsPtype.id : 0;
  return tngAxios.delete(`${END_POINT}/${xref}`, {
    params: { lmsPtype: lmsPtypeId },
  });
};

const updateAssignment = (
  xref: string,
  lmsPtype: LmsProviderType | null,
  modifiedFields: ModifiedAssignData
): Promise<void> => {
  const lmsPtypeId = lmsPtype ? lmsPtype.id : 0;
  return tngAxios.post(
    `${END_POINT}/${xref}?lmsPtype=${lmsPtypeId}`,
    modifiedFields
  );
};

const uploadAssignmentScores = (
  assignmentXref: string,
  lmsPtype: LmsProviderType
): Promise<void> => {
  return tngAxios.post(
    `${END_POINT}/${assignmentXref}/scores/upload?lmsPtype=${lmsPtype.id}`,
    null
  );
};

export {
  getAssignmentDetails,
  deleteAssignment,
  updateAssignment,
  uploadAssignmentScores,
};
