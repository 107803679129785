
import dayjs from 'dayjs';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Answer } from '@/domain/Problem';
import { StudentDataPerAssignmentProblem } from '@/domain/ReportData/Cignition';
import {
  ActionType,
  StudentProblemAction,
} from '@/domain/StudentProblemAction';
import { User } from '@/domain/User';
import LDOEMixins from '@/mixins/ldoe-mixins';

@Component
export default class StudentProblemView extends Mixins(LDOEMixins) {
  @Prop({ required: true }) studentXref: string;
  @Prop({ required: true }) problemAverageTime: number;
  @Prop({ required: true }) problemAnswers: Array<Answer>;
  @Prop({ required: true }) studentData: StudentDataPerAssignmentProblem;
  @Prop({ type: Boolean }) collapsed: boolean;

  // Allows us access to the enum in the template.
  ActionType = ActionType;

  //////////
  // Data //
  //////////

  get colspan(): number {
    return this.collapsed ? 12 : 3;
  }

  get answerColspan(): number {
    const colsLeft = 12 - this.colspan;

    return colsLeft ? colsLeft : 12;
  }

  get student(): User | null {
    // Find user
    const studentFound = this.selectedTutees.find(
      ({ xref }) => xref === this.studentXref
    );

    return studentFound ? studentFound : null;
  }

  get studentInitials(): string | null {
    return this.student
      ? this.student.firstName.charAt(0) + this.student.lastName.charAt(0)
      : null;
  }

  get studentTimeSpent(): string {
    return this.getTimeSpent(this.studentData.timeSpent);
  }

  get classAverageTime(): string {
    return this.getTimeSpent(this.problemAverageTime);
  }

  get hintsReceived(): Array<StudentProblemAction> {
    return this.studentData.studentActions.filter(
      (action: StudentProblemAction) => {
        return action.type === ActionType.HINT_REQUESTED_ACTION;
      }
    );
  }

  get teacherFeedback(): string {
    return this.studentData.teacherFeedback;
  }

  get problemCorrectAnswers(): Array<Answer> {
    if (this.problemAnswers) {
      return this.problemAnswers.filter((answer: Answer) => {
        return answer.isCorrect;
      });
    }

    return [];
  }

  get studentAnswersSubmitted(): Array<StudentProblemAction> {
    return this.studentData.studentActions.filter(
      (action: StudentProblemAction) => {
        return (
          action.type == ActionType.STUDENT_RESPONSE_ACTION ||
          action.type == ActionType.STUDENT_SUBMISSION_ACTION
        );
      }
    );
  }

  get color(): string {
    // Color depends on the order in which this tutee is found
    const index = this.selectedTuteeXrefs.indexOf(this.studentXref);

    switch (index) {
      case 0:
        return this.firstTuteeColor;
      case 1:
        return this.secondTuteeColor;
      case 2:
        return this.thirdTuteeColor;
      case 3:
        return this.fourthTuteeColor;
      default:
        return 'incorrect'; // Well... that's very bad.
    }
  }

  ///////////
  // Store //
  ///////////

  get selectedTutees(): Array<User> {
    return this.$store.getters['ldoe/getSelectedTutees'];
  }

  get selectedTuteeXrefs(): string[] {
    return this.$store.state.ldoe.selectedTuteeXrefs;
  }

  /////////////
  // Methods //
  /////////////

  getTimeSpent(ms: number): string {
    // Format 00:00:00:00
    // return dayjs.duration(ms).format('DD:HH:mm:ss');
    return dayjs.duration(ms).format('HH:mm:ss');
  }

  mounted(): void {
    //Ignore any for now. We want to eventually extend window interface to allow us to use wiris or we want something other than wiris
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).com.wiris.js.JsPluginViewer.parseDocument();
  }
}
