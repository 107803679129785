
import dayjs from 'dayjs';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ProblemSet } from '@/domain/ProblemSet';
import ProblemSetMenu from '@/components/MyProblemSets/ProblemSetMenu.vue';

dayjs.extend(isYesterday);
dayjs.extend(isToday);

@Component({
  components: {
    ProblemSetMenu,
  },
})
export default class ProblemSetView extends Vue {
  @Prop() ps: ProblemSet;
  @Prop({ default: 'created' })
  dateToShow: 'created' | 'lastModifiedByTeacher';

  get displayedDate(): string {
    const unformatedDate = this.ps[this.dateToShow];
    if (unformatedDate) {
      const date = dayjs(unformatedDate);
      if (date.isToday()) {
        return 'Today';
      }
      if (date.isYesterday()) {
        return 'Yesterday';
      }
      return date.format('M/D/YYYY');
    }
    return '';
  }
  get dateName(): string {
    switch (this.dateToShow) {
      case 'created':
        return 'Created';
      case 'lastModifiedByTeacher':
        return 'Last Modified';
      default:
        return '';
    }
  }

  goToProblemSet(): void {
    this.$router.push({
      name: 'editMyPS',
      params: {
        id: `${this.ps.id}`,
      },
    });
  }
}
