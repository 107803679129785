
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AssignmentDefinition, AssignmentDetail } from '@/domain/Assignment';
import { Class } from '@/domain/Class';
import { ProblemSet } from '@/domain/ProblemSet';
import { ModifiedAssignData } from '@/domain/AssignData';
import { getAssignmentDetails } from '@/api/assignment.api';
import AssignmentDetailView from '@/components/MyAssignments/AssignmentDetailView.vue';
import AssignDialog, { Mode } from '@/components/FindProblems/AssignDialog.vue';
import DeleteAssignmentDialog from '@/components/MyAssignments/DeleteAssignmentDialog.vue';
import dayjs from 'dayjs';
import { getProblemsByPsXref, getProblemSetByXref } from '@/api/pspr.api';
import { Problem } from '@/domain/Problem';

@Component({
  components: {
    AssignmentDetailView,
    AssignDialog,
    DeleteAssignmentDialog,
  },
})
export default class AssignmentView extends Vue {
  @Prop({ required: true }) assignment: AssignmentDefinition;
  @Prop({ default: 'releaseDate' }) dateToShow: 'releaseDate' | 'dueDate';

  // To be downloaded asynchronously
  problemSet: ProblemSet | null = null;
  problems: Array<Problem> = [];
  details: AssignmentDetail | null = null;

  // Loading states
  isDownloadingPS = false;
  hasDownloadedPS = false;
  isDownloadingProblems = false;
  hasDownloadedProblems = false;
  isDownloadingDetails = false;
  hasDownloadedDetails = false;

  showMenu = false;

  // Formatting
  placeholder = '-----';
  dateTimeFormat = 'dddd, MMM DD, YYYY h:mm A';

  // Allows us access to the enum in the template.
  Mode = Mode;

  // TODO/FIXME: We can also treat our custom dialog components
  // as any other components where the parent component (here)
  // controls the opening/closing of them based on the events
  // emitted regarding the operations that took place? But for now,
  // custom dialog components emit their new input value upon opening/closing.
  editDialog = false;
  assignDialog = false;
  deleteDialog = false;

  //////////////
  // Computed //
  //////////////

  /**
   * Store
   */
  get classes(): Array<Class> {
    return this.$store.state.classList.classes;
  }
  /**
   * Display & Rendering
   */
  get targetClass(): Class | null {
    const classXref = this.assignment.groupContextXref;

    var classFound = this.classes.find(({ id }) => id === classXref);

    if (classFound) {
      return classFound;
    }

    return null;
  }
  get className(): string {
    if (this.targetClass) {
      return this.targetClass.name;
    }

    return this.placeholder;
  }
  get status(): string {
    const dateOfInterest = this.assignment[this.dateToShow];

    switch (this.dateToShow) {
      case 'releaseDate':
        if (dateOfInterest) {
          var date = dayjs(dateOfInterest);
          var now = dayjs();

          if (date.isBefore(now)) {
            return 'Released';
          } else if (date.isAfter(now)) {
            return 'Release Scheduled for';
          }
        } else {
          return 'No Release Date';
        }

        break;
      case 'dueDate':
        if (dateOfInterest) {
          return 'Due';
        } else {
          return 'No Due Date';
        }
      default:
        return this.placeholder;
    }

    return this.placeholder;
  }
  get date(): string {
    const dateOfInterest = this.assignment[this.dateToShow];

    if (dateOfInterest) {
      let date = dayjs(dateOfInterest);

      return date.format(this.dateTimeFormat);
    }

    return '';
  }
  /**
   * Problem set properties
   */
  get isSkillBuilder(): boolean {
    return this.problemSet ? this.problemSet.isSkillBuilder : false;
  }
  /**
   * Problems to reassign if new PS needs to be created
   */
  get assistmentIds(): Array<number> {
    // Select all problems (cannot choose a subset of problems to reassign)
    return [
      ...new Set(this.problems.map((problem: Problem) => problem.assistmentId)),
    ];
  }
  /**
   * Navigations
   */
  get previewLink(): string {
    const usr = this.getCurrentUser;
    return `${this.$store.state.urls.tutorTemplateUrl}?assignmentRef=${this.assignment.xref}&preview=true&pr=TNG&ut=${usr.userToken}&laId=${usr.loginAliasId}`;
  }
  get skillBuilderReportUrl(): string {
    return `${process.env.VUE_APP_TNG_URL}/masteryreport/${this.assignment.xref}?hideNav=true`;
  }

  /////////////
  // Methods //
  /////////////

  // Must close menu on open of a dialog
  // Otherwise there is an unexpected closing
  // of dialog on mouseup outside of the dialog
  // https://github.com/vuetifyjs/vuetify/issues/11521
  openEditDialog(): void {
    this.editDialog = true;
    this.showMenu = false;
  }
  openAssignDialog(): void {
    this.assignDialog = true;
    this.showMenu = false;
  }
  openDeleteDialog(): void {
    this.deleteDialog = true;
    this.showMenu = false;
  }
  downloadDetails(): void {
    // Download assignment detail if not already
    if (!this.isDownloadingDetails && !this.hasDownloadedDetails) {
      this.isDownloadingDetails = true;

      getAssignmentDetails(this.assignment.xref)
        .then((data) => {
          this.details = data;
          this.hasDownloadedDetails = true;
        })
        .finally(() => {
          this.isDownloadingDetails = false;
        });
    }
  }
  updateAssignment(modifiedAssignData: ModifiedAssignData): void {
    // Modified assignment
    if (modifiedAssignData.name) {
      this.assignment.name = modifiedAssignData.name;
    }

    if (modifiedAssignData.releaseDate) {
      this.assignment.releaseDate = modifiedAssignData.releaseDate;
    } else if (modifiedAssignData.releaseDate === null) {
      // Removed? Set to now.
      this.assignment.releaseDate = dayjs().valueOf();
    } else {
      // Not modified if not included. So nothing to update.
    }

    if (modifiedAssignData.dueDate) {
      this.assignment.dueDate = modifiedAssignData.dueDate;
    } else if (modifiedAssignData.dueDate === null) {
      // We do have a due date set to null
      // Remove/delete due date?
      this.assignment.dueDate = null;
    } else {
      // Not modified if not included. So nothing to update.
    }
  }
  created(): void {
    // Download classes if not already
    // if (this.$store.state.classList.classes.length === 0) {}
    // if (!this.$store.state.classList.hasDownloaded && !this.$store.state.classList.isDownloading) {}
    // If a list of all classes is missing from store, be the first instance to request it
    // Will be prevented to download again in store if already
    this.$store.dispatch('classList/requestClasses');

    // Get PS
    this.isDownloadingPS = true;
    getProblemSetByXref(this.assignment.problemSetCeri, ['SKILLS'])
      .then((ps: ProblemSet) => {
        this.problemSet = ps;
        this.hasDownloadedPS = true;
      })
      .finally(() => {
        this.isDownloadingPS = false;
      });

    // Get problems
    this.isDownloadingProblems = true;
    getProblemsByPsXref(this.assignment.problemSetCeri, [])
      .then((problems: Array<Problem>) => {
        this.problems = problems;
        this.hasDownloadedProblems = true;
      })
      .finally(() => {
        this.isDownloadingProblems = false;
      });
  }
}
