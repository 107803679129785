import { MutationTree } from 'vuex';
import { Problem } from '@/domain/Problem';
import { ProblemSet } from '@/domain/ProblemSet';
import { SearchState } from './types';

export const mutations: MutationTree<SearchState> = {
  resetProblemSearch(state: SearchState): void {
    state.problems = [];
    state.numProblems = 0;
    state.nextPageToken = null;
    state.isDownloadingProblems = false;
    state.shouldDownloadMoreProblems = false;
  },
  setProblems(state: SearchState, newList: Problem[][]): void {
    if (state.problems.length === 0) {
      state.problems = newList;
    } else {
      state.problems = [...state.problems, ...newList];
    }
  },
  setNumProblems(state: SearchState, numProblems: number): void {
    state.numProblems = numProblems;
  },
  setSkillBuilders(state: SearchState, newList: ProblemSet[]): void {
    state.skillBuilders = newList;
  },
  setIsDownloadingProblems(state: SearchState, value: boolean): void {
    state.isDownloadingProblems = value;
  },
  setNextPageToken(state: SearchState, value: string | null): void {
    state.nextPageToken = value;

    state.shouldDownloadMoreProblems = value ? true : false;
  },
  setShouldDownloadMoreProblems(state: SearchState, value: boolean): void {
    state.shouldDownloadMoreProblems = value;
  },
};
