
import { Component, Vue } from 'vue-property-decorator';
import { ProblemSet } from '@/domain/ProblemSet';
@Component({})
export default class SearchResultsSkillBuilders extends Vue {
  get skillBuilders(): ProblemSet {
    return this.$store.state.search.skillBuilders;
  }
  get selectedSkillId(): number {
    return this.$store.state.skillList.selectedSkillId;
  }
  goToSkillBuilder(ps: ProblemSet): void {
    this.$router.push({
      name: 'LessonPage',
      params: {
        type: 'ps',
        psId: `${ps.id}`,
        id: `${ps.id}`,
      },
      query: {
        skillId: `${this.selectedSkillId}`,
      },
    });
  }
}
