import { render, staticRenderFns } from "./ProblemTableView.vue?vue&type=template&id=7b1978b1&scoped=true&"
import script from "./ProblemTableView.vue?vue&type=script&lang=ts&"
export * from "./ProblemTableView.vue?vue&type=script&lang=ts&"
import style0 from "./ProblemTableView.vue?vue&type=style&index=0&id=7b1978b1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b1978b1",
  null
  
)

export default component.exports