
import { DataTableHeader } from 'vuetify';
import { ProblemLog, StudentLog } from '@/domain/ReportData/AssignmentData';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { ActionType, VNextAction } from '@/domain/StudentProblemAction';
import WirisMixins from '@/mixins/wiris';

dayjs.extend(localizedFormat);

interface StudentDetailsTableRow {
  timestamp: string;
  actionType: ActionType;
  response?: string;
  feedback?: string;
  correct?: boolean;
  problemLogId: number;
  problemId: number;
}

@Component
export default class StudentDetailsTable extends Mixins(WirisMixins) {
  @Prop({ required: true }) studentLog: StudentLog;

  ActionType = ActionType;

  get tableRows(): Array<StudentDetailsTableRow> {
    const rows: Array<StudentDetailsTableRow> = [];

    let previousTimestamp: Dayjs;
    let timestamp = '';

    this.studentLog.problemLogAndActions
      ?.sort((a, b) => (a.prLog.startTime > b.prLog.startTime ? 1 : -1))
      .forEach(({ prLog, actions }) => {
        actions?.forEach((action) => {
          if (action.actionType === ActionType.STUDENT_SUBMISSION_ACTION) {
            this.checkForWirisResponse(action.response as string);
          }

          if (
            action.actionType == ActionType.PROBLEM_STARTED_ACTION ||
            action.actionType == ActionType.PROBLEM_RESUMED_ACTION
          ) {
            // timestamp = dayjs(action.timestamp).format(
            //   'ddd MMM DD YYYY hh:mm:ss A'
            // );
            timestamp = dayjs(action.timestamp).format('llll');
          } else if (action.actionType == ActionType.PROBLEM_FINISHED_ACTION) {
            timestamp = '';
          } else {
            let diff = dayjs.duration(
              dayjs(action.timestamp).diff(previousTimestamp)
            );
            timestamp = `+ ${Math.floor(diff.asMinutes())} mins`;
            timestamp += ` ${diff.format('s')} secs`;
          }

          previousTimestamp = dayjs(action.timestamp);

          rows.push(this.transformToTableRow(prLog, action, timestamp));
        });
      });
    return rows;
  }

  //may look weird but I think we should leave the full switch statement here to know that nothing is being added (so far) for those actions
  transformToTableRow(
    prLog: ProblemLog,
    action: VNextAction,
    timestamp: string
  ): StudentDetailsTableRow {
    let row: StudentDetailsTableRow = {
      timestamp,
      actionType: action.actionType,
      problemLogId: prLog.id,
      problemId: prLog.problemDbid,
    };

    //Please do not re-order the actions. They are in a fairly specific order: ProblemActions, ResponseActions, TutoringRequestedActions.
    switch (action.actionType) {
      case ActionType.PROBLEM_STARTED_ACTION:
      case ActionType.PROBLEM_RESUMED_ACTION:
        //nothing needed
        break;

      case ActionType.PROBLEM_FINISHED_ACTION:
        row.feedback = `Score: ${Math.round(
          (prLog.continuousScore || 0) * 100
        )}%`;
        break;

      case ActionType.STUDENT_RESPONSE_ACTION:
        row.response = action.response;
        row.correct = action.correct;
        break;
      case ActionType.STUDENT_SUBMISSION_ACTION:
        row.response = action.response;

        //undefined == null  is true apparently
        if (prLog.continuousScore == null) {
          row.feedback = 'ASSESS';
        } else {
          row.feedback = 'EDIT FEEDBACK';
        }
        break;

      case ActionType.HINT_REQUESTED_ACTION:
      case ActionType.SCAFFOLDING_REQUESTED_ACTION:
      case ActionType.URL_LINK_REQUESTED_ACTION:
      case ActionType.EXPLANATION_REQUESTED_ACTION:
      case ActionType.ANSWER_REQUESTED_ACTION:
      case ActionType.IM_STUCK_REQUEST_ACTION:
      case ActionType.LIVE_TUTORING_REQUESTED_ACTION:
        //nothing needed
        break;
    }

    return row;
  }

  getBackgroundColor(dataTableRow: StudentDetailsTableRow): string {
    switch (dataTableRow.actionType) {
      case ActionType.STUDENT_RESPONSE_ACTION:
        if (dataTableRow.correct) {
          return 'green-background';
        }
        return 'red-background';

      case ActionType.STUDENT_SUBMISSION_ACTION:
        return 'blue-background';

      case ActionType.PROBLEM_STARTED_ACTION:
      case ActionType.PROBLEM_RESUMED_ACTION:
      case ActionType.PROBLEM_FINISHED_ACTION:
        return 'grey-background';

      case ActionType.HINT_REQUESTED_ACTION:
      case ActionType.SCAFFOLDING_REQUESTED_ACTION:
      case ActionType.EXPLANATION_REQUESTED_ACTION:
      case ActionType.ANSWER_REQUESTED_ACTION:
      case ActionType.URL_LINK_REQUESTED_ACTION:
      case ActionType.LIVE_TUTORING_REQUESTED_ACTION:
      case ActionType.IM_STUCK_REQUEST_ACTION:
        return 'orange-background';

      default:
        return 'white-background';
    }
  }

  convertActionTypeToText(actionType: string, correct: boolean): string {
    switch (actionType) {
      case ActionType.PROBLEM_STARTED_ACTION:
        return 'Started a Problem';
      case ActionType.PROBLEM_RESUMED_ACTION:
        return 'Resumed Problem';
      case ActionType.PROBLEM_FINISHED_ACTION:
        return 'Finished Problem';

      case ActionType.STUDENT_RESPONSE_ACTION:
        return correct ? 'Answered Correctly' : 'Answered Incorrectly';
      case ActionType.STUDENT_SUBMISSION_ACTION:
        return 'Answered Open Response';

      case ActionType.HINT_REQUESTED_ACTION:
        return 'Requested Hint';
      case ActionType.SCAFFOLDING_REQUESTED_ACTION:
        return 'Requested Scaffolding';
      case ActionType.ANSWER_REQUESTED_ACTION:
        return 'Requested Answer';
      case ActionType.URL_LINK_REQUESTED_ACTION:
        return 'Requested Instruction';
      case ActionType.EXPLANATION_REQUESTED_ACTION:
        return 'Requested Explanation';

      case ActionType.IM_STUCK_REQUEST_ACTION:
        return 'Student Stuck';
      case ActionType.LIVE_TUTORING_REQUESTED_ACTION:
        return 'Requested Live Tutoring';
      default:
        return '';
    }
  }

  get staticHeaders(): Array<DataTableHeader> {
    const classes: Array<string> = [
      'text-no-wrap',
      'sticky-row',
      'sticky-row-1',
      'text-subtitle-2',
    ];

    const buildStaticHeader = (
      text: string,
      value: string
    ): DataTableHeader => ({
      text,
      value,
      align: 'center',
      class: classes,
      cellClass: text === 'Time' ? ['text-no-wrap'] : undefined,
      sortable: false,
    });

    return [
      buildStaticHeader('Time', 'timestamp'),
      buildStaticHeader('Action Type', 'actionType'),
      buildStaticHeader('Response', 'response'),
      buildStaticHeader('Teacher Feedback', 'feedback'),
    ];
  }
}
