
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import axios, { CancelTokenSource } from 'axios';
import { isEqual } from 'lodash';
import { ProblemSet } from '@/domain/ProblemSet';
import { getProblemSetByXref } from '@/api/pspr.api';

@Component
export default class SelectProblemSetIdDialog extends Vue {
  @Prop({ default: [] }) psCeris: string[];
  @Prop() value: boolean;

  selectedCeri: string | null = null;
  dialog = false;
  initialized = false;
  downloading = false;
  psIndex: number | null = null;
  source: CancelTokenSource | null = null;

  psCeriToPs: { [ceri: string]: ProblemSet | null } = {};

  get showDialog(): boolean {
    return this.value ? this.value : this.dialog;
  }

  set showDialog(val: boolean) {
    this.dialog = val;

    this.$emit('input', val);
  }

  get hasLoadedSome(): boolean {
    return Object.values(this.psCeriToPs).some((x) => x != null);
  }

  setSelectedCeri(ceri: string): void {
    this.selectedCeri = ceri;
  }

  downloadProblemSets(ceris: string[]): Promise<void> {
    // Stop whatever was previously not downloaded (or downloading)
    if (this.source) {
      // Cancel prior requests with this cancel token
      this.source.cancel();
    }

    // Download problem sets
    this.downloading = true;

    // Clear previously downloaded problem sets
    this.psCeriToPs = {};

    // Use share cancel token for Problem Set requests
    this.source = axios.CancelToken.source();

    const promises: Promise<unknown>[] = [];

    for (const psCeri of ceris) {
      // Download Problem Sets
      const psPromise = getProblemSetByXref(
        psCeri,
        undefined,
        this.source?.token
      )
        .then((problemSet: ProblemSet) => {
          // Record Problem Set for Ceri
          this.$set(this.psCeriToPs, psCeri, problemSet);

          return;
        })
        .catch((e) => {
          if (axios.isCancel(e.getError())) {
            return Promise.reject(e);
          } else {
            // Failed to download Problem Set
            this.$set(this.psCeriToPs, psCeri, null);
          }

          return;
        });

      promises.push(psPromise);
    }

    // Finished downloading
    return Promise.all(promises)
      .then(() => {
        // Everything has been handled above. Nothing was canceled.
        this.downloading = false;

        const res = ceris.filter((ceri) => this.psCeriToPs[ceri] === null);

        if (res.length > 0) {
          this.$notify(`${res.length} Problem Sets failed to download.`);
        }
      })
      .finally(() => {
        this.downloading = false;
      });
  }

  created(): void {
    // Download Problem Sets
    this.downloadProblemSets(this.psCeris);
  }

  @Watch('showDialog')
  onShowDialog(value: boolean) {
    if (value) {
      // Clear prior selection
      this.selectedCeri = null;
    }
  }

  @Watch('psCeris')
  onPsCeris(newValue: string[], oldValue: string[]): void {
    if (isEqual(newValue, oldValue)) {
      return;
    }

    this.downloadProblemSets(newValue);
  }
}
