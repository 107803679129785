
import { Component, Vue, Watch } from 'vue-property-decorator';
import SearchResultsTopBar from '@/components/FindProblems/SearchResults/SearchResultsTopBar.vue';
import axios, { CancelTokenSource } from 'axios';
import { ProblemsFilterAndPagingParams } from '@/api/pspr.api';

@Component({
  components: {
    SearchResultsTopBar,
  },
})
export default class SearchResultsProblems extends Vue {
  downloading = false;

  // Filtering/paging
  limit = 20;
  source: CancelTokenSource | null = null;
  extraFields = ['ANSWERS', 'PARENT_PROBLEM_SETS', 'CURRICULA', 'ATTRIBUTIONS'];
  selectedCurricula: Array<number> = [];

  get nextPageToken(): string | null {
    return this.$store.state.search.nextPageToken;
  }

  get params(): ProblemsFilterAndPagingParams {
    const params: ProblemsFilterAndPagingParams = {
      skillId: this.selectedSkillId,
      limit: this.limit,
      extraFields: this.extraFields,
      nextPageToken: this.nextPageToken,
      curricula: this.selectedCurricula,
    };
    return params;
  }

  get selectedSkillId(): number {
    return this.$store.state.skillList.selectedSkillId;
  }

  reset(): void {
    if (this.source) {
      // Cancel prior requests with this cancel token
      this.source.cancel();
    }

    // Clear out prior results
    this.$store.commit('search/resetProblemSearch');

    this.source = axios.CancelToken.source();
    this.downloading = true;
  }

  created(): void {
    this.downloadData();
  }

  @Watch('$route.params.skillId')
  onSkillIdChange(value: string, oldValue: string): void {
    if (value === oldValue) {
      return;
    }
    this.reset();
    this.downloadData();
  }

  downloadData(): void {
    this.downloading = true;
    const skillIdRouteParam = parseInt(this.$route.params.skillId);
    const currentPage = this.$router.currentRoute.name;
    // Redirect home if missing route param
    if (!skillIdRouteParam) {
      this.$router.push({
        name: 'findProblems',
      });
      return;
    }
    this.$store.commit('skillList/setSelectedSkill', skillIdRouteParam);
    // If list of skills is missing from store, request it
    if (this.$store.state.skillList.skills.length === 0) {
      this.$store.dispatch('skillList/requestSkills');
    }
    // Download all curricula if not already
    this.$store.dispatch('curricula/requestCurricula');
    const requestProblems = this.$store.dispatch(
      'search/searchProblemsBySkill',
      {
        params: this.params,
        cancelToken: this.source?.token,
      }
    );
    const requestSkillBuilders = this.$store.dispatch(
      'search/requestSkillBuildersBySkill',
      this.$store.state.skillList.selectedSkillId
    );
    // Load page once the relevant request finishes
    const dataForThisPage =
      currentPage === 'searchProblems' ? requestProblems : requestSkillBuilders;
    dataForThisPage.finally(() => {
      this.downloading = false;
    });
  }

  downloadMoreProblems(): void {
    this.$store
      .dispatch('search/searchProblemsBySkill', {
        params: this.params,
        cancelToken: this.source?.token,
      })
      .finally(() => {
        this.downloading = false;
      });
  }

  onSelectedCurriculaChanged(selectedCurricula: Array<number>): void {
    this.selectedCurricula = selectedCurricula;
    this.reset();
    this.downloadMoreProblems();
  }
}
