
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Problem } from '@/domain/Problem';
import Editor from '@tinymce/tinymce-vue';
import SupportEditor from './SupportEditor.vue';
import { cloneDeep } from 'lodash';

@Component({
  components: {
    Editor,
    SupportEditor,
  },
})
export default class HintsOrExplanationView extends Vue {
  @Prop() problem: Problem;
  @Prop() supportContent: Array<string>;
  @Prop() supportType: string;
  dbText = '';
  currentIndex = -1;
  showEditor = false;
  addNewSupport = false;

  get supportTypeMapped() {
    return this.supportType === 'Hint' ? 'HINT_SET' : 'EXPLANATION';
  }

  created() {
    if (this.supportContent === undefined || this.supportContent.length === 0) {
      this.addNewSupport = true;
    }
  }

  addNew() {
    this.showEditor = true;
    this.addNewSupport = true;
    this.currentIndex = -1;
  }

  publishSupport(hintOrExplanation: string) {
    const support = cloneDeep(this.supportContent);
    if (
      this.currentIndex === -1 &&
      (this.supportContent === undefined || this.supportContent.length === 0)
    ) {
      this.$emit(
        'createStudentSupport',
        this.supportType === 'Hint' ? [hintOrExplanation] : hintOrExplanation,
        this.supportTypeMapped
      );
    } else if (this.currentIndex === -1) {
      support.push(hintOrExplanation);
      this.$emit(
        'updateStudentSupport',
        this.supportType === 'Hint' ? support : hintOrExplanation,
        this.supportTypeMapped
      );
    } else {
      support[this.currentIndex] = hintOrExplanation;
      this.$emit(
        'updateStudentSupport',
        this.supportType === 'Hint' ? support : hintOrExplanation,
        this.supportTypeMapped
      );
    }
    this.addNewSupport = false;
  }

  editSupport(index: number) {
    if (this.supportContent !== undefined) {
      this.dbText = this.supportContent[index];
      this.showEditor = true;
      this.currentIndex = index;
      this.addNewSupport = false;
    }
  }

  deleteSupport(index = -1) {
    const support = cloneDeep(this.supportContent);
    if (index !== -1) {
      support.splice(index, 1);
    }
    this.$emit('updateStudentSupport', support, this.supportTypeMapped);
  }

  showTitle(index: number) {
    return this.supportType === 'Hint'
      ? this.supportType + ' ' + (index + 1)
      : this.supportType;
  }

  noHintsOrExplanation() {
    this.addNewSupport = false;
    this.showEditor = false;
    this.$emit('noHintsOrExplanation');
  }

  cancelEditor() {
    this.showEditor = false;
  }
}
