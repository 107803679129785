import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { SearchState } from './types';
import { RootState } from '../types';

export const state: SearchState = {
  isDownloadingProblems: false,
  problems: [],
  numProblems: 0,
  skillBuilders: [],
  nextPageToken: null,
  shouldDownloadMoreProblems: false,
};

const namespaced = true;

export const search: Module<SearchState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
