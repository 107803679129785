
import { Component, Vue } from 'vue-property-decorator';
import { ActionType } from '@/domain/StudentProblemAction';
import ScoreChip from '@/components/Report/ScoreChip.vue';

@Component({
  components: {
    ScoreChip,
  },
})
export default class ScorkingKeyDialog extends Vue {
  dialogIsOpen = false;
  symbolColumnWidth = 2;
  /**
   * Returns max width for dialog based on current breakpoint
   */
  get maxWidth(): number {
    return this.$vuetify.breakpoint.lgAndUp ? 800 : 600;
  }
  ActionType = ActionType;
  oneWrongAnswer = [this.ActionType.STUDENT_RESPONSE_ACTION];
  oneHint = [this.ActionType.HINT_REQUESTED_ACTION];
  twoWrongAnswers = [
    this.ActionType.STUDENT_RESPONSE_ACTION,
    this.ActionType.STUDENT_RESPONSE_ACTION,
  ];
  hintThenWrongAnswer = [
    this.ActionType.HINT_REQUESTED_ACTION,
    this.ActionType.STUDENT_RESPONSE_ACTION,
  ];
  hintThenTwoWrongAnswers = [
    this.ActionType.HINT_REQUESTED_ACTION,
    this.ActionType.STUDENT_RESPONSE_ACTION,
    this.ActionType.STUDENT_RESPONSE_ACTION,
  ];
  sawAnswer = [this.ActionType.ANSWER_REQUESTED_ACTION];
}
