
import { Component, Vue } from 'vue-property-decorator';
import { getAttributeDefinition } from '@/api/core/attributes.api';
import { updateMyUserAttribute } from '@/api/core/user.api';
import { AttributeDefinitionValue, AttributeNames } from '@/domain/Attributes';

@Component
export default class SelectGC extends Vue {
  selectedGrades: string[] = [];
  selectedCurricula: string[] = [];
  //TODO: need to change this to something else that's a real curriculum object when the actual object is fixed everywhere else
  curricula: AttributeDefinitionValue[] = [];
  grades: AttributeDefinitionValue[] = [];

  submitting = false;

  skip(): void {
    this.goToRedirect();
  }

  submit(): void {
    this.submitting = true;
    const pGrade = updateMyUserAttribute(
      AttributeNames.GRADE_LEVEL,
      this.selectedGrades
    );
    const pCurriculum = updateMyUserAttribute(
      AttributeNames.CURRICULUM,
      this.selectedCurricula
    );
    Promise.all([pGrade, pCurriculum])
      .then(() => {
        this.goToRedirect();
      })
      .catch(() => {
        //Something bad happened.
        this.$notify(
          'An error occurred while saving grade/curriculum info. Please try again or move on.'
        );
      })
      .finally(() => {
        this.submitting = false;
      });
  }

  goToRedirect(): void {
    this.$router.push(this.$route.query.goTo as string);
  }

  created(): void {
    getAttributeDefinition(AttributeNames.CURRICULUM).then((curriculumDefn) => {
      this.curricula = curriculumDefn.values;
    });

    getAttributeDefinition(AttributeNames.GRADE_LEVEL).then((gradeDefn) => {
      this.grades = gradeDefn.values;
    });
  }
}
