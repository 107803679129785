import { ActionTree } from 'vuex';
import { InsightsHubState } from './types';
import { RootState } from '../types';
import { FolderInfo } from '@/domain/FolderInfo';
import { GroupDefinition } from '@/domain/Group';
import { User } from '@/domain/User';
import { CourseDefinition } from '@/domain/Class';
import {
  getTeacherCurriculumStats,
  getCurriculumGradeStructure,
  getCurriculumFolders,
} from '@/api/core/curricula.api';
import { findMenteeGroup, getMentees } from '@/api/core/mentees.api';
import { findCourses } from '@/api/core/course.api';
import { searchSchools } from '@/api/core/schools.api';
import { School } from '@/domain/School';
import { shuffle } from 'lodash';
import axios, { CancelToken } from 'axios';
import { IncludeAttrSettings } from '@/domain/IncludeAttrSettings';
import { CurriculumHierarchyLevel } from '@/domain/Curriculum';

export const actions: ActionTree<InsightsHubState, RootState> = {
  getCurriculumGradeFolders(context, cancelToken?: CancelToken) {
    if (context.state.selectedCurriculumXref) {
      // Reset Grade Folders
      context.commit('setGradeFolders', []);

      return getCurriculumFolders(
        context.state.selectedCurriculumXref,
        {
          levels: [CurriculumHierarchyLevel.LEVEL_1],
          include: [IncludeAttrSettings.ATTRIBUTES],
        },
        cancelToken
      ).then((folders: FolderInfo[]) => {
        // Update to Curriculum Grade Folders
        context.commit('setGradeFolders', folders);
        return folders;
      });
    }
  },
  // Gets mentee teachers for requesting user
  getMenteeTeachers(context) {
    // Get mentee groups
    return findMenteeGroup({ owner: 'me' }).then((group: GroupDefinition) => {
      // Get teachers
      return getMentees(group.groupXref, { attributes: true }).then(
        (menteeTeachers: User[]) => {
          context.commit('setMenteeTeachers', menteeTeachers);
        }
      );
    });
  },
  // Gets all courses for Mentor
  getMentorCourses(context) {
    return findCourses({ mentor: 'me', primaryTeachers: true }).then(
      (menteeCourses: CourseDefinition[]) => {
        context.commit('setMenteeCourses', menteeCourses);
      }
    );
  },
  // Gets all schools for menteeTeachers already in store
  getMenteeSchools(context) {
    const schoolIds = [];

    for (const menteeTeacher of context.state.menteeTeachers) {
      const menteeSchools = menteeTeacher.attributes?.ncesPublicSchool ?? [];

      schoolIds.push(...menteeSchools);
    }

    if (schoolIds.length > 0) {
      return searchSchools({ ID: Array.from(new Set(schoolIds)) }).then(
        (schools: School[]) => {
          context.commit('setMenteeSchools', schools);
        }
      );
    }
  },
  shuffleMenteeTeachers(context) {
    // Clear any table options prior so that rows can be shuffled
    context.commit('setCollapsedPaths', []);
    context.commit('setOptions', {
      ...context.state.options,
      sortBy: [],
      sortDesc: [],
    });

    // Shuffle order of Teachers
    const res = shuffle(context.state.menteeTeachers);

    // Update store with shuffled version
    context.commit('setMenteeTeachers', res);

    return Promise.resolve(res);
  },
  getDashboardData(context) {
    const dashboardPromises = [];

    // Set loading state
    context.commit('setDashboardLoading', true);

    if (context.state.selectedCurriculumXref && context.state.selectedGradeId) {
      // Stop whatever was previously not downloaded (or downloading)
      if (context.state.dashboardSource) {
        // Cancel prior requests with this cancel token
        context.state.dashboardSource.cancel();
      }

      // Clear prior dashboard data if any
      context.commit('setCurriculumGrade', null);
      context.commit('setTeacherCurriculumStats', []);

      // New Token
      context.commit('setDashboardSource', axios.CancelToken.source());

      const folderStructurePromise = getCurriculumGradeStructure(
        context.state.selectedCurriculumXref,
        context.state.selectedGradeId,
        context.state.dashboardSource?.token
      ).then((res) => {
        context.commit('setCurriculumGrade', res);
      });

      dashboardPromises.push(folderStructurePromise);

      const statsPromise = getTeacherCurriculumStats(
        context.state.selectedCurriculumXref,
        context.state.selectedGradeId,
        {
          mentor: 'me',
          primaryTeachers: true,
          timeSelector: context.state.timeSelector ?? undefined,
        },
        context.state.dashboardSource?.token
      ).then((res) => {
        context.commit('setTeacherCurriculumStats', res);
      });

      dashboardPromises.push(statsPromise);
    }

    return Promise.all(dashboardPromises).then(() => {
      context.commit('setDashboardLoading', false);
    });
  },
};
