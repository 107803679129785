
import { ActivityTab } from '@/views/InsightsHub/ActivityPage.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ActivityScoringDialog extends Vue {
  @Prop({ required: true }) tab: ActivityTab;

  ActivityTab = ActivityTab;

  showDialog = false;
}
