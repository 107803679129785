
import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
  updateStudentProblemLog,
  UpdateStudentProblemLogPayload,
} from '@/api/core/assignment.api';
import { ProblemLog } from '@/domain/ReportData/AssignmentData';
import { Problem } from '@/domain/Problem';
import { QuickComment } from '@/domain/QuickComment';
import WirisMixins from '@/mixins/wiris';

enum UpdateTarget {
  COMMENT = 'Comment',
  SCORE = 'Score',
}

@Component
export default class FeedbackSideSheet extends Mixins(WirisMixins) {
  @Prop({ required: true }) value: boolean;
  @Prop({ required: true }) studentName: string;
  @Prop({ required: true }) assignmentXref: string;
  @Prop({ required: true }) studentXref: string;
  @Prop({ required: true }) problemLog: ProblemLog;
  @Prop({ required: true }) problem: Problem;
  @Prop({ required: true }) quickComments: Array<QuickComment>;

  availableScores: Array<number> = [0, 1, 2, 3, 4];

  isExpanded = false;
  updating = false;

  displayedScore: number | null = null;

  get continuousScore(): number {
    return this.problemLog.continuousScore;
  }

  get teacherComment(): string {
    return this.problemLog.teacherComment;
  }

  get answerText(): string {
    return this.problemLog.answerText;
  }

  get problemName(): string {
    let name: string = 'P' + this.problem.assistmentPosition?.toString() || '';

    if (this.problem.partLetter) {
      name += `: ${this.problem.partLetter}`;
    }

    return name;
  }

  get showDrawer(): boolean {
    return this.value;
  }

  set showDrawer(val: boolean) {
    this.$emit('input', val);
  }

  get drawerOption(): string {
    return this.isExpanded ? '>> Collapse' : '<< Expand';
  }

  get comments(): Array<string> {
    return (
      this.quickComments.map((qc) => qc.comments).flat()[
        this.continuousScore * 4
      ] || []
    );
  }

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

  updateScore(value: number): void {
    this.update(UpdateTarget.SCORE, value / 4);
  }

  updateComment(value: string): void {
    this.update(UpdateTarget.COMMENT, value);
  }

  update(target: UpdateTarget, value: string | number): void {
    const payload: UpdateStudentProblemLogPayload = {};

    if (target === UpdateTarget.SCORE) {
      payload.continuousScore = value as number;
    }

    if (target === UpdateTarget.COMMENT) {
      if (this.problemLog.teacherComment === value) {
        return;
      }
      payload.teacherComment = value as string;
      /*  A line below may seem unnecessary but for some odd reason
          if you write a comment, clear the Response field, close 
          and reopen the sidesheet, the comment stays there until
          you refresh the whole page. The line below fixes that.
      */
      this.problemLog.teacherComment = value as string;
    }
    this.updating = true;

    updateStudentProblemLog(
      this.assignmentXref,
      this.studentXref,
      this.problemLog.id,
      payload
    )
      .then(() => {
        this.$emit('update', { ...payload, id: this.problemLog.id });

        this.$notify(`${target} updated!`);
      })
      .catch(() => {
        this.$notify('Something went wrong. Please try again.');
      })
      .finally(() => {
        this.updating = false;
      });
  }

  created(): void {
    this.displayedScore = this.continuousScore * 4;

    this.checkForWirisResponse(this.answerText);
  }
}
