export enum TutorStrategyType {
  EXPLANATIONS = 'EXPLANATIONS',
  INSTRUCTIONAL_RECOMMENDATIONS = 'INSTRUCTIONAL_RECOMMENDATIONS',
  HINTS = 'HINTS',
  SCAFFOLDING = 'SCAFFOLDING',
}

export interface SimplifiedInstructionalRecommendation {
  instructionalRecommendation: string; // HTML
}

export interface TutoringContainer {
  instructionalRecommendations: Array<SimplifiedInstructionalRecommendation>;
}
