var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"landing-page"}},[_c('div',{staticClass:"d-flex justify-space-between surface-container neutral lighten-4 elevation-1"},[_c('h1',{staticClass:"text-h1"},[_vm._v("Grade Reports")]),_c('span',[_c('v-select',{attrs:{"items":_vm.curriculaChoices,"item-text":"name","item-value":"xref","hide-details":""},model:{value:(_vm.selectedCurriculumXref),callback:function ($$v) {_vm.selectedCurriculumXref=$$v},expression:"selectedCurriculumXref"}})],1)]),_c('div',{attrs:{"id":"school-year-dropdown"}},[_c('div',[_c('v-select',{attrs:{"items":_vm.schoolYearChoices,"outlined":"","hide-details":""},model:{value:(_vm.timeSelector),callback:function ($$v) {_vm.timeSelector=$$v},expression:"timeSelector"}})],1),_vm._m(0)]),(_vm.downloading)?_c('div',{attrs:{"id":"loading-spinner-wrapper"}},[_c('v-progress-circular',{attrs:{"size":"100","indeterminate":"","color":"primary"}})],1):_c('div',{attrs:{"id":"grade-choices"}},_vm._l((_vm.gradeFolders),function(gradeFolder){return _c('v-card',{key:gradeFolder.id,staticClass:"grade-card my-4"},[_c('v-card-text',{staticClass:"d-flex justify-space-between"},[_c('h4',{staticClass:"text-h4"},[_vm._v(_vm._s(gradeFolder.name))]),(_vm.timeSelector !== null)?_c('router-link',{staticClass:"text-decoration-none text-body",attrs:{"to":{
            name: 'activityPage',
            params: {
              xref: _vm.selectedCurriculumXref,
              gradeFolderId: gradeFolder.id,
            },
            query: { timeSelector: _vm.timeSelector.toArray() },
          }}},[_vm._v(" View Report ")]):_vm._e()],1)],1)}),1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-center"},[_c('span',{staticClass:"text-caption neutral--text text--darken-3"},[_vm._v(" School Year to View ")])])
}]

export { render, staticRenderFns }