
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { orderBy, shuffle } from 'lodash';
import {
  AssignmentReportData,
  ProblemLogAndActions,
  StudentLog,
  ProblemLog,
} from '@/domain/ReportData/AssignmentData';
import { User } from '@/domain/User';
import { Problem, ProblemType } from '@/domain/Problem';
import { appendGlobalHeaderOptions } from '@/utils/dataTables.utils';
import DeleteStudentProgressDialog from './DeleteStudentProgressDialog.vue';
import ScoringKeyDialog from './ScoringKeyDialog.vue';
import StudentActionsMenu from './StudentActionsMenu.vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { LmsProviderType } from '@/domain/LmsProviderType';
import sortBySortableName from '@/utils/sortBySortableName.util';
import { CommonWrongAnswer } from '@/domain/ReportData/Cignition';

dayjs.extend(duration);

interface StudentData {
  timeSpent: number;
  score: number;
}

interface ProblemData {
  averagePercent: string;
  commonWrongAnswers: Array<CommonWrongAnswer>;
}

@Component({
  components: {
    ScoringKeyDialog,
    StudentActionsMenu,
    DeleteStudentProgressDialog,
  },
})
export default class AssignmentReportTable extends Vue {
  @Prop({ default: null }) assignmentReportData: AssignmentReportData | null;
  @Prop({ default: new Map() }) assigneeXrefToStudentMap: Map<string, User>;
  @Prop({ default: new Map() }) beyondDaysMap: Map<string, string>;
  @Prop({ default: () => [] }) problems: Array<Problem>;
  @Prop({ default: new Map() }) idToProblemMap: Map<number, Problem>;
  @Prop({ default: null }) lmsProviderType: LmsProviderType | null;
  @Prop({ default: null }) downloadCSVUrl: string;
  @Prop({ default: null }) uploadDialog: boolean;
  @Prop({ default: null }) uploadingScores: false;
  @Prop({ default: null }) uploadGradesToLms: false;
  @Prop({ default: false }) isInTestMode: boolean;
  @Prop({ default: null }) dueDate: number;

  //Allows us access to the enum in the template.
  ProblemType = ProblemType;

  showDeleteProgressDialog = false;
  studentToDeleteProgress: User | null = null;

  ////////////////////
  // Hiding Columns //
  ////////////////////
  hideNames = false;
  hideScores = false;
  // FIXME: IMPLEMENT THESE WHEN COLUMNS ARE ADDED
  hideTimes = false;
  hideHintCounts = false;
  hideProblemAverage = false;

  /////////////////
  // Sticky Rows //
  /////////////////
  problemAvgAlwaysVisible = true;
  CWAAlwaysVisible = true;

  // Student Scores on entire assignment
  get assigneeXrefToStudentStatsMap(): Map<string, StudentData> {
    const res = new Map();
    if (
      this.assignmentReportData &&
      this.assignmentReportData.summaryStatsAll &&
      this.assignmentReportData.summaryStatsAll.studentStats
    ) {
      for (const studentStats of this.assignmentReportData.summaryStatsAll
        .studentStats) {
        res.set(studentStats.studentXref, {
          timeSpent: studentStats.timeSpent,
          score: studentStats.score,
        });
      }
    }
    return res;
  }
  ///////////////////////////////
  // 2.0 Open Response Scoring //
  ///////////////////////////////
  get openResponseScoringBaseUrl(): string {
    return `${process.env.VUE_APP_TNG_URL}/openResponseGrade/${this.assignmentReportData?.contentInfo.xref}`;
  }
  isValidOpenResponseScore(score: number): boolean {
    // Score is not outside of range - e.g. 5
    const withinRange: boolean = score >= 0 && score <= 1; // Between 0-4
    // Score is a whole number - e.g. 1.25, 1.5
    const isWholeNumber: boolean = (score * 4) % 1 == 0; // [0, 1, 2, 3, 4] contains score
    return withinRange && isWholeNumber;
  }
  ////////////////////////
  // 3.0 Essay Scoring //
  ///////////////////////

  navigateToEssayScoringPage(pLog: ProblemLog): void {
    this.$router.push({
      name: 'essayScoringPage',
      params: {
        problemId: `${pLog.problemDbid}`,
      },
      query: this.$route.query,
    });
  }

  //////////////////////////
  // Numbers & Formatting //
  //////////////////////////
  // Multiplies by 100 and rounds to (max) two decimal places
  // https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
  decimalToPercent(decimal: number): number {
    return Math.round((decimal + Number.EPSILON) * 10000) / 100;
  }
  formatPercentage(decimal: number): string {
    const percent = Math.round(this.decimalToPercent(decimal));
    return `${percent}%`;
  }
  ///////////////////////////////////////////
  // Common Wrong Answers & Average Scores //
  ///////////////////////////////////////////
  get classAverage(): string {
    if (this.assignmentReportData) {
      return this.assignmentReportData.summaryStatsAll
        ? this.formatPercentage(
            this.assignmentReportData.summaryStatsAll.avgScore
          )
        : '-';
    }

    return 'N/A';
  }

  get openUploadDialog(): boolean {
    return this.uploadDialog;
  }

  set openUploadDialog(value: boolean) {
    this.$emit('input', value);
  }

  get problemIdToProblemDataMap(): Map<number, ProblemData> {
    const res = new Map();
    if (this.assignmentReportData && this.assignmentReportData.prAllStats) {
      for (const problemAverage of this.assignmentReportData.prAllStats) {
        const commonWrongAnswers = problemAverage.cwas || [];
        // Handle missing & null avgScores
        let averagePercent = 'N/A';
        if (
          Object.prototype.hasOwnProperty.call(problemAverage, 'avgScore') &&
          problemAverage.avgScore !== null
        ) {
          averagePercent = this.formatPercentage(problemAverage.avgScore);
        }
        res.set(problemAverage.problemDbid, {
          commonWrongAnswers,
          averagePercent,
        });
      }
    }
    return res;
  }

  ///////////////////
  // Table Headers //
  ///////////////////
  get prependStaticHeaders(): Array<DataTableHeader> {
    return [
      {
        text: 'Student Name/Problem',
        value: 'student',
        align: 'start',
        class: [
          'text-no-wrap',
          'sticky-row',
          'sticky-row-1',
          'text-subtitle-2',
        ],
        cellClass: ['text-no-wrap'],
        sortable: this.hideNames ? false : true,
        sort: sortBySortableName,
      },
      {
        text: 'Average Score',
        value: 'averageScore',
        align: 'center',
        class: [
          'text-no-wrap',
          'sticky-row',
          'sticky-row-1',
          'text-subtitle-2',
        ],
        sortable: true,
      },
    ];
  }

  appendStaticHeaders: Array<DataTableHeader> = [
    {
      text: 'Total Hints',
      value: 'totalHints',
      class: ['text-no-wrap', 'text-subtitle-2'],
      sortable: true,
    },
    {
      text: 'Total Time Spent',
      value: 'totalTimeSpent',
      class: ['text-no-wrap', 'text-subtitle-2'],
      sortable: true,
    },
  ];

  filterHeaders(header: DataTableHeader): boolean {
    switch (header.value) {
      // Filter out average score if it is hidden
      case 'averageScore':
        return !this.hideScores;
      case 'totalHints':
        return !this.hideHintCounts;
      case 'totalTimeSpent':
        return !this.hideTimes;
      default:
        return true;
    }
  }

  get problemHeaders(): Array<DataTableHeader> {
    return this.problems.map((problem: Problem) => {
      const text = problem.partLetter
        ? `P${problem.assistmentPosition}: ${problem.partLetter}`
        : `P${problem.assistmentPosition}`;
      return {
        text,
        value: `${problem.id}`,
      };
    });
  }

  get headers(): Array<DataTableHeader> {
    return [
      ...this.prependStaticHeaders
        .filter(this.filterHeaders)
        .map(appendGlobalHeaderOptions),

      ...this.problemHeaders.map(appendGlobalHeaderOptions),

      ...this.appendStaticHeaders
        .filter(this.filterHeaders)
        .map(appendGlobalHeaderOptions),
    ];
  }

  ////////////////
  // Table Rows //
  ////////////////

  get studentRows(): Array<
    Record<
      string,
      string | number | Map<number, ProblemLogAndActions> | User | null
    >
  > {
    // Generate empty rows (initialize) for all students in roster
    let studentXrefToRowRosterMap: Map<
      string,
      Record<
        string,
        string | number | Map<number, ProblemLogAndActions> | User | null
      >
    > = new Map();

    for (const [studentXref, studentUser] of this.assigneeXrefToStudentMap) {
      studentXrefToRowRosterMap.set(
        studentXref,
        this.initializeRowForStudent(studentUser)
      );
    }

    // Populate rows with data
    if (this.assignmentReportData) {
      for (const studentLog of this.assignmentReportData.studentLogs) {
        const studentXref = studentLog.studentXref;

        // Get student row
        const studentRow = studentXrefToRowRosterMap.get(studentXref);

        // Student in roster
        if (studentRow) {
          // Populate with data in place
          this.updateStudentDataInRow(studentRow, studentLog);
        }
      }
    }

    // Resulting list of student rows with data
    const res = [...studentXrefToRowRosterMap.values()];

    // Shuffle student rows if anonymizing
    if (this.hideNames) {
      return shuffle(res);
      // Otherwise sort alphabetically by lastname, firstname
    } else {
      return orderBy(
        res,
        [
          (row) => {
            const student = row.student as User;
            return student.lastName;
          },
          (row) => {
            const student = row.student as User;
            return student.firstName;
          },
        ],
        ['asc', 'asc']
      );
    }
  }

  initializeRowForStudent(
    studentUser: User
  ): Record<
    string,
    string | number | Map<number, ProblemLogAndActions> | User | null
  > {
    return {
      student: studentUser,
      averageScore: '-',
      totalTimeSpent: '-',
      // No score data for problems aka no problem logs (undefined)
      totalHints: '-',
      problemIdToLogsMap: new Map(),
    };
  }

  updateStudentDataInRow(
    studentRow: Record<
      string,
      string | number | Map<number, ProblemLogAndActions> | User | null
    >,
    studentLog: StudentLog
  ): void {
    const studentStats = this.assigneeXrefToStudentStatsMap.get(
      studentLog.studentXref
    );
    const studentScore = studentStats?.score;
    // Update score
    if (typeof studentScore === 'number') {
      studentRow.averageScore = Math.round(studentScore * 100);
    } else {
      studentRow.averageScore = null;
    }
    // Update time spent
    studentRow.totalTimeSpent = this.getTimeSpent(studentStats?.timeSpent);
    // Map problem xrefs to ProblemLogAndActions:
    const problemIdToLogsMap: Map<number, ProblemLogAndActions> = new Map();
    if (studentLog.problemLogAndActions) {
      let totalHintCount = 0;
      for (const problemLogAndAction of studentLog.problemLogAndActions) {
        totalHintCount += problemLogAndAction.prLog.hintCount;
        // FIXME: What does an undefined score mean? Will there be a case
        // where a problem is NOT completed but its problem log is returned?
        // (especially for open-ended responses)
        // Would we want to leave it as undefined so that we prevent grading
        // in this case?
        const problemScore = problemLogAndAction.prLog.continuousScore;
        if (!problemScore && problemScore != 0) {
          // Null if we do not get a score from a given problem log but to
          // indicate that this NOT undefined and we did indeed get something
          // back (problem log) from the server
          studentRow[problemLogAndAction.prLog.problemDbid] = null;
        } else {
          // Record score for problem
          studentRow[problemLogAndAction.prLog.problemDbid] = problemScore;
        }
        problemIdToLogsMap.set(
          problemLogAndAction.prLog.problemDbid,
          problemLogAndAction
        ); // Populate map
      }
      // Update total hints used
      studentRow.totalHints = totalHintCount;
    }
    // Capture map along with rest of row data
    studentRow.problemIdToLogsMap = problemIdToLogsMap;
  }

  /////////////
  // Methods //
  /////////////

  getTimeSpent(ms?: number): string {
    // Format 00:00:00:00
    return ms ? dayjs.duration(ms).format('DD:HH:mm:ss') : '-';
  }

  showStudentDeleteProgressDialog(studentXref: string): void {
    this.studentToDeleteProgress =
      this.assigneeXrefToStudentMap.get(studentXref) || null;
    this.showDeleteProgressDialog = true;
  }

  deleteStudentProgress(): void {
    if (this.studentToDeleteProgress) {
      this.$emit('deleteStudentProgress', this.studentToDeleteProgress?.xref);
    }
    //close dialog
    this.showDeleteProgressDialog = false;
  }

  navigateToStudentDetailsReport(studentXref: string): void {
    this.$router.push({
      name: 'studentDetailsPage',
      params: {
        studentXref: studentXref,
      },
      query: this.$route.query,
    });
  }

  created(): void {
    this.hideNames =
      this.$store.state.auth.user.settings.anonymizeReportsByDefault || false;
  }
}
