import { VnextAssignData } from '@/domain/AssignData';
import { coreAxios } from '@/plugins/axios';

interface DeepLinkResponseFormData {
  redirectUrl: string;
  jwt: string;
}

const END_POINT = '/lti';

export const getDeepLinkResponse = (
  assignData: VnextAssignData
): Promise<DeepLinkResponseFormData> => {
  return coreAxios.post(`${END_POINT}/assign`, assignData).then((result) => {
    return result.data;
  });
};

export const getContext = (): Promise<string> => {
  return coreAxios.get(`${END_POINT}/context`).then((res) => {
    return res.data;
  });
};
