
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CignitionParent extends Vue {
  get isDownloadingTutees(): boolean {
    return this.$store.state.cignition.isDownloadingTutees;
  }
  get hasDownloadedTutees(): boolean {
    return this.$store.state.cignition.hasDownloadedTutees;
  }
  get isDownloadingSkills(): boolean {
    return this.$store.state.skills.isDownloading;
  }
  get hasDownloadedSkills(): boolean {
    return this.$store.state.skills.hasDownloaded;
  }
  /**
   * Request list of tutees & list of skills
   * if not already downloaded/in progress
   * Commit selected students to store from query params
   */
  initialize(): void {
    // Grab selected student xrefs from url
    let selectedXrefs: string | (string | null)[] | undefined =
      this.$router.currentRoute.query.tutees;
    // If no xrefs are selected, assign empty array
    if (!selectedXrefs) {
      selectedXrefs = [];
    }
    // If exactly one student is selected, wrap xref in array
    if (typeof selectedXrefs === 'string') {
      selectedXrefs = [selectedXrefs];
    }
    // Remove null values from array
    selectedXrefs = selectedXrefs.filter((xref) => xref != null);
    // Commit selectedXrefs to the store
    this.$store.commit('cignition/setSelectedTuteeXrefs', selectedXrefs);
    this.$store.dispatch('cignition/requestTutees'); // only downloads if not done/in progress
    this.$store.dispatch('skillList/requestSkills'); // only downloads if not done/in progress
  }
  created(): void {
    this.initialize();
  }
}
