
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getStudentAndPeerDataPerSkill } from '@/api/ldoe/skillData.api';
import { getMyStudentsAssignments } from '@/api/ldoe/assignment.api';
import UnauthenticatedView from '@/components/base/UnauthenticatedView.vue';
import NotFoundView from '@/components/base/NotFoundView.vue';
import UnauthorizedView from '@/components/LDOE/base/UnauthorizedView.vue';
import NoStudentSelectedView from '@/components/LDOE/TutoringDashboard/NoStudentSelectedView.vue';
import SkillBarChartViewForReport from '@/components/LDOE/TutoringDashboard/SkillBarChartViewForReport.vue';
import ProblemSetStudentDataTableView, {
  CustomTableLabel,
} from '@/components/LDOE/TutoringDashboard/ProblemSetStudentDataTableView.vue';
import {
  PartialAssignmentStudentData,
  StudentAndPeerDataPerSkill,
} from '@/domain/ReportData/Cignition';
import { TimeFrame } from '@/domain/Time';
import { User } from '@/domain/User';
import { CustomDataSetLabel } from '@/components/Report/BarChartView.vue';
import LDOEMixins from '@/mixins/ldoe-mixins';
import { getProblemSets } from '@/api/pspr.api';
import { ProblemSet } from '@/domain/ProblemSet';
import { AssignmentReportType, EventType, mixpanel } from '@/plugins/mixpanel';

interface CustomQueryParams {
  tutees: string[];
}

@Component({
  components: {
    UnauthenticatedView,
    NotFoundView,
    UnauthorizedView,
    NoStudentSelectedView,
    SkillBarChartViewForReport,
    ProblemSetStudentDataTableView,
  },
})
export default class TutorReportPage extends Mixins(LDOEMixins) {
  unauthorized = false;
  skillData: Array<StudentAndPeerDataPerSkill> = [];
  tuteeXrefs: Array<string> = [];
  problemSetData: Map<number, Array<PartialAssignmentStudentData>> = new Map();
  problemSetInformation: Map<number, ProblemSet> = new Map();

  /////////////////////////////
  // No data found scenarios //
  /////////////////////////////
  noSkillDataFound = false;
  noProblemSetInformationFound = false;
  noProblemSetDataFound = false;

  get noDataFoundWhatsoever(): boolean {
    return (
      this.noSkillDataFound &&
      this.noProblemSetInformationFound &&
      this.noProblemSetDataFound
    );
  }

  ////////////////////
  // Loading States //
  ////////////////////
  // Store Data Loading
  get isDownloadingSkills(): boolean {
    return this.$store.state.skillList.isDownloading;
  }
  get isDownloadingTutees(): boolean {
    return this.$store.state.ldoe.isDownloadingTutees;
  }
  // Page Data Loading
  isDownloadingSkillData = false;
  hasDownloadedSkillData = false;
  isDownloadingProblemSetData = false;
  hasDownloadedProblemSetData = false;
  isDownloadingProblemSetInformation = false;
  hasDownloadedProblemSetInformation = false;

  /**
   * @returns selected time frame from component
   */
  get weekSelected(): TimeFrame | null {
    return this.$store.getters['ldoe/getSelectedTimeFrame'];
  }

  /**
   * @returns full list of tutees for current user (from store)
   */
  get tutees(): Array<User> {
    return this.$store.state.ldoe.tutees;
  }

  get noTuteeSelected(): boolean {
    return this.filteredTuteeXrefs.length === 0;
  }

  /**
   * @returns filtered tuteeXrefs list
   */
  get filteredTuteeXrefs(): Array<string> {
    return this.tuteeXrefs.filter((xref) => xref);
  }

  ////////////////
  // Table Data //
  ////////////////
  getTuteeInitials(xref: string): string | null {
    const tutee = this.tutees.find((t) => t.xref === xref);
    if (!tutee) {
      return null;
    } else {
      return `${tutee.firstName.charAt(0)}${tutee.lastName.charAt(0)}`;
    }
  }

  get customTableLabels(): Array<CustomTableLabel> {
    return this.filteredTuteeXrefs.map((xref, i) => ({
      label: this.getTuteeInitials(xref) as string,
      value: xref,
      backgroundColor: this.labelColors[i],
    }));
  }

  get customChartLabels(): Array<CustomDataSetLabel> {
    return this.filteredTuteeXrefs.map((xref, i) => ({
      xref,
      label: this.tutees.find((t) => t.xref === xref)?.displayName as string,
      backgroundColor: this.labelColors[i],
    }));
  }

  ////////////////////
  // Initialization //
  ////////////////////
  updateQueryParam(queryParams: Partial<CustomQueryParams>): void {
    // Update the URL
    this.$router.replace({
      name: 'tutorReport',
      query: {
        ...this.$route.query,
        ...queryParams,
      },
    });
  }

  //to be called when tutees have changed
  @Watch('tuteeXrefs')
  onTuteeChange(): void {
    this.updateQueryParam({
      tutees: this.filteredTuteeXrefs,
    });
    this.$store.commit('ldoe/setSelectedTuteeXrefs', this.filteredTuteeXrefs);
    if (!this.noTuteeSelected) {
      this.downloadData();
      this.tutorReportClicked(
        this.tuteeXrefs[0],
        this.tuteeXrefs[1],
        this.tuteeXrefs[2],
        this.tuteeXrefs[3]
      );
    }
  }

  // Watchers
  @Watch('weekSelected')
  onWeekChange(): void {
    if (!this.noTuteeSelected) {
      this.downloadData();
    }
  }
  // If user logs in/signs up, fetch their assignments
  @Watch('isAuthenticated')
  handleAuthChange(): void {
    if (!this.isAuthenticated) {
      // FIXME:
      // this.goToLoginPortal();
    }
  }

  // Download helpers
  initializeSelectedTutees(): void {
    // Get tutee xrefs from the query params
    if (this.$route.query.tutees?.length > 0) {
      this.tuteeXrefs = (
        Array.isArray(this.$route.query.tutees)
          ? this.$route.query.tutees
          : [this.$route.query.tutees]
      ) as string[];
    }
    if (!this.noTuteeSelected) {
      this.downloadData();
    }
  }

  download(promises: Array<Promise<void>>): Promise<void> {
    return Promise.all(promises)
      .then(() => {
        return;
      })
      .catch((error) => {
        // Any errors
        if (error.response.status === 403) {
          // FIXME: Clear data?
          this.unauthorized = true;
        }
        return Promise.reject(error);
      });
  }
  downloadSkillData(): Promise<void> {
    if (this.weekSelected) {
      this.isDownloadingSkillData = true;
      this.hasDownloadedSkillData = false;
      this.noSkillDataFound = false;

      const promise = getStudentAndPeerDataPerSkill(
        this.filteredTuteeXrefs,
        this.weekSelected.startDateTime,
        this.weekSelected.endDateTime
      )
        .then((skillData) => {
          this.skillData = skillData;
        })
        .catch((error) => {
          this.noSkillDataFound = true;
          return Promise.reject(error);
        })
        .finally(() => {
          this.hasDownloadedSkillData = true;
          this.isDownloadingSkillData = false;
        });

      return promise;
    }

    return Promise.reject();
  }
  downloadProblemSetData(): Promise<void> {
    if (this.weekSelected) {
      this.isDownloadingProblemSetData = true;
      this.hasDownloadedProblemSetData = false;
      this.noProblemSetDataFound = false;

      const promise = getMyStudentsAssignments(
        this.filteredTuteeXrefs,
        this.weekSelected.startDateTime,
        this.weekSelected.endDateTime
      )
        .then(async (assignmentData) => {
          this.problemSetData = new Map(); // Clear table data before adding entries

          assignmentData.forEach((a) => {
            if (!this.problemSetData.has(a.problemSetId)) {
              this.problemSetData.set(a.problemSetId, [a]);
            } else {
              const entries = this.problemSetData.get(
                a.problemSetId
              ) as Array<PartialAssignmentStudentData>;
              this.problemSetData.set(a.problemSetId, [...entries, a]);
            }
          });
          await this.downloadProblemSetInformation();
        })
        .catch((error) => {
          this.noProblemSetDataFound = true;
          return Promise.reject(error);
        })
        .finally(() => {
          this.hasDownloadedProblemSetData = true;
          this.isDownloadingProblemSetData = false;
        });

      return promise;
    }

    return Promise.reject();
  }

  downloadProblemSetInformation(): Promise<void> {
    const psIds: Array<number> = Array.from(this.problemSetData.keys());

    if (psIds.length === 0) {
      return Promise.reject();
    }

    // Reset
    this.isDownloadingProblemSetInformation = false;
    this.hasDownloadedProblemSetInformation = false;

    this.isDownloadingProblemSetInformation = true;

    const promise = getProblemSets(psIds)
      .then((problemSetData) => {
        this.problemSetInformation = new Map(); // Clear map before adding new entries

        problemSetData.forEach((ps) => {
          this.problemSetInformation.set(ps.id, ps);
        });
      })
      .catch((error) => {
        this.noProblemSetInformationFound = true;
        return Promise.reject(error);
      })
      .finally(() => {
        this.isDownloadingProblemSetInformation = false;
        this.hasDownloadedProblemSetInformation = true;
      });

    return promise;
  }

  async downloadData(): Promise<void> {
    if (this.filteredTuteeXrefs.length > 0 && this.weekSelected) {
      this.download([
        this.downloadSkillData(),
        this.downloadProblemSetData(),
      ]).catch(() => {
        this.isDownloadingSkillData = false;
        this.isDownloadingProblemSetData = false;
      });
    }
  }
  created(): void {
    this.initializeSelectedTutees();
  }

  tutorReportClicked(
    s1Xref: string,
    s2Xref: string,
    s3Xref: string,
    s4xref: string
  ): void {
    mixpanel.track(EventType.reportViewed, {
      distinct_id: this.$router.app.getCurrentUser.xref,
      reportType: AssignmentReportType.ldoeTutorReport,
      student1Xref: s1Xref,
      student2Xref: s2Xref,
      student3Xref: s3Xref,
      student4Xref: s4xref,
    });
  }
}
