
import { AssignmentLessonDetails } from '@/domain/ReportData/Cignition';
import { Skill } from '@/domain/Skill';
import { orderBy } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

interface Prerequisites {
  [grade: number]: Array<Skill>;
}

@Component
export default class LessonInformationView extends Vue {
  @Prop({ required: true }) assignmentLessonDetails: AssignmentLessonDetails;
  @Prop({ default: false }) isDownloading: boolean;

  //////////
  // Data //
  //////////

  get goal(): string {
    return this.assignmentLessonDetails.goal;
  }

  get matchedSkills(): Array<Skill> {
    return this.findSkills(this.assignmentLessonDetails.skillIds);
  }

  get prerequisites(): Prerequisites {
    const prerequisiteSkills: Array<Skill> = this.findSkills(
      this.assignmentLessonDetails.prerequisiteSkillIds
    );
    const prerequisiteSkillsByGrade: Prerequisites = {};

    prerequisiteSkills.forEach((skill: Skill) => {
      const grade = Number(skill.code.split('.')[0]);

      if (!prerequisiteSkillsByGrade[grade]) {
        prerequisiteSkillsByGrade[grade] = [];
      }

      prerequisiteSkillsByGrade[grade].push(skill);
    });

    return prerequisiteSkillsByGrade;
  }

  get sortedRerequisiteGrades(): Array<string> {
    return orderBy(Object.keys(this.prerequisites), Number, 'desc');
  }

  ///////////
  // Store //
  ///////////

  get skills(): Array<Skill> {
    return this.$store.state.skillList.skills;
  }

  /////////////
  // Methods //
  /////////////

  findSkills(skillIds: Array<number>): Array<Skill> {
    return this.skills.filter((skill) => {
      return skillIds.includes(Number(skill.id));
    });
  }

  created(): void {
    // Download skills if not already
    // Will be prevented to download again in store if already
    this.$store.dispatch('skillList/requestSkills');
  }
}
