
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Problem } from '@/domain/Problem';
import HintsOrExplanationView from './HintsOrExplanationView.vue';
import NoHintsOrExplanation from './NoHintsOrExplanation.vue';
import ChangeTypeDialog from './ChangeTypeDialog.vue';
import { WIPStudentSupport } from '@/domain/StudentSupport';
import {
  createStudentSupportAuthoring,
  updateStudentSupportAuthoring,
  deleteStudentSupportAuthoring,
  getStudentSupportsForProblems,
} from '@/api/core/ssas.api';

@Component({
  components: {
    HintsOrExplanationView,
    NoHintsOrExplanation,
    ChangeTypeDialog,
  },
})
export default class StudentSupportAuthoring extends Vue {
  @Prop() problem: Problem;

  support: WIPStudentSupport = {};
  items = ['Hint', 'Explanation'];

  supportType = 'Hint';
  supportTypeOld = 'Hint';
  showConfirmationDialog = false;
  firstHintOrExplanation = true;

  isLoading = false;

  changeSupportType(contentKey: string) {
    this.support = {};
    this.isLoading = true;
    deleteStudentSupportAuthoring(contentKey, this.problem.id).then(() => {
      getStudentSupportsForProblems([this.problem.id]).then((data) => {
        if (this.supportType === 'Hint') {
          this.supportType = 'Explanation';
        } else {
          this.supportType = 'Hint';
        }
        this.supportTypeOld = this.supportType;
        this.createSupportDataStructure(data);
        this.firstHintOrExplanation = false;
        this.isLoading = false;
      });
    });
  }

  get supportContent() {
    if (this.supportType === 'Hint') {
      return this.support.content;
    } else if (this.support.content) {
      return [this.support.content];
    }
    return [];
  }

  inputSelect() {
    this.showConfirmationDialog = true;
    // reset value
    this.supportType = '';
    // set timeout here, if no set timeout so the code on the line above will be useless
    setTimeout(() => {
      this.supportType = this.supportTypeOld;
    }, 100);
    this.$forceUpdate();
  }

  noHintsOrExplanation() {
    this.firstHintOrExplanation = true;
  }

  addFirst(type: string) {
    this.firstHintOrExplanation = false;
    this.supportType = type;
  }

  created() {
    this.isLoading = true;
    getStudentSupportsForProblems([this.problem.id]).then((data) => {
      this.createSupportDataStructure(data);
      this.isLoading = false;
    });
  }

  createSupportDataStructure(studentSupports: any) {
    let content, contentKey;
    if (
      studentSupports?.result &&
      studentSupports.result[this.problem.id].length !== 0
    ) {
      this.supportType =
        studentSupports.result[this.problem.id][0]?.simplifiedContentType ===
        'EXPLANATION'
          ? 'Explanation'
          : 'Hint';
      content =
        this.supportType === 'Hint'
          ? studentSupports.result[this.problem.id][0]?.hints
          : studentSupports.result[this.problem.id][0]?.explanation;
      contentKey = studentSupports.result[this.problem.id][0]?.contentKey;
    }

    let studentSupport = {
      simplifiedContentType:
        this.supportType === 'Hint' ? 'HINT_SET' : 'EXPLANATION',
      content,
      contentKey,
      dirtyFlag: false,
      problemId: this.problem.id,
      problemType: this.problem.type,
    };

    this.supportTypeOld = this.supportType;
    this.support = studentSupport;
  }

  createStudentSupport(
    content: Array<string> | string,
    simplifiedContentType: string
  ) {
    let requestBody = {
      problemId: this.problem.id,
      simplifiedContentType,
    };

    if (simplifiedContentType === 'EXPLANATION') {
      requestBody = { ...requestBody, ...{ explanation: content } };
    } else {
      requestBody = { ...requestBody, ...{ hints: content } };
    }
    this.isLoading = true;
    createStudentSupportAuthoring(requestBody).then(() => {
      getStudentSupportsForProblems([this.problem.id]).then((data) => {
        this.createSupportDataStructure(data);
        this.isLoading = false;
      });
      const newContent = this.support;
      this.support = newContent;
    });
  }

  updateStudentSupport(
    content: Array<string> | string,
    simplifiedContentType: string
  ) {
    this.isLoading = true;
    if (content.length < 1 && this.support.contentKey) {
      this.firstHintOrExplanation = true;

      deleteStudentSupportAuthoring(
        this.support.contentKey,
        this.problem.id
      ).then(() => {
        getStudentSupportsForProblems([this.problem.id]).then((data) => {
          this.createSupportDataStructure(data);
          this.isLoading = false;
        });
        const newContent = this.support;
        this.support = newContent;
      });
    } else {
      let requestBody = {
        simplifiedContentType,
        contentKey: this.support.contentKey,
        problemId: this.problem.id,
      };

      if (simplifiedContentType === 'EXPLANATION') {
        requestBody = { ...requestBody, ...{ explanation: content } };
      } else {
        requestBody = { ...requestBody, ...{ hints: content } };
      }

      updateStudentSupportAuthoring(requestBody).then(() => {
        getStudentSupportsForProblems([this.problem.id]).then((data) => {
          this.createSupportDataStructure(data);
          this.isLoading = false;
        });
        const newContent = this.support;
        this.support = newContent;
      });
    }
  }
}
