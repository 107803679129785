
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Doughnut } from 'vue-chartjs';
import Chart from 'chart.js';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { ProblemStats } from './AssignmentBarChartViewForReport.vue';
import { Padding } from 'node_modules/chartjs-plugin-datalabels/types/options';

Chart.defaults.global.defaultFontFamily = 'Montserrat';

@Component
export default class DonutChartView extends Mixins(Doughnut) {
  @Prop({ required: true }) problemStats: ProblemStats;

  //////////////////////////
  // Chart Configurations //
  //////////////////////////

  get correctColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.correct;
  }

  get correctEventuallyColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.correctEventually;
  }

  get incorrectColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.incorrect;
  }

  get chartLabels(): Array<string> {
    return ['Correct', 'Correct Eventually', 'Incorrect'];
  }

  get chartData(): Chart.ChartData {
    const datasets: Array<Chart.ChartDataSets> = [];

    const dataset: Chart.ChartDataSets = {
      data: [
        this.problemStats.numCorrect,
        this.problemStats.numCorrectEventually,
        this.problemStats.numIncorrect,
      ],
      backgroundColor: [
        this.correctColor,
        this.correctEventuallyColor,
        this.incorrectColor,
      ],
    };

    datasets.push(dataset);

    return {
      labels: this.chartLabels,
      datasets: datasets,
    };
  }

  options: Chart.ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'right',
      labels: {
        usePointStyle: true,
        fontColor: this.fontColor,
        padding: 20,
      },
    },
    tooltips: {
      // Turn off tooltip
      enabled: false,
    },
    plugins: {
      datalabels: {
        borderRadius: 12,
        color: this.labelFontColor,
        // FIXME: Better way to do this?
        padding: function (context: Context): Padding {
          const prependedPadding = {
            top: 4,
            bottom: 4,
          };

          if (context.dataset.data) {
            const count = String(context.dataset.data[context.dataIndex]);
            // Special cases for additional padding
            if (count === '1') {
              return {
                ...prependedPadding,
                left: 10,
                right: 10,
              };
            } else if (count.length === 1 || count === '11') {
              // One digit
              return {
                ...prependedPadding,
                left: 8,
                right: 8,
              };
            }
          }

          // Default padding
          return {
            ...prependedPadding,
            left: 6,
            right: 6,
          };
        },
        font: {
          family: 'Montserrat',
          size: 14,
        },
        backgroundColor: '#FFFFFF',
        display: function (context: Context): boolean {
          // Ignore 0
          if (context.dataset.data) {
            return context.dataset.data[context.dataIndex] !== 0;
          }

          return false;
        },
      },
    },
  };

  //////////////////
  // Chart Colors //
  //////////////////

  get fontColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.darken1;
  }

  get labelFontColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.darken4;
  }

  /////////////
  // Methods //
  /////////////

  //////////////
  // Watchers //
  //////////////

  @Watch('chartData')
  onDataChange(): void {
    // Tranform data, mutation instead of pushing new data
    // Better if we implement our own watcher
    // this.renderChart(this.chartData, this.options);

    // New datasets
    this.$data._chart.data = this.chartData;

    // Update chart instance (with animation) to the new data values and options
    this.$data._chart.update();
  }

  mounted(): void {
    // Add the plugin to chart
    this.addPlugin(ChartDataLabels);

    // Have to do this on mount. On create doesn't render
    // No template - take it from the extended one
    // Create chart instance inside canvas
    this.renderChart(this.chartData, this.options);
  }
}
