export interface CurriculumDefinition {
  xref: string;
  name: string;
  curriculumCategoryType: CurriculumCategoryType;
  rootFolderId: number;
}

export enum CurriculumCategoryType {
  CURRICULA = 'CURRICULA',
  STATE_TESTS = 'STATE_TESTS',
}

export enum CurriculumHierarchyLevel {
  LEVEL_1 = 'LEVEL_1',
  LEVEL_2 = 'LEVEL_2',
  LEVEL_3 = 'LEVEL_3',
}

// Represents the curricular structure of a grade folder
export interface CurriculumGrade {
  folderId: number;
  // Name for modules in this curriculum e.g. 'Unit'
  moduleTitle: string;
  lessonTitle: string;
  // Keys are module folder numbers
  modules: Map<number, ModuleDefinition>;
  numProblemsPerProblemSetType: Map<string, number>;
  numProblemsPerAssessmentType: Map<string, number>;
}

export interface ModuleDefinition {
  folderId: number;
  moduleName: string;
  moduleNumber: string;
  // Keys are lesson folder numbers
  lessons: Map<number, LessonDefinition>;
  // Keys are problem set types of lessons
  numProblemsPerProblemSetType: Map<string, number>;
  // Keys are problem set types of assessments
  assessmentTypeStats: Map<string, ProblemSetTypeStats>;
}

export interface LessonDefinition {
  folderId: number;
  lessonName: string;
  lessonNumber: string;
  // Keys are problem set types
  problemSetTypeStats: Map<string, ProblemSetTypeStats>;
}

// Info on all problem sets of a given type within a lesson
export interface ProblemSetTypeStats {
  type: string;
  numProblems: number;
  // PSA's of problems sets of this type within the lesson
  ceris: Array<string>;
}
