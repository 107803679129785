import { render, staticRenderFns } from "./AssignmentStudentDataTableView.vue?vue&type=template&id=6804002b&scoped=true&"
import script from "./AssignmentStudentDataTableView.vue?vue&type=script&lang=ts&"
export * from "./AssignmentStudentDataTableView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6804002b",
  null
  
)

export default component.exports