import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { LDOEState } from './types';
import { RootState } from '../types';

export const state: LDOEState = {
  hasDownloadedTutees: false,
  isDownloadingTutees: false,
  tutees: [],
  selectedTuteeXrefs: [],
  selectedTimeFrame: null,
};

const namespaced = true;

export const ldoe: Module<LDOEState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
