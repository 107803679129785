import { TutoringContainer, TutorStrategyType } from '@/domain/Tutoring';
import { coreAxios } from '@/plugins/axios';

const END_POINT = '/legacy-content';

const getTutoringForProblem = (
  problemId: number,
  type: TutorStrategyType
): Promise<TutoringContainer> => {
  return coreAxios
    .get(`${END_POINT}/problems/${problemId}/tutoring`, {
      params: { type },
    })
    .then((res) => res.data);
};

const getTutoringForProblemWithTutorStrategyId = (
  problemId: number,
  tutorStrategyId: number
): Promise<TutoringContainer> => {
  return coreAxios
    .get(`${END_POINT}/problems/${problemId}/tutoring/${tutorStrategyId}`)
    .then((res) => res.data);
};

export { getTutoringForProblem, getTutoringForProblemWithTutorStrategyId };
