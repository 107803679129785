
import { TimeFrame } from '@/domain/Time';
import { buildTimeFrame } from '@/utils/buildTimeFrame.util';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class DatePicker extends Vue {
  datePickerItems = [
    {
      text: 'Last 15 days',
      value: 15,
    },
    {
      text: 'Last 30 days',
      value: 30,
    },
    {
      text: 'Last 60 days',
      value: 60,
    },
    {
      text: 'Last 90 days',
      value: 90,
    },
    {
      text: 'Last 120 days',
      value: 120,
    },
  ];

  daysToBeSubtracted: number = this.storedTimeFrame ?? 30;

  get timeSelected(): TimeFrame {
    return buildTimeFrame(this.daysToBeSubtracted);
  }

  get storedTimeFrame(): number | null {
    const timeFrameString = localStorage.getItem('selectedTimeFrame');

    return timeFrameString ? Number(timeFrameString) : null;
  }

  setTimeFrame(): void {
    this.$store.commit(
      'ldoe/setSelectedTimeFrame',
      buildTimeFrame(this.daysToBeSubtracted)
    );

    localStorage.setItem('selectedTimeFrame', `${this.daysToBeSubtracted}`);
  }
}
