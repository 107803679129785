
import { Component, Vue } from 'vue-property-decorator';
import NavBar from './components/base/NavBar.vue';
import NotificationsView from './components/base/notifications/NotificationsView.vue';
import AlertMessagesView from './components/base/notifications/AlertMessagesView.vue';
import userflow from 'userflow.js';

interface userflowInitUserData {
  name: string;
  email: string;
}

@Component({
  components: {
    NavBar,
    NotificationsView,
    AlertMessagesView,
  },
})
export default class App extends Vue {
  initialized = false;

  created(): void {
    this.$store.dispatch('initialize').finally(() => {
      if (this.$route.query.newUser === 'true') {
        //Copy the query object to a new one. Has to be done.
        let query = Object.assign({}, this.$route.query);

        //remove newUser
        delete query.newUser;

        //Resolve the current path without new User
        const resolved = this.$router.resolve({
          path: this.$route.path,
          query: query,
        });

        //go to second registration with current url as redirect.
        this.$router.push({
          name: 'select-school',
          query: {
            goTo: `${resolved.href}`,
          },
        });
      }

      this.initialized = true;

      if (
        process.env.VUE_APP_USERFLOW_ENABLED === 'true' &&
        this.isUserATeacher
      ) {
        this.initializeUserflow(this.getCurrentUser.xref);
      }
    });
  }
  get isUserATeacher(): boolean {
    return this.getCurrentUser?.roles.includes('TEACHER') || false;
  }
  get userflowUserData(): userflowInitUserData {
    return {
      name: this.getCurrentUser.displayName,
      email: this.getCurrentUser.userName,
    };
  }
  initializeUserflow(userXref: string): void {
    // https://userflow.com/
    userflow.init(process.env.VUE_APP_USERFLOW_TOKEN);
    userflow.identify(userXref, { ...this.userflowUserData });
  }
}
