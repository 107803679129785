
import StudentDetailsTable from '@/components/Report/StudentDetailsTable.vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import NoDataView from '@/components/Report/NoDataView.vue';
import FeedbackSideSheet from '@/components/Report/FeedbackSideSheet.vue';
import ProblemSideSheetForReport from '@/components/Report/ProblemSideSheetForReport.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { User } from '@/domain/User';
import { getAssignmentAssignees } from '@/api/core/assignment.api';
import { AssignmentDefinition } from '@/domain/Assignment';
import { getProblemsByPsXref } from '@/api/pspr.api';
import { Problem } from '@/domain/Problem';
import sortBySortableName from '@/utils/sortBySortableName.util';

import {
  AssignmentReportData,
  ProblemLog,
  StudentLog,
  StudentStats,
} from '@/domain/ReportData/AssignmentData';
import {
  getAssignmentDefinition,
  getAssignmentReportData,
} from '@/api/core/assignment.api';
import { getQuickComments, RequestParams } from '@/api/quickcomments.api';
import { QuickComment } from '@/domain/QuickComment';
import { isEmpty } from 'lodash';

dayjs.extend(duration);

enum Direction {
  PREVIOUS = -1,
  NEXT = 1,
}

@Component({
  components: {
    StudentDetailsTable,
    NoDataView,
    FeedbackSideSheet,
    ProblemSideSheetForReport,
  },
})
export default class StudentDetailsPage extends Vue {
  Direction = Direction;

  assignmentXref = '';

  assignment: AssignmentDefinition | null = null;
  assignmentReportData: AssignmentReportData | null = null;
  students: Array<User> = [];
  quickComments: Array<QuickComment> = [];

  feedbackSideSheet = false;
  problemSideSheet = false;

  problemSetXref = '';
  selectedProblemId = 0;
  selectedProblemLogId = 0;

  outerPromisesReady = false;
  innerPromisesReady = false;
  quickCommentsReady = false;

  numberOfRetrievedComments = 3;

  problemLogIdToLog: Map<number, ProblemLog> = new Map();
  problemIdtoProblem: Map<number, Problem> = new Map();

  get studentXref(): string {
    return this.$route.params.studentXref;
  }

  set studentXref(studentXref: string) {
    this.updateQueryParam(studentXref);
  }

  get selectedStudentIndex(): number {
    return this.students.findIndex(
      (student) => student.xref === this.studentXref
    );
  }

  get selectedStudent(): User {
    return this.students[this.selectedStudentIndex];
  }

  get selectedStudentLog(): StudentLog | null {
    return (
      this.assignmentReportData?.studentLogs.find(
        (log) => log.studentXref === this.studentXref
      ) || null
    );
  }

  get selectedStudentStats(): StudentStats | null {
    return (
      this.assignmentReportData?.summaryStatsAll?.studentStats.find(
        (log) => log.studentXref === this.studentXref
      ) || null
    );
  }

  get selectedStudentScore(): number {
    return Math.round((this.selectedStudentStats?.score ?? 0) * 100);
  }

  get selectedStudentTimeSpent(): string {
    return this.getTimeSpent(this.selectedStudentStats?.timeSpent ?? 0);
  }

  get selectedProblem(): Problem | undefined {
    return this.problemIdtoProblem.get(this.selectedProblemId);
  }

  get selectedProblemLog(): ProblemLog | undefined {
    return this.problemLogIdToLog.get(this.selectedProblemLogId);
  }

  get pageReady(): boolean {
    return (
      this.innerPromisesReady &&
      this.outerPromisesReady &&
      this.quickCommentsReady
    );
  }

  get isQuickCommentsTeacher(): boolean {
    return (
      this.$store.getters['auth/getCurrentUser']?.attributes
        ?.quickCommentsTeacher ?? false
    );
  }

  get quickCommentsRequestParams(): RequestParams {
    const params: RequestParams = {
      body: [],
    };
    params.body.push({
      userId: this.studentXref,
      problemId: this.selectedProblemId,
      teacherId: this.$store.getters['auth/getCurrentUser'].xref,
      answerText: this.selectedProblemLog?.answerText || '',
      numOfComments: this.numberOfRetrievedComments,
    });
    return params;
  }

  updateSelectedStudentLog(data: {
    id: number;
    continuousScore?: number;
    teacherComment?: string;
  }): void {
    const pLog = this.problemLogIdToLog.get(data.id);

    if (!pLog) {
      return;
    }

    if (typeof data.continuousScore == 'number') {
      pLog.continuousScore = data.continuousScore;
    }
    if (data.teacherComment) {
      pLog.teacherComment = data.teacherComment;
    }
  }

  openFeedbackSideSheet(item: {
    problemLogId: number;
    problemId: number;
  }): void {
    this.selectedProblemLogId = item.problemLogId;
    this.selectedProblemId = item.problemId;
    this.feedbackSideSheet = true;
  }

  openProblemSideSheet(problemId: number): void {
    this.selectedProblemId = problemId;
    this.problemSideSheet = true;
  }

  updateQueryParam(studentXref: string): void {
    this.$router.push({
      name: 'studentDetailsPage',
      params: {
        studentXref: studentXref,
      },
      query: this.$route.query,
    });
  }

  changeSelectedStudent(direction: Direction): void {
    const student = this.students[this.selectedStudentIndex + direction];

    if (student) {
      this.studentXref = student.xref;
    }
  }

  getTimeSpent(ms?: number): string {
    // Format 00:00:00
    if (typeof ms === 'number') {
      const d = dayjs.duration(ms);
      return `${Math.floor(d.asHours())}:${d.format('mm:ss')}`;
    }

    return '-';
  }

  @Watch('$route.params.studentXref')
  fetchQuickComments(): void {
    this.quickCommentsReady = false;

    if (this.isQuickCommentsTeacher) {
      getQuickComments(this.quickCommentsRequestParams)
        .then((data) => {
          this.quickComments = data.flat();
        })
        .finally(() => {
          this.quickCommentsReady = true;
        });
    } else {
      this.quickComments = [];
      this.quickCommentsReady = true;
    }
  }

  created(): void {
    this.assignmentXref = this.$route.params.xref;

    // Load report data
    const assignmentReportDataPromise = getAssignmentReportData(
      this.assignmentXref
    ).then((assignmentReportData: AssignmentReportData) => {
      if (!isEmpty(assignmentReportData)) {
        this.assignmentReportData = assignmentReportData;

        this.assignmentReportData?.studentLogs.forEach((slog) => {
          //for each student log
          slog.problemLogAndActions?.forEach((logAndAction) => {
            const plog = logAndAction.prLog;
            this.problemLogIdToLog.set(plog.id, plog);
          });
        });
      }
    });

    // Load assignment definition
    const assignmentDefinitionPromise = getAssignmentDefinition(
      this.assignmentXref
    ).then((assignmentDefinition: AssignmentDefinition) => {
      if (assignmentDefinition) {
        this.problemSetXref = assignmentDefinition.problemSetCeri;
        this.assignment = assignmentDefinition;

        const getProblemsPromise = getProblemsByPsXref(
          this.problemSetXref as string,
          ['ANSWERS', 'CURRICULA', 'SKILLS', 'ATTRIBUTIONS']
        ).then((problems: Array<Problem>) => {
          problems.forEach((problem) => {
            this.problemIdtoProblem.set(problem.id, problem);
          });
        });

        // Load assignees
        const rosterPromise = getAssignmentAssignees(assignmentDefinition.xref)
          .then((students: Array<User>) => {
            if (students) {
              // Sort students alphabetically
              students.sort((a, b) => {
                return sortBySortableName(a, b);
              });
              this.students = students;
            }
          })
          .catch(() => {
            // 404 - No assignees found
            this.students = [];
          });

        Promise.all([rosterPromise, getProblemsPromise]).finally(() => {
          this.innerPromisesReady = true;
        });
      }
    });

    Promise.all([
      assignmentReportDataPromise,
      assignmentDefinitionPromise,
    ]).finally(async () => {
      await this.fetchQuickComments();
      this.outerPromisesReady = true;
    });
  }
}
