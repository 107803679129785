
import { ProblemSet } from '@/domain/ProblemSet';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AssignDialog from './AssignDialog.vue';
import LoginButton from '@/components/base/LoginButton.vue';
import SaveToMyProblemSetsDialog from '@/components/MyProblemSets/SaveToMyProblemSetsDialog.vue';

@Component({
  components: {
    AssignDialog,
    LoginButton,
    SaveToMyProblemSetsDialog,
  },
})
export default class AssignBottomBar extends Vue {
  @Prop() problemSet: ProblemSet | null;
  @Prop() selectedAssistmentIds: number[] | null;
  @Prop() numTotalProblems: number;
  @Prop({ default: false }) saveToPS: boolean;
  @Prop({ default: false }) removeProblems: boolean;

  isSkillBuilder = false;
  savingToMyProblemSets = false;

  get disabled(): boolean {
    let ret = true;
    if (this.problemSet && this.isSkillBuilder) {
      //IF we are a skillbuilder, we are enabled.
      ret = false;
    } else {
      //IF we have selected problems then enable also
      if (this.selectedAssistmentIds && this.selectedAssistmentIds.length > 0) {
        //we good
        ret = false;
      }
    }
    return ret;
  }

  get assignButtonText(): string {
    if (this.isSkillBuilder) {
      return 'ASSIGN SKILLBUILDER';
    } else {
      return `ASSIGN TO CLASS`;
    }
  }

  get assignmentName(): string {
    if (this.problemSet) {
      return this.problemSet.name;
    }
    return '';
  }

  get removeProblemBtnText(): string {
    if (!this.selectedAssistmentIds) {
      return '';
    }
    let length = this.selectedAssistmentIds.length;
    let addS = length > 1 ? 'S' : '';
    return `REMOVE ${length} PROBLEM${addS}`;
  }

  created(): void {
    this.isSkillBuilder = this.problemSet
      ? this.problemSet.isSkillBuilder
      : false;
  }
}
