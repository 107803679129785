import dayjs from 'dayjs';
import { TimeFrame } from '@/domain/Time';

export function buildTimeFrame(daysToSubtract: number): TimeFrame {
  const now = dayjs();

  return {
    endDateTime: now.valueOf(),
    startDateTime: now.subtract(daysToSubtract, 'days').valueOf(),
  };
}
