import { MutationTree } from 'vuex';
import { cloneDeep } from 'lodash';
import { Skill } from '@/domain/Skill';
import { SkillState } from './types';

export const mutations: MutationTree<SkillState> = {
  setHasDownloaded(state: SkillState, flag: boolean) {
    state.hasDownloaded = flag;
  },
  setIsDownloading(state: SkillState, flag: boolean) {
    state.isDownloading = flag;
  },
  setSkillList(state: SkillState, newList: Array<Skill>) {
    // Calculate each skill's displayName
    state.skills = newList.map((skill) => {
      const mappedSkill = cloneDeep(skill);
      mappedSkill.displayName = `${mappedSkill.code} - ${mappedSkill.name}`;

      return mappedSkill;
    });
  },
  setSelectedSkill(state: SkillState, newSelection: number) {
    state.selectedSkillId = Number(newSelection);
  },
};
