
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  PartialAssignmentStudentData,
  BaseStudentDataPerAssignment,
} from '@/domain/ReportData/Cignition';
import { Skill } from '@/domain/Skill';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { DataTableHeader } from 'vuetify';

dayjs.extend(duration);

interface TableRow {
  assignmentXref: string;
  assignmentName: string;
  averageScore: { [xref: string]: string | null };
  timeSpent: { [xref: string]: string | null };
  lastWorkedOn: { [xref: string]: string | null };
  standardsCovered: Array<Skill>;
  // TODO: Add assignmentType when server supports this
  // assignmentType: string;
}

export interface CustomTableLabel {
  label: string;
  value: string;
  backgroundColor: string;
}

@Component
export default class AssignmentStudentDataTableView extends Vue {
  @Prop() customHeaderLabels!: Array<CustomTableLabel>;
  @Prop() assignmentData!: Array<PartialAssignmentStudentData>;
  @Prop({ default: false }) loading: boolean;

  get sortedAssignmentData(): Array<PartialAssignmentStudentData> {
    let listToSort = [...this.assignmentData];

    return listToSort.sort(
      (a: PartialAssignmentStudentData, b: PartialAssignmentStudentData) => {
        return (
          this.getMinDeltaTimeLastWorkedOn(a) -
          this.getMinDeltaTimeLastWorkedOn(b)
        );
      }
    );
  }

  getMinDeltaTimeLastWorkedOn(data: PartialAssignmentStudentData): number {
    let deltaTimes: Array<number> = [];

    data.studentData.forEach((info: BaseStudentDataPerAssignment) => {
      let now = dayjs();
      let lastWorkedOn = dayjs(info.lastWorkedOn);

      deltaTimes.push(now.diff(lastWorkedOn));
    });

    return Math.min.apply(null, deltaTimes);
  }

  /**
   * Computed props: skills from store
   */
  get skills(): Array<Skill> {
    return this.$store.state.skillList.skills;
  }

  customHeaderOptions: Partial<DataTableHeader> = {
    align: 'center',
    sortable: false,
  };

  sharedHeaders: Array<DataTableHeader> = [
    {
      text: '% Correct',
      value: 'averageScore',
    },
    {
      text: 'Time Spent',
      value: 'timeSpent',
    },
    {
      text: 'Last Worked On',
      value: 'lastWorkedOn',
    },
  ];

  appendCustomHeaderOptions(header: DataTableHeader): DataTableHeader {
    return { ...this.customHeaderOptions, ...header };
  }

  get customHeaders(): Array<DataTableHeader> {
    return this.sharedHeaders.map(this.appendCustomHeaderOptions);
  }

  get tableHeaders(): Array<DataTableHeader> {
    return [
      {
        text: 'Assignment Name',
        value: 'assignmentName',
        sortable: true,
      },
      ...this.customHeaders,
      {
        text: 'Standards Covered',
        value: 'standardsCovered',
        sortable: false,
      },
      // TODO: Add once server supports assignmentType
      // {
      //   text: 'Assignment Type',
      //   value: 'assignmentType',
      //   sortable: false,
      // },
    ];
  }

  get tableData(): Array<TableRow> {
    return this.sortedAssignmentData.map(
      (data: PartialAssignmentStudentData) => {
        return this.transformToTableRow(data);
      }
    );
  }

  transformToTableRow(data: PartialAssignmentStudentData): TableRow {
    const { xref, name, skillIds, studentData } = data;

    // const assignmentType = data.type;

    let standardsCovered: Array<Skill> = [];

    if (skillIds) {
      standardsCovered = this.skills.filter((skill: Skill) => {
        return skillIds.includes(Number(skill.id));
      });
    }

    let averageScore: { [xref: string]: string | null } = {};
    let timeSpent: { [xref: string]: string | null } = {};
    let lastWorkedOn: { [xref: string]: string | null } = {};

    studentData.forEach((data: BaseStudentDataPerAssignment) => {
      averageScore[data.studentXref] = this.getPercentCorrect(
        data.averageScore
      );
      timeSpent[data.studentXref] = this.getTimeSpent(data.timeSpent);
      lastWorkedOn[data.studentXref] = this.getLastWorkedOn(data.lastWorkedOn);
    });

    return {
      assignmentXref: xref ? xref : '',
      assignmentName: name,
      // assignmentType: assignmentType ? assignmentType : '',
      standardsCovered: standardsCovered,
      averageScore: averageScore,
      timeSpent: timeSpent,
      lastWorkedOn: lastWorkedOn,
    };
  }

  getPercentCorrect(score?: number): string | null {
    const intScore: string | null =
      score != undefined ? Math.round(score * 100) + '%' : null;

    return intScore;
  }

  getTimeSpent(ms?: number): string | null {
    // Format 00:00:00:00
    return ms != undefined ? dayjs.duration(ms).format('HH:mm:ss') : null;
  }

  getLastWorkedOn(unixms?: number): string | null {
    // Format MM/DD
    return unixms != undefined ? dayjs(unixms).format('MM/DD') : null;
  }

  created(): void {
    // Download skills if not already
    // Will be prevented to download again in store if already
    this.$store.dispatch('skillList/requestSkills');
  }

  goToAssignmentView(assignmentXref: string): void {
    this.$router.push({
      name: 'TutoringAssignments',
      params: {
        xref: assignmentXref,
      },
      query: this.$route.query,
    });
  }

  goToProblemsByStandard(skillId: string): void {
    this.$router.push({
      name: 'problemsByStandard',
      params: {
        id: skillId,
      },
      query: this.$route.query,
    });
  }
}
