import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { LTIState } from './types';
import { RootState } from '../types';

export const state: LTIState = {
  currentlyLaunchedCourseXref: null,
};

const namespaced = true;

export const lti: Module<LTIState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
