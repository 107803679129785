
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Problem } from '@/domain/Problem';
import SaveToMyProblemSetsDialog from '@/components/MyProblemSets/SaveToMyProblemSetsDialog.vue';
import GoToProblemSetDialog from '@/components/base/GoToProblemSetDialog.vue';
import StudentSupportsDialog from '@/components/StudentSupportAuthoring/StudentSupportsDialog.vue';

export enum ParentPage {
  LESSON_VIEW = 1,
  SEARCH_RESULTS = 2,
  MY_PROBLEM_SETS = 3,
}

@Component({
  components: {
    SaveToMyProblemSetsDialog,
    GoToProblemSetDialog,
    StudentSupportsDialog,
  },
})
export default class ProblemViewMenu extends Vue {
  @Prop() parentPage: ParentPage;
  @Prop() mainProblem: Problem;
  @Prop() problems: Problem[];

  @Prop() isFirst: boolean; // is first assistment in list
  @Prop() isLast: boolean; // is last assistment in list

  ParentPage = ParentPage;
  saveToPSDialog = false;
  // For 'Go to Problem Set' dialog (when multiple options)
  showGoToPsDialog = false;
  editSSDialog = false;

  get assistmentId(): number {
    return this.mainProblem.assistmentId;
  }
  get previewLink(): string {
    const usr = this.$store.getters['auth/getCurrentUser'];
    return `${this.$store.state.urls.tutorTemplateUrl}?assignmentRef=PR${this.assistmentId}&preview=true&pr=TNG&ut=${usr.userToken}&laId=${usr.loginAliasId}`;
  }
  get showPrMenu(): boolean {
    return this.isAuthenticated || this.parentPage == ParentPage.SEARCH_RESULTS;
  }
  get problemSetIds(): Array<number> {
    return this.mainProblem.parentProblemSetIds as Array<number>;
  }
  get problemSetIdsExist(): boolean {
    const psIds = this.problemSetIds;
    return psIds && psIds.length > 0;
  }

  goToProblemSet(): void {
    const psIds = this.problemSetIds;
    if (psIds.length === 1) {
      this.$router.push({
        name: 'LessonPage',
        params: {
          type: 'ps',
          psId: `${psIds[0]}`,
          id: `${psIds[0]}`,
        },
        query: {
          skillId: `${this.$route.params.skillId}`,
        },
      });
    } else {
      this.showGoToPsDialog = true;
    }
  }
}
