
import SummaryBanner from '@/components/InsightsHub/ActivityPage/SummaryBanner.vue';
import { CourseDefinition } from '@/domain/Class';
import {
  ClassCurriculumStats,
  StatsPerProblemSetType,
  TeacherCurriculumStats,
} from '@/domain/ReportData/InsightsHub';
import { User } from '@/domain/User';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import { CurriculumGrade, ModuleDefinition } from '@/domain/Curriculum';
import LessonDetailsTable, {
  LessonProblemSet,
} from '@/components/InsightsHub/ActivityPage/LessonDetailsTable.vue';
import HeatMap from '@/components/InsightsHub/ActivityPage/HeatMap.vue';
import { School } from '@/domain/School';
import {
  Fraction,
  GradientHeaders,
  GradientRows,
  Row,
  RowType,
  StaticHeader,
} from '@/components/InsightsHub/base/TreeGradientTable.vue';
import { mergeProblemSetTypeStats } from '@/api/core/curricula.api';
import ActivityScoringDialog from '@/components/InsightsHub/ActivityPage/ActivityScoringDialog.vue';

export enum ActivityLevel {
  MODULE = 'Module',
  GRADE = 'Grade',
}

export enum ActivityMode {
  ASSIGNED = 'Curriculum Assigned',
  COMPLETED = 'Student Completion',
  SCORE = '% Average Score',
}

export enum ActivityTab {
  OVERVIEW = 'Overview',
  DETAILED_REPORT = 'Detailed Report',
}

@Component({
  components: {
    ActivityScoringDialog,
    HeatMap,
    LessonDetailsTable,
    SummaryBanner,
  },
})
export default class ActivityPage extends Vue {
  // Allows us access to the enum in the template.
  ActivityMode = ActivityMode;
  ActivityLevel = ActivityLevel;

  RowType = RowType;

  /////////////////////////////////////////////////////////////////////////////
  // Read from store table settings and other preserved states of the report //
  /////////////////////////////////////////////////////////////////////////////

  // Preserve and sync table settings
  get collapsedPaths(): string[] {
    return this.$store.state.insightsHub.collapsedPaths;
  }

  set collapsedPaths(value: string[]) {
    this.$store.commit('insightsHub/setCollapsedPaths', value);
  }

  get options(): DataOptions {
    return this.$store.state.insightsHub.options;
  }

  set options(value: DataOptions) {
    this.$store.commit('insightsHub/setOptions', value);
  }

  // Preserve and sync other states of the report
  get selectedModuleId(): number | null {
    return this.$store.state.insightsHub.selectedModuleId;
  }

  get tab(): number | null {
    return this.$store.state.insightsHub.selectedTab;
  }

  set tab(value: number | null) {
    this.$store.commit('insightsHub/setSelectedTab', value);
  }

  get selectedTab(): ActivityTab {
    return this.tab === 0 || this.tab === null
      ? ActivityTab.OVERVIEW
      : ActivityTab.DETAILED_REPORT;
  }

  modeChoices: ActivityMode[] = [
    ActivityMode.ASSIGNED,
    ActivityMode.COMPLETED,
    ActivityMode.SCORE,
  ];

  get selectedMode(): ActivityMode {
    const mode = this.$store.state.insightsHub.selectedMode;

    return mode === null ? ActivityMode.ASSIGNED : mode;
  }

  set selectedMode(value: ActivityMode) {
    this.$store.commit('insightsHub/setSelectedMode', value);
  }

  get psTypes(): string[] {
    const res = [];

    const numProblemsPerProblemSetType =
      this.curriculumGrade?.numProblemsPerProblemSetType ?? new Map();

    for (const [psType, numProblems] of numProblemsPerProblemSetType) {
      if (numProblems > 0) {
        res.push(psType);
      }
    }

    return res;
  }

  get selectedPsTypes(): string[] {
    const selectedTypes = this.$store.state.insightsHub.selectedPsTypes;

    return selectedTypes.length === 0
      ? this.psTypes.length > 0
        ? this.psTypes
        : []
      : selectedTypes;
  }

  set selectedPsTypes(value: string[]) {
    this.$store.commit('insightsHub/setSelectedPsTypes', value);
  }

  get anonymized(): boolean {
    return this.$store.state.insightsHub.anonymized;
  }

  set anonymized(value: boolean) {
    /*
    Upside:
    - Preserves the shuffled version of the teacher curriculum stats in the store so that the user can see the same thing or in same order
    (if not sorted in table) when he or she returns from another page when their anonymized setting is on (so that we can avoid shuffling
    again upon returning because it was already shuffled before the user left the page.
    - Allows us to keep calculations as getters so that we are reactive to any changes in selections

    Downside:
    - Recomputes everything if anonymizes setting is on

    Alternatively, we can keep the calculated and shuffled version in store. However, if we want to keep it as a getter (calculations), we
    will have two versions of the same thing in memory - both in Activity Page and in the store?, and we will need a watcher on that getter
    to know when to update the one in the store (which is duplicate data just to preserve shuffled and/or expanded states). Or, we can keep
    one version in the store, but the getter turns into a method that computes everything based on current selections and saves/updates the
    result to the store. But for every factor that impacts the computation, we will need a watcher for to know when to recompute. That’ll be
    too many watchers just to recalculate everything and making sure the store is up-to-date.
    */
    if (value) {
      // Shuffled version is now saved to the store and is only shuffled when the user physically makes a change to that setting so that we
      // don’t shuffle again upon returning to this page from somewhere else
      this.$store.dispatch('insightsHub/shuffleMenteeTeachers');
    }

    this.$store.commit('insightsHub/setAnonymized', value);
  }

  get loading(): boolean {
    return this.$store.state.insightsHub.dashboardLoading;
  }

  //////////////////////
  // Report Rendering //
  //////////////////////

  get curriculumXref(): string {
    return this.$route.params.xref;
  }

  get gradeFolderId(): number {
    return Number(this.$route.params.gradeFolderId);
  }

  get xrefToGradeTeacherMap(): Map<string, User> {
    return this.$store.getters['insightsHub/xrefToGradeTeacherMap'];
  }

  get xrefToCourseMap(): Map<string, CourseDefinition> {
    return this.$store.getters['insightsHub/xrefToCourseMap'];
  }

  get xrefToSchoolMap(): Map<string, School> {
    return this.$store.getters['insightsHub/xrefToSchoolMap'];
  }

  get showOrHideNamesText() {
    return this.anonymized ? 'Show' : 'Hide';
  }

  get modeColor(): string {
    switch (this.selectedMode) {
      case ActivityMode.COMPLETED:
        // eslint-disable-next-line
        // @ts-ignore
        return this.$vuetify.theme.themes.light.correctEventually;
      case ActivityMode.SCORE:
        // eslint-disable-next-line
        // @ts-ignore
        return this.$vuetify.theme.themes.light.correct;
      case ActivityMode.ASSIGNED:
      default:
        // eslint-disable-next-line
        // @ts-ignore
        return this.$vuetify.theme.themes.light.primary.base;
    }
  }

  get level(): ActivityLevel {
    if (this.selectedModuleDefinition !== null) {
      return ActivityLevel.MODULE;
    } else {
      return ActivityLevel.GRADE;
    }
  }

  get tableHeaderTitle(): string {
    switch (this.level) {
      case ActivityLevel.MODULE:
        return this.curriculumGrade?.lessonTitle ?? '';
      case ActivityLevel.GRADE:
      default:
        return this.curriculumGrade?.moduleTitle ?? '';
    }
  }

  // Header values are strings shaped <folderId>_<mode> e.g. `3971_ASSIGNED`
  get computedHeaders(): GradientHeaders {
    const res: GradientHeaders = {
      averageHeader: { text: 'AVG', value: '' },
      itemHeaders: [],
    };
    if (this.curriculumGrade) {
      let modeSuffix;
      switch (this.selectedMode) {
        case ActivityMode.ASSIGNED:
          modeSuffix = 'ASSIGNED';
          break;
        case ActivityMode.COMPLETED:
          modeSuffix = 'COMPLETED';
          break;
        case ActivityMode.SCORE:
          modeSuffix = 'SCORE';
          break;
      }
      switch (this.level) {
        case ActivityLevel.GRADE:
          for (const [folderId, module] of this.curriculumGrade.modules) {
            res.itemHeaders.push({
              text: module.moduleNumber,
              value: `${folderId}_${modeSuffix}`,
            });
          }
          break;
        case ActivityLevel.MODULE:
          if (this.selectedModuleDefinition) {
            for (const [folderId, lesson] of this.selectedModuleDefinition
              .lessons) {
              if (this.selectedTab === ActivityTab.OVERVIEW) {
                res.itemHeaders.push({
                  text: lesson.lessonNumber,
                  value: `${folderId}_${modeSuffix}`,
                });
              } else if (this.selectedTab === ActivityTab.DETAILED_REPORT) {
                for (const psType of this.selectedPsTypes) {
                  if (lesson.problemSetTypeStats.get(psType)) {
                    // Lesson has Problem Set(s) of Type
                    res.itemHeaders.push({
                      text: `${lesson.lessonNumber}: ${lesson.lessonName}`,
                      value: `${folderId}_${psType}_${modeSuffix}`,
                    });
                  }
                }
              }
            }
          }
          break;
      }
      // Update Average Header Value
      res.averageHeader.value = `average_${modeSuffix}`;
    }
    return res;
  }

  /////////////////
  // Report Data //
  /////////////////

  // Curricular shape
  get curriculumGrade(): CurriculumGrade | null {
    return this.$store.state.insightsHub.curriculumGrade;
  }

  get selectedModuleDefinition(): ModuleDefinition | null {
    if (this.curriculumGrade && this.selectedModuleId) {
      return this.curriculumGrade.modules.get(this.selectedModuleId) ?? null;
    }

    return null;
  }

  // Student & Teacher Data
  get teacherCurriculumStats(): TeacherCurriculumStats[] {
    return this.$store.state.insightsHub.teacherCurriculumStats;
  }

  get teacherXrefToCurriculumStats(): Map<string, TeacherCurriculumStats> {
    const res = new Map();

    for (const stats of this.teacherCurriculumStats) {
      res.set(stats.teacherXref, stats);
    }

    return res;
  }

  // Summary Banner
  get assignedPercent(): number {
    const fraction: Fraction = this.computedRows.averageRow[
      `average_ASSIGNED`
    ] as Fraction;

    if (fraction) {
      return fraction.numerator / fraction.denominator;
    }

    return 0;
  }

  get completedPercent(): number | null {
    const fraction: Fraction = this.computedRows.averageRow[
      `average_COMPLETED`
    ] as Fraction;

    if (fraction) {
      return fraction.numerator / fraction.denominator;
    }

    return null;
  }

  get averageScore(): number | null {
    const fraction: Fraction = this.computedRows.averageRow[
      `average_SCORE`
    ] as Fraction;

    if (fraction) {
      return fraction.numerator / fraction.denominator;
    }

    return null;
  }

  get computedRows(): GradientRows {
    const res = {
      averageRow: {
        [StaticHeader.NAME]: 'AVG',
        children: [],
      } as unknown as Row,
      itemRows: new Map<string, Row>(),
    };
    for (const [xref, teacher] of this.xrefToGradeTeacherMap) {
      // Average Row
      res.averageRow.children?.push(teacher.xref);

      // School Row
      const schoolXrefs = teacher.attributes?.ncesPublicSchool ?? [];
      const schoolRows = [];

      for (const schoolXref of schoolXrefs) {
        const school = this.xrefToSchoolMap.get(schoolXref);

        if (school) {
          const schoolId = `school_${school.ncesId}`;
          const schoolName = school.name;

          const schoolRow = res.itemRows.get(schoolId) ?? {
            parents: null,
            xref: schoolId,
            type: RowType.SCHOOL,
            [StaticHeader.NAME]: schoolName,
            children: [],
          };

          // Add Teacher to School Row/Subtree
          schoolRow.children?.push(teacher.xref);

          schoolRows.push(schoolRow);
        }
      }

      // Not affiliated to any Schools (or not found) whatsoever
      if (schoolRows.length === 0) {
        let schoolId = 'unaffiliated';
        let schoolName = 'Unaffiliated';

        const schoolRow = res.itemRows.get(schoolId) ?? {
          parents: null,
          xref: schoolId,
          type: RowType.SCHOOL,
          [StaticHeader.NAME]: schoolName,
          children: [],
        };

        // Add Teacher to School Row/Subtree
        schoolRow.children?.push(teacher.xref);

        schoolRows.push(schoolRow);
      }
      let teacherRow: Row = {
        parents: schoolRows.map((schoolRow) => schoolRow.xref),
        xref: teacher.xref,
        type: RowType.TEACHER,
        [StaticHeader.NAME]: teacher.displayName,
        children: [],
        sortOverride: {
          [StaticHeader.NAME]: `${teacher.lastName},${teacher.firstName}`,
        },
      };

      const teacherStats = this.teacherXrefToCurriculumStats.get(teacher.xref);

      if (teacherStats) {
        // Course Rows
        for (const classStats of teacherStats.classStats) {
          const course = this.xrefToCourseMap.get(classStats.classXref);

          if (course) {
            // Add Course to Teacher Row/Subtree
            teacherRow.children?.push(course.courseXref);

            const courseRow: Row = {
              parents: [teacherRow.xref],
              xref: course.courseXref,
              type: RowType.COURSE,
              [StaticHeader.NAME]: course.courseName,
            };

            // Update Subtree computations (in place) with new Course Stats
            switch (this.level) {
              case ActivityLevel.GRADE:
                this.computeGradeLevelStats(
                  classStats,
                  res.averageRow,
                  schoolRows,
                  teacherRow,
                  courseRow
                );
                break;
              case ActivityLevel.MODULE:
                this.computeModuleLevelStats(
                  classStats,
                  res.averageRow,
                  schoolRows,
                  teacherRow,
                  courseRow
                );
                break;
            }

            // Done computing Course Row. Add node to Map.
            res.itemRows.set(course.courseXref, courseRow);
          }
        }
      } else {
        // Initialize Teacher Row
        switch (this.level) {
          case ActivityLevel.GRADE:
            this.computeGradeLevelStats(
              null,
              res.averageRow,
              schoolRows,
              teacherRow,
              null
            );
            break;
          case ActivityLevel.MODULE:
            this.computeModuleLevelStats(
              null,
              res.averageRow,
              schoolRows,
              teacherRow,
              null
            );
            break;
        }
      }

      // Now that we are done updating Parent Rows. Add updated nodes to Map.
      for (const schoolRow of schoolRows) {
        res.itemRows.set(schoolRow.xref, schoolRow);
      }
      res.itemRows.set(teacherRow.xref, teacherRow);
    }

    return res;
  }

  /////////////
  // Methods //
  /////////////

  updateRow(
    row: Row,
    folder: number,
    mode: string,
    psType: string | null,
    newValue: Fraction
  ): { [header: string]: number } {
    const results: { [header: string]: number } = {};

    const folderHeader = psType
      ? `${folder}_${psType}_${mode}`
      : `${folder}_${mode}`;

    // Sum current numerator and denominator of Folder Header with newly-calculated
    // numerator and denominator for target Row
    let accumulated: Fraction | null = (row[folderHeader] as Fraction) ?? null;
    let oldAvg = accumulated
      ? accumulated.numerator / accumulated.denominator
      : 0;

    if (accumulated) {
      accumulated.numerator += newValue.numerator;
      accumulated.denominator += newValue.denominator;
    } else {
      accumulated = { ...newValue };
    }

    // Set header value of target Row to the resulted fraction
    row[folderHeader] = accumulated;
    let newAvg = accumulated.numerator / accumulated.denominator;
    results[folderHeader] = newAvg - oldAvg;

    const averageHeader = psType
      ? `average_${psType}_${mode}`
      : `average_${mode}`;

    // Sum current numerator and denominator of Average Header with newly-calculated
    // numerator and denominator for target Row
    accumulated = (row[averageHeader] as Fraction) ?? null;
    oldAvg = accumulated ? accumulated.numerator / accumulated.denominator : 0;

    if (accumulated) {
      accumulated.numerator += newValue.numerator;
      accumulated.denominator += newValue.denominator;
    } else {
      accumulated = { ...newValue };
    }

    row[averageHeader] = accumulated;
    newAvg = accumulated.numerator / accumulated.denominator;
    results[averageHeader] = newAvg - oldAvg;

    return results;
  }

  updateAvgChange(row: Row, avgChange: { [header: string]: number }) {
    for (const header in avgChange) {
      // Current average
      let accumulated: Fraction | null = (row[header] as Fraction) ?? null;

      // Set header value of target Row to the resulted fraction (added change)
      row[header] = {
        numerator: (accumulated?.numerator ?? 0) + avgChange[header],
        denominator: row.children?.length,
      };
    }
  }

  /**
   * Adds the numerator and denominator of `newValue` to the specified header of the specified class row
   * and aggregates the change in the associated teacherRow and schoolRows
   * NOTE: Updates rows in place
   */
  updateAccumulatedFraction(
    averageRow: Row,
    schoolRows: Row[],
    teacherRow: Row,
    classRow: Row | null,
    folder: number,
    mode: string,
    psType: string | null,
    newValue: Fraction
  ): void {
    if (classRow) {
      this.updateRow(classRow, folder, mode, psType, newValue);
    }

    const avgChange = this.updateRow(
      teacherRow,
      folder,
      mode,
      psType,
      newValue
    );

    if (mode === 'ASSIGNED') {
      for (const schoolRow of schoolRows) {
        this.updateAvgChange(schoolRow, avgChange);
      }

      this.updateAvgChange(averageRow, avgChange);
    } else {
      for (const schoolRow of schoolRows) {
        this.updateRow(schoolRow, folder, mode, psType, newValue);
      }

      this.updateRow(averageRow, folder, mode, psType, newValue);
    }
  }

  // Updates ALL Rows (propagate class stats upwards to higher levels)
  updatePsTypeStats(
    averageRow: Row,
    schoolRows: Row[],
    teacherRow: Row,
    classRow: Row | null,
    folder: number,
    psType: string | null,
    numPsTypeProblems: number,
    classPsTypeStats: StatsPerProblemSetType
  ): void {
    if (numPsTypeProblems > 0) {
      const percentAssigned =
        (classPsTypeStats.totalNumUniqueProblemsAssigned ?? 0) /
        numPsTypeProblems;

      // Mode: ASSIGNED (average of averages)
      // Numerator: % Assigned
      // Denominator: count
      this.updateAccumulatedFraction(
        averageRow,
        schoolRows,
        teacherRow,
        classRow,
        folder,
        'ASSIGNED',
        psType,
        {
          numerator: percentAssigned,
          denominator: 1,
        }
      );
    }

    if (
      classPsTypeStats.totalNumProblemsCompleted != null &&
      classPsTypeStats.totalNumProblemsAssigned > 0
    ) {
      // Mode: COMPLETED
      // Numerator: total number of problems completed
      // Denominator: total number of problems assigned
      this.updateAccumulatedFraction(
        averageRow,
        schoolRows,
        teacherRow,
        classRow,
        folder,
        'COMPLETED',
        psType,
        {
          numerator: classPsTypeStats.totalNumProblemsCompleted,
          denominator: classPsTypeStats.totalNumProblemsAssigned,
        }
      );
    }

    if (
      classPsTypeStats.sumOfStudentAverageScores != null &&
      classPsTypeStats.numStudentsScored > 0
    ) {
      // Mode: SCORE
      // Numerator: sum of average scores
      // Denominator: total number of students who have scored
      this.updateAccumulatedFraction(
        averageRow,
        schoolRows,
        teacherRow,
        classRow,
        folder,
        'SCORE',
        psType,
        {
          numerator: classPsTypeStats.sumOfStudentAverageScores,
          denominator: classPsTypeStats.numStudentsScored,
        }
      );
    }

    // Record Problem Set Type Assignments for Class Row
    if (classRow && classPsTypeStats.assignmentXrefs) {
      const folderHeader = psType ? `${folder}_${psType}` : `${folder}`;

      let accumulated: string[] =
        (classRow[`${folderHeader}_assignmentXrefs`] as string[]) ?? [];

      accumulated.push(...classPsTypeStats.assignmentXrefs);

      classRow[`${folderHeader}_assignmentXrefs`] = Array.from(
        new Set(accumulated)
      );

      const averageHeader = psType ? `average_${psType}` : 'average';

      accumulated =
        (classRow[`${averageHeader}_assignmentXrefs`] as string[]) ?? [];

      accumulated.push(...classPsTypeStats.assignmentXrefs);

      classRow[`${averageHeader}_assignmentXrefs`] = Array.from(
        new Set(accumulated)
      );
    }
  }

  /**
   * Update all metrics for a specified course in a specified column based on the provided `psTypeStats`
   * and aggregate results up to teacher and school levels
   * NOTE: Updates rows in place
   */
  updateAccumulatedPsTypeStats(
    averageRow: Row,
    schoolRows: Row[],
    teacherRow: Row,
    classRow: Row | null,
    folder: number,
    psTypeToProblemCount: Map<string, number>,
    psTypeToClassStats: Map<string, StatsPerProblemSetType>,
    includeDetails: boolean
  ): void {
    let numSelectedPsTypeProblems = 0;
    let accumulatedClassPsTypeStats = {} as StatsPerProblemSetType;

    // Aggregate Problem Set Type Stats selected
    for (const psType of this.selectedPsTypes) {
      const classPsTypeStats =
        psTypeToClassStats.get(psType) ?? ({} as StatsPerProblemSetType);

      numSelectedPsTypeProblems += psTypeToProblemCount.get(psType) ?? 0;

      if (includeDetails) {
        // Detailed version
        this.updatePsTypeStats(
          averageRow,
          schoolRows,
          teacherRow,
          classRow,
          folder,
          psType,
          psTypeToProblemCount.get(psType) ?? 0,
          classPsTypeStats
        );
      }

      accumulatedClassPsTypeStats = mergeProblemSetTypeStats(
        accumulatedClassPsTypeStats,
        classPsTypeStats
      );
    }

    // Overview version
    this.updatePsTypeStats(
      averageRow,
      schoolRows,
      teacherRow,
      classRow,
      folder,
      null,
      numSelectedPsTypeProblems,
      accumulatedClassPsTypeStats
    );
  }

  // NOTE: Update Rows in place
  computeGradeLevelStats(
    classStats: ClassCurriculumStats | null,
    averageRow: Row,
    schoolRows: Row[],
    teacherRow: Row,
    classRow: Row | null
  ): void {
    if (this.curriculumGrade) {
      for (const [moduleFolder, moduleDefinition] of this.curriculumGrade
        .modules) {
        const moduleStats = classStats?.moduleStats.get(moduleFolder);

        const moduleSummaryProblemSetTypeStats =
          moduleStats?.summaryProblemSetTypeStats ?? new Map();

        // Update Table Stats on Module
        this.updateAccumulatedPsTypeStats(
          averageRow,
          schoolRows,
          teacherRow,
          classRow,
          moduleFolder,
          moduleDefinition.numProblemsPerProblemSetType,
          moduleSummaryProblemSetTypeStats,
          false
        );
      }
    }
  }

  // NOTE: Update Rows in place
  computeModuleLevelStats(
    classStats: ClassCurriculumStats | null,
    averageRow: Row,
    schoolRows: Row[],
    teacherRow: Row,
    classRow: Row | null
  ): void {
    if (this.selectedModuleDefinition) {
      // Class Stats on selected Module
      const moduleStats = classStats?.moduleStats.get(
        this.selectedModuleDefinition.folderId
      );
      for (const [lessonFolder, lessonDefinition] of this
        .selectedModuleDefinition.lessons) {
        // Class Stats on Lesson
        const classLessonStats = moduleStats?.lessonStats.get(lessonFolder);

        // Summary Stats regarding each Problem Set Type within Lesson
        const classProblemSetTypeStats =
          classLessonStats?.problemSetTypeStats ?? new Map();

        // Update Table Stats on Lesson
        this.updateAccumulatedPsTypeStats(
          averageRow,
          schoolRows,
          teacherRow,
          classRow,
          lessonFolder,
          new Map(
            [...lessonDefinition.problemSetTypeStats.entries()].map(
              ([key, value]) => [key, value.numProblems ?? 0]
            )
          ),
          classProblemSetTypeStats,
          true
        );
      }
    }
  }

  selectedModule(value: string): void {
    // Header values are strings shaped <folderId>_<mode> e.g. `15_SCORE`
    const folderId = Number(value.split('_')[0]);

    if (this.level === ActivityLevel.GRADE) {
      this.$store.commit('insightsHub/setSelectedModuleId', folderId);
    }
  }

  selectedLessonProblemSet(value: LessonProblemSet): void {
    // Go to the Lesson Page of Problem Set
    this.$router.push({
      name: 'LessonPage',
      params: {
        type: 'lesson',
        id: String(value.lessonId),
        psid: String(value.psId),
      },
      query: {
        returnToName: 'Curriculum View',
        returnToPath: this.$router.currentRoute.fullPath,
      },
    });
  }

  selectedAssignment(value: string | null): void {
    // Go to Assignment Report
    if (value !== null) {
      this.$router.push({
        name: 'ReportLandingPage',
        params: {
          xref: value,
        },
        query: {
          returnToName: 'Curriculum View',
          returnToPath: this.$router.currentRoute.fullPath,
        },
      });
    }
  }

  updateOptions(options: DataOptions): void {
    this.options = options;
  }

  updateCollapsedPaths(collapsedPaths: string[]): void {
    this.$store.commit('insightsHub/setCollapsedPaths', collapsedPaths);
  }

  //////////////
  // Watchers //
  //////////////
  @Watch('level')
  onLevelChange(value: ActivityLevel): void {
    if (value === ActivityLevel.GRADE) {
      this.tab = null;
    }
  }
}
