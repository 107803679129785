
import { Component, Prop, Vue } from 'vue-property-decorator';
import ScoreChip from '@/components/Report/ScoreChip.vue';
import { ProblemType, Answer } from '@/domain/Problem';
import { ProblemLogAndActions } from '@/domain/ReportData/AssignmentData';
import { VNextAction, ActionType } from '@/domain/StudentProblemAction';
// import { HintSet } from '@/domain/StudentSupport';
import { getHint } from '@/api/core/studentSupports.api';
import { VnextLegacyHint } from '@/domain/StudentSupport';

@Component({
  components: {
    ScoreChip,
  },
})
export default class StudentActionsMenu extends Vue {
  @Prop({ required: true }) studentName: string;
  @Prop({ required: true }) problemName: string;
  @Prop({ required: true }) problemType: ProblemType;
  @Prop({ required: true }) problemAnswers: Array<Answer>;
  @Prop({ required: true }) score: number;
  @Prop({ required: true }) studentData: ProblemLogAndActions;
  @Prop({ required: false }) isInTestMode: boolean;

  showMenu = false;
  wirisLoaded = false;
  hintsDownloading = false;
  hintsHaveDownloaded = false;
  hintsReceived: Array<VnextLegacyHint> = [];

  // Allows us access to the enum in the template.
  ActionType = ActionType;
  ProblemType = ProblemType;

  // HTML placeholder blob for hints that failed to download
  downloadFailureMessage =
    '<span class="error--text">Hint failed to download because it no longer exists.</span>';

  // If actions are missing, convert to empty array
  get actions(): Array<VNextAction> {
    return this.studentData.actions || [];
  }
  get maxWidth(): number {
    return this.$vuetify.breakpoint.lgAndUp ? 500 : 400;
  }

  get maxHeight(): number {
    return this.$vuetify.breakpoint.lgAndUp ? 700 : 600;
  }

  get problemCorrectAnswers(): Array<Answer> {
    if (this.problemAnswers) {
      return this.problemAnswers.filter((answer: Answer) => {
        return answer.isCorrect;
      });
    }

    return [];
  }

  get studentAnswersSubmitted(): Array<VNextAction> {
    return this.actions.filter((action: VNextAction) => {
      return (
        action.actionType == ActionType.STUDENT_RESPONSE_ACTION ||
        action.actionType == ActionType.STUDENT_SUBMISSION_ACTION
      );
    });
  }

  get hintRequestActions(): Array<VNextAction> {
    return this.actions.filter((action: VNextAction) => {
      return action.actionType === ActionType.HINT_REQUESTED_ACTION;
    });
  }

  get studentActions(): Array<ActionType> {
    return this.actions.map((action: VNextAction) => {
      return action.actionType;
    });
  }

  get sawAnswer(): boolean {
    return this.studentData.prLog.sawAnswer;
  }

  //If we are open response then we are never in testMode.
  get testMode(): boolean {
    return this.problemType != ProblemType.OPEN_RESPONSE && this.isInTestMode;
  }

  get showZeroAsIncorrect(): boolean {
    return this.problemType === ProblemType.OPEN_RESPONSE;
  }

  // Download hints upon opening menu
  created(): void {
    // Setup watcher & unwatcher for showMenu
    const unwatchShowMenu = this.$watch('showMenu', (newValue) => {
      if (newValue && !this.hintsHaveDownloaded) {
        this.hintsDownloading = true;
        // Initialize wiris for student answers
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).com.wiris.js.JsPluginViewer.parseDocument();
        const hintRequestPromises = this.hintRequestActions.map(
          (hintRequest: VNextAction) => {
            if (hintRequest.hintId) {
              const hintId = hintRequest.hintId;
              return getHint(hintId);
            }
            return null;
          }
        );

        Promise.allSettled(hintRequestPromises)
          .then((promises) => {
            // Initialize wiris for hints received
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).com.wiris.js.JsPluginViewer.parseDocument();
            this.hintsReceived = promises.map((promise) => {
              if (promise.status === 'fulfilled' && promise.value) {
                return promise.value as unknown as VnextLegacyHint;
              } else {
                // Placeholder for error
                return { hint: this.downloadFailureMessage };
              }
            });

            // Downloaded SOME hints
            this.hintsHaveDownloaded = true;
            unwatchShowMenu();
          })
          .finally(() => {
            this.hintsDownloading = false;
          });
      }
    });
  }
}
