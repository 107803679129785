
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { shuffle } from 'lodash';
import { Problem } from '@/domain/Problem';
import {
  ProblemLogAndActions,
  StudentLog,
  VnextStudentData,
  ProblemLog,
} from '@/domain/ReportData/AssignmentData';
import { Skill } from '@/domain/Skill';
import { User } from '@/domain/User';
import { getSkills } from '@/api/skills.api';
import { appendGlobalHeaderOptions } from '@/utils/dataTables.utils';
import ScoreChip from '@/components/Report/ScoreChip.vue';
import StandardsScoringDialog from '@/components/Report/StandardsScoringDialog.vue';
import { scoreProblemForStandardsReport } from '@/utils/problem.util';

interface SkillColumn {
  skillCode: string;
  value: number;
  problemsCount: number;
}

interface StandardsReportTableRow {
  studentName: string;
  studentXref: string;
  averageScore: number;
  skills: Array<SkillColumn>;
}

@Component({
  components: {
    ScoreChip,
    StandardsScoringDialog,
  },
})
export default class StandardsReportTable extends Vue {
  @Prop({ default: null }) reportData: VnextStudentData | null;
  @Prop({ default: () => [] }) problems: Array<Problem>;
  @Prop({ default: () => [] }) assignees: Array<User>;
  @Prop({ default: () => [] }) assignmentXrefs: Array<string>;

  problemCountsMap: Map<string, number> = new Map<string, number>();
  showScoringDialog = false;
  ////////////////////
  // Hiding Columns //
  ////////////////////
  anonymizeNames = false;

  prependStaticHeaders: Array<DataTableHeader> = [
    {
      text: 'Student Name/Problem',
      value: 'studentName',
      align: 'start',
      class: ['text-no-wrap', 'sticky-row', 'sticky-row-1'],
      cellClass: ['text-no-wrap'],
    },
    {
      text: 'Average Score',
      value: 'averageScore',
      align: 'center',
      class: ['text-no-wrap', 'sticky-row', 'sticky-row-1'],
    },
  ];

  /**
   * Maps problems ids to the problem objects
   * Used to check problem types when scoring problems
   */
  get problemIdToProblemMap(): Map<number, Problem> {
    const res = new Map();
    for (const prob of this.problems) {
      res.set(prob.id, prob);
    }
    return res;
  }

  /**
   * Score an individual problem log based on problem type
   * Assign partial credit to open response questions
   * All other questions are 1 if correct 0 otherwise
   * @param prLog: ProblemLog - the log to score
   * @returns score 0-1
   */
  scoreProblem(prLog: ProblemLog): number {
    const problem = this.problemIdToProblemMap.get(prLog.problemDbid);
    return scoreProblemForStandardsReport(prLog, problem);
  }

  // TODO: Re-implement this getter once we figure out what we want to do with the student details report within LDOE
  // getStudentsDetailReportUrl(xref: string): string {
  //   return `${process.env.VUE_APP_TNG_URL}/studentActions/${xref}/${this.assignmentXref}?hideNav=true`;
  // }

  get skillIdToSkillMap(): Map<number, Skill> {
    return this.$store.getters['skillList/getSkillsMap'];
  }

  get skillIdToProblemIdsMap(): Map<number, Array<number>> {
    const res: Map<number, Array<number>> = new Map<number, Array<number>>();

    for (let i = 0; i < this.uniqueSkillIds.length; i++) {
      const skillId = this.uniqueSkillIds[i];
      const problemIds = this.problems
        .filter((problem) => problem.skillIds?.includes(skillId))
        .map((problem) => Number(problem.id));

      if (problemIds.length > 0) {
        res.set(skillId, problemIds);
      }
    }

    return res;
  }

  get studentXrefToStudentLogMap(): Map<string, StudentLog> {
    const res = new Map<string, StudentLog>();

    if (this.reportData) {
      this.reportData.studentLogs.forEach((log) => {
        res.set(log.studentXref, log);
      });
    }

    return res;
  }

  get headers(): Array<DataTableHeader> {
    return [
      ...this.prependStaticHeaders,
      ...this.skillCodeHeaders.map((header) => {
        const transformedHeader = appendGlobalHeaderOptions(header);
        return {
          ...transformedHeader,
          sortable: true,
        };
      }),
    ];
  }

  get uniqueSkillIds(): Array<number> {
    const skillIds: Array<number> = [];

    this.problems.forEach((problem) =>
      problem.skillIds?.forEach((skillId) => skillIds.push(skillId))
    );

    return Array.from(new Set(skillIds)); // remove duplicates
  }

  get skillCodeHeaders(): Array<DataTableHeader> {
    const headers: Array<DataTableHeader> = [];

    this.uniqueSkillIds.forEach((id) => {
      const skill = this.skillIdToSkillMap.get(id);

      if (skill) {
        headers.push({
          text: skill.code,
          value: skill.code,
          align: 'center',
        });
      }
    });

    return headers;
  }

  getProblemCountPerStandard(skillCode: string): number | null {
    return this.problemCountsMap.get(skillCode) || null;
  }

  generateSkillColumns(studentLog: StudentLog): Array<SkillColumn> {
    const res: Array<SkillColumn> = [];

    this.uniqueSkillIds.forEach((id) => {
      const skill = this.skillIdToSkillMap.get(id) as Skill;
      const problemIds = this.skillIdToProblemIdsMap.get(id) as Array<number>;
      const logs =
        studentLog.problemLogAndActions as Array<ProblemLogAndActions>;
      const filteredLogs = logs.filter(
        (log) =>
          problemIds.includes(log.prLog.problemDbid) &&
          log.prLog.endTime &&
          (typeof log.prLog.discreteScore === 'number' ||
            typeof log.prLog.continuousScore === 'number')
      );
      let value: number | null = null;

      if (filteredLogs.length === 0) {
        value = 0;
      } else {
        const sum = filteredLogs.reduce(
          (acc, curr) => acc + this.scoreProblem(curr.prLog),
          0
        );

        value = Math.round((sum / filteredLogs.length) * 100);
      }

      this.problemCountsMap.set(skill.code, problemIds.length);

      res.push({
        skillCode: skill.code || '',
        value: value,
        problemsCount: problemIds.length,
      });
    });

    return res;
  }

  get rows(): Array<StandardsReportTableRow> {
    const rows: Array<StandardsReportTableRow> = [];

    for (let i = 0; i < this.assignees.length; i++) {
      // Get the average score **per standard**
      const assignee = this.assignees[i];
      const studentLog = this.studentXrefToStudentLogMap.get(
        assignee.xref
      ) as StudentLog;

      if (!studentLog) {
        continue;
      }

      const skills: Array<SkillColumn> = this.generateSkillColumns(studentLog);

      const average =
        skills.reduce((acc, curr) => (acc += curr.value), 0) / skills.length;

      rows.push({
        studentName: assignee.displayName,
        studentXref: assignee.xref,
        averageScore: Math.round(average),
        skills,
      });
    }

    rows.forEach((row) => {
      const { skills } = row;
      skills.forEach((skill) => {
        row = Object.assign(row, { [skill.skillCode]: skill.value });
      });
    });

    // Shuffle rows if anonymizing names
    if (this.anonymizeNames) {
      return shuffle(rows);
    }

    return rows;
  }

  async created(): Promise<void> {
    this.anonymizeNames =
      this.$store.state.auth.user.settings.anonymizeReportsByDefault || false;
    if (this.skillIdToSkillMap.size === 0) {
      const skills = await getSkills();
      this.$store.commit('skillList/setSkillList', skills);
    }
  }
}
