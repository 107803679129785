
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ProblemView from '@/components/base/ProblemView.vue';
import StudentProblemView from '@/components/Cignition/base/StudentProblemView.vue';
import DonutChartView from '@/components/Report/DonutChartView.vue';
import { Answer, Problem, ProblemType } from '@/domain/Problem';
import { Skill } from '@/domain/Skill';
import { ProblemStats } from './AssignmentBarChartViewForReport.vue';
import { getTutoringForProblem } from '@/api/core/legacyContent.api';
import { EventType, mixpanel } from '@/plugins/mixpanel';

import {
  SimplifiedInstructionalRecommendation,
  TutoringContainer,
  TutorStrategyType,
} from '@/domain/Tutoring';

@Component({
  components: {
    ProblemView,
    StudentProblemView,
    DonutChartView,
  },
})
export default class ProblemSideSheetForReport extends Vue {
  @Prop({ required: true }) problemSetXref: string;
  @Prop({ required: true }) problem: Problem;
  @Prop({ default: null }) stats: ProblemStats | null;

  @Prop({ type: Boolean }) disableProblemNavigation: boolean;
  @Prop({ default: false }) disablePrevious: boolean;
  @Prop({ default: false }) disableNext: boolean;

  @Prop({ default: true }) value: boolean;

  //Allows us access to the enum in the template.
  ProblemType = ProblemType;
  tab = null;
  IRs: Array<SimplifiedInstructionalRecommendation> = [];

  ////////////////////
  // Loading States //
  ////////////////////
  isDownloadingTutoringContainer = false;
  hasDownloadedTutoringContainer = false;

  //////////
  // Data //
  //////////
  isExpanded = false;
  problemDetails = false;

  get showDrawer(): boolean {
    return this.value;
  }

  set showDrawer(val: boolean) {
    this.$emit('input', val);
  }

  get title(): string {
    let title = 'Problem';

    // Problem number
    if (this.problem.assistmentPosition) {
      title += ' ' + this.problem.assistmentPosition;
    }

    if (this.problem.partLetter) {
      // A subproblem so include part letter
      return (title += ': ' + this.problem.partLetter);
    }

    return title;
  }

  get matchedSkills(): Array<Skill> {
    return this.skills.filter((skill) => {
      if (this.problem.skillIds) {
        return this.problem.skillIds.includes(Number(skill.id));
      }

      return false;
    });
  }

  get correctAnswers(): Array<Answer> {
    if (this.problem.answers) {
      return this.problem.answers.filter((answer: Answer) => {
        return answer.isCorrect;
      });
    }

    return [];
  }

  get drawerOption(): string {
    return this.isExpanded ? '>> Collapse' : '<< Expand';
  }

  get hasNoStats(): boolean {
    if (this.stats) {
      // No data to display
      return (
        this.stats.numCorrect === 0 &&
        this.stats.numCorrectEventually === 0 &&
        this.stats.numIncorrect === 0
      );
    }

    // No stats given
    return true;
  }

  get hasIRs(): boolean {
    return this.IRs.length > 0;
  }

  get IRsDownloaded(): boolean {
    return (
      !this.isDownloadingTutoringContainer &&
      this.hasDownloadedTutoringContainer
    );
  }

  ///////////
  // Store //
  ///////////

  get skills(): Array<Skill> {
    return this.$store.state.skillList.skills;
  }

  /////////////
  // Methods //
  /////////////

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

  openProblemDetails(): void {
    // Open problem details dialog
    this.problemDetails = true;
  }

  getIRsForProblem(): void {
    this.isDownloadingTutoringContainer = true;
    // Reset on problem/IR change (to hide TAB for next problem)
    this.IRs = [];

    getTutoringForProblem(
      this.problem.id,
      TutorStrategyType.INSTRUCTIONAL_RECOMMENDATIONS
    )
      .then((tutoringContainer: TutoringContainer) => {
        // Have new IRs
        this.IRs = tutoringContainer.instructionalRecommendations;

        this.hasDownloadedTutoringContainer = true;
      })
      .catch(() => {
        // TODO: Handle error
      })
      .finally(() => {
        this.isDownloadingTutoringContainer = false;
      });
  }

  created(): void {
    // Download skills if not already
    // Will be prevented to download again in store if already
    this.$store.dispatch('skillList/requestSkills');
    this.getIRsForProblem();
  }

  irClicked(): void {
    mixpanel.track(EventType.irClick, {
      distinct_id: this.$router.app.getCurrentUser.xref,
      assignmentXref: this.$router.currentRoute.params.xref,
      problemCode: this.problem.xref,
    });
  }

  /////////////
  // Watcher //
  /////////////
  @Watch('problem')
  handleProblemPropChange(): void {
    this.getIRsForProblem();
  }
}
