
import { Component, Vue } from 'vue-property-decorator';
import timezones from '@/utils/timezones';
import ActionButtons from '@/components/Settings/ActionButtons.vue';
import { UpdateUserObject, UserAttributes } from '@/domain/User';
import { inputValidationRules } from '@/utils/inputValidation.util';
import { User } from '@/domain/User';
import { getAttributeDefinition } from '@/api/core/attributes.api';
import {
  AttributeDefinition,
  AttributeDefinitionValue,
} from '@/domain/Attributes';
import { AttributeNames } from '@/domain/Attributes';
import SchoolSelector from '@/components/Settings/SchoolSelector.vue';
import { cloneDeep, isEqual } from 'lodash';

@Component({
  components: {
    ActionButtons,
    SchoolSelector,
  },
})
export default class Preferences extends Vue {
  rules = inputValidationRules;

  // Defaults
  data: UpdateUserObject = cloneDeep(this.default);

  // Loading state
  loaded = false;

  // Attribute names
  AttributeNames = AttributeNames;

  // Timezones
  timezones = timezones;

  // Value options
  curricula: AttributeDefinitionValue[] = [];
  gradeLevels: AttributeDefinitionValue[] = [];

  ///////////
  // Store //
  ///////////

  get me(): User {
    return this.$store.getters['auth/getCurrentUser'];
  }

  get myAnonymizeReportsByDefaultSetting(): boolean {
    return this.me.settings
      ? this.me.settings.anonymizeReportsByDefault || false
      : false;
  }

  get myAttributes(): UserAttributes {
    return this.me.attributes || {};
  }

  get default(): UpdateUserObject {
    return {
      user: {
        timeZone: this.me.timeZone,
      },
      settings: {
        anonymizeReportsByDefault: this.myAnonymizeReportsByDefaultSetting,
      },
      attributes: this.myAttributes,
    };
  }

  get dataChanged(): boolean {
    // Deep object comparison
    return !isEqual(this.data, this.default);
  }

  // FIXME:
  set dataChanged(value: boolean) {
    // Do nothing.
  }

  //////////////
  //  Methods //
  //////////////

  discardChanges(): void {
    // Make a new (deep) copy rather than editing the default
    // and avoid mutating store state outside mutation handlers
    this.data = cloneDeep(this.default);
  }

  created(): void {
    const curriculumPromise = getAttributeDefinition(
      AttributeNames.CURRICULUM
    ).then((curriculum: AttributeDefinition) => {
      this.curricula = curriculum.values;
    });

    const gradePromise = getAttributeDefinition(
      AttributeNames.GRADE_LEVEL
    ).then((gradeLevel: AttributeDefinition) => {
      this.gradeLevels = gradeLevel.values;
    });

    Promise.all([curriculumPromise, gradePromise]).then(() => {
      this.loaded = true;
    });
  }
}
