
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router/types/router';
import NotFoundView from '@/components/base/NotFoundView.vue';
import AssignmentTable from '@/components/LDOE/TutoringProblemSets/AssignmentTable.vue';
import LessonDetailsNotFoundView from '@/components/LDOE/TutoringProblemSets/LessonDetailsNotFoundView.vue';
import AccelerateLessonInformationView from '@/components/LDOE/TutoringProblemSets/AccelerateInformationView.vue';
import {
  StudentDataReturnType,
  VnextStudentData,
} from '@/domain/ReportData/AssignmentData';
import { addPartLetters } from '@/utils/problem.util';
import StandardsReportTable from '@/components/LDOE/TutoringProblemSets/StandardsReportTable.vue';
import { User } from '@/domain/User';
import { getStudentDataForProblemSet } from '@/api/core/content.api';
import { getAssignmentLessonDetails } from '@/api/ldoe/assignment.api';
import { getCanonicalAssignmentStudentAndClassData } from '@/api/ldoe/assignment.api';
import { TargetAssignmentStudentAndClassDataWithProblems } from '@/domain/ReportData/Cignition';

@Component({
  components: {
    NotFoundView,
    AssignmentTable,
    LessonDetailsNotFoundView,
    AccelerateLessonInformationView,
    StandardsReportTable,
  },
})
export default class TutorProblemSetsPage extends Vue {
  assignmentXrefs: Array<string> | null = null;
  problemSetXref: string | null = null;
  lessonData: Array<string> = [];
  canonicalAssignmentReportData: TargetAssignmentStudentAndClassDataWithProblems | null =
    null;
  reportData: VnextStudentData | null = null;
  canonicalAssignmentReportDataNotFound = false;
  tab = null;

  get assignmentName(): string {
    if (this.canonicalAssignmentReportData) {
      return this.canonicalAssignmentReportData.reportData.name;
    }

    return '';
  }

  get reportPage(): RawLocation {
    // Remove assignment xrefs from the query
    delete this.$route.query.assignments;

    return {
      name: 'tutorReport',
      query: this.$route.query,
    };
  }

  get selectedTuteeXrefs(): Array<string> {
    return this.$store.state.ldoe.selectedTuteeXrefs;
  }

  get assignees(): Array<User> {
    return this.$store.state.ldoe.tutees.filter((tutee: User) =>
      this.selectedTuteeXrefs.includes(tutee.xref)
    );
  }

  ////////////////////
  // Loading States //
  ////////////////////
  isDownloadingAssignmentData = false;
  hasDownloadedAssignmentData = false;
  isDownloadingReportData = false;
  hasDownloadedReportData = false;
  isDownloadingLessonDetails = false;

  @Watch('isAuthenticated')
  handleAuthChange(): void {
    if (!this.isAuthenticated) {
      // FIXME:
      // this.goToLoginPortal();
    }
  }

  created(): void {
    // Get assignment xref from path
    const assignmentXrefs = this.$route.query.assignments;

    if (Array.isArray(assignmentXrefs)) {
      this.assignmentXrefs = assignmentXrefs as Array<string>;
    } else {
      this.assignmentXrefs = [assignmentXrefs];
    }

    this.initializeSelectedTutees();

    // Download data
    this.getAssignmentLessonDetails();
    this.getAssignmentStudentAndClassData();
  }

  getAssignmentLessonDetails(): void {
    if (this.assignmentXrefs) {
      this.isDownloadingLessonDetails = true;

      for (const assignmentXref of this.assignmentXrefs) {
        getAssignmentLessonDetails(assignmentXref)
          .then((blob) => {
            this.lessonData.push(blob);
          })
          .catch(() => {
            // Don't care.
          });
      }
      this.isDownloadingLessonDetails = false;
    }
  }

  getAssignmentStudentAndClassData(): void {
    if (this.assignmentXrefs && this.selectedTuteeXrefs.length > 0) {
      this.isDownloadingAssignmentData = true;
      this.hasDownloadedAssignmentData = false;

      getCanonicalAssignmentStudentAndClassData(this.assignmentXrefs)
        .then(async (data) => {
          addPartLetters(data.problems);
          this.canonicalAssignmentReportData = data;
          this.problemSetXref =
            this.canonicalAssignmentReportData.reportData.xref;
          this.hasDownloadedAssignmentData = true;

          await this.getReportDataForStandardsReport();
        })
        .catch(() => {
          // Any errors
          this.canonicalAssignmentReportDataNotFound = true;
        })
        .finally(() => {
          this.isDownloadingAssignmentData = false;
        });
    }
  }

  getReportDataForStandardsReport(): void {
    if (this.assignmentXrefs) {
      this.isDownloadingReportData = true;

      getStudentDataForProblemSet(this.problemSetXref as string, {
        dTypes: [StudentDataReturnType.PROBLEM_LOGS],
        usersSubset: this.selectedTuteeXrefs,
        assignmentsScope: this.assignmentXrefs,
      })
        .then((data) => {
          this.hasDownloadedReportData = true;
          this.reportData = data;
        })
        .finally(() => {
          this.isDownloadingReportData = false;
        });
    }
  }

  // Download helpers
  initializeSelectedTutees(): void {
    if (this.selectedTuteeXrefs.length === 0) {
      // Go to dashboard to select tutees
      this.$router.push(this.reportPage);
    }
  }

  // created(): void {
  // }
}
