
import { Component, Vue, Prop } from 'vue-property-decorator';
import { FolderInfo } from '@/domain/FolderInfo';
import FolderItem from './FolderItem.vue';

@Component({
  components: {
    FolderItem,
  },
})
export default class FolderBrowser extends Vue {
  @Prop() folderData!: FolderInfo[];
}
