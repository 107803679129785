
import { Component, Vue, Prop } from 'vue-property-decorator';
import { loginMock } from '@/api/auth.api';
import { CommonRequestAttributes } from '@/domain/CommonRequestAttributes';

@Component
export default class LoginButton extends Vue {
  @Prop({
    default: 'yellow lighten-1',
  })
  color: string;
  get serverIsMocked(): boolean {
    return process.env.VUE_APP_MIRAGE === 'true';
  }
  get redirectUrl(): string {
    return this.$route.query[
      `${CommonRequestAttributes.REDIRECT_URL}`
    ] as string;
  }
  get targetResourceUrl(): string {
    return this.redirectUrl || `${window.location.href}`;
  }
  /**
   * Real Authentication
   *
   * Navigates to login portal
   * This should be a getter used as an href for the button,
   * but Vue doesn't reactively track updates on the $route or window
   * This way we grab the needed info at the time the button is clicked
   */
  goToLoginPortal(): void {
    const loginPortalUrl = process.env.VUE_APP_LOGIN_PORTAL_URL || '';
    const encodedCurrentUrl = encodeURIComponent(this.targetResourceUrl);
    const redirectTo =
      process.env.VUE_APP_DEPLOYED === 'true'
        ? this.targetResourceUrl
        : `http://localhost:8081/TNG/api/redirect?redirectUrl=${encodedCurrentUrl}`;
    const encodedRedirectUrl = encodeURIComponent(redirectTo);
    const fullUrl = `${loginPortalUrl}/?callbackText=ASSISTments&callingPartnerRef=TNG&callbackURL=${encodedRedirectUrl}`;
    window.location.href = fullUrl;
  }
  /**
   * Mocked Authentication (mirage)
   */
  mockAuthenticate(): void {
    loginMock(this.targetResourceUrl);
  }
}
