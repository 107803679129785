import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CignitionMixins extends Vue {
  get primaryTuteeColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.primary.darken2;
  }

  get secondaryTuteeColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.primary.base;
  }

  get classAverageColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.darken3;
  }
}

// Vue.mixin(CignitionMixins);
