
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getPsIdFromPsa } from '@/api/pspr.api';

@Component
export default class SearchPsByPsaDialog extends Vue {
  showDialog = false;
  psa = '';
  noResults = false;
  loading = false;

  /**
   * When psa changes, clear noResults display
   */
  @Watch('psa')
  onPsaChange(): void {
    this.noResults = false;
  }

  @Watch('showDialog')
  onShowDialogChange(): void {
    // Cleanup on close
    if (!this.showDialog) {
      this.resetDefaults();
    }
  }

  get hasContent(): boolean {
    return this.psa != '';
  }

  cancel(): void {
    this.showDialog = false;

    this.resetDefaults();
  }

  resetDefaults(): void {
    this.psa = '';
    this.noResults = false;
    this.loading = false;
  }

  submitSearch(): void {
    this.loading = true;

    getPsIdFromPsa(this.psa)
      .then((res) => {
        this.loading = false;

        if (res != '0') {
          this.$router.push({
            name: 'LessonPage',
            params: {
              type: 'ps',
              id: res,
              psId: res,
            },
          });
        }
      })
      .catch(() => {
        this.loading = false;
        this.noResults = true;
      });
  }
}
