
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ProblemSet } from '@/domain/ProblemSet';

@Component
export default class ProblemSetMenu extends Vue {
  @Prop({ default: true }) showIcon!: boolean;
  @Prop() ps!: ProblemSet;
  @Prop({ type: Boolean }) includeGoToPs!: boolean;

  deleteDialog = false;
  showMenu = false;

  goToProblemSet(): void {
    this.$router.push({
      name: 'editMyPS',
      params: {
        id: `${this.ps.id}`,
      },
    });
  }

  duplicateProblemSet(): void {
    this.$emit('duplicate-requested');
    this.$store
      .dispatch('myps/requestDuplicateProblemSet', this.ps.id)
      .then((ps) => {
        this.$emit('duplicate-succeeded', ps.xref);
      });
  }

  get previewLink(): string {
    const usr = this.getCurrentUser;
    return `${this.$store.state.urls.tutorTemplateUrl}?assignmentRef=PS${this.ps.id}&preview=true&pr=TNG&ut=${usr.userToken}&laId=${usr.loginAliasId}`;
  }

  deleteProblemSet(): void {
    this.$emit('delete-requested', this.ps.xref);
    this.$store
      .dispatch('myps/requestDeleteProblemSet', this.ps.id)
      .then(() => {
        this.$emit('delete-succeeded', this.ps.xref);
      });
  }

  /**
   * Edit PS Details dialog
   */
  showEditPsDialog = false;
  newPsName = '';
  awaitingResponse = false;

  get nameIsUnchanged(): boolean {
    return this.newPsName === this.ps.name;
  }
  // Close overflow menu when opening/closing edit ps dialog
  @Watch('showEditPsDialog')
  openEditPsDialog(): void {
    this.showMenu = false;
    this.newPsName = this.ps.name;
  }
  updateName(): void {
    // Only update if new name is different
    this.showMenu = false;
    if (!this.nameIsUnchanged) {
      this.awaitingResponse = true;
      this.$store
        .dispatch('myps/requestRenameProblemSet', {
          id: this.ps.id,
          name: this.newPsName,
        })
        .then(() => {
          this.awaitingResponse = false;
          this.showEditPsDialog = false;
        });
    }
  }
  cancelEdit(): void {
    this.showEditPsDialog = false;
    this.newPsName = this.ps.name;
  }

  created(): void {
    this.newPsName = this.ps.name;
  }
}
