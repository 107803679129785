
import { Component, Vue } from 'vue-property-decorator';
import DashboardHeader from '@/components/InsightsHub/base/DashboardHeader.vue';
import { HybridFolderDefinition } from '@/domain/Folder';
import { TimeSelector, TimeSelectorType } from '@/domain/Time';
import { isEqual } from 'lodash';
import { getCurrentSchoolYear } from '@/utils/InsightsHub/schoolyear.util';

@Component({
  components: {
    DashboardHeader,
  },
})
export default class DashboardParent extends Vue {
  get gradeFolders(): HybridFolderDefinition[] {
    return this.$store.state.insightsHub.gradeFolders;
  }
  get selectedGradeId(): number | null {
    return this.$store.state.insightsHub.selectedGradeId;
  }
  get timeSelector(): TimeSelector | null {
    return this.$store.state.insightsHub.timeSelector;
  }

  created(): void {
    this.$store.commit(
      'insightsHub/setAnonymized',
      this.$store.state.auth.user.settings.anonymizeReportsByDefault || false
    );

    // Request Grade Folders if not already
    if (this.gradeFolders.length === 0) {
      this.$store.dispatch('insightsHub/getCurriculumGradeFolders');
    }

    // Set the Curriculum and Grade Folder ID from path
    const { gradeFolderId } = this.$route.params;
    const gradeFolder = Number(gradeFolderId);

    if (this.selectedGradeId !== gradeFolder) {
      // Set Grade Folder
      this.$store.commit('insightsHub/setSelectedGradeId', gradeFolder);

      // Request Dashboard Data
      this.$store.dispatch('insightsHub/getDashboardData');
    }

    const { timeSelector } = this.$route.query;
    let urlTimeSelector = null;

    if (timeSelector) {
      urlTimeSelector = TimeSelector.fromArray(
        timeSelector as Array<TimeSelectorType | number>
      );
    }

    if (urlTimeSelector === null) {
      // Default to one
      const currentSchoolYear = getCurrentSchoolYear();

      urlTimeSelector = new TimeSelector(
        TimeSelectorType.INCLUSIVE,
        currentSchoolYear.startDateTime.valueOf(),
        currentSchoolYear.endDateTime.valueOf()
      );

      // Update the URL
      this.$router.replace({
        name: this.$route.name ?? 'activityPage',
        query: {
          ...this.$route.query,
          timeSelector: urlTimeSelector.toArray() as string[],
        },
      });
    }

    if (!isEqual(this.timeSelector, urlTimeSelector)) {
      this.$store.commit('insightsHub/setTimeSelector', urlTimeSelector);

      // Request Dashboard Data
      this.$store.dispatch('insightsHub/getDashboardData');
    }
  }
}
