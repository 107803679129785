
import { User, UpdateUserObject } from '@/domain/User';
import { Component, Vue } from 'vue-property-decorator';
import ActionButtons from '@/components/Settings/ActionButtons.vue';
import GenerateInviteCodeDialog from '@/components/Settings/GenerateInviteCodeDialog.vue';
import HowToGetATeacherRoleDialog from '@/components/Settings/HowToGetATeacherRole/HowToGetATeacherRoleDialog.vue';
import { inputValidationRules } from '@/utils/inputValidation.util';
import { LmsProviderType } from '@/domain/LmsProviderType';

@Component({
  components: {
    ActionButtons,
    GenerateInviteCodeDialog,
    HowToGetATeacherRoleDialog,
  },
})
export default class AccountSettings extends Vue {
  dataChanged = false;
  showHowToGetATeacherRoleDialog = false;
  showGenerateInviteCodeDialog = false;
  rules = inputValidationRules;

  triggerDataChanged(): void {
    this.dataChanged = this.areInputsValid;
  }

  get areInputsValid(): boolean {
    const inputs = [
      this.data.user?.firstName,
      this.data.user?.lastName,
      this.data.user?.displayName,
    ];

    return inputs.every((input) => input?.trim() !== '');
  }

  get user(): User {
    return this.$store.getters['auth/getCurrentUser'];
  }

  get hasTeacherRole(): boolean {
    return this.user.roles?.includes('TEACHER');
  }

  get isCanvasUser(): boolean {
    return this.getCurrentUser.lmsProviderType === LmsProviderType.CANVAS;
  }

  // User data
  data: UpdateUserObject = {
    user: {
      userName: this.user.userName,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      displayName: this.user.displayName,
    },
  };

  discardChanges(): void {
    this.dataChanged = false;

    this.data = {
      user: {
        userName: this.user.userName,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        displayName: this.user.displayName,
      },
    };
  }
}
