
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Problem, ProblemType } from '@/domain/Problem';
import SaveToMyProblemSetsDialog from '@/components/MyProblemSets/SaveToMyProblemSetsDialog.vue';
import ProblemView from './ProblemView.vue';
import ProblemViewMenu, {
  ParentPage,
} from '@/components/base/ProblemViewMenu.vue';

@Component({
  components: {
    SaveToMyProblemSetsDialog,
    ProblemView,
    ProblemViewMenu,
  },
})
export default class ProblemViewForFind extends Vue {
  @Prop({ required: true }) problems!: Problem[];
  @Prop() value: boolean;
  @Prop({ default: false }) isSkillBuilder!: boolean;
  @Prop() parentPage: ParentPage;
  @Prop() isFirst: boolean; // is first assistment in list
  @Prop() isLast: boolean; // is last assistment in list
  // Allows us access to the helper funciton in utils.

  //Allows us access to the enum in the template.
  ProblemType = ProblemType;
  ParentPage = ParentPage;

  //handle the selected problems (get and set works as computed)
  get selected(): boolean {
    return this.value;
  }
  set selected(val: boolean) {
    this.$emit('checkbox-change', val);
  }

  get mainProblem(): Problem {
    return this.problems[0];
  }

  get xref(): string {
    return this.mainProblem.xref;
  }

  get assistmentId(): number {
    return this.mainProblem.assistmentId;
  }

  get multiPart(): boolean {
    return this.problems.length > 1;
  }
}
