import { CommonRequestAttributes } from '@/domain/CommonRequestAttributes';
import { NavigationGuardNext, Route } from 'vue-router';
import { isAuthenticated } from './cookie.util';

///////////////////
// Router Guards //
///////////////////

/**
 * Check authentication status in store
 * Must be called after checkAuthenticatedServer()
 * @param {Route} to newly requested route
 * @param {Route} from previous route
 * @param {Function} next allows navigation or redirects
 */
export function redirectIfUnauthenticated(
  to: Route,
  from: Route,
  next: NavigationGuardNext
): void {
  // Rebuild target destination
  // window.location.href doesn't work here because if the user
  // is coming from other parts of the application (not by URL)
  // the window.location.href will not be updated to target destination
  // before router guard kicks in
  const targetDestination = `${window.location.origin}${to.fullPath}`;
  const unauthenticated = `/401?${CommonRequestAttributes.REDIRECT_URL}=${targetDestination}`;

  if (isAuthenticated()) {
    next();
  } else {
    next(unauthenticated);
  }
}
