import { Server } from 'miragejs';
import dayjs from 'dayjs';
import { Problem, ProblemType } from '@/domain/Problem';
import { PsType, ProblemSet } from '@/domain/ProblemSet';

const today = dayjs().unix();
const yesterday = dayjs().subtract(1, 'day').unix();

export interface mirageProblemSet {
  id: string;
  name: string;
  xref: string;
  type: PsType;
  isSkillBuilder: boolean;
  children: number[];
  ownerId: string;
  created: number;
  lastModifiedByTeacher: number;
}
export interface mirageProblem {
  id: string;
  assistmentId: string;
  xref: string;
  body: string;
  type: ProblemType;
  parentProblemSetIds: number[];
  curriculumXrefs?: string[];
  attributionXrefs?: string[];
  skillIds?: number[];
  position: number;
}
export function transformProblemSet(ps: mirageProblemSet): ProblemSet {
  // Remove children (application expects array of problems or problem sets if present)
  const { children: _children, ...rest } = ps;
  return {
    ...rest,
    id: Number(ps.id),
  };
}
export function transformProblem(pr: mirageProblem): Problem {
  const { id, assistmentId, ...rest } = pr;
  return {
    ...rest,
    id: Number(id),
    assistmentId: Number(assistmentId),
    partLetter: null,
  };
}

export default function (server: Server): void {
  server.create('problemSet', {
    id: '1248011',
    name: 'TEST PS',
    xref: 'PSATEST',
    type: PsType.SKILL_BUILDER_RANDOM.id,
    isSkillBuilder: true,
    // children: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    children: [1, 2, 3],
    ownerId: '1337',
    created: today,
    lastModifiedByTeacher: today,
    curriculumXrefs: ['a9beff09-6e2a-8eef-d36a-4ebac161ca69'],
  });
  server.create('problemSet', {
    id: '46',
    name: '6.1 Lesson 1 Student Facing Task - Tiling the Plane (6.G.A.1)',
    xref: 'PSABHT54',
    type: PsType.LINEAR_COMPLETE_ALL.id,
    children: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    created: 1605448800,
    lastModifiedByTeacher: yesterday,
  });
  server.create('problemSet', {
    id: '1151599',
    name: '6.1 Lesson 1 Practice - Tiling the Plane (6.G.A.1)',
    xref: 'PSABHT5A',
    type: PsType.LINEAR_COMPLETE_ALL.id,
    children: [1],
    ownerId: '1337',
    created: 1600168260,
    lastModifiedByTeacher: today,
  });
  server.create('problemSet', {
    id: '1149011',
    name: '6.1 Lesson 1 Cool down - Tiling the Plane (6.G.A.1)',
    xref: 'PSA6N9K', // Real xref for testing purposes
    type: PsType.LINEAR_COMPLETE_ALL.id,
    children: [1, 13],
    ownerId: '1337',
    created: yesterday,
    lastModifiedByTeacher: dayjs.unix(yesterday).subtract(2, 'hour').unix(),
  });
  server.create('problemSet', {
    id: '100',
    name: 'My Problem set 1',
    xref: 'PSAMINE',
    type: PsType.LINEAR_COMPLETE_ALL.id,
    children: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  });
  // I am super lazy to do this by hand, so I'll just generate 50 problem sets with a loop
  for (let i = 0; i < 50; i++) {
    server.create('problemSet', {
      id: `1337${i}`,
      ownerId: '1337',
      name: `Test Problem Set - ${i}`,
      xref: `PSATEST${i}`,
      type: PsType.LINEAR_COMPLETE_ALL.id,
      children: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      lastModifiedByTeacher: dayjs
        .unix(yesterday)
        .subtract(i + 1, 'hour')
        .unix(),
    });
  }
  server.create('problem', {
    id: '1',
    assistmentId: '1325314',
    xref: 'PRABGYJ8',
    body: "<p>which pattern doesn't belong?</p>",
    type: ProblemType.FILL_IN,
    parentProblemSetIds: [1248011],
    curriculumXrefs: [
      'a9beff09-6e2a-8eef-d36a-4ebac161ca69',
      'cb031912-8c16-1d17-53be-d5473fcc3e4f',
    ],
    attributionXrefs: ['1'],
    skillIds: [1, 212],
    position: 1,
  });
  server.create('problem', {
    id: '2',
    assistmentId: '1054808',
    xref: 'PRABPFDP',
    body:
      '<p> Your teacher will assign you to look at pattern A or B</p>\n' +
      '<p> In your pattern which shapes cover more of the plane: Blue rhombuses, red trapezoids, or green triangles?</p>\n' +
      '<p> Please write down your explanation on a separate piece of paper why the shape you selected covers more of the plane.</p>',
    answers: [
      { value: 'blue rhombuses', isCorrect: false },
      { value: 'red trapezoids', isCorrect: true },
      { value: 'green triangles', isCorrect: false },
    ],
    type: ProblemType.CHOOSE_ONE,
    parentProblemSetIds: [1248011, 46],
    curriculumXrefs: [
      'a9beff09-6e2a-8eef-d36a-4ebac161ca69',
      'cb031912-8c16-1d17-53be-d5473fcc3e4f',
    ],
    attributionXrefs: ['1'],
    skillIds: [1, 2, 212],
    position: 1,
  });
  server.create('problem', {
    id: '3',
    assistmentId: '1054808',
    xref: 'PRASRIVQ',
    body: '<p>Explain how you know.</p>',
    type: ProblemType.OPEN_RESPONSE,
    parentProblemSetIds: [1248011, 46],
    curriculumXrefs: [
      'a9beff09-6e2a-8eef-d36a-4ebac161ca69',
      'cb031912-8c16-1d17-53be-d5473fcc3e4f',
    ],
    attributionXrefs: ['1'],
    skillIds: [1, 2],
    position: 2,
  });
  server.create('problem', {
    id: '4',
    assistmentId: '1054808',
    xref: 'PRABPFDP',
    body: '<p>Choose all that apply problem</p>',
    answers: [
      { value: 'Pick Me', isCorrect: true },
      { value: 'Not me', isCorrect: false },
      { value: 'pick me too', isCorrect: true },
    ],
    type: ProblemType.CHOOSE_N,
    parentProblemSetIds: [1248011, 46],
    curriculumXrefs: ['cb031912-8c16-1d17-53be-d5473fcc3e4f'],
    attributionXrefs: ['1'],
    skillIds: [1, 2],
    position: 3,
  });
  server.create('problem', {
    id: '5',
    assistmentId: '1054808',
    xref: 'PRABPFDP',
    body: '<p>Rank these</p>',
    answers: [
      { value: 'tiny', isCorrect: false },
      { value: 'little', isCorrect: false },
      { value: 'medium', isCorrect: false },
      { value: 'tall', isCorrect: false },
      { value: 'giant', isCorrect: false },
    ],
    type: ProblemType.RANK,
    parentProblemSetIds: [1248011, 46],
    curriculumXrefs: ['cb031912-8c16-1d17-53be-d5473fcc3e4f'],
    attributionXrefs: ['1'],
    skillIds: [1, 2],
    position: 4,
  });
  server.create('problem', {
    id: '6',
    assistmentId: '1054808',
    xref: 'PRABPFDP',
    body: '<p>old algebra</p>',
    type: ProblemType.OLD_ALGEBRA,
    parentProblemSetIds: [1248011, 46],
    curriculumXrefs: ['cb031912-8c16-1d17-53be-d5473fcc3e4f'],
    attributionXrefs: ['1'],
    skillIds: [1, 2],
    position: 5,
  });
  server.create('problem', {
    id: '7',
    assistmentId: '1054808',
    xref: 'PRABPFDP',
    body: '<p>fillin ignore case</p>',
    type: ProblemType.FILL_IN_IGNORE_CASE,
    parentProblemSetIds: [45, 46],
    curriculumXrefs: ['cb031912-8c16-1d17-53be-d5473fcc3e4f'],
    attributionXrefs: ['1'],
    skillIds: [1, 2],
    position: 6,
  });
  server.create('problem', {
    id: '8',
    assistmentId: '1054808',
    xref: 'PRABPFDP',
    body: '<p>iframe </p>',
    type: ProblemType.IFRAME,
    parentProblemSetIds: [45, 46],
    curriculumXrefs: ['cb031912-8c16-1d17-53be-d5473fcc3e4f'],
    attributionXrefs: ['1'],
    skillIds: [1, 2],
    position: 7,
  });
  server.create('problem', {
    id: '9',
    assistmentId: '1054808',
    xref: 'PRABPFDP',
    body: '<p>numeric</p>',
    type: ProblemType.NUMERIC,
    parentProblemSetIds: [45, 46],
    curriculumXrefs: ['cb031912-8c16-1d17-53be-d5473fcc3e4f'],
    attributionXrefs: ['1'],
    skillIds: [1, 2],
    position: 8,
  });
  server.create('problem', {
    id: '10',
    assistmentId: '1054808',
    xref: 'PRABPFDP',
    body: '<p>numeric expresion</p>',
    type: ProblemType.NUMERIC_EXPRESSION,
    parentProblemSetIds: [45, 46],
    curriculumXrefs: ['cb031912-8c16-1d17-53be-d5473fcc3e4f'],
    attributionXrefs: ['1'],
    skillIds: [1, 2],
    position: 9,
  });
  server.create('problem', {
    id: '11',
    assistmentId: '1054808',
    xref: 'PRABPFDP',
    body: '<p>fraction reduced</p>',
    type: ProblemType.EXACT_FRACTION,
    parentProblemSetIds: [45, 46],
    curriculumXrefs: ['cb031912-8c16-1d17-53be-d5473fcc3e4f'],
    attributionXrefs: ['1'],
    skillIds: [1, 2],
    position: 10,
  });
  server.create('problem', {
    id: '12',
    assistmentId: '1054808',
    xref: 'PRABPFDP',
    body: '<p>algebra</p>',
    type: ProblemType.ALGEBRA,
    parentProblemSetIds: [45, 46],
    curriculumXrefs: ['cb031912-8c16-1d17-53be-d5473fcc3e4f'],
    attributionXrefs: ['1'],
    skillIds: [1, 2],
    position: 11,
  });
  server.create('problem', {
    id: '13',
    assistmentId: '3',
    xref: 'PRABPFDP',
    body: '<p>Which number is biggest?</p>',
    type: ProblemType.NUMERIC,
    parentProblemSetIds: [1149011],
    curriculumXrefs: null,
    attributionXrefs: [],
    skillIds: [1, 3],
    position: 1,
  });
  server.create('problem', {
    id: '14',
    assistmentId: '4',
    xref: 'PRAQ',
    body: '<p>Problem body 4</p>',
    type: ProblemType.NUMERIC,
    parentProblemSetIds: [100],
    curriculumXrefs: [],
    skillIds: [1],
    position: 1,
  });
  server.create('problem', {
    id: '15',
    assistmentId: '5',
    xref: 'PRAR',
    body: '<p>Problem body 5</p>',
    type: ProblemType.NUMERIC,
    parentProblemSetIds: [100],
    attributionXrefs: [],
    skillIds: [1],
    position: 1,
  });
  server.create('problem', {
    id: '16',
    assistmentId: '6',
    xref: 'PRAS',
    body: '<p>Problem body 6</p>',
    type: ProblemType.NUMERIC,
    parentProblemSetIds: [100],
    curriculumXrefs: [],
    attributionXrefs: null,
    skillIds: [1],
    position: 1,
  });
  server.create('problem', {
    id: '17',
    assistmentId: '7',
    xref: 'PRAT',
    body: '<p>Problem body 7</p>',
    type: ProblemType.NUMERIC,
    parentProblemSetIds: [100],
    curriculumXrefs: [],
    skillIds: [1],
    position: 1,
  });
  server.create('problem', {
    id: '18',
    assistmentId: '8',
    xref: 'PRAU',
    body: '<p>Problem body 9</p>',
    type: ProblemType.NUMERIC,
    parentProblemSetIds: [100],
    curriculumXrefs: [],
    skillIds: [1],
    position: 1,
  });
  server.create('problem', {
    id: '19',
    assistmentId: '9',
    xref: 'PRAV',
    body: '<p>Problem body 9</p>',
    type: ProblemType.NUMERIC,
    parentProblemSetIds: [100],
    curriculumXrefs: [],
    skillIds: [1],
    position: 1,
  });
  server.create('problem', {
    id: '20',
    assistmentId: '10',
    xref: 'PRAW',
    body: '<p>Problem body 10</p>',
    type: ProblemType.NUMERIC,
    parentProblemSetIds: [100],
    curriculumXrefs: null,
    skillIds: [],
    position: 1,
  });
  server.create('problem', {
    id: '21',
    assistmentId: '11',
    xref: 'PRAX',
    body: '<p>Problem body 11</p>',
    type: ProblemType.NUMERIC,
    parentProblemSetIds: [100],
    curriculumXrefs: [],
    skillIds: [],
    position: 1,
  });
  server.create('problem', {
    id: '22',
    assistmentId: '12',
    xref: 'PRAY',
    body: '<p>Problem body 12</p>',
    type: ProblemType.NUMERIC,
    parentProblemSetIds: [100],
    curriculumXrefs: [],
    skillIds: [],
    position: 1,
  });
  server.create('problem', {
    id: '23',
    assistmentId: '13',
    xref: 'PRAZ',
    body: '<p>Problem body 13</p>',
    type: ProblemType.NUMERIC,
    parentProblemSetIds: [100],
    skillIds: [],
    position: 1,
  });
}
