
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Problem, ProblemType } from '@/domain/Problem';

@Component
export default class ProblemAnswers extends Vue {
  @Prop({ required: true }) problem: Problem;

  //Allows us access to the enum in the template.
  ProblemType = ProblemType;

  getResponseDescription(type: ProblemType): string {
    switch (type) {
      case ProblemType.OLD_ALGEBRA:
      case ProblemType.ALGEBRA:
        return 'Type your answer below (mathematical expression):';
      case ProblemType.NUMERIC_EXPRESSION:
        return 'Type your answer below (numeric expression):';
      case ProblemType.EXACT_FRACTION:
        return 'Type your answer below (fraction):';
      case ProblemType.NUMERIC:
        return 'Type your answer below as a number (example: 5, 3.1, 4 1/2, or 3/2):';
      case ProblemType.CHOOSE_ONE:
        return 'Select one:';
      case ProblemType.CHOOSE_N:
        return 'Select all that apply:';
      case ProblemType.RANK:
        return 'Type the correct order of each item (eg. 1, 2, 3, etc):';
      default:
        return 'Type your answer below:';
    }
  }
}
