import { tngAxios } from '@/plugins/axios';
import { Skill } from '@/domain/Skill';

const END_POINT = '/metadata/skills';

const getSkills = (): Promise<Skill[]> => {
  return tngAxios.get(`${END_POINT}`).then((result) => {
    const data = result.data;
    data.sort((a: Skill, b: Skill) => {
      let comp = 0;
      if (a.code > b.code) {
        comp = 1;
      } else if (a.code < b.code) {
        comp = -1;
      }
      return comp;
    });
    return data;
  });
};

export { getSkills };
