import { cignitionAxios } from '@/plugins/axios';
import {
  AssignmentLessonDetails,
  PartialAssignmentStudentData,
  TargetAssignmentStudentAndClassDataWithProblems,
} from '@/domain/ReportData/Cignition';

const END_POINT = '/assignments';

const getAssignmentLessonDetails = (
  assignmentXref: string
): Promise<AssignmentLessonDetails> => {
  return cignitionAxios
    .get(`${END_POINT}/${assignmentXref}/lessons/engageNY`)
    .then((result) => {
      return result.data;
    });
};

const getAssignmentStudentAndClassData = (
  assignmentXref: string
): Promise<TargetAssignmentStudentAndClassDataWithProblems> => {
  return cignitionAxios
    .get(`${END_POINT}`, {
      params: {
        assignmentXref: assignmentXref,
      },
    })
    .then((result) => {
      return result.data;
    });
};

const getMyTuteesAssignments = (
  studentXrefs: string[],
  startTime: number,
  endTime: number
): Promise<PartialAssignmentStudentData[]> => {
  return cignitionAxios
    .get(`${END_POINT}/find`, {
      params: {
        studentXrefs: studentXrefs,
        startTime: startTime,
        endTime: endTime,
      },
    })
    .then((result) => {
      return result.data;
    });
};

export {
  getAssignmentLessonDetails,
  getAssignmentStudentAndClassData,
  getMyTuteesAssignments,
};
