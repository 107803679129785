import { cignitionAxios } from '@/plugins/axios';
import { User } from '@/domain/User';

const END_POINT = '/students';

const getMyTutees = (): Promise<User[]> => {
  return cignitionAxios.get(`${END_POINT}`).then((result) => {
    return result.data;
  });
};

export { getMyTutees };
