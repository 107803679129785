
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BarChartView, {
  BarChartData,
  CustomDataSetLabel,
} from '@/components/Report/BarChartView.vue';

@Component({
  components: {
    BarChartView,
  },
})
export default class BarChartViewForReport extends Vue {
  @Prop({ required: true }) customChartLabels: Array<CustomDataSetLabel>;
  @Prop({ required: true }) customChartData: BarChartData;
  @Prop({ default: false }) isLoading: boolean;
  @Prop({ default: false }) noSkillDataFound: boolean;
  @Prop({ default: 'end' }) legendAlignment: 'center' | 'end' | 'start';
  @Prop({ default: null }) unitSymbol: '%' | null;
  @Prop({ default: null }) stepSize: number | null;
  @Prop({ default: 3 }) scrollingSize: number; // Window chunk size for scrolling
  @Prop({ required: false }) height: number;

  ///////////////
  // Scrolling //
  ///////////////
  windowIndex = 0;

  get windowChunks(): Array<BarChartData> {
    const windowChunks: Array<BarChartData> = [];
    const chartDataSize: number = this.customChartData.xTickLabels.length;

    for (let i = 0; i < chartDataSize; i += this.scrollingSize) {
      // Get target chunk of labels
      const chunk: BarChartData = {
        xTickLabels: this.customChartData.xTickLabels.slice(
          i,
          i + this.scrollingSize
        ),
        datasets: {},
      };

      // Get target chunk of data in datasets
      for (const dataset in this.customChartData.datasets) {
        chunk.datasets[dataset] = this.customChartData.datasets[dataset].slice(
          i,
          i + this.scrollingSize
        );
      }

      windowChunks.push(chunk);
    }

    return windowChunks;
  }
  get targetWindowChunk(): BarChartData {
    if (this.windowChunks.length > 0) {
      return this.windowChunks[this.windowIndex];
    }

    return {
      xTickLabels: [],
      datasets: {},
    };
  }
  get leftScrollEnabled(): boolean {
    return this.windowIndex > 0;
  }
  get rightScrollEnabled(): boolean {
    return this.windowIndex < this.windowChunks.length - 1;
  }
  scrollLeft(): void {
    if (this.leftScrollEnabled) {
      this.windowIndex -= 1;
    }
  }
  scrollRight(): void {
    if (this.rightScrollEnabled) {
      this.windowIndex += 1;
    }
  }

  //////////////
  // Watchers //
  //////////////
  @Watch('customChartData')
  onDataChange(): void {
    // Reset display window (size varies per download)
    this.windowIndex = 0;
  }

  @Watch('scrollingSize')
  onSizeChange(): void {
    // Reset display window (index may no longer apply to new size)
    this.windowIndex = 0;
  }
}
