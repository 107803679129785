import { ActionTree } from 'vuex';
import { CignitionState } from './types';
import { RootState } from '../types';
import { getMyTutees } from '@/api/cignition/student.api';
import { User } from '@/domain/User';

export const actions: ActionTree<CignitionState, RootState> = {
  /**
   * Downloads this users' tutees (students)
   * only if not already downloaded/downloading
   */
  requestTutees(context): Promise<Array<User>> {
    if (
      !context.state.hasDownloadedTutees &&
      !context.state.isDownloadingTutees
    ) {
      context.commit('setIsDownloadingTutees', true);

      return getMyTutees().then((tutees: Array<User>) => {
        context.commit('setHasDownloadedTutees', true);
        context.commit('setIsDownloadingTutees', false);
        context.commit('setTutees', tutees);

        return Promise.resolve(tutees);
      });
    }

    return Promise.resolve(context.state.tutees);
  },
};
