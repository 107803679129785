
import { orderBy } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';

export enum ProgressState {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
  ASSIGNED = 4,
}

export interface StudentProgressRecord {
  name: string;
  timestamp?: string;
}

@Component
export default class ProgressCard extends Vue {
  @Prop({ required: true }) state: ProgressState;
  @Prop({ default: [] }) studentProgressRecords: Array<StudentProgressRecord>;

  placeholder = '-----';
  progressDetails = false;
  // Allows us access to the enum in the template.
  ProgressState = ProgressState;

  /////////////
  // Display //
  /////////////

  get statusDisplayText(): string {
    switch (this.state) {
      case ProgressState.NOT_STARTED:
        return 'Not Started';
      case ProgressState.IN_PROGRESS:
        return 'In Progress';
      case ProgressState.COMPLETED:
        return 'Completed';
      case ProgressState.ASSIGNED:
        return 'Assigned';
      default:
        return this.placeholder; // Well... that's very bad.
    }
  }

  get iconName(): string | null {
    switch (this.state) {
      case ProgressState.NOT_STARTED:
        return 'clipboard-alert';
      case ProgressState.IN_PROGRESS:
        return 'clipboard-text';
      case ProgressState.COMPLETED:
        return 'clipboard-check';
      case ProgressState.ASSIGNED:
        return 'clipboard-account';
      default:
        return null;
    }
  }

  get statusDisplayValue(): string {
    switch (this.state) {
      case ProgressState.IN_PROGRESS:
        return 'Last Worked On';
      case ProgressState.COMPLETED:
        return 'Completed On';
      case ProgressState.ASSIGNED:
        return 'Assigned On';
      default:
        return this.placeholder; // Well... that's very bad.
    }
  }

  //////////
  // Data //
  //////////

  get orderedStudentProgressRecords(): Array<StudentProgressRecord> {
    return orderBy(
      this.studentProgressRecords,
      [
        (studentProgressRecord) => {
          const sortTarget = studentProgressRecord.name;

          return typeof sortTarget !== 'string'
            ? sortTarget
            : sortTarget.toLowerCase();
        },
      ],
      'asc'
    );
  }
}
