import { AttributeDefinition } from '@/domain/Attributes';
import { coreAxios } from '@/plugins/axios';

const END_POINT = `/attributes`;

const getAttributeDefinition = (
  attributeName: string
): Promise<AttributeDefinition> => {
  return coreAxios.get(`${END_POINT}/${attributeName}`).then((res) => {
    return res.data;
  });
};

export { getAttributeDefinition };
