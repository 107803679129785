
import { Component, Prop, Vue } from 'vue-property-decorator';
import ScoreChip from '@/components/Report/ScoreChip.vue';
import { Answer } from '@/domain/Problem';
import { StudentDataPerAssignmentProblem } from '@/domain/ReportData/Cignition';
import {
  ActionType,
  StudentProblemAction,
} from '@/domain/StudentProblemAction';
import { hasSeenAnswer } from '@/utils/report.util';

@Component({
  components: {
    ScoreChip,
  },
})
export default class CignitionStudentActionsMenu extends Vue {
  @Prop({ required: true }) studentName: string;
  @Prop({ required: true }) problemName: string;
  @Prop({ required: true }) score: number;
  @Prop({ required: true }) problemAnswers: Array<Answer>;
  @Prop({ required: true }) studentData: StudentDataPerAssignmentProblem;
  showMenu = false;
  wirisLoaded = false;

  // Allows us access to the enum in the template.
  ActionType = ActionType;

  get maxWidth(): number {
    return this.$vuetify.breakpoint.lgAndUp ? 500 : 400;
  }

  get maxHeight(): number {
    return this.$vuetify.breakpoint.lgAndUp ? 700 : 600;
  }

  get problemCorrectAnswers(): Array<Answer> {
    if (this.problemAnswers) {
      return this.problemAnswers.filter((answer: Answer) => {
        return answer.isCorrect;
      });
    }

    return [];
  }

  get studentAnswersSubmitted(): Array<StudentProblemAction> {
    return this.studentData.studentActions.filter(
      (action: StudentProblemAction) => {
        return (
          action.type == ActionType.STUDENT_RESPONSE_ACTION ||
          action.type == ActionType.STUDENT_SUBMISSION_ACTION
        );
      }
    );
  }

  get hintsReceived(): Array<StudentProblemAction> {
    return this.studentData.studentActions.filter(
      (action: StudentProblemAction) => {
        return action.type === ActionType.HINT_REQUESTED_ACTION;
      }
    );
  }

  get studentActions(): Array<ActionType> {
    return this.studentData.studentActions.map(
      (action: StudentProblemAction) => {
        return action.type;
      }
    );
  }

  get sawAnswer(): boolean {
    return hasSeenAnswer(this.studentActions);
  }

  created(): void {
    const unwatchShowMenu = this.$watch('showMenu', (newVal: boolean) => {
      if (newVal) {
        //we are showing the menu for the first time

        this.$nextTick(() => {
          //Ignore any for now. We want to eventually extend window interface to allow us to use wiris or we want something other than wiris
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).com.wiris.js.JsPluginViewer.parseDocument();
          unwatchShowMenu();
        });
      }
    });
  }
}
