
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Problem } from '@/domain/Problem';
import ProblemView from '@/components/base/ProblemView.vue';
import Editor from '@tinymce/tinymce-vue';
import StudentSupportAuthoring from './StudentSupportAuthoring.vue';

@Component({
  components: {
    ProblemView,
    Editor,
    StudentSupportAuthoring,
  },
})
export default class StudentSupportsDialog extends Vue {
  @Prop() value: boolean; // Whether the dialog is open or not
  @Prop() problems: Problem[];
  @Prop() assistmentId: string;

  problem = 0;
  get compValue(): boolean {
    return this.value;
  }

  set compValue(newVal: boolean) {
    this.$emit('input', newVal);
  }

  get lastProblem(): boolean {
    return this.problem === this.problems.length - 1;
  }

  goNext() {
    if (this.problem !== null && !this.lastProblem) {
      this.problem++;
    }
  }
}
