import { LmsProviderType } from '@/domain/LmsProviderType';
import { GetterTree } from 'vuex';
import { AuthenticatedUser } from '@/domain/User';
import { AuthState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<AuthState, RootState> = {
  isAuthenticated(state: AuthState): boolean {
    return !!state.user;
  },
  isLtiUser(state: AuthState): boolean {
    return state.user?.lmsProviderType === LmsProviderType.LTI_ENABLED;
  },
  getCurrentUser(state: AuthState): AuthenticatedUser | undefined {
    return state.user;
  },
};
