
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  getProblemSet,
  getProblemsByPsIdGroupedByAssistmentId,
} from '@/api/pspr.api';
import {
  getFolderChildren,
  getFolder,
  getFolderPathWithFolder,
  getFolderPathWithPS,
} from '@/api/folders.api';
import { Problem } from '@/domain/Problem';
import { ProblemSet } from '@/domain/ProblemSet';
import { FolderInfo } from '@/domain/FolderInfo';
import { ParentPage } from '@/components/base/ProblemViewMenu.vue';
import LessonPageTopBar from '@/components/FindProblems/LessonPage/LessonPageTopBar.vue';
import AssignBottomBar from '@/components/FindProblems/AssignBottomBar.vue';
import ProblemListView from '@/components/base/ProblemListView.vue';

@Component({
  components: {
    LessonPageTopBar,
    AssignBottomBar,
    ProblemListView,
  },
})
export default class LessonPage extends Vue {
  ignoreNewRoute = false;
  topBarReady = false;
  problemListReady = false;
  type: string;
  parentPage: ParentPage = ParentPage.LESSON_VIEW;
  problems: Problem[][] = [];
  selectedProblemIds: number[] = [];

  problemSetFI: FolderInfo;
  lesson: FolderInfo | null = null;
  chosenChildIndex: number;

  problemSet: ProblemSet | null = null;

  folderPath: FolderInfo[] = [];

  get title(): string {
    if (this.type === 'lesson' && this.lesson) {
      return `${this.lesson.title}: ${this.lesson.name}`;
    } else if (this.type === 'ps' && this.problemSet) {
      return this.problemSet.name;
    }

    return '';
  }

  get numTotalProblems(): number {
    return this.problems.length;
  }

  //Handle initialization in the following methods
  @Watch('$route.params.psid')
  onPSIDChange(value: string, oldValue: string): void {
    if (value === oldValue || this.type !== 'lesson' || this.ignoreNewRoute) {
      return;
    }
    this.problemListReady = false;
    this.initializeProblemList();
  }

  finishLessonInitialization(): void {
    if (this.lesson) {
      //Grab the lesson and then call initialize problem List
      getFolderChildren(this.lesson.id).then((children) => {
        if (this.lesson) {
          this.lesson.children = children;
          this.topBarReady = true;

          if (this.lesson.children) {
            if (!this.$route.params.psid) {
              this.$router.replace({
                name: 'LessonPage',
                params: {
                  type: 'lesson',
                  id: `${this.lesson.id}`,
                  psid: `${this.lesson.children[0].id}`,
                },
                query: this.$route.query,
              });
            } else {
              this.initializeProblemList();
            }
          }
        }
      });
    }
  }

  //initialize the problem list.
  initializeProblemList(): void {
    if (this.type === 'lesson' && this.lesson && this.lesson.children) {
      //figure out index
      const routePSId = Number(this.$route.params.psid);
      let found = false;

      for (let i = 0; i < this.lesson.children.length; i++) {
        if (this.lesson.children[i].id === routePSId) {
          this.chosenChildIndex = i;
          found = true;
          break;
        }
      }

      if (!found) {
        return;
      }

      this.problemSetFI = this.lesson.children[this.chosenChildIndex];

      let promiseArr = [];

      if (this.problemSet && this.problemSet.id === this.problemSetFI.id) {
        //We already have the problem set most likely from the initialize of a problem set view
        // being redirected to the lesson version
      } else {
        const psPromise = getProblemSet(this.problemSetFI.id).then((ps) => {
          this.problemSet = ps;
        });
        promiseArr.push(psPromise);
      }
      const prPromise = this.getAndSetupProblems(this.problemSetFI.id);
      promiseArr.push(prPromise);

      Promise.all(promiseArr).then(() => {
        this.problemListReady = true;
      });
    }
    //We already have the problemSet because we are a ps type
    else if (this.type === 'ps' && this.problemSet) {
      this.getAndSetupProblems(this.problemSet.id).then(() => {
        this.problemListReady = true;
      });
    }
  }

  getAndSetupProblems(psId: number): Promise<void> {
    return getProblemsByPsIdGroupedByAssistmentId(psId).then((problems) => {
      this.problems = problems;
      this.selectedProblemIds = [];
      for (const p of problems) {
        this.selectedProblemIds.push(p[0].assistmentId);
      }
    });
  }

  created(): void {
    this.type = this.$route.params.type;
    if (this.type === 'lesson') {
      getFolder(Number(this.$route.params.id)).then((result) => {
        if (result) {
          this.lesson = result;
          getFolderPathWithFolder(result.id)
            .then((path) => {
              this.folderPath = path;
            })
            .finally(() => {
              //finish initialization
              this.finishLessonInitialization();
            });
        }
      });
    } else if (this.type === 'ps') {
      //start initializing ps
      getProblemSet(Number(this.$route.params.id)).then((result) => {
        if (result) {
          this.problemSet = result;
          getFolderPathWithPS(result.id)
            .then((path) => {
              //Figure out if we are in a lesson actually
              this.folderPath = path;
              if (path) {
                let f = path[path.length - 1];
                //We are in a lesson. Lets redirect and re-initialize
                if (f.type === 'lesson') {
                  //We are hcaning more than just psid. Ignore the watcher for psid
                  this.ignoreNewRoute = true;
                  this.lesson = f;
                  this.folderPath.pop();

                  this.$router.replace({
                    name: 'LessonPage',
                    params: {
                      type: 'lesson',
                      id: `${f.id}`,
                      psid: `${this.$route.params.id}`,
                    },
                    query: this.$route.query,
                  });

                  this.ignoreNewRoute = false;

                  //Re initialize
                  this.type = 'lesson';
                  this.finishLessonInitialization();

                  return;
                }
              }
            })
            .finally(() => {
              this.topBarReady = true;
              this.initializeProblemList();
            });
        }
      });
    }
  }
}
