import { MutationTree } from 'vuex';
import { User } from '@/domain/User';
import { LDOEState } from './types';
import { TimeFrame } from '@/domain/Time';

export const mutations: MutationTree<LDOEState> = {
  setHasDownloadedTutees(state: LDOEState, flag: boolean) {
    state.hasDownloadedTutees = flag;
  },
  setIsDownloadingTutees(state: LDOEState, flag: boolean) {
    state.isDownloadingTutees = flag;
  },
  setTutees(state: LDOEState, tutees: Array<User>) {
    state.tutees = tutees;
  },
  setSelectedTuteeXrefs(state: LDOEState, tuteeXrefs: Array<string>) {
    state.selectedTuteeXrefs = [...tuteeXrefs];
  },
  setSelectedTimeFrame(state: LDOEState, timeFrame: TimeFrame | null) {
    state.selectedTimeFrame = timeFrame;
  },
};
