var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.item.type == 'folder')?_c('div',{attrs:{"data-cy":"folder-wrapper"}},[_c('div',{staticClass:"hover-menu-btn-parent",class:[
        {
          open: _vm.item.open,
          'has-open-child': _vm.hasOpenChild,
          'root-folder': _vm.depth == 0,
        },
        _vm.item.type,
      ],attrs:{"data-cy":"parent-folder"},on:{"click":_vm.openFolder}},[_c('div',{staticClass:"header"},[_vm._l((_vm.depth),function(n){return _c('div',{key:n,staticClass:"indent"})}),_c('span',{staticClass:"label",class:[_vm.folderHeader],domProps:{"innerHTML":_vm._s(_vm.item.name)}})],2),(
          _vm.depth === 0 &&
          _vm.item.attributionXrefs &&
          _vm.item.attributionXrefs.length > 0
        )?_c('div',[_c('Attribution',{staticClass:"mt-2",attrs:{"attributionXrefs":_vm.item.attributionXrefs}})],1):_vm._e()]),_c('v-expand-transition',[(_vm.isDownloading)?_c('div',{staticClass:"loading-spinner-wrapper"},[_c('v-progress-circular',{staticClass:"folder-children",attrs:{"size":"32","indeterminate":"","color":"primary"}})],1):_vm._e(),(_vm.hasDownloaded && _vm.item.open && _vm.item.children.length === 0)?_c('div',{staticClass:"folder-children"},[_c('EmptyFolderItem')],1):_vm._e(),(_vm.hasDownloaded && _vm.item.open && _vm.item.children.length > 0)?_c('div',{staticClass:"folder-children"},[_c('FolderGroup',{attrs:{"folderData":_vm.item.children,"parentDepth":_vm.depth},on:{"folder-opened":function($event){return _vm.$emit('folder-opened', $event)}}})],1):_vm._e()])],1):_c('div',[_c('router-link',{class:[_vm.item.type],attrs:{"to":{ name: 'LessonPage', params: { type: _vm.item.type, id: _vm.item.id } },"tag":"div"}},[_c('div',{staticClass:"header"},[_vm._l((_vm.depth),function(n){return _c('div',{key:n,staticClass:"indent"})}),_c('div',{staticClass:"d-flex flex-column"},[(_vm.item.type == 'lesson')?_c('span',{staticClass:"lesson-label"},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]):_vm._e(),_c('span',{staticClass:"text-h6",domProps:{"innerHTML":_vm._s(_vm.item.name)}})])],2)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }