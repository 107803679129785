
import { Component, Vue, Prop } from 'vue-property-decorator';
import { FolderInfo } from '@/domain/FolderInfo';

@Component
export default class LessonPageTopBar extends Vue {
  @Prop() path: FolderInfo[];
  @Prop() title: string;
  @Prop() tabs?: FolderInfo[];
  @Prop() lessonId?: number;

  backToSearch = this.$route.query.skillId || 0;

  returnToName = this.$route.query.returnToName;
  returnToPath = this.$route.query.returnToPath;

  getPathText(index: number): string {
    let arr = this.path.slice(0, index + 1).map((p) => {
      return p.id;
    });
    //Is this how I want to do this? I feel like I want to just not create links if the parent does not exist on the first path
    if (this.path[0] != null && this.path[0].parent_id != null) {
      arr.unshift(this.path[0].parent_id);
    }
    return arr.join(',');
  }
}
