import { InitData } from '@/domain/InitData';
import { tngAxios } from '@/plugins/axios';
import { getMyUser, getUserSettings } from '@/api/core/user.api';
import { AuthenticatedUser } from '@/domain/User';
import { LmsProviderType } from '@/domain/LmsProviderType';

//////////
// DTOs //
//////////

export interface AuthenticatedUserDTO {
  userToken: string;
  loginAliasId: string;
  lmsProviderType: string;
}

const initializeApp = (): Promise<InitData> => {
  const requestTngInit = tngAxios.get('/setup');
  const requestCoreUserProfile = getMyUser(true, true);
  const requestSettings = getUserSettings();
  return Promise.all([requestTngInit, requestCoreUserProfile, requestSettings])
    .then((responses) => {
      const tngInitData = {
        user: transformToAuthenticatedUser(responses[0].data.user),
        tutorTemplateUrl: responses[0].data.tutorTemplateUrl,
      };
      const coreUserProfile = responses[1];
      const settings = responses[2];

      return {
        // Combine user data from TNG + Core
        user: {
          ...tngInitData.user,
          ...coreUserProfile,
          settings,
        },
        tutorTemplateUrl: tngInitData.tutorTemplateUrl,
      };
    })
    .catch((err) => {
      return err;
    });
};

/////////////
// Helpers //
/////////////
function transformToAuthenticatedUser(
  authenticatedUserDTO: AuthenticatedUserDTO
): AuthenticatedUser {
  const { lmsProviderType, ...rest } = authenticatedUserDTO;
  return {
    lmsProviderType: LmsProviderType.findByName(lmsProviderType),
    ...rest,
  } as unknown as AuthenticatedUser;
}

export { initializeApp };
