
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs';
import Chart from 'chart.js';

export interface Data {
  value: number; // Y-axis value
  // unitSymbol?: string; // Append to axis value in tooltip
  numProblems?: number;
}

export interface DataSets {
  [xref: string]: Array<Data>;
}

export interface XTickLabel {
  text: string; // Set property of label's text value
  value: string | number; // Set property of label's value
  unicodeSymbol?: string; // Prepend to label's text value in tick label
  totalProblems?: number;
  // targetObject: any; // Look for text and value keys in Object
}

export interface BarChartData {
  xTickLabels: Array<XTickLabel>;
  datasets: DataSets;
}

export interface CustomDataSetLabel {
  xref: string; // Identify dataset
  label: string; // Display text for dataset
  backgroundColor: string;
}

Chart.defaults.global.defaultFontFamily = 'Montserrat';

@Component
export default class BarChartView extends Mixins(Bar) {
  @Prop({ required: true }) customChartLabels: Array<CustomDataSetLabel>;
  @Prop({ required: true }) customChartData: BarChartData;
  @Prop({ default: 'end' }) legendAlignment: 'center' | 'end' | 'start';
  @Prop({ default: null }) unitSymbol: '%' | null;
  @Prop({ default: null }) stepSize: number | null;

  //////////////////////////
  // Chart Configurations //
  //////////////////////////

  get chartData(): Chart.ChartData {
    const labels = this.customChartData.xTickLabels.map(
      (label: XTickLabel) => label.value
    );

    const datasets: Array<Chart.ChartDataSets> = [];

    // Graphed in order of given in custom chart labels
    this.customChartLabels.forEach((label: CustomDataSetLabel) => {
      if (this.customChartData.datasets[label.xref]) {
        // Get dataset
        datasets.push({
          ...label,
          data: this.customChartData.datasets[label.xref].map(
            // Plot on value on Y-axis
            (data: Data) => data.value
          ),
        });
      } else {
        // No dataset at all?
        // Might not happen at all.
        datasets.push({
          ...label,
          data: [],
        });
      }
    });

    return {
      labels: labels, // X-axis: any
      datasets: datasets, // Y-axis: number
    };
  }

  options: Chart.ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      // align: 'end',
      labels: {
        usePointStyle: true,
        fontSize: 14,
        padding: 20,
        fontColor: this.fontColor,
      },
    },
    tooltips: {
      displayColors: false,
      backgroundColor: this.tooltipColor,
      bodyFontColor: this.fontColor,
      yPadding: 10,
      callbacks: {
        title: function (): string {
          return ''; // No title
        },
        label: this.getDetailedInfo,
      },
    },
    onClick: this.clickLabel,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: this.xTickColor,
            // Include a focus skill icon
            callback: this.getXTickLabel,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: this.yTickColor,
          },
          gridLines: {
            borderDash: [20, 5],
          },
        },
      ],
    },
  };

  //////////////////
  // Chart Colors //
  //////////////////

  get fontColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.darken1;
  }

  get tooltipColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.lighten3;
  }

  get xTickColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.primary.base;
  }

  get yTickColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.base;
  }

  /////////////
  // Methods //
  /////////////
  /**
   * This method returns the index of the clicked X Label IF one was clicked. -1 if no actual label is clicked
   */
  getClickedXLabelIndex(event: MouseEvent): number {
    if (event == null) {
      return -1;
    }
    const chart = this.$data._chart;
    const mousePoint = Chart.helpers.getRelativePosition(event, chart);

    const xAxis = chart.scales['x-axis-0'];

    const tickIndex = xAxis.getValueForPixel(mousePoint.x);
    //try to use the cached width?
    const textCache = xAxis.longestTextCache;
    let textWidth = 0;
    if (
      textCache &&
      Object.keys(textCache).length > 0 &&
      textCache[Object.keys(textCache)[0]].data &&
      textCache[Object.keys(textCache)[0]].data[xAxis.ticks[tickIndex]]
    ) {
      textWidth =
        textCache[Object.keys(textCache)[0]].data[xAxis.ticks[tickIndex]];
    } else {
      textWidth = chart.ctx.measureText(xAxis.ticks[tickIndex]).width;
    }

    const labelMidpoint = xAxis.getPixelForTick(tickIndex);
    const labelLeftBound = labelMidpoint - textWidth / 2;
    const labelRightBound = labelMidpoint + textWidth / 2;
    const withinX =
      mousePoint.x > labelLeftBound - 5 && mousePoint.x < labelRightBound + 5;
    const withinY = mousePoint.y > xAxis.top && mousePoint.y < xAxis.bottom;

    if (withinX && withinY) {
      return tickIndex;
    }

    return -1;
  }

  clickLabel(event: MouseEvent): void {
    // https://vue-chartjs.org/api/#renderchart
    // this.$data._chart is the chartjs instance and exposes all methods and events
    const tickIndex = this.getClickedXLabelIndex(event);

    if (tickIndex >= 0) {
      //Use the tick index and the first dataset (we don't care about dataset)
      // to get the skill id.
      const label = this.$data._chart.scales['x-axis-0'].getLabelForIndex(
        tickIndex,
        0
      );

      // Emit event with label value and allow parent handling
      // Maybe we can do a v-model here?
      this.$emit('clickedLabel', label);
    }
  }

  getDetailedInfo(
    tooltipItem: Chart.ChartTooltipItem,
    chartData: Chart.ChartData
  ): string {
    let info = '';

    if (chartData.datasets && tooltipItem.datasetIndex !== undefined) {
      // Dataset label : Y value
      info +=
        chartData.datasets[tooltipItem.datasetIndex].label +
        ': ' +
        tooltipItem.yLabel;

      if (this.unitSymbol) {
        // Add unit (e.g. %)
        info += this.unitSymbol;
      }

      const targetDataset =
        this.customChartLabels[tooltipItem.datasetIndex].xref;

      if (tooltipItem.index !== undefined) {
        const dataPoint: Data =
          this.customChartData.datasets[targetDataset][tooltipItem.index];

        if (dataPoint !== undefined && dataPoint.numProblems) {
          // Total number of problems completed
          info += ' (' + dataPoint.numProblems + ' problems)';
        }
      }
    }

    return info;
  }

  getXTickLabel(id: number, index: number): string | null {
    const targetLabel: XTickLabel = this.customChartData.xTickLabels[index];

    if (targetLabel) {
      const labelText = targetLabel.text;

      if (targetLabel.unicodeSymbol && targetLabel.totalProblems) {
        // Make note of the number of problems examined
        return (
          targetLabel.unicodeSymbol +
          ' ' +
          labelText +
          ' [' +
          targetLabel.totalProblems +
          ']'
        );
      } else if (targetLabel.unicodeSymbol) {
        return targetLabel.unicodeSymbol + ' ' + labelText;
      } else if (targetLabel.totalProblems) {
        return labelText + ' [' + targetLabel.totalProblems + ']';
      } else {
        return labelText;
      }
    }

    return null;
  }

  //////////////
  // Watchers //
  //////////////

  @Watch('chartData')
  onDataChange(): void {
    // Tranform data, mutation instead of pushing new data
    // Better if we implement our own watcher
    // this.renderChart(this.chartData, this.options);

    // New datasets
    this.$data._chart.data = this.chartData;

    // Update chart instance (with animation) to the new data values and options
    this.$data._chart.update();
  }

  @Watch('legendAlignment')
  onOptionsChange(): void {
    if (this.$data._chart.options.legend.align === this.legendAlignment) {
      return;
    }

    // New legend alignment in options
    this.$data._chart.options.legend.align = this.legendAlignment;

    // Update chart instance (with animation) to the new data values and options
    this.$data._chart.update();
  }

  @Watch('stepSize')
  onStepSizeChange(): void {
    if (this.$data._chart.options.scales.yAxes.stepSize === this.stepSize) {
      return;
    }

    // New step size in options
    this.$data._chart.options.scales.yAxes.stepSize = this.stepSize;

    // Update chart instance (with animation) to the new data values and options
    this.$data._chart.update();
  }

  mounted(): void {
    if (this.options.legend) {
      this.options.legend.align = this.legendAlignment;
    }

    if (this.options.scales && this.options.scales.yAxes) {
      if (this.options.scales.yAxes[0].ticks && this.stepSize) {
        this.options.scales.yAxes[0].ticks.stepSize = this.stepSize;
      }
    }

    // Have to do this on mount. On create doesn't render
    // No template - take it from the extended one
    // Create chart instance inside canvas
    this.renderChart(this.chartData, this.options);
  }
}
