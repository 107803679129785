import { UserAttributes } from './../domain/User';
import { Server, Model } from 'miragejs';
import { SettingsDTO } from '@/api/core/user.api';
import { AuthenticatedUser } from '@/domain/User';
import seedData from './seeds';
import initializeEndpoints from './endpoints';
import { LmsProviderType } from '@/domain/LmsProviderType';
import { AttributeNames } from '@/domain/Attributes';

const mockInvitationCode = 'mirage';

const mockUserAttributes: UserAttributes = {
  [AttributeNames.NCES_PUBLIC_SCHOOL]: ['1'],
  [AttributeNames.GRADE_LEVEL]: ['GRADE_1'],
  [AttributeNames.CURRICULUM]: ['a9beff09-6e2a-8eef-d36a-4ebac161ca69'],
  [AttributeNames.QUICK_COMMENTS_TEACHER]: true,
};

const mockUserSettings: Array<SettingsDTO> = [
  {
    name: 'anonymizeReportsByDefault',
    value: false,
    hasBeenSet: true,
  },
  {
    name: 'testModeEnabled',
    value: true,
    hasBeenSet: true,
  },
  {
    name: 'testModeCorrectness',
    value: false,
    hasBeenSet: true,
  },
  {
    name: 'testModeStudentReport',
    value: false,
    hasBeenSet: true,
  },
  {
    name: 'correctnessFeedback',
    value: false,
    hasBeenSet: true,
  },
  {
    name: 'starredTeacherEnabled',
    value: true,
    hasBeenSet: true,
  },
  {
    name: 'testMode',
    value: false,
    hasBeenSet: true,
  },
  {
    name: 'personalizedTutoringEnabled',
    value: false,
    hasBeenSet: true,
  },
  {
    name: 'accessToLDOEReport',
    value: true,
    hasBeenSet: true,
  },
  {
    name: 'accessToInsightsHub',
    value: true,
    hasBeenSet: true,
  },
  {
    name: 'hideTestModeDialog',
    value: false,
    hasBeenSet: true,
  },
];

const mockUser = {
  data: {
    xref: 'hLVmvz6zgr6nmyEMW9qt',
    userName: 'tBucks1337@testmail.com',
    firstName: 'Terrance',
    lastName: 'Buckington',
    displayName: 'Mr Buckington',
    userToken:
      process.env.VUE_APP_USER_TOKEN || '40b5b1d8-4f61-4e3e-bd31-16b3da8d937f',
    loginAliasId: process.env.VUE_APP_LAID || '48839',
    roles: ['MENTOR'],
    timeZone: 'America/New_York',
    attributes: mockUserAttributes,
    lmsProviderType: 'LTI_ENABLED' as unknown as LmsProviderType,
  } as AuthenticatedUser,
};

/**
 * Mocks authentication status
 * first time requesting, it responds to indicate not authenticated
 * After that, says you're authenticated and sends hard-coded mocked user data
 */
const isLoggedIn = { status: false };
const cookieName = `ASSISTmentsService${
  process.env.VUE_APP_COOKIE_SUFFIX || ''
}`;

function checkLogin(): void {
  //https://stackoverflow.com/a/25490531
  const b = document.cookie.match(
    '(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)'
  );
  const cookie = b ? b.pop() : '';
  if (cookie) {
    isLoggedIn.status = true;
  }
}

function encodeId(prefix: string, id: number): string {
  let ret = '';
  const alphabet = 'ABCDEFGHJKMNPQRSTUVWXYZ23456789';
  const alphabetLen = alphabet.length;

  while (id > 0) {
    ret += alphabet.charAt(id % alphabetLen);
    id = Math.floor(id / alphabetLen);
  }

  return `${prefix.toUpperCase()}A${ret.split('').reverse().join('')}`;
}

export {
  mockUser,
  mockUserSettings,
  mockUserAttributes,
  mockInvitationCode,
  isLoggedIn,
  checkLogin,
  cookieName,
  encodeId,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function makeServer({
  environment = 'development',
} = {}): Server {
  const server = new Server({
    environment,
    urlPrefix: 'http://localhost:8081',
    models: {
      folderInfo: Model,
      skill: Model,
      problem: Model,
      problemSet: Model,
      curriculum: Model,
      class: Model,
      lmsClass: Model,
      assignment: Model,
      assignmentDetail: Model,
      user: Model,
      studentAndPeerDataPerSkill: Model,
      assignmentDataStudent: Model,
      assignmentDataStudentAndClass: Model,
      assignmentLessonDetail: Model,
      settings: Model,
      student: Model,
      assignmentDefinition: Model,
      assignmentReport: Model,
      studentSupport: Model,
      settingsDefinitions: Model,
      classDefinition: Model,
      problemSetReport: Model,
      school: Model,
      attribute: Model,
      attribution: Model,
      quickComment: Model,
      ssasSupports: Model, // we want to rename it once we can unify the hint api method for the reports
      group: Model,
      teacherCurriculumStat: Model,
      curriculumGrade: Model,
      folder: Model,
    },
  });

  seedData(server);
  initializeEndpoints(server);

  return server;
}
