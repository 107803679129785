import { coreAxios } from '@/plugins/axios';

const END_POINT = '/icodes';

const createTeacherRoleCode = (emails: string[]): Promise<string> => {
  return coreAxios
    .post(`${END_POINT}/`, null, {
      params: {
        roles: 'TEACHER',
        emails,
      },
    })
    .then((res) => {
      return res.data;
    });
};

const submitTeacherRoleCode = (inviteCode: string): Promise<void> => {
  return coreAxios.post(`${END_POINT}/${inviteCode}`, null).then((res) => {
    return res.data;
  });
};

export { createTeacherRoleCode, submitTeacherRoleCode };
