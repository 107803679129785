import { getAlertMessages } from '@/api/core/notifications.api';
import store from '@/store';

export default (): Promise<void> => {
  if (store.getters['auth/isAuthenticated']) {
    return getAlertMessages().then((alertMessages) => {
      store.commit('notifications/setAlertMessages', alertMessages);
    });
  }
  return Promise.resolve();
};
