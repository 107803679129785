
import {
  getAssignmentDefinition,
  getAssignmentReportData,
} from '@/api/core/assignment.api';
import { getProblemsByPsXref, getProblemSetByXref } from '@/api/pspr.api';
import NoDataView from '@/components/Report/NoDataView.vue';
import StudentAssignmentReportSideSheet from '@/components/Report/StudentAssignmentReportSideSheet.vue';
import StudentAssignmentReportTable from '@/components/Report/StudentAssignmentReportTable.vue';
import OpenResponseImageModal from '@/components/Report/OpenResponseImageModal.vue';
import { AssignmentDefinition } from '@/domain/Assignment';
import { Problem } from '@/domain/Problem';
import { ProblemSet } from '@/domain/ProblemSet';
import {
  AssignmentReportData,
  ProblemLog,
} from '@/domain/ReportData/AssignmentData';
import { isEmpty } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';

export interface StudentAssignmentReportSideSheetPaylod {
  problem: Problem;
  problemLog: ProblemLog;
}

@Component({
  components: {
    StudentAssignmentReportTable,
    StudentAssignmentReportSideSheet,
    NoDataView,
    OpenResponseImageModal,
  },
})
export default class StudentAssignmentReportPage extends Vue {
  assignmentXref: string;

  assignmentDefinition: AssignmentDefinition;
  reportData: AssignmentReportData;
  problemSet: ProblemSet;
  problems: Array<Problem>;
  problemLogs: Array<ProblemLog>;

  selectedProblemIndex: number | null = null;

  problemIdToProblemMap: Map<number, Problem> = new Map<number, Problem>();
  problemIdToProblemLogMap: Map<number, ProblemLog> = new Map<
    number,
    ProblemLog
  >();
  problemIdToClassAverageScoreMap: Map<number, number> = new Map<
    number,
    number
  >();

  dataDownloaded = false;
  dataReady = false;

  showSideSheet = false;
  showImageModal = false;

  imageSource: string | null = null;

  get isReady(): boolean {
    return this.dataDownloaded && this.dataReady;
  }

  get selectedProblem(): Problem {
    return this.problems[this.selectedProblemIndex as number];
  }

  get selectedProblemLog(): ProblemLog {
    return this.problemIdToProblemLogMap.get(
      this.selectedProblem.id as number
    ) as ProblemLog;
  }

  openProblemSideSheet(problemId: number): void {
    this.selectedProblemIndex = this.findIndexOfProblemId(problemId);

    this.showSideSheet = true;
  }

  openImageModal(imageSource: string): void {
    this.imageSource = imageSource;

    this.showImageModal = true;
  }

  setNextProblem(): void {
    if (
      this.selectedProblemIndex !== null &&
      this.selectedProblemIndex < this.problems.length - 1
    ) {
      this.selectedProblemIndex += 1;
    }
  }

  setPreviousProblem(): void {
    if (this.selectedProblemIndex !== null && this.selectedProblemIndex > 0) {
      this.selectedProblemIndex -= 1;
    }
  }

  private async download(): Promise<void> {
    const outerPromises: Promise<void>[] = [];
    const innerPromises: Promise<void>[] = [];

    // Load assignment definition
    const assignmentDefinitionPromise = getAssignmentDefinition(
      this.assignmentXref,
      {
        details: true,
      }
    ).then((assignmentDefinition: AssignmentDefinition) => {
      this.assignmentDefinition = assignmentDefinition;
      const problemSetXref = this.assignmentDefinition.problemSetCeri;
      // Problem set
      const problemSetPromise = getProblemSetByXref(problemSetXref).then(
        (problemSet) => {
          this.problemSet = problemSet;
        }
      );
      innerPromises.push(problemSetPromise);
      // Problems
      const problemsPromise = getProblemsByPsXref(problemSetXref, [
        'ANSWERS',
        'ATTRIBUTIONS',
        'SKILLS',
      ]).then((problems: Array<Problem>) => {
        this.problems = problems;
      });
      innerPromises.push(problemsPromise);
    });
    outerPromises.push(assignmentDefinitionPromise);
    // Load report data
    const currentStudentXref = this.getCurrentUser.xref;

    const reportDataPromise = getAssignmentReportData(this.assignmentXref, {
      usersFilter: [currentStudentXref],
    })
      .then((reportData: AssignmentReportData) => {
        if (isEmpty(reportData)) {
          throw new Error('No report data.');
        }

        this.reportData = reportData;

        // NOTE: `studentLogs` should contain a single student log
        const [studentLog] = this.reportData.studentLogs;

        if (studentLog.problemLogAndActions) {
          this.problemLogs =
            studentLog.problemLogAndActions
              .map(({ prLog }) => prLog)
              .filter((prLog) => prLog.endTime)
              .sort((a, b) => (a.startTime > b.startTime ? 1 : -1)) || [];
        }
      })
      .catch(() => {
        // TODO: Discuss what to do if no report data is found
      });
    outerPromises.push(reportDataPromise);

    try {
      await Promise.all(outerPromises);
      await Promise.all(innerPromises);
      this.dataDownloaded = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      error.handleGlobally && error.handleGlobally();
    }
  }

  private generateProblemIdToProblemMap(): void {
    this.problems.forEach((problem) =>
      this.problemIdToProblemMap.set(problem.id, problem)
    );
  }

  private generateProblemIdToClassAverageScoreMap(): void {
    if (this.reportData && this.reportData.prAllStats) {
      this.reportData.prAllStats.forEach(({ problemDbid, avgScore }) => {
        this.problemIdToClassAverageScoreMap.set(problemDbid, avgScore);
      });
    }
  }

  private generateProblemIdToProblemLogMap(): void {
    if (this.reportData) {
      this.reportData.studentLogs.forEach(({ problemLogAndActions }) => {
        if (problemLogAndActions) {
          problemLogAndActions.forEach(({ prLog }) =>
            this.problemIdToProblemLogMap.set(prLog.problemDbid, prLog)
          );
        }
      });
    }
  }

  private generateDataMaps(): void {
    this.generateProblemIdToProblemMap();
    this.generateProblemIdToProblemLogMap();
    this.generateProblemIdToClassAverageScoreMap();

    this.dataReady = true;
  }

  private findIndexOfProblemId(problemId: number): number {
    return this.problems.findIndex(({ id }) => id === problemId);
  }

  async created(): Promise<void> {
    this.assignmentXref = this.$route.params.xref;

    await this.download();
    this.generateDataMaps();
  }
}
