
import { uniq } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ProblemSet } from '@/domain/ProblemSet';
import { Problem } from '@/domain/Problem';
import {
  createProblemSet,
  getProblemsByPsIdGroupedByAssistmentId,
  ProblemSetFilterAndPagingParams,
  ProblemSetOwnershipType,
  updateProblemSet,
} from '@/api/pspr.api';
import { CancelTokenSource } from 'axios';
import ScrollObserver from '@/components/base/ScrollObserver.vue';

@Component({
  components: {
    ScrollObserver,
  },
})
export default class SaveToMyProblemSetsDialog extends Vue {
  @Prop() value: boolean; // Whether the dialog is open or not
  @Prop() pToAdd: number | number[]; // id or ids or assistment(s) to add
  saved = false;
  savedPSId = 0;
  submitting = false; // Actively submitting request to save
  loading = false; // Loading my problem sets
  loadingMore = false; // Loading more problem sets

  searchText: string | null = null; //The search text

  limit = 5; // Paging result limiter
  source: CancelTokenSource | null = null;

  searchTimeout = 0;
  timeout = 500;

  /**
   * Chosen problem set
   * @type: ProblemSet | string | Null
   *  Null means nothing chosen.
   *  Empty string means the same
   *  A string means they chose the option that just adds a new PS.
   *    Use this string to check if any of "my problem sets" contains a name with that string.
   *    If not create a new one, if so then use that one
   */
  ps: ProblemSet | string | null = null;

  get nextPageToken(): string {
    return this.$store.getters['myps/getNextPageToken'];
  }

  get params(): ProblemSetFilterAndPagingParams {
    return {
      limit: this.limit,
    };
  }

  get problemSets(): ProblemSet[] {
    return this.$store.getters['myps/getProblemSets'];
  }

  get isLoadingProblemSets(): boolean {
    return this.loading || this.loadingMore;
  }

  get compValue(): boolean {
    return this.value;
  }

  set compValue(newVal: boolean) {
    this.$emit('input', newVal);
    this.saved = false;
    this.savedPSId = 0;
    // When canceling/closing dialog
    if (newVal === false) {
      this.ps = null;
    }
  }

  psChanged(): void {
    if (typeof this.ps === 'string') {
      //We are given a string. lets see if it's the same as a name of one of our problem sets
      const existingPS = this.problemSets.find((p) => {
        return p.name === this.ps;
      });

      if (existingPS) {
        this.ps = existingPS;
      }
    }
  }

  get addToPSButtonText(): string {
    let buttonText = 'SAVE TO PROBLEM SET';
    if (typeof this.ps === 'string') {
      buttonText = `CREATE & ${buttonText}`;
    }

    return buttonText;
  }

  async addToPS(): Promise<void> {
    if (!this.ps) {
      return;
    }
    this.submitting = true;
    if (typeof this.ps === 'string') {
      //create a new one.
      let children;
      if (Array.isArray(this.pToAdd)) {
        children = this.pToAdd;
      } else {
        children = [this.pToAdd];
      }

      createProblemSet({
        name: this.ps,
        assistmentIds: children,
        ownership: ProblemSetOwnershipType.ME,
      })
        .then((ps: ProblemSet) => {
          this.$store.commit('myps/addProblemSet', ps);
          this.savedPSId = ps.id;
          this.saved = true;
          this.submitting = false;
        })
        .catch(() => {
          this.submitting = false;
        });
    } else {
      //updating

      // Get unique assistmentIds of problems in the problem set
      const children: number[] = await getProblemsByPsIdGroupedByAssistmentId(
        this.ps.id
      ).then((assistments: Problem[][]) => {
        return uniq(
          assistments.map((assistment) => assistment[0].assistmentId)
        );
      });
      if (Array.isArray(this.pToAdd)) {
        for (const p of this.pToAdd) {
          if (!children.includes(p)) {
            children.push(p);
          }
        }
      } else {
        if (!children.includes(this.pToAdd)) {
          children.push(this.pToAdd);
        }
      }

      const psID = this.ps.id;

      updateProblemSet(psID, {
        assistmentIds: children,
      })
        .then(() => {
          this.saved = true;
          this.savedPSId = psID;
          this.submitting = false;
        })
        .catch(() => {
          this.submitting = false;
        });
    }
  }

  goToProblemSet(): void {
    // Must have specific problem set to go to (just edited/created)
    if (!this.savedPSId) {
      // TODO Handle error
      return;
    }
    this.$router.push({
      name: 'editMyPS',
      params: {
        id: `${this.savedPSId}`,
      },
    });
  }

  donwloadProblemSets(): Promise<ProblemSet[]> {
    return this.$store.dispatch('myps/requestMyProblemSets', {
      params: this.params,
      cancelToken: this.source?.token,
    });
  }

  downloadMoreProblemSets(): void {
    this.loadingMore = true;
    this.donwloadProblemSets().then(() => {
      this.loadingMore = false;
    });
  }

  searchForProblemSets(name: string): void {
    this.loadingMore = true;
    this.$store
      .dispatch('myps/requestMyProblemSets', {
        name,
        params: this.params,
        cancelToken: this.source?.token,
      })
      .finally(() => {
        this.loadingMore = false;
      });
  }

  mounted(): void {
    this.loading = true;
    this.donwloadProblemSets().then(() => {
      this.loading = false;
    });
  }

  get numToAdd(): number {
    if (Array.isArray(this.pToAdd)) {
      return this.pToAdd.length;
    } else {
      return 1;
    }
  }

  @Watch('searchText')
  onSearchTextChanged(name: string): void {
    if (!name || name.trim() === '') {
      return;
    }

    if (this.searchTimeout > 0) {
      window.clearTimeout(this.searchTimeout);
      this.searchTimeout = 0;
    }
    if (name) {
      this.searchTimeout = window.setTimeout(() => {
        this.searchForProblemSets(name);
      }, this.timeout);
    }
  }
}
