import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import FindProblemsPage from '../views/FindProblems/FindProblemsPage.vue';
import SearchResults from '../views/FindProblems/SearchResults.vue';
import SearchResultsProblems from '../views/FindProblems/SearchResultsProblems.vue';
import SearchResultsSkillBuilders from '../views/FindProblems/SearchResultsSkillBuilders.vue';
import MyAssignmentsPage from '../views/MyAssignments/MyAssignmentsPage.vue';
import ReportLandingPage from '../views/MyAssignments/ReportLandingPage.vue';
import StudentDetailsPage from '../views/MyAssignments/StudentDetailsPage.vue';
import EssayScoringPage from '../views/MyAssignments/EssayScoringPage.vue';
import MyProblemSetsPage from '../views/MyProblemSets/MyProblemSetsPage.vue';
import EditMyProblemSetPage from '../views/MyProblemSets/EditMyProblemSetPage.vue';
import MyBookmarksPage from '../views/MyBookmarksPage.vue';
import TutoringDashboardPage from '../views/Cignition/TutoringDashboardPage.vue';
import TutoringProblemsByStandardPage from '../views/Cignition/TutoringProblemsByStandardPage.vue';
import TutoringAssignmentsPage from '../views/Cignition/TutoringAssignmentsPage.vue';
import LessonPage from '../views/FindProblems/LessonPage.vue';
import NotFoundPage from '../views/NotFoundPage.vue';
import PassThrough from '../components/base/PassThrough.vue';
import CignitionParent from '../views/Cignition/CignitionParent.vue';
import SettingsPage from '../views/Settings/SettingsPage.vue';
import AccountSettings from '../views/Settings/AccountSettings.vue';
import Preferences from '../views/Settings/Preferences.vue';
import FeatureSettings from '../views/Settings/FeatureSettings.vue';
import UnauthenticatedPage from '../views/UnauthenticatedPage.vue';
import UnauthorizedPage from '../views/UnauthorizedPage.vue';
import { redirectIfUnauthenticated } from '../utils/router.utils';
import LDOEParent from '../views/LDOE/LDOEParent.vue';
import TutorReportPage from '../views/LDOE/TutorReportPage.vue';
import TutorProblemSetsPage from '../views/LDOE/TutorProblemSetsPage.vue';
import TutorProblemsByStandardPage from '../views/LDOE/TutorProblemsByStandardPage.vue';
import StudentAssignmentReportPage from '@/views/MyAssignments/StudentAssignmentReportPage.vue';
import SelectSchool from '../views/AdditionalRegistration/SelectSchool.vue';
import SelectGC from '../views/AdditionalRegistration/SelectGC.vue';
import LtiDeepLinkResponsePage from '../views/LtiDeepLinkResponsePage.vue';
import getAlertMessagesMiddleware from './middleware/getAlertMessages';
import reloadOnChangeMiddleware from './middleware/reloadOnChange';
import InsightsHubParent from '../views/InsightsHub/InsightsHubParent.vue';
import InsightsHubLandingPage from '../views/InsightsHub/InsightsHubLandingPage.vue';
import DashboardParent from '../views/InsightsHub/DashboardParent.vue';
import ActivityPage from '../views/InsightsHub/ActivityPage.vue';
import AchievementPage from '../views/InsightsHub/AchievementPage.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/find' },
  {
    path: '/find',
    component: PassThrough,
    children: [
      {
        path: '',
        name: 'findProblems',
        component: FindProblemsPage,
      },
      {
        path: 'lv/:type/:id/:psid?',
        name: 'LessonPage',
        component: LessonPage,
      },
      {
        path: 'search-skills/:skillId',
        name: 'Search Skills View Problems',
        component: SearchResults,
        children: [
          {
            path: 'problems',
            name: 'searchProblems',
            component: SearchResultsProblems,
          },
          {
            path: 'skill-builders',
            name: 'searchSkillBuilders',
            component: SearchResultsSkillBuilders,
          },
        ],
      },
    ],
  },
  {
    path: '/lti/response',
    name: 'LtiDeepLinkResponsePage',
    component: LtiDeepLinkResponsePage,
  },
  {
    path: '/assignments',
    component: PassThrough,
    beforeEnter: redirectIfUnauthenticated,
    children: [
      {
        path: '',
        name: 'myAssignments',
        component: MyAssignmentsPage,
      },
      {
        path: ':xref/report',
        name: 'ReportLandingPage',
        component: ReportLandingPage,
      },
      {
        path: ':xref/student_details/:studentXref',
        name: 'studentDetailsPage',
        component: StudentDetailsPage,
      },
      {
        path: ':xref/essay_scoring/:problemId',
        name: 'essayScoringPage',
        component: EssayScoringPage,
      },
      {
        path: 'student/:xref/report',
        name: 'StudentAssignmentReportPage',
        component: StudentAssignmentReportPage,
      },
    ],
  },
  {
    path: '/myps',
    component: PassThrough,
    beforeEnter: redirectIfUnauthenticated,
    children: [
      {
        path: '',
        name: 'myProblemSets',
        component: MyProblemSetsPage,
      },
      {
        path: 'edit/:id',
        name: 'editMyPS',
        component: EditMyProblemSetPage,
      },
    ],
  },
  {
    path: '/bookmarks',
    component: MyBookmarksPage,
  },
  {
    path: '/cignition',
    component: CignitionParent,
    children: [
      {
        path: 'report',
        name: 'tutoringDashboard',
        component: TutoringDashboardPage,
      },
      {
        path: 'report/skill/:id/problems',
        name: 'problemsByStandard',
        component: TutoringProblemsByStandardPage,
      },
      {
        path: 'report/assignment/:xref',
        name: 'TutoringAssignments',
        component: TutoringAssignmentsPage,
      },
      // Handles empty /cignition route
      {
        path: '',
        redirect: 'report',
      },
      // Catch all for /cignition/XXX routes
      {
        path: '*',
        redirect: 'report',
      },
    ],
  },
  {
    path: '/ldoe',
    component: LDOEParent,
    children: [
      {
        path: 'report',
        name: 'tutorReport',
        component: TutorReportPage,
      },
      {
        path: 'report/skill/:id/problems',
        name: 'tutorProblemsByStandard',
        component: TutorProblemsByStandardPage,
      },
      {
        path: 'report/problemSet/:problemSetId',
        name: 'tutorProblemSets',
        component: TutorProblemSetsPage,
      },
      // Handles empty /ldoe route
      {
        path: '',
        redirect: 'report',
      },
      // Catch all for /ldoe/XXX routes
      {
        path: '*',
        redirect: 'report',
      },
    ],
  },
  {
    path: '/settings',
    component: SettingsPage,
    children: [
      {
        name: 'accountSettings',
        path: 'account-settings',
        component: AccountSettings,
      },
      {
        name: 'preferencesSettings',
        path: 'preferences',
        component: Preferences,
      },
      {
        name: 'featureSettings',
        path: 'feature-settings',
        component: FeatureSettings,
      },
      // Handles empty /settings route
      {
        path: '',
        redirect: 'account-settings',
      },
      // Catch all for /settings/XXX routes
      {
        path: '*',
        redirect: 'account-settings',
      },
    ],
  },

  {
    path: '/registration',
    component: PassThrough,
    children: [
      {
        path: 'selectSchool',
        name: 'select-school',
        component: SelectSchool,
      },
      {
        path: 'selectGC',
        name: 'select-grade-curriculum',
        component: SelectGC,
      },
    ],
  },
  {
    path: '/insights-hub',
    component: InsightsHubParent,
    beforeEnter: redirectIfUnauthenticated,
    children: [
      {
        path: '',
        name: 'insightsHubLanding',
        component: InsightsHubLandingPage,
      },
      {
        path: 'curricula/:xref/grade-folders/:gradeFolderId',
        component: DashboardParent,
        children: [
          {
            path: 'activity',
            name: 'activityPage',
            component: ActivityPage,
          },
          {
            path: 'achievement',
            name: 'achievementPage',
            component: AchievementPage,
          },
        ],
      },
    ],
  },

  { path: '/401', component: UnauthenticatedPage },
  { path: '/403', component: UnauthorizedPage },
  { path: '/404', component: NotFoundPage },
  { path: '*', redirect: '/404' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Middleware application
router.afterEach(getAlertMessagesMiddleware);
router.beforeEach(reloadOnChangeMiddleware);

export default router;
