import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { auth } from './auth/index';
import { skillList } from './skills/index';
import { search } from './search/index';
import { curricula } from './curriculum/index';
import { classList } from './class/index';
import { myps } from './myps/index';
import { urls } from './urls/index';
import { initializeApp } from '@/api/init.api';
import { cignition } from './cignition/index';
import { ldoe } from './ldoe/index';
import { notifications } from './notifications/index';
import { meta } from './meta/index';
import { attributions } from './attribution';
import { lti } from './lti/index';
import { insightsHub } from './insightshub/index';
import { LmsProviderType } from '@/domain/LmsProviderType';
import getAlertMessages from '../router/middleware/getAlertMessages';
import { isAuthenticated } from '../utils/cookie.util';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  strict: process.env.NODE_ENV !== 'production',

  actions: {
    // Check out async vs non-asyc key word.
    initialize({ commit, dispatch }) {
      if (
        !this.state.auth.hasInitialized &&
        !this.state.auth.isInitializing &&
        isAuthenticated()
      ) {
        commit('auth/setIsInitializing', true);

        return initializeApp()
          .then((resp) => {
            if (resp) {
              if (resp.user?.lmsProviderType === LmsProviderType.LTI_ENABLED) {
                dispatch('lti/requestLaunchedCourseXref');
              }

              commit('auth/setUser', resp.user, { root: true });
              // TODO: Clean this up: currently ignores url sent from server and generates programatically here
              //   should back end omit the url? Is there a reason to send it from back-end?
              // Future plan is to turn these urls into a singlular object OR to have these urls hard-coded in env files
              const tutorTemplateUrl = `https://tutor${process.env.VUE_APP_URL_SUB}.assistments.org/TutorClient/student/index.html`;
              commit('urls/setTutorTemplateUrl', tutorTemplateUrl, {
                root: true,
              });
              commit('auth/setHasInitialized', true);
              getAlertMessages();
            }
          })
          .finally(() => {
            commit('auth/setIsInitializing', false);
          });
      }
      // Nothing to do anymore
      return Promise.resolve(null);
    },
  },
  modules: {
    auth,
    skillList,
    search,
    curricula,
    classList,
    myps,
    urls,
    cignition,
    ldoe,
    notifications,
    meta,
    attributions,
    lti,
    insightsHub,
  },
};

export default new Vuex.Store<RootState>(store);
