
import { Component, Watch, Vue } from 'vue-property-decorator';
import { Problem } from '@/domain/Problem';
import { ParentPage } from '@/components/base/ProblemViewMenu.vue';
import ProblemListView from '@/components/base/ProblemListView.vue';
import AssignBottomBar from '@/components/FindProblems/AssignBottomBar.vue';
import { CurriculumDefinition } from '@/domain/Curriculum';

@Component({
  components: {
    ProblemListView,
    AssignBottomBar,
  },
})
export default class SearchResultsProblems extends Vue {
  selectedProblemIds: number[] = [];
  selectedCurricula: string[] = [];
  parentPage = ParentPage.SEARCH_RESULTS;
  deselectAllProblems = false;

  /**
   * Converts the Map of Curricula in store into flat list of objects
   * Each entry is either a { header: string } shaped object, or Curriculum
   * (A header precedes each block of curricula of a given type e.g. State Test)
   */
  get curriculaFilters(): Array<CurriculumDefinition | { header: string }> {
    let res: Array<CurriculumDefinition | { header: string }> = [];
    const curriculaTypeMap =
      this.$store.getters['curricula/getTypeToCurriculaMap'];

    for (let [type, curricula] of curriculaTypeMap) {
      res.push({ header: type });
      res = [...res, ...curricula];
    }
    return res;
  }
  get problems(): Problem[][] {
    return this.$store.state.search.problems;
  }

  get numTotalProblems(): number {
    return this.problems.length;
  }

  updateSelectedProblemIds(event: number[]): void {
    this.selectedProblemIds = event;
  }
  // When user selects different skill, clear selected problems
  @Watch('$store.state.skillList.selectedSkillId')
  onSelectedSkillIdChange(): void {
    this.deselectAllProblems = true;
  }

  @Watch('selectedCurricula')
  onSelectedCurriculaChange(): void {
    this.$emit('selectedCurriculaChanged', this.selectedCurricula);
  }

  downloadMoreProblems(): void {
    this.$emit('downloadMoreProblems');
  }
}
