
import { Component, Vue } from 'vue-property-decorator';
import { Skill } from '@/domain/Skill';

@Component({})
export default class SearchResultsTopBar extends Vue {
  get skills(): Array<Skill> {
    return this.$store.state.skillList.skills;
  }
  get selectedSkillId(): number | null {
    const skillId = this.$store.getters['skillList/getSelectedSkill'];
    return skillId ? skillId : null;
  }
  get numProblems(): number {
    return this.$store.state.search.numProblems;
  }
  get numSkillBuilders(): number {
    return this.$store.state.search.skillBuilders.length;
  }
  selectSkill(skillId: string): void {
    // Does nothing if skill was cleared
    if (skillId) {
      const currentRouteName = this.$route.name
        ? this.$route.name
        : 'searchProblems';
      this.$router.push({
        name: currentRouteName,
        params: { skillId },
      });
    }
  }
}
