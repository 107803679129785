var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"insights-hub-table neutral--text text--darken-4 text-center align-center",attrs:{"headers":_vm.tableHeaders,"items":_vm.itemRows,"custom-sort":_vm.customSort,"options":_vm.options,"loading":_vm.loading,"hide-default-header":"","hide-default-footer":"","disable-pagination":""},on:{"update:options":function($event){return _vm.updateOptions($event)}},scopedSlots:_vm._u([{key:"header",fn:function({ props, on }){return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.value},[(header.value === _vm.StaticHeader.NAME)?_c('span',{staticClass:"px-6 d-flex align-center text-no-wrap text-subtitle-2 font-weight-medium text--darken-3",class:{
            clickable: !_vm.anonymized,
          },on:{"click":function($event){!_vm.anonymized && on.sort(header.value)}}},[_vm._v(" "+_vm._s(header.text)+" "),(!_vm.anonymized)?_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":"neutral darken-3"}},[_vm._v(" mdi-sort ")]):_vm._e(),_vm._v(" / "+_vm._s(_vm.headerTitle)+" ")],1):(header.value === _vm.rowHeaders.averageHeader.value)?_vm._t("avg-header",function(){return [_c('span',{staticClass:"d-flex align-center justify-center text-caption clickable",on:{"click":function($event){return on.sort(header.value)}}},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":"neutral darken-3"}},[_vm._v(" mdi-sort ")])],1)]},{"on":on,"header":header.value}):_vm._t("data-header",null,{"header":header})],2)}),0)]}},{key:`body.prepend`,fn:function({ headers }){return [_c('tr',{staticClass:"font-weight-bold"},[_vm._l((headers),function(header){return [(header.value === _vm.StaticHeader.NAME)?_c('td',{key:header.value,staticClass:"text-caption text-right"},[_vm._v(" "+_vm._s(_vm.averageRow[_vm.StaticHeader.NAME])+" ")]):[_c('td',{key:header.value,staticClass:"pa-0 ma-0",class:{
              'pr-2': header.value === _vm.rowHeaders.averageHeader.value,
            }},[(_vm.averageRow[header.value])?_vm._t("data-cell",null,{"gradient":_vm.convertHexToRGBA(
                  _vm.color,
                  _vm.averageRow[header.value].numerator /
                    _vm.averageRow[header.value].denominator
                ),"percentage":Math.floor(
                  (_vm.averageRow[header.value].numerator /
                    _vm.averageRow[header.value].denominator) *
                    100
                ),"item":_vm.averageRow,"header":header}):_vm._t("empty-cell")],2)]]})],2)]}},{key:"item",fn:function({ item, headers }){return [_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hiddenPaths.includes(item.path)),expression:"!hiddenPaths.includes(item.path)"}]},[_vm._l((headers),function(header){return [(header.value === _vm.StaticHeader.NAME)?_c('td',{key:header.value,staticClass:"text-body-2 text-left"},[(
              item.type === _vm.RowType.SCHOOL || item.type === _vm.RowType.TEACHER
            )?_c('div',{staticClass:"d-flex align-center clickable",class:{ 'ml-5': item.type === _vm.RowType.TEACHER },on:{"click":function($event){return _vm.togglePath(item.path)}}},[(_vm.collapsedPaths.includes(item.path))?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-down ")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-up")]),(item.type === _vm.RowType.SCHOOL)?_c('span',{staticClass:"ml-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.anonymized ? 'SXXXXX' : item[_vm.StaticHeader.NAME])+" ")]):_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.anonymized ? 'TXXXXX' : item[_vm.StaticHeader.NAME])+" ")])],1):_c('div',{staticClass:"course-name-cell text-right"},[_vm._v(" "+_vm._s(_vm.anonymized ? 'CXXXXX' : item[_vm.StaticHeader.NAME])+" ")])]):[_c('td',{key:header.value,staticClass:"pa-0 ma-0",class:{
              'font-weight-bold':
                header.value === _vm.rowHeaders.averageHeader.value,
              'pr-2': header.value === _vm.rowHeaders.averageHeader.value,
              'pt-2': item.type === _vm.RowType.SCHOOL,
            }},[(item[header.value])?_vm._t("data-cell",null,{"gradient":_vm.convertHexToRGBA(
                  _vm.color,
                  item[header.value].numerator /
                    item[header.value].denominator
                ),"percentage":Math.floor(
                  (item[header.value].numerator /
                    item[header.value].denominator) *
                    100
                ),"item":item,"header":header}):_vm._t("empty-cell")],2)]]})],2)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }