import { quickCommentsAxios } from '@/plugins/axios';
import { QuickComment } from '@/domain/QuickComment';

const END_POINT = '/generate';

//////////
// DTOs //
//////////

export interface QuickCommentDTO {
  user_ID: string;
  comments: Array<string[]>;
  grade: number;
}

export interface QuickCommentsRequestBody {
  userId: string;
  problemId: number;
  teacherId: number;
  answerText: string;
  numOfComments: number;
}

export interface RequestParams {
  body: Array<QuickCommentsRequestBody>;
}

const getQuickComments = (
  requestParams: RequestParams
): Promise<Array<QuickComment>> => {
  const payload = requestParams.body.map((body) => ({
    user_ID: body.userId,
    problem_ID: body.problemId,
    teacher_ID: body.teacherId,
    answer_text: body.answerText,
    num_comments: body.numOfComments,
  }));

  return quickCommentsAxios.post(`${END_POINT}`, payload).then((result) => {
    return buildQuickComments(result.data.students_info);
  });
};

function buildQuickComments(
  quickCommentsDTOs: Array<QuickCommentDTO>
): Array<QuickComment> {
  return quickCommentsDTOs.map((dto) => {
    return {
      userXref: dto.user_ID,
      comments: dto.comments,
      suggestedGrade: dto.grade,
    };
  });
}

export { getQuickComments };
