import { getContext } from './../../api/core/lti.api';
import { RootState } from '@/store/types';
import { LTIState } from './types';
import { ActionTree } from 'vuex';

export const actions: ActionTree<LTIState, RootState> = {
  requestLaunchedCourseXref(context): Promise<string> {
    return getContext().then((courseXref) => {
      context.commit('setCurrentlyLaunchedCourseXref', courseXref);
      return courseXref;
    });
  },
};
