import { DataTableHeader } from 'vuetify';

const globalHeaderOptions: Partial<DataTableHeader> = {
  align: 'start',
  class: 'text-subtitle-2', // apply class to header
  sortable: false,
  cellClass: 'text-body-2', // apply class to body cells
};

/**
 * Appends the global header options to an individual header using spread syntax
 * When 'header' config conflicts with globalHeaderOptions, take value from 'header'
 * @param header: DataTableHeader is the header to be updated
 * @returns DataTableHelper that includes the config from this.globalHeaderOptions
 */
export function appendGlobalHeaderOptions(
  header: DataTableHeader
): DataTableHeader {
  return { ...globalHeaderOptions, ...header };
}
