import { LmsProviderType } from './LmsProviderType';

// FIXME: To be deprecated
export interface Assignment {
  xref: string;
  classXref: string;
  problemSetId: number;
  name: string;
  releaseDate: number;
  dueDate: number | null;
  type?: AssignmentType;
  lmsPtype: LmsProviderType | null;
  skillIds?: Array<number>;
}

export interface AssignmentDetail {
  studentCompletedCount: number;
  studentAssignedCount: number;
  problemCount: number;
}

export interface AssignmentDefinition {
  scope: AssignmentScope;
  assigneeType: AssigneeType;
  xref: string;
  assigneeXref: string;
  ownerXref: string;
  groupContextXref: string;
  problemSetCeri: string;
  lmsProviderType: LmsProviderType | null;
  name: string;
  assignDate: number;
  releaseDate: number;
  dueDate: number | null;
  active: boolean;
  properties?: Array<AssignmentProperty>;
}

export interface AssignmentList {
  assignments: Array<AssignmentDefinition>;
  nextPageToken: string | null;
}

export interface AssignmentProperty {
  categoryName: string;
  categoryDescription: string;
  propertyKey: string;
  propertyDescription: string;
  propertyValue: string;
}

export interface AssignmentSort {
  attribute: keyof AssignmentDefinition;
  sortBy: AssignmentSortByField;
}

export enum AssignmentType {
  PROBLEM_SET = 'PROBLEM SET',
  EXIT_TICKET = 'EXIT TICKET',
  HOMEWORK = 'HOMEWORK',
  // etc. TBD
}

export enum AssignmentScope {
  USER = 'USER',
  PRINCIPAL_GROUP = 'PRINCIPAL_GROUP',
  NOT_A_PRINCIPAL = 'NOT_A_PRINCIPAL',
}

export enum AssigneeType {
  INDIVIDUAL = 'INDIVIDUAL',
  INDIVIDUAL_IN_GROUP = 'INDIVIDUAL_IN_GROUP',
  GROUP = 'GROUP',
}

export enum AssignmentStatus {
  UPCOMING = 'UPCOMING',
  RELEASED = 'RELEASED',
  PAST_DUE = 'PAST_DUE',
}

export type AssignmentSortByField =
  | '+DUE_DATE'
  | '-DUE_DATE'
  | '+RELEASE_DATE'
  | '-RELEASE_DATE';

/**
 * These enum values are used on the back-end,
 * but the server sends the keys rather than values.
 * The above fix can be rolled back if the server
 * is switched to send enum values, rather than keys
 */
// export enum ActionType {
// PROBLEM_SET = 1,
// EXIT_TICKET = 2,
// HOMEWORK = 3,
// }
