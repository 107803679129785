import { ActionTree } from 'vuex';
import { ClassState } from './types';
import { RootState } from '../types';
import { getClasses } from '@/api/class.api';
import { Class, ClassWrapper, ImportFilter } from '@/domain/Class';

export const actions: ActionTree<ClassState, RootState> = {
  requestClasses(context, forceRedownload = false): Promise<Array<Class>> {
    if (
      forceRedownload ||
      (!context.state.hasDownloaded && !context.state.isDownloading)
    ) {
      // Let the component decide when to load or reload classes
      // Update loading state here to notify other instances or components if
      // we do perform such operation rather than having the calling component do
      // this kind of update?
      context.commit('setIsDownloading', true);

      return getClasses({ importFilter: ImportFilter.IMPORTED }).then(
        (classes: ClassWrapper) => {
          const importedClasses = classes.importedClasses;
          if (importedClasses) {
            context.commit('setHasDownloaded', true);
            context.commit('setIsDownloading', false);
            context.commit('setClassList', importedClasses);

            return Promise.resolve(importedClasses);
          } else {
            return Promise.resolve([]);
          }
        }
      );
    }

    return Promise.resolve(context.state.classes);
  },
};
