// One per submission, hint, or request for answer by a single student
// on a single problem
export interface StudentProblemAction {
  type: ActionType;
  value: string; // Answer submitted or hint received (html)
}

/////////////////
// CAS Web API //
/////////////////

// TODO: Deprecate StudentProblemAction
export interface VNextAction {
  actionType: ActionType;
  activeTutoringType?: string; // FIXME: Enum type?
  concreteProblemType?: string; // FIXME: Enum type?
  maxAttemptsAndHints?: number;
  showCorrectness?: boolean;
  cbviewable?: boolean;
  pcseligible?: boolean;
  timestamp: number;
  pathInfo: string;
  scaffolding: boolean;
  discreteScore: number;
  continuousScore: number;
  ofTotalHints: number;
  hintNumber: number;
  // STUDENT_SUBMISSION or STUDENT_RESPONSE
  // HINT_REQUESTED_ACTION
  hintId?: number;
  // EXPLANATION_REQUESTED_ACTION
  explanationId: number;
  response?: string; // student's answer as html
  correct?: boolean;
  work?: string;
  // SCAFFOLDING_REQUESTED_ACTION
  scaffoldingId: number;
}

export enum ActionType {
  // Assignment Actions
  ASSIGNMENT_STARTED_ACTION = 'ASSIGNMENT_STARTED_ACTION',
  ASSIGNMENT_RESUMED_ACTION = 'ASSIGNMENT_RESUMED_ACTION',
  ASSIGNMENT_FINISHED_ACTION = 'ASSIGNMENT_FINISHED_ACTION',
  USER_SELECTED_CONTINUE_ACTION = 'USER_SELECTED_CONTINUE_ACTION',
  // Problem Set Actions
  PROBLEM_SET_STARTED_ACTION = 'PROBLEM_SET_STARTED_ACTION',
  PROBLEM_SET_RESUMED_ACTION = 'PROBLEM_SET_RESUMED_ACTION',
  PROBLEM_SET_FINISHED_ACTION = 'PROBLEM_SET_FINISHED_ACTION',
  PROBLEM_SET_MASTERED_ACTION = 'PROBLEM_SET_MASTERED_ACTION',
  PROBLEM_SET_EXHAUSTED_ACTION = 'PROBLEM_SET_EXHAUSTED_ACTION',
  PROBLEM_LIMIT_EXCEEDED_ACTION = 'PROBLEM_LIMIT_EXCEEDED_ACTION',
  // Problem Actions
  PROBLEM_STARTED_ACTION = 'PROBLEM_STARTED_ACTION',
  PROBLEM_RESUMED_ACTION = 'PROBLEM_RESUMED_ACTION',
  PROBLEM_FINISHED_ACTION = 'PROBLEM_FINISHED_ACTION',
  // Tutoring Set Actions
  TUTORING_SET_STARTED_ACTION = 'TUTORING_SET_STARTED_ACTION',
  TUTORING_SET_RESUMED_ACTION = 'TUTORING_SET_RESUMED_ACTION',
  TUTORING_SET_FINISHED_ACTION = 'TUTORING_SET_FINISHED_ACTION',
  // Tutoring Request Actions
  HINT_REQUESTED_ACTION = 'HINT_REQUESTED_ACTION',
  SCAFFOLDING_REQUESTED_ACTION = 'SCAFFOLDING_REQUESTED_ACTION',
  URL_LINK_REQUESTED_ACTION = 'URL_LINK_REQUESTED_ACTION',
  EXPLANATION_REQUESTED_ACTION = 'EXPLANATION_REQUESTED_ACTION',
  ANSWER_REQUESTED_ACTION = 'ANSWER_REQUESTED_ACTION',
  // Response Actions
  STUDENT_RESPONSE_ACTION = 'STUDENT_RESPONSE_ACTION',
  STUDENT_SUBMISSION_ACTION = 'STUDENT_SUBMISSION_ACTION',
  WORK_SUBMITTED_ACTION = 'WORK_SUBMITTED_ACTION',
  IM_STUCK_REQUEST_ACTION = 'IM_STUCK_REQUEST_ACTION',
  LIVE_TUTORING_REQUESTED_ACTION = 'LIVE_TUTORING_REQUESTED_ACTION',
  // Timer actions
  TIMER_STARTED_ACTION = 'TIMER_STARTED_ACTION',
  TIMER_RESUMED_ACTION = 'TIMER_RESUMED_ACTION',
  TIMER_PAUSED_ACTION = 'TIMER_PAUSED_ACTION',
  TIMER_FINISHED_ACTION = 'TIMER_FINISHED_ACTION',
}
