
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import TreeGradientTable, {
  GradientHeaders,
  GradientRows,
} from '../base/TreeGradientTable.vue';

@Component({
  components: { TreeGradientTable },
})
export default class HeatMap extends Vue {
  @Prop({ required: true }) color: string;
  @Prop({ required: true }) headerTitle: string;
  @Prop({ required: true }) rowHeaders: GradientHeaders;
  @Prop({ required: true }) rows: GradientRows;
  @Prop({ default: [] }) collapsedPaths: string[];
  @Prop({ required: false }) options: DataOptions;
  @Prop({ default: false }) anonymized: boolean;
  @Prop({ default: false }) link: boolean;
  @Prop({ default: false }) loading: boolean;
}
