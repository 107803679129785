
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Problem } from '@/domain/Problem';
import Editor from '@tinymce/tinymce-vue';
import editorConfig from '@/utils/studentSupportAuthoringConfig.util';

@Component({
  components: {
    Editor,
  },
})
export default class SupportEditor extends Vue {
  @Prop() value: boolean; // Whether the dialog is open or not
  @Prop() problem: Problem;
  @Prop({ default: '' }) supportContent: string;
  @Prop() title: string;

  showEditor = false;
  editorData = editorConfig;

  supportTypeSelected = null;
  selectedTimeFrame: string;

  content = '';

  created() {
    this.content = this.supportContent;
  }

  publish() {
    this.$emit('publish', this.content);
    this.cancelEditor();
  }

  cancelEditor() {
    this.$emit('cancelEditor');
  }

  deleteThis() {
    this.$emit('remove');
    this.cancelEditor();
  }
}
