import { CombinedProblemSetNameProblemSetRootFolderName } from '@/domain/FolderInfo';
import { tngAxios } from '@/plugins/axios';
import { Problem, ProblemList } from '@/domain/Problem';
import { ProblemSet, PsType, ProblemSetList } from '@/domain/ProblemSet';
import { addPartLetters, addProblemNumber } from '@/utils/problem.util';
import { CancelToken } from 'axios';

//////////
// DTOs //
//////////

export enum ProblemSetOwnershipType {
  ME = 'ME',
  SYSTEM = 'SYSTEM',
}

export interface ProblemSetDTO {
  id: number;
  xref: string;
  name: string;
  type: string;
  isSkillBuilder: boolean;
  created?: number; // Unix Timestamp
  lastModifiedByTeacher?: number; //Unix Timestamp
  skillIds?: Array<number>; // Set of skill ids from constituent problems
  children?: Array<number | ProblemSet>;
}

export interface PersistProblemSetDTO {
  name: string;
  assistmentIds: Array<number>;
  type?: string | null; // Defaults to LINEAR_COMPLETE_ALL
  ownership: ProblemSetOwnershipType;
}

export interface ProblemsFilterAndPagingParams {
  skillId: number;
  limit?: number;
  extraFields?: Array<string>;
  curricula?: Array<number>;
  nextPageToken: string | null;
}

const END_POINT = '/content';
const PS_END_POINT = `${END_POINT}/problemSets`;
const PR_END_POINT = `${END_POINT}/problems`;

export interface ProblemSetFilterAndPagingParams {
  limit?: number;
  nextPageToken?: string;
  extraFields?: Array<string>;
}

/////////////////////////
// Problems Controller //
/////////////////////////

/**
 * Requests problems within a problem set
 * from server by problemSetId.
 * Can optionally specify extra fields to include
 *
 * @param psId = id of the problem set
 * @param extraFields = list of extra fields to include
 *  @default = ['ANSWERS']
 *  values permitted: 'ANSWERS', 'PARENT_PROBLEM_SETS', 'CURRICULA', 'ATTRIBUTIONS', 'SKILLS'
 */
const getProblemsByPsIdGroupedByAssistmentId = (
  psId: number,
  extraFields: string[] = ['ANSWERS', 'ATTRIBUTIONS']
): Promise<Problem[][]> => {
  return tngAxios
    .get(`${PR_END_POINT}`, {
      params: {
        problemSetId: psId,
        extraFields,
      },
    })
    .then((result) => {
      return transformProblemList(result.data);
    });
};

/**
 * Requests problems within a problem set from server by problemSetId.
 * Problems are ordered based on their positions in the sequence and their
 * custom attributes are set accordingly.
 *
 * @param psId = id of the problem set
 * @param extraFields = list of extra fields to include
 * @default = ['ANSWERS']
 *  values permitted: 'ANSWERS', 'PARENT_PROBLEM_SETS', 'CURRICULA', 'ATTRIBUTIONS', 'SKILLS'
 */
const getProblemsByPsXref = (
  problemSetXref: string,
  extraFields: string[] = ['ANSWERS', 'ATTRIBUTIONS']
): Promise<Problem[]> => {
  return tngAxios
    .get(`${PR_END_POINT}`, {
      params: {
        problemSetXref,
        extraFields,
      },
    })
    .then((result) => {
      addCustomAttributes(result.data);
      return result.data;
    });
};

/**
 * Requests all problems matching a skill
 *
 * @param skId id of requested skill
 */
const getProblemsBySkill = (skId: number): Promise<Problem[][]> => {
  return tngAxios
    .get(`${PR_END_POINT}`, {
      params: {
        skillIds: skId,
        extraFields: [
          'ANSWERS',
          'PARENT_PROBLEM_SETS',
          'CURRICULA',
          'ATTRIBUTIONS',
        ],
      },
      timeout: 30000, // TODO: reduce when endpoint is performant
    })
    .then((result) => {
      return transformProblemList(result.data);
    });
};

///////////////////////////
// ProblemSetsController //
///////////////////////////
const getMyProblemSets = (
  params: ProblemSetFilterAndPagingParams,
  cancelToken?: CancelToken
): Promise<ProblemSetList> => {
  return tngAxios
    .get(`${PS_END_POINT}/me`, {
      params,
      cancelToken,
    })
    .then((res) => {
      return {
        nextPageToken: res.data.nextPageToken,
        problemSets: buildProblemSets(res.data.problemSets),
      };
    });
};

const getMyProblemSetsByName = (
  name: string,
  params: ProblemSetFilterAndPagingParams,
  cancelToken?: CancelToken
): Promise<ProblemSetList> => {
  return tngAxios
    .get(`${PS_END_POINT}/me/search`, {
      params: {
        name,
        ...params,
      },
      cancelToken,
    })
    .then((res) => {
      return {
        nextPageToken: res.data.nextPageToken,
        problemSets: buildProblemSets(res.data.problemSets),
      };
    });
};

const getMyProblemSetById = (psId: number): Promise<ProblemSet> => {
  return tngAxios.get(`${PS_END_POINT}/me/search/${psId}`).then((res) => {
    return buildProblemSet(res.data);
  });
};

const getProblemSet = (psId: number): Promise<ProblemSet> => {
  return tngAxios
    .get(`${PS_END_POINT}`, {
      params: {
        ids: psId,
      },
    })
    .then((result) => {
      return buildProblemSet(result.data[0]);
    });
};

const getProblemSetByXref = (
  xref: string,
  extraFields?: string[],
  cancelToken?: CancelToken
): Promise<ProblemSet> => {
  return tngAxios
    .get(`${PS_END_POINT}`, {
      params: {
        xrefs: xref,
        extraFields: extraFields,
      },
      cancelToken,
    })
    .then((result) => {
      return buildProblemSet(result.data[0]);
    });
};

const getProblemSets = (psIds: Array<number>): Promise<Array<ProblemSet>> => {
  return tngAxios
    .get(`${PS_END_POINT}`, {
      params: {
        ids: psIds,
      },
    })
    .then((result) => {
      return buildProblemSets(result.data);
    });
};

const getSkillBuildersBySkill = (skId: number): Promise<ProblemSet[]> => {
  return tngAxios
    .get(`${PS_END_POINT}`, {
      params: {
        skillIds: skId,
        skillBuilderOnly: true,
        // includeChildren: IncludeChildren.NO_CHILDREN,
      },
    })
    .then((result) => {
      if (result.data) {
        return result.data.map((dto: ProblemSetDTO) => buildProblemSet(dto));
      }

      return null;
    });
};
const getProblemSetNamesAndRootFolders = (
  psIds: number[]
): Promise<Array<CombinedProblemSetNameProblemSetRootFolderName>> => {
  return tngAxios
    .get('/metadata/combinedProblemSetNamesRootFolders', {
      params: {
        sequenceIds: psIds,
      },
    })
    .then((result) => {
      return Promise.resolve(result.data);
    });
};

const getPsIdFromPsa = (psa: string): Promise<string> => {
  return tngAxios
    .get(`/metadata/ids/problemSet`, {
      params: {
        psa: psa,
      },
    })
    .then((result) => {
      return result.data;
    });
};

const duplicateProblemSet = (psId: number): Promise<ProblemSet> => {
  return tngAxios.post(`${PS_END_POINT}/${psId}/copy`, null).then((res) => {
    return res.data;
  });
};
const deleteProblemSet = (psId: string): Promise<void> => {
  return tngAxios.delete(`${PS_END_POINT}/${psId}`);
};
//Figure out something better than REcord string unknown
const updateProblemSet = (
  psId: number,
  psUpdateObject: Record<string, unknown>
): Promise<void> => {
  return tngAxios
    .post(`${PS_END_POINT}/${psId}`, psUpdateObject)
    .then((result) => {
      return result.data;
    });
};

const renameProblemSet = (psId: number, newName: string): Promise<void> => {
  return updateProblemSet(psId, { name: newName });
};

//Same as above. figure out something better than record string unknown
const createProblemSet = (
  psObject: PersistProblemSetDTO
): Promise<ProblemSet> => {
  return tngAxios.put(`${PS_END_POINT}`, psObject).then((result) => {
    return buildProblemSet(result.data);
  });
};

const searchForProblemsBySkill = (
  params: ProblemsFilterAndPagingParams,
  cancelToken?: CancelToken
): Promise<ProblemList> => {
  return tngAxios
    .get(`${PR_END_POINT}/search/${params.skillId}`, {
      params,
      cancelToken,
    })
    .then((res) => {
      return {
        nextPageToken: res.data.nextPageToken,
        problems: transformProblemList(res.data.problems),
        numProblems: res.data.numProblems,
      };
    });
};

function buildProblemSets(psDtos: ProblemSetDTO[]): ProblemSet[] {
  return psDtos.map((psDto) => {
    return buildProblemSet(psDto);
  });
}

function buildProblemSet(psDto: ProblemSetDTO): ProblemSet {
  return {
    id: psDto.id,
    xref: psDto.xref,
    name: psDto.name,
    type: PsType.find(psDto.type),
    isSkillBuilder: psDto.isSkillBuilder,
    created: psDto.created,
    lastModifiedByTeacher: psDto.lastModifiedByTeacher,
    skillIds: psDto.skillIds,
  };
}

/**
 * Helpers
 */

function transformProblemList(problems: Problem[]): Problem[][] {
  // Add problem number based on position of problem's assistment id in list
  addProblemNumber(problems);

  const problemsGroupedByAssistmentIds = [];
  let ast: Array<Problem> = [];
  let assistmentId = 0;
  // Group problems by assistmentId into 2D array
  for (const p of problems) {
    if (p.assistmentId !== assistmentId) {
      ast = [p];
      assistmentId = p.assistmentId;
      problemsGroupedByAssistmentIds.push(ast);
    } else {
      ast.push(p);
    }
  }

  // Add part letter if multipart problem
  for (const p of problemsGroupedByAssistmentIds) {
    // Multipart
    if (p.length > 1) {
      addPartLetters(p);
    }
  }

  return problemsGroupedByAssistmentIds;
}

function addCustomAttributes(problems: Array<Problem>): void {
  addPartLetters(problems);
  addProblemNumber(problems);
}

export {
  getProblemsByPsIdGroupedByAssistmentId,
  getProblemsByPsXref,
  getProblemSet,
  getProblemSetByXref,
  getProblemSets,
  getProblemsBySkill,
  getSkillBuildersBySkill,
  getProblemSetNamesAndRootFolders,
  getPsIdFromPsa,
  getMyProblemSets,
  getMyProblemSetsByName,
  getMyProblemSetById,
  renameProblemSet,
  duplicateProblemSet,
  deleteProblemSet,
  updateProblemSet,
  buildProblemSet,
  createProblemSet,
  searchForProblemsBySkill,
};
