
import { Component, Vue, Watch } from 'vue-property-decorator';
import axios, { CancelTokenSource } from 'axios';
import { FolderInfo } from '@/domain/FolderInfo';
import { TimeSelector, TimeSelectorType } from '@/domain/Time';
import { CurriculumDefinition } from '@/domain/Curriculum';
import { getSchoolYears } from '@/utils/InsightsHub/schoolyear.util';

@Component({})
export default class InsightsHubLandingPage extends Vue {
  downloading = false;
  source: CancelTokenSource | null = null;
  supportedCurricula = [
    'cb031912-8c16-1d17-53be-d5473fcc3e4f',
    'a9beff09-6e2a-8eef-d36a-4ebac161ca69',
    '619db8db-5e63-5391-ed95-83d658597d13',
  ];
  schoolYearChoices: { text: string; value: TimeSelector }[] = [];

  get curriculaChoices(): CurriculumDefinition[] {
    const curricula = this.$store.state.curricula.downloadedDefinitions;

    return curricula.filter((c: CurriculumDefinition) =>
      this.supportedCurricula.includes(c.xref)
    );
  }

  // Selected Curriculum
  timeSelector: TimeSelector | null = null;
  get selectedCurriculumXref(): string | null {
    return this.$store.state.insightsHub.selectedCurriculumXref;
  }
  set selectedCurriculumXref(xref: string | null) {
    this.$store.commit('insightsHub/setSelectedCurriculumXref', xref);
  }

  get gradeFolders(): FolderInfo[] {
    return this.$store.state.insightsHub.gradeFolders;
  }

  clearSelections(): void {
    this.$store.commit('insightsHub/setSelectedModuleId', null);
    this.$store.commit('insightsHub/setSelectedMode', null);
    this.$store.commit('insightsHub/setSelectedPsTypes', []);
    this.$store.commit('insightsHub/setSelectedAssessmentType', null);
    this.$store.commit('insightsHub/setAnonymized', false);
    this.$store.commit('insightsHub/setSelectedTab', null);
    this.$store.commit('insightsHub/setCollapsedPaths', []);
    this.$store.commit('insightsHub/setOptions', {});
  }

  created(): void {
    this.clearSelections();

    const schoolYears = getSchoolYears();

    for (const schoolYear of schoolYears) {
      this.schoolYearChoices.push({
        text: `${schoolYear.startDateTime.format(
          'YY'
        )}/${schoolYear.endDateTime.format('YY')}`,
        value: new TimeSelector(
          TimeSelectorType.INCLUSIVE,
          schoolYear.startDateTime.valueOf(),
          schoolYear.endDateTime.valueOf()
        ),
      });
    }

    this.timeSelector =
      this.$store.state.insightsHub.timeSelector ??
      this.schoolYearChoices[0].value;

    if (this.selectedCurriculumXref === null) {
      // Default to one
      this.selectedCurriculumXref = this.supportedCurricula[0];
    }
  }

  @Watch('selectedCurriculumXref')
  onCurriculumChange(): void {
    // Stop whatever was previously not downloaded (or downloading)
    if (this.source) {
      // Cancel prior requests with this cancel token
      this.source.cancel();
    }

    this.downloading = true;
    this.source = axios.CancelToken.source();

    // Request Grade Folders
    this.$store
      .dispatch('insightsHub/getCurriculumGradeFolders', this.source?.token)
      .then(() => {
        this.downloading = false;
      });
  }
}
