import { ldoeAxios } from '@/plugins/axios';
import { User } from '@/domain/User';

const END_POINT = '/students';

const getStudentsInAllClasses = (): Promise<User[]> => {
  return ldoeAxios.get(`${END_POINT}`).then((result) => {
    return result.data;
  });
};

export { getStudentsInAllClasses };
