
import ScoreChip from './ScoreChip.vue';
import { Problem } from '@/domain/Problem';
import { ProblemLog } from '@/domain/ReportData/AssignmentData';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableCompareFunction, DataTableHeader } from 'vuetify';
import {
  sortBySortableNumber,
  sortBySortableString,
} from '@/utils/table.utils';

interface StudentAssignmentReportTableRow {
  problemId: number;
  problemName: string;
  score: number;
  classAverage: string;
  hintCount: number;
  endTime: number;
}

@Component({
  components: {
    ScoreChip,
  },
})
export default class StudentAssignmentReportTable extends Vue {
  @Prop({ required: true }) problemLogs: Array<ProblemLog>;
  @Prop({ required: true }) problemIdToProblemMap: Map<number, Problem>;
  @Prop({ required: true }) problemIdToClassAverageScoreMap: Map<
    number,
    number
  >;

  headers: Array<DataTableHeader> = [
    {
      text: 'Problem',
      value: 'problemName',
      sort: sortBySortableString,
    },
    {
      text: 'Score',
      value: 'score',
      sort: sortBySortableNumber,
    },
    {
      text: 'Class Average',
      value: 'classAverage',
    },
    {
      text: 'Hint Usage',
      value: 'hintCount',
      sort: sortBySortableNumber,
    },
  ].map(this.buildTableHeader);

  get rows(): Array<StudentAssignmentReportTableRow> {
    const rows: Array<StudentAssignmentReportTableRow> = [];

    this.problemLogs.forEach((prLog) => {
      rows.push(this.buildTableRow(prLog));
    });

    return rows;
  }

  openProblemSideSheet(problemId: number): void {
    this.$emit('openProblemSideSheet', problemId);
  }

  private buildTableRow(prLog: ProblemLog): StudentAssignmentReportTableRow {
    const problem = this.problemIdToProblemMap.get(
      prLog.problemDbid
    ) as Problem;

    const problemName = this.getProblemNameForDisplay(problem);
    const score = this.getScoreForDisplay(prLog.continuousScore);
    const hintCount = prLog.hintCount;
    const endTime = prLog.endTime;
    const classAverage = this.getScoreForDisplay(
      this.problemIdToClassAverageScoreMap.get(problem.id) as number
    );

    return {
      problemId: problem.id,
      problemName,
      score,
      hintCount,
      endTime,
      classAverage: `${classAverage}%`,
    };
  }

  private getScoreForDisplay(score: number): number {
    return Math.round(score * 100);
  }

  private getProblemNameForDisplay(problem: Problem): string {
    return problem.partLetter
      ? `P${problem.assistmentPosition}: ${problem.partLetter}`
      : `P${problem.assistmentPosition}`;
  }

  private buildTableHeader(partialHeader: {
    text: string;
    value: string;
    sort?: DataTableCompareFunction;
  }): DataTableHeader {
    const header: DataTableHeader = {
      text: partialHeader.text,
      value: partialHeader.value,
      sortable: true,
      align: 'center',
      class: [
        'text-no-wrap',
        'sticky-row',
        'sticky-row-1',
        'text-subtitle-2',
        'neutral--text text--darken-3',
        'neutral lighten-3',
      ],
    };

    if (partialHeader.sort) {
      header.sort = partialHeader.sort;
    }

    return header;
  }
}
