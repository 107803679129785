
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { CombinedProblemSetNameProblemSetRootFolderName } from '@/domain/FolderInfo';
import { getProblemSetNamesAndRootFolders } from '@/api/pspr.api';

@Component({})
export default class GoToProblemSetDialog extends Vue {
  @Prop({ required: true }) value: boolean; // Displays dialog
  @Prop({ required: true }) parentProblemSetIds: Array<number>;

  loading = false;
  hasDownloadedProblemSets = false;
  parentProblemSets: Array<CombinedProblemSetNameProblemSetRootFolderName> = [];

  get showDialog(): boolean {
    return this.value;
  }
  set showDialog(val: boolean) {
    this.$emit('input', val);
  }

  @Watch('value') // if `value` is true, then the dialog's opening
  goToProblemSet(isDialogOpen: boolean): void {
    if (isDialogOpen) {
      // Check if there's more than one ID in the array
      this.downloadParentProblemSets();
    }
  }

  downloadParentProblemSets(): void {
    if (this.hasDownloadedProblemSets) {
      return;
    }

    this.loading = true;

    getProblemSetNamesAndRootFolders(this.parentProblemSetIds)
      .then((results) => {
        this.parentProblemSets = results;
      })
      .catch(() => {
        // TODO: Handle error
      })
      .finally(() => {
        this.loading = false;
        this.hasDownloadedProblemSets = true;
      });
  }
}
