import { Problem } from './Problem';

export interface ProblemSet {
  id: number;
  xref: string;
  name: string;
  type: PsType;
  isSkillBuilder: boolean;
  created?: number; // Unix Timestamp
  lastModifiedByTeacher?: number; //Unix Timestamp
  skillIds?: Array<number>; // set of skill ids from constituent problems
  children?: (Problem | ProblemSet)[];
}

export interface ProblemSetList {
  problemSets: Array<ProblemSet>;
  nextPageToken: string | null;
}

export class PsType {
  // TODO: Is ANY needed?
  public static readonly ANY = new PsType('ANY', 'ANY');
  public static readonly LINEAR_COMPLETE_ALL = new PsType(
    'LINEAR_COMPLETE_ALL',
    'Linear'
  );
  public static readonly RANDOM_COMPLETE_ALL = new PsType(
    'RANDOM_COMPLETE_ALL',
    'Random'
  );
  public static readonly MULTI_PART_PROBLEM_SET = new PsType(
    'MULTI_PART_PROBLEM_SET',
    'Multi-Part'
  );
  public static readonly SKILL_BUILDER_RANDOM = new PsType(
    'SKILL_BUILDER_RANDOM',
    'Skillbuilder'
  );
  public static readonly SKILL_BUILDER_LINEAR = new PsType(
    'SKILL_BUILDER_LINEAR',
    'Linear Skillbuilder'
  );
  public static readonly RANDOM_COMPLETE_ONE = new PsType(
    'RANDOM_COMPLETE_ONE',
    'Random (One)'
  );
  public static readonly IF_THEN_ELSE = new PsType(
    'IF_THEN_ELSE',
    'If Then Else'
  );
  public static readonly STUDENT_CHOICE = new PsType(
    'SKIPPABLE',
    'Student Choice'
  );
  public static readonly PLACEMENTS = new PsType('PLACEMENTS', 'Placements');

  public static readonly RANDOM_CHILD_ORDER = new PsType(
    'RANDOM_CHILD_ORDER',
    'Random'
  );
  public static readonly RANDOM_ITERATE = new PsType(
    'RANDOM_ITERATE',
    'Random'
  );
  public static readonly GROUP_SEQUENCES = new PsType(
    'GROUP_SEQUENCES',
    'Random'
  );

  readonly id: string;
  readonly displayName: string;

  private constructor(id: string, displayName: string) {
    this.id = id;
    this.displayName = displayName;
  }

  static get values(): PsType[] {
    return [
      this.ANY,
      this.LINEAR_COMPLETE_ALL,
      this.SKILL_BUILDER_RANDOM,
      this.SKILL_BUILDER_LINEAR,
      this.STUDENT_CHOICE,
      this.RANDOM_COMPLETE_ALL,
      this.RANDOM_COMPLETE_ONE,
      this.MULTI_PART_PROBLEM_SET,
      this.IF_THEN_ELSE,
      this.PLACEMENTS,
      this.RANDOM_CHILD_ORDER,
      this.RANDOM_ITERATE,
      this.GROUP_SEQUENCES,
    ];
  }

  //Figure out if this is what we want...
  public static find(id: string): PsType {
    for (const l of PsType.values) {
      if (l.id === id) {
        return l;
      }
    }
    throw new Error(`Unknown psType: ${id}`);
  }

  public toJSON(): string {
    return this.id;
  }
}

export enum IncludeChildren {
  NO_CHILDREN = 0,
  AS_IDS = 1,
  AS_OBJECTS = 2,
}
