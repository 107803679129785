// Want to change or update something?
// Stashing changes synchronously commit mutation
import { MutationTree } from 'vuex';
import { User } from '@/domain/User';
import { CignitionState } from './types';
import { TimeFrame } from '@/domain/Time';

export const mutations: MutationTree<CignitionState> = {
  setHasDownloadedTutees(state: CignitionState, flag: boolean) {
    state.hasDownloadedTutees = flag;
  },
  setIsDownloadingTutees(state: CignitionState, flag: boolean) {
    state.isDownloadingTutees = flag;
  },
  setTutees(state: CignitionState, tutees: Array<User>) {
    state.tutees = tutees;
  },
  setSelectedTuteeXrefs(state: CignitionState, tuteeXrefs: Array<string>) {
    state.selectedTuteeXrefs = [...tuteeXrefs];
  },
  setSelectedTimeFrame(state: CignitionState, timeFrame: TimeFrame | null) {
    state.selectedTimeFrame = timeFrame;
  },
};
