
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Highcharts, { Options, Point, Series } from 'highcharts';
// Load the exporting module.
import Exporting from 'highcharts/modules/exporting';
import { ModuleDefinition } from '@/domain/Curriculum';
import { Fraction, Row } from '../base/TreeGradientTable.vue';
import { isEqual } from 'lodash';
// Initialize exporting module. (CommonJS only)
Exporting(Highcharts);

@Component
export default class AssessmentsBarChart extends Vue {
  // Categories
  @Prop({ required: true }) modules: Map<number, ModuleDefinition>;
  // Series
  @Prop({ required: true }) assessmentTypes: string[];
  @Prop({ required: true }) assessmentTypeColors: Map<string, string>;
  // Data
  @Prop({ required: true }) averageRow: Row;
  @Prop({ default: false }) loading: boolean;

  chart: Highcharts.Chart | null = null;
  pointWidth = 20;
  pointPadding = 2;

  options: Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Montserrat',
      },
      // height: 350,
    },
    exporting: {
      // enabled: false,
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen'],
        },
      },
    },
    title: {
      // Disable Title
      text: undefined,
    },
    xAxis: {
      labels: {
        style: {
          // eslint-disable-next-line
          // @ts-ignore
          color: this.$vuetify.theme.themes.light.neutral.darken4,
          fontSize: '12px',
        },
      },
      lineColor: 'white',
      offset: -2,
      lineWidth: 2,
      zIndex: 4,
    },
    yAxis: {
      min: 0,
      gridLineDashStyle: 'Dash',
      offset: -10,
      title: {
        text: undefined,
      },
      labels: {
        style: {
          // eslint-disable-next-line
          // @ts-ignore
          color: this.$vuetify.theme.themes.light.neutral.darken3,
          fontSize: '12px',
        },
        formatter: function ({ value }) {
          return value + '%';
        },
      },
      lineWidth: 1,
      // eslint-disable-next-line
      // @ts-ignore
      lineColor: this.$vuetify.theme.themes.light.neutral.base,
      zIndex: 5,
    },
    legend: {
      align: 'left',
      layout: 'horizontal',
      alignColumns: true,
      itemStyle: {
        // eslint-disable-next-line
        // @ts-ignore
        color: this.$vuetify.theme.themes.light.neutral.darken2,
        fontSize: '14px',
        fontWeight: 'regular',
      },
      itemDistance: 16,
      margin: 24,
      symbolWidth: 0,
      squareSymbol: false,
      labelFormatter: function () {
        return (
          '<div style="background-color:#F2F4F5;border-radius:4px;min-height:27px;padding:4px 8px 4px 8px;">' +
          ` <div style="background-color:${
            (this as Point).color
          };height:8px;width:8px;display:inline-block;border-radius:50%"/></div>` +
          ` <div style="display: inline-block;padding-left:8px">${
            (this as Point).name
          }</div>` +
          '</div>'
        );
      },
      useHTML: true,
      // floating: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}:</td>' +
        '<td style="padding:0"><b>{point.y}%</b></td></tr>',
      footerFormat: '</table>',
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointWidth: this.pointWidth,
        pointPadding: this.pointPadding,
        borderRadius: 4,
        minPointLength: 2,
      },
    },
  };

  get chartData(): Partial<Options> {
    const categories: string[] = [];
    const series: Map<string, (number | null)[]> = new Map();

    for (const [folderId, module] of this.modules) {
      categories.push(`${module.moduleNumber} - ${module.moduleName}`);
      for (const aType of this.assessmentTypes) {
        const dataPoints = series.get(aType) ?? [];

        const dataPoint = this.averageRow[
          `${module.folderId}_${aType}`
        ] as Fraction;

        if (dataPoint) {
          const score = Math.floor(
            (dataPoint.numerator / dataPoint.denominator) * 100
          );
          dataPoints.push(score);
        } else {
          // Placeholder for missing data point
          dataPoints.push(null);
        }

        series.set(aType, dataPoints);
      }
    }

    return {
      chart: {
        ...this.options.chart,
        scrollablePlotArea: {
          minWidth:
            this.modules.size *
              this.assessmentTypes.length *
              (this.pointWidth + this.pointPadding) +
            this.modules.size * 70,
          scrollPositionX: 0,
        },
      },
      xAxis: {
        ...this.options.xAxis,
        categories,
      },
      series: [...series.entries()].map(([aType, dataPoints]) => {
        return {
          id: aType,
          name: aType,
          data: dataPoints,
          color: this.assessmentTypeColors.get(aType),
        };
      }) as Highcharts.SeriesOptionsType[],
    };
  }

  mounted(): void {
    // Generate the chart
    this.chart = Highcharts.chart(
      'assessments-bar',
      // options - see https://api.highcharts.com/highcharts
      { ...this.options, ...this.chartData }
    );

    // Chart overflows on initial load?! Calling this method reflows the chart to its container.
    // By default, the chart reflows automatically to its container following a window.resize event,
    // as per the chart.reflow option. However, there are no reliable events for div resize, so if
    // the container is resized without a window resize event, this must be called explicitly.
    this.chart?.reflow();
  }

  @Watch('chartData')
  onDataChange(newValue: Partial<Options>, oldValue: Partial<Options>): void {
    if (!isEqual(newValue, oldValue)) {
      this.chart?.update(newValue, true, true);

      // Redraw the graph by considering the div width?
      this.chart?.reflow();
    }
  }
}
