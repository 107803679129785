// Computed properties that are shared
import { GetterTree } from 'vuex';
import { User } from '@/domain/User';
import { CignitionState } from './types';
import { RootState } from '../types';
import { TimeFrame } from '@/domain/Time';

export const getters: GetterTree<CignitionState, RootState> = {
  getSelectedTutees(state: CignitionState): Array<User> {
    const selectedTutees: Array<User> = state.tutees.filter((tutee: User) =>
      state.selectedTuteeXrefs.includes(tutee.xref)
    );

    // Maintain order of selected tutees
    return selectedTutees.sort((a: User, b: User) => {
      return (
        state.selectedTuteeXrefs.indexOf(a.xref) -
        state.selectedTuteeXrefs.indexOf(b.xref)
      );
    });
  },
  getSelectedTimeFrame(state: CignitionState): TimeFrame | null {
    return state.selectedTimeFrame;
  },
};
