import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class WirisMixins extends Vue {
  documentContainsWiris = false;

  checkForWirisResponse(response: string): void {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = response;

    const mathElements = wrapper.getElementsByTagName('math');

    if (mathElements.length === 0) {
      return;
    }

    this.documentContainsWiris = true;

    wrapper.remove();
  }

  @Watch('documentContainsWiris')
  parseWirisResponsesFromDocument(containsWiris: boolean): void {
    if (containsWiris) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).com.wiris.js.JsPluginViewer.parseDocument();
    }
  }
}
