import { User } from '@/domain/User';

export const sortBySortableUserName = (a: User, b: User): number => {
  const sortableNameA = `${a.lastName},${a.firstName}`;
  const sortableNameB = `${b.lastName},${b.firstName}`;
  return sortableNameA.localeCompare(sortableNameB);
};

export const sortBySortableString = (a: string, b: string): number =>
  a.localeCompare(b);

export const sortBySortableNumber = (a: number, b: number): number =>
  a > b ? 1 : -1;
