var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"feature-settings"},[_c('section',{staticClass:"pt-6 pr-8 pl-0 pb-0"},[_c('h4',{staticClass:"text-h4 pb-6"},[_vm._v("Feature Settings")]),_c('v-tabs',{attrs:{"background-color":"neutral lighten-5","color":"primary darken-2"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.section,staticClass:"font-weight-bold px-2",on:{"click":function($event){return _vm.scrollToSection(tab.section)}}},[_vm._v(" "+_vm._s(tab.tabName)+" ")])}),1)],1),_c('v-divider'),_c('section',{staticClass:"settings-underline py-6 pr-8 pl-0",attrs:{"id":"testMode"}},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Test Mode")]),_c('TestModeInformationDialog'),_c('p',{staticClass:"text-body-2"},[_vm._v("Set your defaults for the test mode")]),_c('v-checkbox',{on:{"click":function($event){_vm.triggerDataChanged();
        _vm.toggleTestModeFeatures();}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:_vm.data.settings.testModeEnabled
              ? 'primary--text text--darken-3'
              : ''},[_vm._v(" Enable Test Mode at Assign Time ")])]},proxy:true}]),model:{value:(_vm.data.settings.testModeEnabled),callback:function ($$v) {_vm.$set(_vm.data.settings, "testModeEnabled", $$v)},expression:"data.settings.testModeEnabled"}}),_c('v-checkbox',{staticClass:"mt-0 pl-8",attrs:{"disabled":!_vm.data.settings.testModeEnabled},on:{"click":_vm.triggerDataChanged},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:_vm.data.settings.testModeStudentReport
              ? 'primary--text text--darken-3'
              : ''},[_vm._v(" Deliver student report at the end of the assignment ")])]},proxy:true}]),model:{value:(_vm.data.settings.testModeStudentReport),callback:function ($$v) {_vm.$set(_vm.data.settings, "testModeStudentReport", $$v)},expression:"data.settings.testModeStudentReport"}})],1),_c('v-divider'),_c('section',{staticClass:"settings-underline py-6 pr-8 pl-0",attrs:{"id":"studentSupports"}},[_c('h6',{staticClass:"text-subtitle-2"},[_vm._v("Student Supports")]),_c('p',{staticClass:"text-body-2"},[_vm._v(" Deliver hints and explanations to students for problems when available ")]),_c('v-checkbox',{on:{"click":_vm.triggerDataChanged},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{class:_vm.data.settings.starredTeacherEnabled
              ? 'primary--text text--darken-3'
              : ''},[_vm._v(" Enable Student Supports ")])]},proxy:true}]),model:{value:(_vm.data.settings.starredTeacherEnabled),callback:function ($$v) {_vm.$set(_vm.data.settings, "starredTeacherEnabled", $$v)},expression:"data.settings.starredTeacherEnabled"}})],1),_c('v-divider'),_c('ActionButtons',{attrs:{"data":_vm.data},on:{"discardChanges":_vm.discardChanges},model:{value:(_vm.dataChanged),callback:function ($$v) {_vm.dataChanged=$$v},expression:"dataChanged"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }