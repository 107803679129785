
import { getCourseRoster } from '@/api/core/course.api';
import { Class } from '@/domain/Class';
import { User } from '@/domain/User';
import { differenceWith, isEqual } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

interface AssigneeItem {
  itemId: string;
  type: 'course' | 'student';
  xref: string;
  name: string;
  courseXref?: string;
}

export interface AssigneeSummary {
  class?: boolean;
  studentXrefs?: string[];
}

@Component
export default class AssigneeSelector extends Vue {
  @Prop() value: Map<string, AssigneeSummary>;
  @Prop({ required: true }) courses: Class[];
  @Prop({ required: false, default: true })
  enableStudentSelection: boolean;

  // Loading states
  downloadingRosters = false;

  // Data for internal use
  searchAssigneeName = '';
  assigneesInternal: Map<string, AssigneeItem[]> = new Map();
  courseRosters: Map<string, User[]> = new Map();

  //////////////
  // Computed //
  //////////////

  get courseCatalog(): Map<string, Class> {
    const res = new Map();
    for (const course of this.courses) {
      res.set(course.id, course);
    }
    return res;
  }

  get studentCatalog(): Map<string, User> {
    const users: User[] = [...this.courseRosters.values()].flat();
    const res = new Map();
    for (const user of users) {
      res.set(user.xref, user);
    }
    return res;
  }

  get selectedAllStudents(): boolean {
    return this.selectedAssignees.length === this.assigneeItems.length;
  }

  set selectedAllStudents(selectAll: boolean) {
    if (selectAll) {
      this.selectedAssignees = [...this.assigneeItems];
    } else {
      this.selectedAssignees = [];
    }
  }

  get assigneeItems(): AssigneeItem[] {
    let items: AssigneeItem[] = [];
    for (const course of this.courses) {
      // Course header
      items.push(this.getCourseAssigneeItem(course));
      const roster = this.courseRosters.get(course.id);
      if (roster) {
        // Followed by students in course
        const studentItems: AssigneeItem[] = roster.map(
          (student: User): AssigneeItem => {
            return this.getStudentAssigneeItem(course.id, student);
          }
        );
        items = [...items, ...studentItems];
      }
    }
    return items;
  }

  get numAssigneesLeft(): number {
    if (this.selectedAssignees.length > 0) {
      const selectedFirst = this.selectedAssignees[0];
      if (selectedFirst.type === 'course') {
        return this.selectedAssignees.filter(
          (assignee) =>
            assignee.type === 'student' &&
            assignee.courseXref &&
            assignee.courseXref !== selectedFirst.xref
        ).length;
      } else {
        return (
          this.selectedAssignees.filter(
            (assignee) => assignee.type === 'student'
          ).length - 1
        );
      }
    }

    return 0;
  }

  get selectedAssignees(): AssigneeItem[] {
    return [...this.assignees.values()].flat();
  }

  set selectedAssignees(assigneesItems: AssigneeItem[]) {
    // Create a map of courses to selections
    const results = new Map();
    for (const assigneeItem of assigneesItems) {
      // Determine course
      let courseXref = null;
      if (assigneeItem.type === 'course') {
        courseXref = assigneeItem.xref;
      } else if (assigneeItem.type === 'student') {
        courseXref = assigneeItem.courseXref;
      }
      if (courseXref) {
        // Add selection to course
        const courseAssignees = results.get(courseXref);
        if (courseAssignees) {
          courseAssignees.push(assigneeItem);
        } else {
          results.set(courseXref, [assigneeItem]);
        }
      }
    }
    // Update and notify
    this.assignees = results;
  }

  get assignees(): Map<string, AssigneeItem[]> {
    // External value to read from
    if (this.value) {
      // Converts to map of courses to selections
      const result: Map<string, AssigneeItem[]> = new Map();
      for (const [courseXref, assignees] of this.value) {
        let assigneeItems: AssigneeItem[] = [];
        if (assignees.class) {
          // Course selected
          const course = this.courseCatalog.get(courseXref);
          if (course) {
            // Selects course
            assigneeItems.push(this.getCourseAssigneeItem(course));
          }
          // By default, selects all students in roster
          const roster = this.courseRosters.get(courseXref);
          if (roster) {
            const studentAssigneeItems = roster.map((student) =>
              this.getStudentAssigneeItem(courseXref, student)
            );
            assigneeItems = [...assigneeItems, ...studentAssigneeItems];
          }
        } else if (assignees.studentXrefs) {
          // Individually students selected
          const studentAssigneeItems = assignees.studentXrefs
            .map((studentXref: string): AssigneeItem | null => {
              const student = this.studentCatalog.get(studentXref);
              return student
                ? this.getStudentAssigneeItem(courseXref, student)
                : null;
            })
            .filter(
              (assigneeItem) => assigneeItem !== null
            ) as unknown as AssigneeItem[];
          assigneeItems = [...assigneeItems, ...studentAssigneeItems];
        }
        // Record selections to course
        result.set(courseXref, assigneeItems as unknown as AssigneeItem[]);
      }
      return result;
    }
    // Internal value
    return this.assigneesInternal;
  }

  set assignees(value: Map<string, AssigneeItem[]>) {
    // Converts selections into a more understandable map that summarizes the results
    const result: Map<string, AssigneeSummary> = new Map();
    for (const [courseXref, assignees] of value) {
      // Selected student assignees in course
      const studentXrefs = assignees
        .filter((assignee) => assignee.type === 'student')
        .map((assignee) => assignee.xref);
      // Determine whether all students in roster are selected
      const roster = this.courseRosters.get(courseXref);
      const assignToClass = roster
        ? studentXrefs.length === roster.length
        : true; // If no roster found, we say it is okay to assign to the course
      if (assignToClass) {
        // Indicate that the course is selected
        result.set(courseXref, {
          class: true,
        });
      } else if (studentXrefs.length > 0) {
        // Selected a subset of students so report selected student xrefs
        result.set(courseXref, {
          studentXrefs,
        });
      } // Otherwise do not include course in the map for output
    }
    // Update internal value
    this.assigneesInternal = value;
    // Notify
    this.$emit('input', result);
  }

  /////////////
  // Methods //
  /////////////

  filterStudentsOnlyByName(
    item: AssigneeItem,
    queryText: string,
    itemText: string
  ): boolean {
    return (
      // Search student names only
      item.type === 'student' &&
      // Default
      itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }

  selectCourse(courseXref: string, selected: boolean): void {
    // Keep existing selections
    const results = new Map(this.assignees);
    if (selected) {
      let assigneeItems: AssigneeItem[] = [];
      // Select course
      const course = this.courseCatalog.get(courseXref);
      if (course) {
        assigneeItems.push(this.getCourseAssigneeItem(course));
      }
      // Select all students in course
      const students = this.courseRosters.get(courseXref);
      if (students) {
        const studentItems: AssigneeItem[] = students.map(
          (student: User): AssigneeItem => {
            return this.getStudentAssigneeItem(courseXref, student);
          }
        );
        assigneeItems = [...assigneeItems, ...studentItems];
      }
      // Update map with new assignees
      results.set(courseXref, assigneeItems);
    } else {
      // Deselect (or remove) all existing selections in course
      results.delete(courseXref);
    }
    this.assignees = results;
  }

  selectStudent(student: AssigneeItem, selected: boolean): void {
    const courseXref = student.courseXref;
    if (courseXref) {
      const courseAssignees = courseXref
        ? this.assignees.get(courseXref)
        : null;
      if (courseAssignees) {
        if (!selected) {
          // Deselect course (no longer assigning to all students)
          // FIXME: Find by type?
          var target = courseAssignees
            .map((assignee: AssigneeItem) => assignee.xref)
            .indexOf(courseXref);
          if (target > -1) {
            // Found
            courseAssignees.splice(target, 1);
          }
        } else {
          // Select course only if all students in roster is selected
          const roster = this.courseRosters.get(courseXref);
          if (roster && courseAssignees.length === roster.length) {
            const course = this.courseCatalog.get(courseXref);
            if (course) {
              courseAssignees.push(this.getCourseAssigneeItem(course));
            }
          }
        }
      }
    }
  }

  getCourseAssigneeItem(course: Class): AssigneeItem {
    return {
      itemId: course.id,
      type: 'course',
      xref: course.id,
      name: course.name,
    };
  }

  getStudentAssigneeItem(courseXref: string, student: User): AssigneeItem {
    return {
      itemId: `${student.xref}@${courseXref}`,
      type: 'student',
      xref: student.xref,
      name: student.displayName,
      courseXref: courseXref,
    };
  }

  addRosters(courses: Class[]): void {
    this.downloadingRosters = true;
    const rosterPromises = [];

    for (const course of courses) {
      const roster = this.courseRosters.get(course.id);
      if (!roster && this.enableStudentSelection) {
        // Was NOT downloaded previously
        const promise = getCourseRoster(course.id).then((students: User[]) => {
          // Add new course roster for lookup
          this.courseRosters = new Map([
            ...this.courseRosters.entries(),
            ...Object.entries({ [course.id]: students }),
          ]);
        });
        rosterPromises.push(promise);
      }
      this.selectCourse(course.id, true);
    }

    Promise.allSettled(rosterPromises).then(() => {
      this.downloadingRosters = false;
    });
  }

  created(): void {
    // Download rosters
    if (this.courses.length > 0) {
      this.addRosters(this.courses);
    }
  }

  /////////////
  // Watcher //
  /////////////

  @Watch('courses')
  onCourses(newValue: Class[], oldValue: Class[]): void {
    // Removed courses
    const removedCourses = differenceWith(oldValue, newValue, isEqual);
    for (const course of removedCourses) {
      // Update (or clear out) prior selections
      this.selectCourse(course.id, false);
    }
    // Newly-added courses
    const addedCourses = differenceWith(newValue, oldValue, isEqual);
    this.addRosters(addedCourses);
  }
}
