import { MutationTree } from 'vuex';
import { MyPsState } from './types';
import { ProblemSet } from '@/domain/ProblemSet';

export const mutations: MutationTree<MyPsState> = {
  setOriginalDownloadDone(state, downloadDone: boolean): void {
    state.originalDownloadDone = downloadDone;
  },
  setDownloading(state, downloading: boolean): void {
    state.downloading = downloading;
  },
  setProblemSets(state, psList: ProblemSet[]): void {
    if (state.problemSets.length === 0) {
      state.problemSets = psList;
    } else {
      state.problemSets = [...state.problemSets, ...psList];
    }
  },
  resetProblemSets(state): void {
    state.problemSets = [];
    state.nextPageToken = undefined;
    state.downloading = false;
    state.originalDownloadDone = false;
  },
  setNextPageToken(state, nextPageToken: string): void {
    state.nextPageToken = nextPageToken;
  },

  updateProblemSet(state, updatedPs: ProblemSet): void {
    const psToUpdate: undefined | ProblemSet = state.problemSets.find(
      (ps) => ps.id === updatedPs.id
    );
    if (psToUpdate) {
      Object.assign(psToUpdate, updatedPs);
    }
  },
  addProblemSet(state, newPs: ProblemSet): void {
    const alreadyInState = state.problemSets.find(
      (ps) => ps.xref === newPs.xref
    );

    if (!alreadyInState) {
      state.problemSets.push(newPs);
    }
  },
  deleteProblemSet(state, psId: number): void {
    state.problemSets = state.problemSets.filter(
      (ps: ProblemSet) => ps.id != psId
    );
  },
};
