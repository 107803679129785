
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router/types/router';
import { uniqBy, sortBy } from 'lodash';
import { getProblemsGroupedByAssignmentForStandard } from '@/api/ldoe/skillData.api';
import NotFoundView from '@/components/base/NotFoundView.vue';
import UnauthorizedView from '@/components/LDOE/base/UnauthorizedView.vue';
import {
  CustomProblemRowHeader,
  ProblemRow,
  ProblemRowHeaderValue,
} from '@/components/LDOE/TutoringProblemsByStandard/ProblemRowView.vue';
import ProblemTableView from '@/components/LDOE/TutoringProblemsByStandard/ProblemTableView.vue';
import { Problem } from '@/domain/Problem';
import {
  FullAssignmentStudentData,
  AssignmentStudentAndClassDataWithProblems,
} from '@/domain/ReportData/Cignition';
import { Skill } from '@/domain/Skill';
import { TimeFrame } from '@/domain/Time';
import { User } from '@/domain/User';
import { addPartLetters } from '@/utils/problem.util';
import DatePicker from '@/components/LDOE/base/DatePicker.vue';

interface AssignmentTable {
  problemSetId: number;
  assignmentXref?: string;
  assignmentName: string;
  problems: Array<ProblemRow>;
}

@Component({
  components: {
    NotFoundView,
    UnauthorizedView,
    ProblemTableView,
    DatePicker,
  },
})
export default class TutorProblemsByStandardPage extends Vue {
  assignmentStudentClassDataWithProblems: AssignmentStudentAndClassDataWithProblems | null =
    null;
  skillId = 0;
  unauthorized = false;
  dataNotFound = false;
  ////////////////////
  // Loading States //
  ////////////////////

  // Store Data Loading
  get isDownloadingSkills(): boolean {
    return this.$store.state.skillList.isDownloading;
  }

  // Page Data Loading
  isDownloadingSkillData = false;

  //////////
  // Grid //
  //////////

  get tuteeHeaders(): Array<CustomProblemRowHeader> {
    let tuteeLabels: Array<CustomProblemRowHeader> = [];

    this.selectedTutees.forEach((tutee: User) => {
      tuteeLabels.push({
        text: this.getDisplayLabel(tutee),
        value: tutee.xref,
      });
    });

    return tuteeLabels;
  }

  get customHeaders(): Array<CustomProblemRowHeader> {
    return [
      ...this.tuteeHeaders,
      {
        text: 'Class Average',
        value: ProblemRowHeaderValue.CLASS_AVERAGE,
      },
    ];
  }

  get assignmentTables(): Array<AssignmentTable | null> {
    if (this.assignmentStudentClassDataWithProblems) {
      const problemSetToProblemsMap: { [psId: number]: Array<Problem> } =
        this.assignmentStudentClassDataWithProblems.problems;

      return this.assignmentStudentClassDataWithProblems.reportData.map(
        (assignmentData) => {
          const problemSetId: number = assignmentData.problemSetId;
          let problems: Array<Problem> | undefined =
            problemSetToProblemsMap[problemSetId];

          if (problems) {
            problems = uniqBy(problems, (prob) => prob.id);
            addPartLetters(problems);
            const problemMap = this.getProblemMap(problems);
            return this.transformToAssignmentTable(assignmentData, problemMap);
          }

          return null;
        }
      );
    }

    return [];
  }

  transformToAssignmentTable(
    assignment: FullAssignmentStudentData,
    allProblems: { [id: number]: { problem: Problem; index: number } }
  ): AssignmentTable {
    const assignmentXref = assignment.xref;
    const assignmentName = assignment.name;
    const problemSetId = assignment.problemSetId;

    // Assignment-level info
    let assignmentTable: AssignmentTable = {
      problemSetId: problemSetId,
      assignmentXref: assignmentXref,
      assignmentName: assignmentName,
      problems: [],
    };

    // FIXME: Could use a map but not as flexible since we
    // need to update object with student data later?
    let problems: { [id: number]: ProblemRow } = {};

    // Aggregate problems in the assignment
    assignment.classProblemData.forEach((data) => {
      const targetProblemRow = allProblems[data.problemId];

      if (targetProblemRow) {
        problems[data.problemId] = {
          problem: targetProblemRow.problem,
          index: targetProblemRow.index,
          problemData: data,
          studentData: {},
        };
      }
    });

    // Aggregate student data for each problem
    assignment.studentData.forEach((studentData) => {
      studentData.studentProblemData.forEach((studentProblemData) => {
        if (problems[studentProblemData.problemId]) {
          problems[studentProblemData.problemId].studentData[
            studentData.studentXref
          ] = studentProblemData;
        } // else: shouldn't happen right? Why would a problem not exists in assignment?
      });
    });
    // Flatten dictionary into array of ProblemRows, sorted by original index
    assignmentTable.problems = sortBy(Object.values(problems), 'index');

    return assignmentTable;
  }

  //////////////
  // Watchers //
  //////////////

  @Watch('weekSelected')
  onWeekChange(value: TimeFrame | null, oldValue: TimeFrame | null): void {
    if (value === oldValue) {
      return;
    }

    // Download data
    this.downloadData();
  }

  @Watch('isAuthenticated')
  handleAuthChange(): void {
    if (!this.isAuthenticated) {
      // FIXME:
      // this.goToLoginPortal();
    }
  }

  //////////
  // Data //
  //////////

  get targetSkill(): Skill | null {
    const skillFound = this.skills.find(
      (skill) => skill.id == Number(this.skillId)
    );

    if (skillFound) {
      return skillFound;
    }

    return null;
  }

  get reportPage(): RawLocation {
    // Remove assignment xrefs from the query
    delete this.$route.query.assignments;

    return {
      name: 'tutorReport',
      query: this.$route.query,
    };
  }

  ///////////
  // Store //
  ///////////

  get weekSelected(): TimeFrame | null {
    return this.$store.getters['ldoe/getSelectedTimeFrame'];
  }

  set weekSelected(timeFrame: TimeFrame | null) {
    // Update store of new selection
    this.$store.commit('ldoe/setSelectedTimeFrame', timeFrame);
  }

  get selectedTuteeXrefs(): Array<string> {
    return this.$store.state.ldoe.selectedTuteeXrefs;
  }

  get selectedTutees(): Array<User> {
    return this.$store.getters['ldoe/getSelectedTutees'];
  }

  get skills(): Array<Skill> {
    return this.$store.state.skillList.skills;
  }

  /////////////
  // Methods //
  /////////////

  getDisplayLabel(user: User): string {
    return user.firstName + ' ' + user.lastName.charAt(0);
  }

  getProblemMap(problems: Array<Problem>): {
    [id: number]: { problem: Problem; index: number };
  } {
    let problemMap: { [id: number]: { problem: Problem; index: number } } = {};

    problems.forEach((problem: Problem, index: number) => {
      problemMap[problem.id] = {
        problem,
        index,
      };
    });

    return problemMap;
  }

  goToReportPage(): void {
    this.$router.push(this.reportPage);
  }

  ////////////////////
  // Initialization //
  ////////////////////

  created(): void {
    // Get skill id from path
    this.skillId = Number(this.$route.params.id);

    this.initializeSelectedTutees();

    // Download data
    this.downloadData();
  }

  ///////////////////////////
  // Report Data & Helpers //
  ///////////////////////////

  // Download helpers
  initializeSelectedTutees(): void {
    if (this.selectedTuteeXrefs.length === 0) {
      // Go to dashboard to select tutees
      this.goToReportPage();
    }
  }

  async getProblemsGroupedByAssignment(): Promise<void> {
    if (
      this.skillId &&
      this.selectedTuteeXrefs.length > 0 &&
      this.weekSelected
    ) {
      this.isDownloadingSkillData = true;
      this.dataNotFound = false;
      try {
        const data = await getProblemsGroupedByAssignmentForStandard(
          this.skillId,
          this.selectedTuteeXrefs,
          this.weekSelected?.startDateTime,
          this.weekSelected?.endDateTime
        );
        this.assignmentStudentClassDataWithProblems = data;
      } catch (err) {
        this.dataNotFound = true;
        return Promise.reject(err);
      } finally {
        this.isDownloadingSkillData = false;
      }
      return Promise.resolve();
    }
  }

  downloadData(): void {
    this.download([this.getProblemsGroupedByAssignment()]);
  }

  download(promises: Array<Promise<void>>): void {
    Promise.all(promises).catch((error) => {
      // Any errors
      if (error.response && error.response.status === 403) {
        // FIXME: Clear data?
        this.unauthorized = true;
      }
    });
  }

  goToProblemSetView(problemSetId: number, assignmentXref: string): void {
    this.$router.push({
      name: 'tutorProblemSets',
      params: {
        problemSetId: problemSetId.toString(),
      },
      query: {
        tutees: this.$route.query.tutees,
        assignments: [assignmentXref],
      },
    });
  }
}
