
import AchievementScoringDialog from '@/components/InsightsHub/AchievementPage/AchievementScoringDialog.vue';
import AssessmentsBarChart from '@/components/InsightsHub/AchievementPage/AssessmentsBarChart.vue';
import HeatMap from '@/components/InsightsHub/ActivityPage/HeatMap.vue';
import SelectAssignmentIdDialog from '@/components/InsightsHub/ActivityPage/SelectAssignmentIdDialog.vue';
import {
  Fraction,
  GradientHeaders,
  GradientRows,
  Row,
  RowType,
  StaticHeader,
} from '@/components/InsightsHub/base/TreeGradientTable.vue';
import { CourseDefinition } from '@/domain/Class';
import { CurriculumGrade, ModuleDefinition } from '@/domain/Curriculum';
import {
  ClassCurriculumStats,
  StatsPerProblemSetType,
  TeacherCurriculumStats,
} from '@/domain/ReportData/InsightsHub';
import { School } from '@/domain/School';
import { User } from '@/domain/User';
import { convertHexToRGBA } from '@/utils/color.util';
import { orderBy } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';

@Component({
  components: {
    AchievementScoringDialog,
    AssessmentsBarChart,
    HeatMap,
    SelectAssignmentIdDialog,
  },
})
export default class AchievementPage extends Vue {
  // Allows us access to the enum in the template.
  RowType = RowType;

  /////////////////////////////////////////////////////////////////////////////
  // Read from store table settings and other preserved states of the report //
  /////////////////////////////////////////////////////////////////////////////

  // Preserve and sync table settings
  get collapsedPaths(): string[] {
    return this.$store.state.insightsHub.collapsedPaths;
  }

  set collapsedPaths(value: string[]) {
    this.$store.commit('insightsHub/setCollapsedPaths', value);
  }

  get options(): DataOptions {
    return this.$store.state.insightsHub.options;
  }

  set options(value: DataOptions) {
    this.$store.commit('insightsHub/setOptions', value);
  }

  get orderedAssessmentTypes(): string[] {
    const res = [];

    const numProblemsPerAssessmentType =
      this.curriculumGrade?.numProblemsPerAssessmentType ?? new Map();

    const readiness: string[] = [];
    const others: string[] = [];

    for (const [aType, numProblems] of numProblemsPerAssessmentType) {
      const isReadiness = aType.toLowerCase().includes('readiness');
      if (isReadiness) {
        readiness.push(aType);
      } else {
        others.push(aType);
      }
    }

    // Order and group Assessment Types
    res.push(...orderBy(readiness, String, 'asc'));
    res.push(...orderBy(others, String, 'asc'));

    return res;
  }

  get selectedAssessmentType(): string | null {
    const selectedType = this.$store.state.insightsHub.selectedAssessmentType;

    return (
      selectedType ??
      (this.orderedAssessmentTypes.length > 0
        ? this.orderedAssessmentTypes[0]
        : null)
    );
  }

  set selectedAssessmentType(value: string | null) {
    this.$store.commit('insightsHub/setSelectedAssessmentType', value);
  }

  get anonymized(): boolean {
    return this.$store.state.insightsHub.anonymized;
  }

  set anonymized(value: boolean) {
    /*
    Upside:
    - Preserves the shuffled version of the teacher curriculum stats in the store so that the user can see the same thing or in same order
    (if not sorted in table) when he or she returns from another page when their anonymized setting is on (so that we can avoid shuffling
    again upon returning because it was already shuffled before the user left the page.
    - Allows us to keep calculations as getters so that we are reactive to any changes in selections

    Downside:
    - Recomputes everything if anonymizes setting is on

    Alternatively, we can keep the calculated and shuffled version in store. However, if we want to keep it as a getter (calculations), we
    will have two versions of the same thing in memory - both in Activity Page and in the store?, and we will need a watcher on that getter
    to know when to update the one in the store (which is duplicate data just to preserve shuffled and/or expanded states). Or, we can keep
    one version in the store, but the getter turns into a method that computes everything based on current selections and saves/updates the
    result to the store. But for every factor that impacts the computation, we will need a watcher for to know when to recompute. That’ll be
    too many watchers just to recalculate everything and making sure the store is up-to-date.
    */
    if (value) {
      // Shuffled version is now saved to the store and is only shuffled when the user physically makes a change to that setting so that we
      // don’t shuffle again upon returning to this page from somewhere else
      this.$store.dispatch('insightsHub/shuffleMenteeTeachers');
    }

    this.$store.commit('insightsHub/setAnonymized', value);
  }

  get loading(): boolean {
    return this.$store.state.insightsHub.dashboardLoading;
  }

  //////////////////////
  // Report Rendering //
  //////////////////////
  get modules(): Map<string, ModuleDefinition> {
    return this.curriculumGrade?.modules ?? new Map();
  }

  get curriculumXref(): string {
    return this.$route.params.xref;
  }

  get gradeFolderId(): number {
    return Number(this.$route.params.gradeFolderId);
  }

  get xrefToGradeTeacherMap(): Map<string, User> {
    return this.$store.getters['insightsHub/xrefToGradeTeacherMap'];
  }

  get xrefToCourseMap(): Map<string, CourseDefinition> {
    return this.$store.getters['insightsHub/xrefToCourseMap'];
  }

  get xrefToSchoolMap(): Map<string, School> {
    return this.$store.getters['insightsHub/xrefToSchoolMap'];
  }

  get showOrHideNamesText() {
    return this.anonymized ? 'Show' : 'Hide';
  }

  get assessmentGroupToTypes(): Map<string, string[]> {
    const res = new Map();

    for (const aType of this.orderedAssessmentTypes) {
      const aGroup = this.getAssessmentTypeColorGroup(aType);
      const aTypes = res.get(aGroup) ?? [];
      aTypes.push(aType);
      res.set(aGroup, aTypes);
    }

    return res;
  }

  get assessmentGroupToColor(): Map<string, string> {
    // eslint-disable-next-line
    // @ts-ignore
    const readinessTypeColor = this.$vuetify.theme.themes.light.neutral.darken2;
    const colorSet = [
      // eslint-disable-next-line
      // @ts-ignore
      this.$vuetify.theme.themes.light.primary.base,
      this.$vuetify.theme.themes.light.correctEventually,
      this.$vuetify.theme.themes.light.correct,
      '#FFB600',
      '#007D54',
      '#438FE6',
    ];

    const res = new Map();
    const assessmentGroups = [...this.assessmentGroupToTypes.keys()];

    for (let i = 0; i < assessmentGroups.length; i++) {
      const aGroup: string = assessmentGroups[i];
      if (aGroup === 'readiness') {
        res.set(aGroup, readinessTypeColor);
      } else {
        if (i < colorSet.length) {
          res.set(aGroup, colorSet[i]);
        } else {
          // FIXME Wrap?
          const index = i % colorSet.length;
          res.set(aGroup, colorSet[index]);
        }
      }
    }

    return res;
  }

  // Graph Gradient Color (rgba)
  // FIXME: We do not want to play around with opacity. Rather, we would probably want to investigate how
  // vuetify does its color where there is a set base color and lighter color (build the missing colors).
  // Maybe loop through two dimentional array then for the different shades of the color?
  get assessmentTypeToRGBAColor(): Map<string, string> {
    const res = new Map();
    for (const [aGroup, aTypes] of this.assessmentGroupToTypes) {
      for (let i = 0; i < aTypes.length; i++) {
        const aType = aTypes[i];
        // Group color
        const baseColor = this.assessmentGroupToColor.get(aGroup);

        if (baseColor) {
          // A shade of gray based on index
          const opacity = (i + 1) / aTypes.length;
          res.set(aType, convertHexToRGBA(baseColor, opacity));
        }
      }
    }

    return res;
  }

  // Heat Map Color (hex) based on Assessment Group
  get selectedAssessmentGroupHexColor(): string {
    if (this.selectedAssessmentType) {
      const group = this.getAssessmentTypeColorGroup(
        this.selectedAssessmentType
      );

      return this.assessmentGroupToColor.get(group) ?? '';
    }

    return '';
  }

  get curriculumModuleTitle(): string {
    return this.curriculumGrade?.moduleTitle ?? '';
  }

  get computedHeaders(): GradientHeaders {
    const res: GradientHeaders = {
      averageHeader: { text: 'AVG', value: '' },
      itemHeaders: [],
    };
    for (const [folderId, module] of this.modules) {
      res.itemHeaders.push({
        text: module.moduleNumber,
        value: `${folderId}_${this.selectedAssessmentType}`,
      });
    }
    // Update Average Header Value
    res.averageHeader.value = `average_${this.selectedAssessmentType}`;

    return res;
  }

  /////////////////
  // Report Data //
  /////////////////

  // Curricular shape
  get curriculumGrade(): CurriculumGrade | null {
    return this.$store.state.insightsHub.curriculumGrade;
  }
  // Student & Teacher Data
  get teacherCurriculumStats(): TeacherCurriculumStats[] {
    return this.$store.state.insightsHub.teacherCurriculumStats;
  }
  get teacherXrefToCurriculumStats(): Map<string, TeacherCurriculumStats> {
    const res = new Map();

    for (const stats of this.teacherCurriculumStats) {
      res.set(stats.teacherXref, stats);
    }

    return res;
  }
  get computedRows(): GradientRows {
    const res = {
      averageRow: { [StaticHeader.NAME]: 'AVG' } as unknown as Row,
      itemRows: new Map<string, Row>(),
    };
    for (const [xref, teacher] of this.xrefToGradeTeacherMap) {
      // School Row
      const schoolXrefs = teacher.attributes?.ncesPublicSchool ?? [];
      const schoolRows = [];

      for (const schoolXref of schoolXrefs) {
        const school = this.xrefToSchoolMap.get(schoolXref);

        if (school) {
          const schoolId = `school_${school.ncesId}`;
          const schoolName = school.name;

          const schoolRow = res.itemRows.get(schoolId) ?? {
            parents: null,
            xref: schoolId,
            type: RowType.SCHOOL,
            [StaticHeader.NAME]: schoolName,
            children: [],
          };

          // Add Teacher to School Row/Subtree
          schoolRow.children?.push(teacher.xref);

          schoolRows.push(schoolRow);
        }
      }

      // Not affiliated to any Schools (or not found) whatsoever
      if (schoolRows.length === 0) {
        let schoolId = 'unaffiliated';
        let schoolName = 'Unaffiliated';

        const schoolRow = res.itemRows.get(schoolId) ?? {
          parents: null,
          xref: schoolId,
          type: RowType.SCHOOL,
          [StaticHeader.NAME]: schoolName,
          children: [],
        };

        // Add Teacher to School Row/Subtree
        schoolRow.children?.push(teacher.xref);

        schoolRows.push(schoolRow);
      }
      let teacherRow: Row = {
        parents: schoolRows.map((schoolRow) => schoolRow.xref),
        xref: teacher.xref,
        type: RowType.TEACHER,
        [StaticHeader.NAME]: teacher.displayName,
        children: [],
        sortOverride: {
          [StaticHeader.NAME]: `${teacher.lastName},${teacher.firstName}`,
        },
      };

      const teacherStats = this.teacherXrefToCurriculumStats.get(teacher.xref);

      if (teacherStats) {
        // Course Rows
        for (const classStats of teacherStats.classStats) {
          const course = this.xrefToCourseMap.get(classStats.classXref);

          if (course) {
            // Add Course to Teacher Row/Subtree
            teacherRow.children?.push(course.courseXref);

            const courseRow: Row = {
              parents: [teacherRow.xref],
              xref: course.courseXref,
              type: RowType.COURSE,
              [StaticHeader.NAME]: course.courseName,
            };

            // Update Subtree computations (in place) with new Course Stats
            this.computeGradeLevelStats(
              classStats,
              res.averageRow,
              schoolRows,
              teacherRow,
              courseRow
            );

            // Done computing Course Row. Add node to Map.
            res.itemRows.set(course.courseXref, courseRow);
          }
        }
      }

      // Now that we are done updating Parent Rows. Add updated nodes to Map.
      for (const schoolRow of schoolRows) {
        res.itemRows.set(schoolRow.xref, schoolRow);
      }
      res.itemRows.set(teacherRow.xref, teacherRow);
    }

    return res;
  }

  /////////////
  // Methods //
  /////////////
  addWeightedAverage(
    current: Fraction | null,
    average: number,
    numStudentsScored: number
  ): Fraction {
    if (current) {
      const res = { ...current };
      res.numerator += average * numStudentsScored;
      res.denominator += numStudentsScored;

      return res;
    } else {
      return {
        numerator: average * numStudentsScored,
        denominator: numStudentsScored,
      };
    }
  }

  updateRow(
    row: Row,
    module: number,
    assessmentType: string,
    average: number,
    weight: number
  ) {
    const moduleHeader = `${module}_${assessmentType}`;

    let accumulated: Fraction | null = (row[moduleHeader] as Fraction) ?? null;

    // Sum current numerator and denominator of Module Header with newly-calculated numerator and denominator
    // (weighted based on the number of students started the assignment) for target Row
    row[moduleHeader] = this.addWeightedAverage(accumulated, average, weight);

    const averageHeader = `average_${assessmentType}`;

    // Update Average Header with current average
    accumulated = (row[averageHeader] as Fraction) ?? null;

    row[averageHeader] = this.addWeightedAverage(accumulated, average, weight);
  }

  /**
   * Update all metrics for a specified course in a specified column based on the provided `psTypeStats`
   * and aggregate results up to teacher and school levels
   * NOTE: Updates rows in place
   */
  accumulateAssessmentTypeStats(
    averageRow: Row,
    schoolRows: Row[],
    teacherRow: Row,
    classRow: Row,
    module: number,
    totalNumProblems: number,
    classAssessmentTypeStats: StatsPerProblemSetType
  ): void {
    // % Score for Course Cell
    // Numerator: sum of scores on the assessment problems of the selected types
    // Denominator: (# of problems in the assessments of the selected types * total number of students started the assessments)
    const newValue = {
      numerator: classAssessmentTypeStats.sumOfStudentAverageScores,
      denominator:
        totalNumProblems * classAssessmentTypeStats.numStudentsScored,
    };

    const classAverage = newValue.numerator / newValue.denominator;

    this.updateRow(
      classRow,
      module,
      classAssessmentTypeStats.problemSetType,
      classAverage,
      classAssessmentTypeStats.numStudentsScored
    );

    // Propagate class average upwards to higher levels
    this.updateRow(
      teacherRow,
      module,
      classAssessmentTypeStats.problemSetType,
      classAverage,
      classAssessmentTypeStats.numStudentsScored
    );

    for (const schoolRow of schoolRows) {
      this.updateRow(
        schoolRow,
        module,
        classAssessmentTypeStats.problemSetType,
        classAverage,
        classAssessmentTypeStats.numStudentsScored
      );
    }

    this.updateRow(
      averageRow,
      module,
      classAssessmentTypeStats.problemSetType,
      classAverage,
      classAssessmentTypeStats.numStudentsScored
    );

    // Record Assessment Type Assignments for Class Row
    const moduleHeader = `${module}_${classAssessmentTypeStats.problemSetType}`;

    let accumulated: string[] =
      (classRow[`${moduleHeader}_assignmentXrefs`] as string[]) ?? [];

    if (classAssessmentTypeStats.assignmentXrefs) {
      accumulated.push(...classAssessmentTypeStats.assignmentXrefs);
    }

    classRow[`${moduleHeader}_assignmentXrefs`] = Array.from(
      new Set(accumulated)
    );

    const averageHeader = `average_${classAssessmentTypeStats.problemSetType}`;

    accumulated =
      (classRow[`${averageHeader}_assignmentXrefs`] as string[]) ?? [];

    if (classAssessmentTypeStats.assignmentXrefs) {
      accumulated.push(...classAssessmentTypeStats.assignmentXrefs);
    }

    classRow[`${averageHeader}_assignmentXrefs`] = Array.from(
      new Set(accumulated)
    );
  }

  computeGradeLevelStats(
    classStats: ClassCurriculumStats | null,
    averageRow: Row,
    schoolRows: Row[],
    teacherRow: Row,
    classRow: Row
  ): void {
    if (this.curriculumGrade) {
      for (const assessmentType of this.orderedAssessmentTypes) {
        for (const [moduleFolder, moduleDefinition] of this.curriculumGrade
          .modules) {
          const classModuleStats = classStats?.moduleStats.get(moduleFolder);
          const assessmentTypeStats = classModuleStats?.assessmentTypeStats;

          const numAssessmentTypeProblems =
            moduleDefinition.assessmentTypeStats.get(
              assessmentType
            )?.numProblems;

          const classAssessmentTypeStats =
            assessmentTypeStats?.get(assessmentType);

          if (numAssessmentTypeProblems && classAssessmentTypeStats) {
            // Update Table Module Stats
            this.accumulateAssessmentTypeStats(
              averageRow,
              schoolRows,
              teacherRow,
              classRow,
              moduleFolder,
              numAssessmentTypeProblems,
              classAssessmentTypeStats
            );
          }
        }
      }
    }
  }

  selectedAssignment(value: string | null): void {
    // Go to Assignment Report
    if (value !== null) {
      this.$router.push({
        name: 'ReportLandingPage',
        params: {
          xref: value,
        },
        query: {
          returnToName: 'Curriculum View',
          returnToPath: this.$router.currentRoute.fullPath,
        },
      });
    }
  }

  updateOptions(options: DataOptions): void {
    this.options = options;
  }

  updateCollapsedPaths(collapsedPaths: string[]): void {
    this.$store.commit('insightsHub/setCollapsedPaths', collapsedPaths);
  }

  getAssessmentTypeColorGroup(aType: string): string {
    return (
      aType
        // Remove dashes (some has dashes some don't)
        .replace(/-/g, '')
        // Remove ending (A), (B), etc.
        .replace(/\([A-Z,a-z,0-9]\)/, '')
        // Remove ending [A], [B], etc.
        .replace(/\[[A-Z,a-z,0-9]\]/, '')
        // Remove ending ' A', ' B ', etc.
        .replace(/\s[A-Z,a-z,0-9]\s*$/g, '')
        // Remove any extra spaces
        .replace(/\s/g, '')
        // Make all lowercase for case insenstive comparisons
        .toLowerCase()
    );
  }

  //////////////
  // Watchers //
  //////////////
}
