import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';
import { InsightsHubState } from './types';
import { RootState } from '../types';
import { DataOptions } from 'vuetify';

export const state: InsightsHubState = {
  selectedCurriculumXref: null,
  gradeFolders: [],
  menteeTeachers: [],
  menteeCourses: [],
  menteeSchools: [],
  selectedGradeId: null,
  selectedModuleId: null,
  teacherCurriculumStats: [],
  curriculumGrade: null,
  selectedMode: null,
  selectedPsTypes: [],
  selectedAssessmentType: null,
  anonymized: false,
  selectedTab: null,
  collapsedPaths: [],
  options: {} as DataOptions,
  timeSelector: null,
  dashboardLoading: false,
  dashboardSource: null,
};

const namespaced = true;

export const insightsHub: Module<InsightsHubState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
