
import { CourseDefinition } from '@/domain/Class';
import { TimeSelector, TimeSelectorType } from '@/domain/Time';
import { User } from '@/domain/User';
import dayjs from 'dayjs';
import { Component, Vue } from 'vue-property-decorator';
import UnauthorizedPage from '../UnauthorizedPage.vue';

@Component({
  components: {
    UnauthorizedPage,
  },
})
export default class InsightsHubParent extends Vue {
  get selectedCurriculumXref(): string | null {
    return this.$store.state.insightsHub.selectedCurriculumXref;
  }
  get menteeTeachers(): User[] {
    return this.$store.state.insightsHub.menteeTeachers;
  }
  get menteeCourses(): CourseDefinition[] {
    return this.$store.state.insightsHub.menteeCourses;
  }
  get hasMentorRole(): boolean {
    return this.getCurrentUser.roles?.includes('MENTOR');
  }
  get hasInsightsHubAccess(): boolean {
    return this.$store.state.auth.user?.settings.accessToInsightsHub || false;
  }
  get timeSelector(): TimeSelector | null {
    return this.$store.state.insightsHub.timeSelector;
  }

  created(): void {
    if (this.hasMentorRole && this.hasInsightsHubAccess) {
      // Request curriculum definitions - no-op if already done or in-progress
      this.$store.dispatch('curricula/requestCurricula');

      const selectedCurriculum = this.$route.params?.xref;
      if (
        selectedCurriculum &&
        this.selectedCurriculumXref !== selectedCurriculum
      ) {
        // Set selected Curriculum if different from that in store
        this.$store.commit(
          'insightsHub/setSelectedCurriculumXref',
          selectedCurriculum
        );
      }

      // Request Mentor Courses (of their Mentees) if not already
      if (this.menteeCourses.length === 0) {
        this.$store.dispatch('insightsHub/getMentorCourses');
      }

      // Request user's mentee teachers and their schools if not already
      if (this.menteeTeachers.length === 0) {
        this.$store.dispatch('insightsHub/getMenteeTeachers').then(() => {
          this.$store.dispatch('insightsHub/getMenteeSchools');
        });
      }
    }
  }
}
