import {
  StatisticsForType,
  StudentDataReturnType,
  VnextStudentData,
} from '@/domain/ReportData/AssignmentData';
import { coreAxios } from '@/plugins/axios';

const END_POINT = '/content';

type IsActiveFilter = 'ENABLED' | 'DISABLED' | 'IGNORE';

interface RequestParams {
  dTypes: Array<StudentDataReturnType>;
  isActive?: IsActiveFilter;
  statisticsFor?: Array<StatisticsForType>;
  groupsScope?: Array<string>;
  assignmentsScope?: Array<string>;
  groupsSubset?: Array<string>;
  usersSubset?: Array<string>;
  timeSelector?: Array<string>;
}

const getStudentDataForProblemSet = (
  psCeri: string,
  params: RequestParams
): Promise<VnextStudentData> => {
  if (!params.isActive) params.isActive = 'ENABLED';

  return coreAxios
    .get(`${END_POINT}/problem-sets/${psCeri}/sdata`, {
      params,
    })
    .then((res) => {
      return res.data;
    });
};

export { getStudentDataForProblemSet };
