
import { Component, Vue } from 'vue-property-decorator';
import { updateMyUserAttribute } from '@/api/core/user.api';
import SchoolSelector from '@/components/Settings/SchoolSelector.vue';
import { AttributeNames } from '@/domain/Attributes';

@Component({
  components: {
    SchoolSelector,
  },
})
export default class SelectSchool extends Vue {
  selectedSchools: string[] = [];
  submitting = false;

  //These do nothing for now other than move you onto selecting a grade and curriculum. When someone decides what to do then we will do something
  noSchool(): void {
    this.goToGCSelectPage();
  }
  missingSchool(): void {
    this.goToGCSelectPage();
  }

  goToGCSelectPage(): void {
    this.$router.push({
      name: 'select-grade-curriculum',
      query: this.$route.query,
    });
  }

  submitSchool(): void {
    this.submitting = true;
    updateMyUserAttribute(
      AttributeNames.NCES_PUBLIC_SCHOOL,
      this.selectedSchools
    )
      .then(() => {
        this.goToGCSelectPage();
      })
      .catch(() => {
        //Something bad happened.
        this.$notify(
          'An error occurred while saving school info. Please try again or move on.'
        );
      })
      .finally(() => {
        this.submitting = false;
      });
  }
}
