import { cignitionAxios } from '@/plugins/axios';
import {
  StudentAndPeerDataPerSkill,
  AssignmentStudentAndClassDataWithProblems,
} from '@/domain/ReportData/Cignition';

const END_POINT = '/skillData';

const getStudentAndPeerDataPerSkill = (
  studentXrefs: string[],
  startTime: number,
  endTime: number
): Promise<StudentAndPeerDataPerSkill[]> => {
  return cignitionAxios
    .get(`${END_POINT}`, {
      params: {
        studentXrefs: studentXrefs,
        startTime: startTime,
        endTime: endTime,
      },
    })
    .then((result) => {
      return result.data;
    });
};

const getProblemsGroupedByAssignmentForStandard = (
  skillId: number,
  studentXrefs: string[],
  startTime: number,
  endTime: number
): Promise<AssignmentStudentAndClassDataWithProblems> => {
  return cignitionAxios
    .get(`${END_POINT}/problemsByStandard/${skillId}`, {
      params: {
        studentXrefs: studentXrefs,
        startTime: startTime,
        endTime: endTime,
      },
    })
    .then((result) => {
      return result.data;
    });
};
export {
  getStudentAndPeerDataPerSkill,
  getProblemsGroupedByAssignmentForStandard,
};
