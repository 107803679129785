
import ProblemViewForFind from '@/components/base/ProblemViewForFind.vue';
import { ProblemSet } from '@/domain/ProblemSet';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { Problem } from '@/domain/Problem';
import { ParentPage } from '@/components/base/ProblemViewMenu.vue';
import ScrollObserver from '@/components/base/ScrollObserver.vue';

@Component({
  components: {
    ProblemViewForFind,
    ScrollObserver,
  },
})
export default class ProblemListView extends Vue {
  //If we can figure out how to get reactive objects that we don't need to mark as Object | null
  // Then we can get away from this "as unknown as Object" garbage.
  @Prop({ default: null }) problemSet: ProblemSet;
  @Prop({ default: [] }) problems: Problem[][];
  @Prop() parentPage: ParentPage;
  @Prop() value: number[];
  @Prop({ default: false }) selectAllByDefault: boolean;
  @Prop({ default: false }) deselectAll: boolean;

  isSkillBuilder = false;
  isInSearchResults = false;
  selectedAssistmentsBooleans: boolean[] = [];
  ParentPageEnum = ParentPage; // The enum definition

  emitDownloadMoreProblems(): void {
    this.$emit('downloadMoreProblems');
  }

  get isDownloadingProblems(): boolean {
    return this.$store.state.search.isDownloadingProblems;
  }

  get isObserverEnabled(): boolean {
    return (
      !this.isDownloadingProblems &&
      this.$store.state.search.shouldDownloadMoreProblems
    );
  }

  get numTotalProblems(): number {
    return this.problems.length;
  }
  get previewLink(): string {
    const usr = this.getCurrentUser;
    if (!this.problemSet) {
      return '';
    }
    return `${this.$store.state.urls.tutorTemplateUrl}?assignmentRef=PS${this.problemSet.id}&preview=true&pr=TNG&ut=${usr.userToken}&laId=${usr.loginAliasId}`;
  }
  //Check all checkbox
  get selectAllProblems(): boolean {
    return this.problems
      ? this.selectedAssistmentIds.length == this.problems.length
      : false;
  }
  set selectAllProblems(val: boolean) {
    this.selectedAssistmentsBooleans = this.selectedAssistmentsBooleans.map(
      () => val
    );
    this.$emit('input', this.selectedAssistmentIds);
  }

  toggleCheckbox(index: number, newValue: boolean): void {
    this.$set(this.selectedAssistmentsBooleans, index, newValue);

    this.$emit('input', this.selectedAssistmentIds);
  }

  get selectedAssistmentIds(): number[] {
    const selectedAssistmentIds: number[] = [];
    this.selectedAssistmentsBooleans.forEach(
      (isSelected: boolean, assistmentIndex) => {
        if (isSelected) {
          selectedAssistmentIds.push(
            this.problems[assistmentIndex][0].assistmentId
          );
        }
      }
    );
    return selectedAssistmentIds;
  }

  initialize(): void {
    // Initialize one boolean per assistment
    this.selectedAssistmentsBooleans = this.problems.map(
      () => this.selectAllByDefault
    );
  }

  // When user selects different skill, clear selected problems
  @Watch('deselectAll')
  onDeselectAll(newVal: boolean): void {
    if (newVal) {
      this.initialize();
      this.$emit('deselected-all');
      this.$emit('input', this.selectedAssistmentIds);
    }
  }

  created(): void {
    this.isInSearchResults =
      this.parentPage == this.ParentPageEnum.SEARCH_RESULTS;

    this.isSkillBuilder = this.problemSet
      ? this.problemSet.isSkillBuilder
      : false;

    this.initialize();
  }

  shouldObserverTrigger(index: number): boolean {
    // Break out if the observer is not enabled
    if (!this.isObserverEnabled) return false;
    /*
      We should download more problems when user scrolls
      past the first of the last (numofProblemsAfterObserver - 1)
      problems on the page, hence the threshold.
    */
    const problemsArrayLength: number = this.problems.length;
    const numOfProblemsAfterObserver = 5;

    const threshold = problemsArrayLength - numOfProblemsAfterObserver;

    // The observer is placed below the element whose index equals to threshold
    return index === threshold;
  }
}
