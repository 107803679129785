var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('TreeGradientTable',{attrs:{"color":_vm.color,"anonymized":_vm.anonymized,"headerTitle":_vm.headerTitle,"rowHeaders":_vm.rowHeaders,"rows":_vm.rows,"collapsedPaths":_vm.collapsedPaths,"options":_vm.options,"loading":_vm.loading},on:{"updateOptions":function($event){return _vm.$emit('updateOptions', $event)},"updateCollapsedPaths":function($event){return _vm.$emit('updateCollapsedPaths', $event)}},scopedSlots:_vm._u([{key:"avg-header",fn:function({ on, header }){return [_c('div',[_c('div',{staticClass:"header-name"}),_c('div',{staticClass:"d-flex align-center justify-center text-caption mt-2 clickable",on:{"click":function($event){return on.sort(header)}}},[_vm._v(" AVG "),_c('v-icon',{staticClass:"mx-1",attrs:{"small":"","color":"neutral darken-3"}},[_vm._v(" mdi-sort ")])],1)])]}},{key:"data-header",fn:function({ header }){return [_c('div',{staticClass:"ma-2"},[_c('div',{staticClass:"header-name text-caption text-left overflow-hidden"},[_vm._v(" "+_vm._s(header.text)+" ")]),_c('div',{staticClass:"header-problem-set neutral lighten-2 mt-2 px-3 clickable text-caption primary--text text--darken-2 overflow-hidden text-no-wrap text-truncate",on:{"click":function($event){return _vm.openProblemSetDialog(header.value)}}},[_vm._v(" "+_vm._s(header.value.split('_')[1])+" ")])])]}},{key:"data-cell",fn:function({ gradient, percentage, header, item }){return [_c('div',{staticClass:"d-inline-flex rounded-pill px-3 py-1",class:{
          clickable: item.type === _vm.RowType.COURSE,
          'chip-outline': percentage === 0,
        },style:({
          background: gradient,
          'border-color': percentage === 0 ? _vm.color : gradient,
        }),on:{"click":function($event){item.type === _vm.RowType.COURSE &&
            _vm.openAssignmentDialog(header.value, item)}}},[_c('span',{class:{
            'text-decoration-underline': item.type === _vm.RowType.COURSE,
          }},[_vm._v(" "+_vm._s(percentage)+"% ")])])]}},{key:"empty-cell",fn:function(){return [_c('span',[_vm._v("-")])]},proxy:true}])}),_c('SelectProblemSetIdDialog',{attrs:{"psCeris":_vm.psCeris},on:{"clickProblemSet":_vm.goToLessonProblemSet},model:{value:(_vm.problemSetDialog),callback:function ($$v) {_vm.problemSetDialog=$$v},expression:"problemSetDialog"}}),_c('SelectAssignmentIdDialog',{attrs:{"assignmentXrefs":_vm.assignmentXrefs},on:{"clickAssignment":function($event){return _vm.$emit('clickAssignment', $event)}},model:{value:(_vm.assignmentDialog),callback:function ($$v) {_vm.assignmentDialog=$$v},expression:"assignmentDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }