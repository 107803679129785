import { render, staticRenderFns } from "./SearchResultsSkillBuilders.vue?vue&type=template&id=2dcc568f&scoped=true&"
import script from "./SearchResultsSkillBuilders.vue?vue&type=script&lang=ts&"
export * from "./SearchResultsSkillBuilders.vue?vue&type=script&lang=ts&"
import style0 from "./SearchResultsSkillBuilders.vue?vue&type=style&index=0&id=2dcc568f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dcc568f",
  null
  
)

export default component.exports