var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"pv-response-description my-4"},[_vm._v(" "+_vm._s(_vm.getResponseDescription(_vm.problem.type))+" ")]),_c('div',[(
        Array(
          _vm.ProblemType.CHOOSE_N,
          _vm.ProblemType.CHOOSE_ONE,
          _vm.ProblemType.RANK
        ).includes(_vm.problem.type)
      )?_c('div',_vm._l((_vm.problem.answers),function(ans,index){return _c('div',{key:`${ans.value}_${index}`},[_c('v-row',{attrs:{"dense":""}},[(_vm.problem.type == _vm.ProblemType.CHOOSE_ONE)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-radio',{staticClass:"mt-0 pa-0",attrs:{"disabled":"","hide-details":""}})],1):(_vm.problem.type == _vm.ProblemType.CHOOSE_N)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{staticClass:"mt-0 pa-0",attrs:{"disabled":"","hide-details":""}})],1):(_vm.problem.type == _vm.ProblemType.RANK)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{staticStyle:{"width":"50px"},attrs:{"disabled":"","hide-details":"","single-line":"","outlined":"","dense":"","height":"15px","type":"number"}})],1):_vm._e(),_c('v-col',{staticClass:"align-center d-flex"},[_c('label',{domProps:{"innerHTML":_vm._s(ans.value)}})])],1)],1)}),0):(
        Array(
          _vm.ProblemType.FILL_IN,
          _vm.ProblemType.OLD_ALGEBRA,
          _vm.ProblemType.FILL_IN_IGNORE_CASE,
          _vm.ProblemType.NUMERIC,
          _vm.ProblemType.NUMERIC_EXPRESSION,
          _vm.ProblemType.EXACT_FRACTION,
          _vm.ProblemType.ALGEBRA
        ).includes(_vm.problem.type)
      )?_c('v-row',[_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('v-text-field',{attrs:{"disabled":"","outlined":"","dense":"","hide-details":""}})],1)],1):(_vm.problem.type == _vm.ProblemType.OPEN_RESPONSE)?_c('v-row',[_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('v-textarea',{attrs:{"disabled":"","outlined":"","dense":"","rows":"2","hide-details":""}})],1)],1):(
        Array(_vm.ProblemType.RACKET, _vm.ProblemType.IFRAME).includes(_vm.problem.type)
      )?_c('div'):_c('div',[_vm._v(" Something went wrong. Cannot display answers for this problem ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }