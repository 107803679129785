import {
  PartialAssignmentStudentData,
  TargetAssignmentStudentAndClassDataWithProblems,
} from '@/domain/ReportData/Cignition';
import { ldoeAxios } from '@/plugins/axios';

interface LdoeAssignmentLessonDetailsDTO {
  blob: string;
}

const END_POINT = '/assignments';

/**
 * Fetches the lesson details (LDOELesson) object using the assignment xref and extracts the string blob from it
 * @param assignmentXref
 * @returns Lesson details HTML blob as a string
 */
const getAssignmentLessonDetails = (
  assignmentXref: string
): Promise<string> => {
  return ldoeAxios
    .get<LdoeAssignmentLessonDetailsDTO>(
      `${END_POINT}/${assignmentXref}/lessons`
    )
    .then((res) => {
      return res.data.blob;
    });
};

const getCanonicalAssignmentStudentAndClassData = (
  assignmentXrefs: Array<string>
): Promise<TargetAssignmentStudentAndClassDataWithProblems> => {
  return ldoeAxios
    .get(`${END_POINT}`, {
      params: {
        assignmentXrefs: assignmentXrefs,
      },
    })
    .then((result) => {
      return result.data;
    });
};

const getMyStudentsAssignments = (
  studentXrefs: string[],
  startTime: number,
  endTime: number
): Promise<PartialAssignmentStudentData[]> => {
  return ldoeAxios
    .get(`${END_POINT}/find`, {
      params: {
        studentXrefs: studentXrefs,
        startTime: startTime,
        endTime: endTime,
      },
    })
    .then((result) => {
      return result.data;
    });
};

export {
  getAssignmentLessonDetails,
  getMyStudentsAssignments,
  getCanonicalAssignmentStudentAndClassData,
};
