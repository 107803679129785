import { VNextAction } from '../StudentProblemAction';

export interface BaseContentInfo {
  xref: string;
  active: boolean;
}

export interface ContentInfo extends BaseContentInfo {
  itemType: 'ASSIGNMENT';
}

export interface VnextStudentDataContentInfo extends BaseContentInfo {
  itemType: 'PROBLEM_SET';
}

export interface VNextCommonWrongAnswer {
  answer: string; // Answer (html)
  percent: number; // Percent of users who chose that answer
}

/////////////////
// CAS Web API //
/////////////////

export interface ProblemLog {
  id: number;
  prCeri: string;
  startTime: number;
  endTime: number;
  continuousScore: number;
  discreteScore?: number;
  answerText: string;
  firstActionType: string; // FIXME: Enum type?
  attemptCount: number;
  hintCount: number;
  sawAnswer: boolean;
  pathInfo: string;
  firstResponseTime: number;
  teacherComment: string;
  problemDbid: number;
}

export interface ProblemLogAndActions {
  prLog: ProblemLog;
  // Order by timestamp in ASC
  actions?: Array<VNextAction>;
}

export interface ProblemSetAction {
  actionType: string; // TODO: Enum type?
  timestamp: number;
  pathInfo: string;
  scaffolding: boolean;
}

export interface StudentLog {
  studentXref: string;
  asStartTime: number;
  asEndTime: number;
  psActions?: Array<ProblemSetAction>;
  problemLogAndActions?: Array<ProblemLogAndActions>;
}

export interface ProblemStats {
  prCeri: string; // ProblemXref
  cwas: Array<VNextCommonWrongAnswer>;
  avgScore: number;
  avgTime: number;
  problemDbid: number;
}

export interface StudentScore {
  studentXref: string;
  score: number;
}

export interface StudentStats extends StudentScore {
  timeSpent: number;
}

export interface AssignmentSummaryStats {
  avgScore: number;
  avgTime: number; // TODO: in milliseconds?
  studentStats: Array<StudentStats>;
}

export interface VnextStudentDataSummaryStats {
  avgScore: number;
  avgTime: number; // TODO: in milliseconds?
  studentScores: Array<StudentScore>;
}

export interface AssignmentReportData {
  contentInfo: ContentInfo;
  summaryStatsAll?: AssignmentSummaryStats;
  summaryStatsSubset?: AssignmentSummaryStats | null;
  prAllStats?: Array<ProblemStats>;
  prSubsetStats?: Array<ProblemStats> | null;
  studentLogs: Array<StudentLog>;
}

export interface VnextStudentData {
  contentInfo: VnextStudentDataContentInfo;
  summaryStatsAll?: VnextStudentDataSummaryStats;
  summaryStatsSubset?: VnextStudentDataSummaryStats | null;
  prAllStats?: Array<ProblemStats>;
  prSubsetStats?: Array<ProblemStats> | null;
  studentLogs: Array<StudentLog>;
}

export enum StudentDataReturnType {
  ASSIGNMENT_LOGS = 'ASSIGNMENT_LOGS',
  PROBLEM_LOGS = 'PROBLEM_LOGS',
  ACTION_LOGS = 'ACTION_LOGS',
  STATISTICS = 'STATISTICS',
}

export enum ActionGroupsType {
  ASSIGNMENT_ACTIONS = 'ASA',
  PROBLEM_SET_ACTIONS = 'PSA',
  PROBLEM_ACTIONS = 'PRA',
  RESPONSE_ACTIONS = 'RA',
  TUTORING_REQUEST_ACTIONS = 'TRA',
  TIMER_ACTIONS = 'TA',
  TUTORING_SET_ACTIONS = 'TSA',
  ODD_SOCK_ACTIONS = 'OSA',
}

export enum StatisticsForType {
  ALL_STUDENTS = 'ALL_STUDENTS',
  SPECIFIED_STUDENTS = 'SPECIFIED_STUDENTS',
}
