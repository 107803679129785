
import { Component, Vue, Prop } from 'vue-property-decorator';
import { FolderInfo } from '@/domain/FolderInfo';
import { getFolderChildren } from '@/api/folders.api';

@Component({
  components: {
    FolderGroup: () => import('./FolderGroup.vue'),
    Attribution: () => import('@/components/base/Attribution.vue'),
    EmptyFolderItem: () => import('./EmptyFolderItem.vue'),
  },
  // emits: {
  //   'folderOpened'
  // },
})
export default class FolderItem extends Vue {
  @Prop() item!: FolderInfo;
  @Prop() depth!: number;

  hasDownloaded = false;
  isDownloading = false;

  mounted(): void {
    this.$nextTick().then(() => {
      const scrollToElementSelector = '.open:not(.has-open-child)';
      const scrollToElement = document.querySelector(scrollToElementSelector);
      if (this.item.open && scrollToElement) {
        this.$vuetify.goTo(scrollToElementSelector);
      }
    });
  }

  created(): void {
    if (this.item.children && this.item.children.length > 0) {
      this.hasDownloaded = true; //don't re-download any children we already have.
      // if (this.item.open) {
      //   this.openFolder();
      // }
    }
  }

  openFolder(): void {
    if (!this.hasDownloaded && !this.isDownloading) {
      this.getChildren();
    }
    this.item.open = !this.item.open;

    if (this.item.open) {
      this.$emit('folder-opened');
    }
  }

  getChildren(): void {
    this.isDownloading = true;
    var thisItem = this.item;
    getFolderChildren(thisItem.id)
      .then((children) => {
        thisItem.children = children;
        this.isDownloading = false;
        this.hasDownloaded = true;
      })
      .catch(() => {
        //We have no error handling. mark folder as empty for now
        this.isDownloading = false;
        this.hasDownloaded = true;
        thisItem.children = [];
      });
  }

  get hasOpenChild(): boolean {
    let openChild = false;
    if (this.item.open && this.item.children) {
      this.item.children.forEach((i) => {
        let childActive = i.open ? i.open : false;
        openChild = openChild || childActive;
      });
    }

    return openChild;
  }

  get folderHeader(): string {
    let n = this.depth + 3;
    if (n > 5) {
      //5 is max
      n = 5;
    }
    return `text-h${n}`;
  }
}
