
import { Component, Vue } from 'vue-property-decorator';
import { logOut } from '@/api/auth.api';
import { RawLocation } from 'vue-router';
import LoginButton from '@/components/base/LoginButton.vue';

interface NavigationItem {
  title: string;
  icon: string;
  link?: RawLocation;
  cypressAttribute?: string;
  onClick?(): unknown;
}

@Component({
  components: {
    LoginButton,
  },
})
export default class NavBar extends Vue {
  isDrawerCollapsed = false;
  navItems: Array<NavigationItem> = [
    {
      title: 'Find & Assign',
      link: { name: 'findProblems' },
      icon: 'mdi-magnify',
      cypressAttribute: 'find-problems-tab',
    },
    {
      title: 'My Assignments',
      link: { name: 'myAssignments' },
      icon: 'mdi-clipboard-text',
      cypressAttribute: 'my-assignments-tab',
    },
    {
      title: 'My Problem Sets',
      link: { name: 'myProblemSets' },
      icon: 'mdi-folder-heart',
      cypressAttribute: 'my-problem-sets-tab',
    },
  ];

  menuItems: Array<NavigationItem> = [
    {
      title: 'Settings',
      icon: 'mdi-cog',
      onClick: this.navigateToSettings,
      cypressAttribute: 'account-menu-settings-btn',
    },
    {
      title: 'Log Out',
      icon: 'mdi-logout',
      onClick: logOut,
      cypressAttribute: 'account-menu-logout-btn',
    },
  ];

  //Consider a better way for handling this so that the restricted paths don't grow forever (maybe the router)
  get hideNavDrawer(): boolean {
    const restrictedPaths: string[] = [
      'cignition',
      'settings',
      'registration',
      'student',
    ];

    return restrictedPaths.some((path) => this.$route.path.includes(path));
  }

  get navbarTitle(): string {
    return `${
      process.env.VUE_APP_APPEND_TITLE
        ? `${process.env.VUE_APP_APPEND_TITLE} ${process.env.VUE_APP_VERSION}`
        : ''
    }`;
  }
  get displayName(): string {
    return this.$store.state.auth.user.displayName;
  }
  get userName(): string {
    return this.$store.state.auth.user.userName;
  }

  get tutorReportAccess(): boolean {
    return this.$store.state.auth.user?.settings.accessToLDOEReport || false;
  }

  get insightsHubAccess(): boolean {
    return this.$store.state.auth.user?.settings.accessToInsightsHub || false;
  }

  get collapseMenuButtonIcon(): string {
    const arrowIconDirection = this.isDrawerCollapsed ? 'right' : 'left';

    return `mdi-arrow-${arrowIconDirection}`;
  }

  collapseDrawer(): void {
    this.isDrawerCollapsed = !this.isDrawerCollapsed;
  }

  navigateToSettings(): void {
    this.$router.push({
      name: 'accountSettings',
    });
  }

  mounted(): void {
    if (this.tutorReportAccess) {
      const tutorReportNavItem: NavigationItem = {
        title: 'Tutor Report',
        link: { name: 'tutorReport' },
        icon: 'mdi-account-group',
        cypressAttribute: 'ldoe-tab',
      };

      this.navItems.push(tutorReportNavItem);
    }

    if (this.insightsHubAccess) {
      const curriculumViewNavItem: NavigationItem = {
        title: 'Curriculum View',
        link: { name: 'insightsHubLanding' },
        icon: 'mdi-chart-bar',
        cypressAttribute: 'insights-hub-tab',
      };

      this.navItems.push(curriculumViewNavItem);
    }
  }
}
