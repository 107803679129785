var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"id":"goToPsDialog","max-width":"560px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{attrs:{"data-cy":"ps-dialog"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"text-h3",attrs:{"role":"heading"}},[_vm._v("Go to Problem Set")]),_c('v-icon',{attrs:{"x-large":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("mdi-close")])],1),_c('v-card-text',[_c('p',{attrs:{"id":"goToPsExposition"}},[_vm._v(" This problem is included in multiple problem sets. Which one would you like to go to? ")]),(_vm.loading)?_c('div',{staticClass:"d-flex justify-center pa-4"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","data-cy":"ps-dialog-loader"}})],1):_c('div',_vm._l((_vm.parentProblemSets),function(ps){return _c('div',{key:ps.problemSetId,staticClass:"parent-problem-set mb-4"},[_c('div',{staticClass:"overline"},[_vm._v(_vm._s(ps.problemSetRootFolderName))]),_c('router-link',{staticClass:"ps-link text-label text-decoration-none",attrs:{"data-cy":"ps-link","to":{
              name: 'LessonPage',
              params: {
                type: 'ps',
                psId: `${ps.problemSetId}`,
                id: `${ps.problemSetId}`,
              },
              query: {
                skillId: `${_vm.$route.params.skillId}`,
              },
            }}},[_vm._v(" "+_vm._s(ps.problemSetName)+" ")])],1)}),0)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }