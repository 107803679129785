import { tngAxios, loginPortalAxios } from '@/plugins/axios';
import { removeAuthCookie } from '@/utils/cookie.util';
import store from '@/store/index';

const loginMock = (redirectTo: string): Promise<void> => {
  return tngAxios
    .get('/loginMock', {
      params: {
        redirectTo,
      },
    })
    .then((res) => {
      return res.data;
    });
};

const logOut = (): Promise<void> => {
  // Navigate home once the request has been completed
  return loginPortalAxios.get('/signout').then(() => {
    removeAuthCookie();
    store.commit('auth/setUser', null);
    window.location.href = '/';
  });
};

export { loginMock, logOut };
