
import { CurriculumDefinition, ModuleDefinition } from '@/domain/Curriculum';
import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class DashboardHeader extends Vue {
  // Selected Curriculum
  get selectedCurriculumXref(): string | null {
    return this.$store.state.insightsHub.selectedCurriculumXref;
  }
  get curriculumName(): string {
    const curricula = this.$store.state.curricula.downloadedDefinitions;

    const target = curricula.find(
      (curriculum: CurriculumDefinition) =>
        curriculum.xref === this.selectedCurriculumXref
    );

    return target?.name ?? '';
  }
  get gradeName(): string {
    return this.$store.getters['insightsHub/selectedGradeFolder']?.name || '';
  }
  get selectedModule(): ModuleDefinition | null {
    return this.$store.getters['insightsHub/selectedModuleDefinition'];
  }
  get moduleTitle(): string {
    return this.$store.state.insightsHub.curriculumGrade.moduleTitle ?? '';
  }
  get headerTitle(): string {
    if (this.selectedModule) {
      return `${this.moduleTitle} ${this.selectedModule.moduleNumber}: ${this.selectedModule.moduleName}`;
    }
    return this.gradeName;
  }

  clearSelectedModule(): void {
    this.$store.commit('insightsHub/setSelectedModuleId', null);
    this.$store.commit('insightsHub/setSelectedTab', null);
  }
}
