
import { Component, Vue } from 'vue-property-decorator';
import LoginButton from '@/components/base/LoginButton.vue';

@Component({
  components: {
    LoginButton,
  },
})
export default class UnauthenticatedPage extends Vue {
  /**
   * Vue is smart enough to know that this component is reused
   * when switching between pages that require authentication,
   * and thus only creates this once, updating the query param (the redirect URL)
   * based on which resource the user requested access to
   * https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
   */
  // get redirectUrl(): string {
  //   // Navigate home otherwise
  //   return this.$route.query.redirect
  //     ? (this.$route.query.redirect as string)
  //     : '/';
  // }
}
