
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getStudentAndPeerDataPerSkill } from '@/api/cignition/skillData.api';
import { getMyTuteesAssignments } from '@/api/cignition/assignment.api';
import UnauthenticatedView from '@/components/base/UnauthenticatedView.vue';
import NotFoundView from '@/components/base/NotFoundView.vue';
import UnauthorizedView from '@/components/Cignition/base/UnauthorizedView.vue';
import WeeklySelect from '@/components/Cignition/base/WeeklySelect.vue';
import NoStudentSelectedView from '@/components/Cignition/TutoringDashboard/NoStudentSelectedView.vue';
import SkillBarChartViewForReport from '@/components/Cignition/TutoringDashboard/SkillBarChartViewForReport.vue';
import AssignmentStudentDataTableView, {
  CustomTableLabel,
} from '@/components/Cignition/TutoringDashboard/AssignmentStudentDataTableView.vue';
import {
  PartialAssignmentStudentData,
  StudentAndPeerDataPerSkill,
} from '@/domain/ReportData/Cignition';
import { TimeFrame } from '@/domain/Time';
import { User } from '@/domain/User';
import CignitionMixins from '@/mixins/cignition-report';
import { CustomDataSetLabel } from '@/components/Report/BarChartView.vue';

interface CustomQueryParams {
  tutees: string[];
}

@Component({
  components: {
    UnauthenticatedView,
    NotFoundView,
    UnauthorizedView,
    WeeklySelect,
    NoStudentSelectedView,
    SkillBarChartViewForReport,
    AssignmentStudentDataTableView,
  },
})
export default class TutoringDashboardPage extends Mixins(CignitionMixins) {
  unauthorized = false;
  skillData: Array<StudentAndPeerDataPerSkill> = [];
  assignmentData: Array<PartialAssignmentStudentData> = [];

  ////////////////////
  // Loading States //
  ////////////////////
  // Store Data Loading
  get isDownloadingSkills(): boolean {
    return this.$store.state.skillList.isDownloading;
  }
  get isDownloadingTutees(): boolean {
    return this.$store.state.cignition.isDownloadingTutees;
  }
  // Page Data Loading
  isDownloadingSkillData = false;
  isDownloadingAssignmentData = false;

  ///////////////////////////////
  // Tutee xrefs for selectors //
  ///////////////////////////////
  primaryTuteeXrefInternal: string | null = null;
  secondaryTuteeXrefInternal: string | null = null;

  get primaryTuteeXref(): string | null {
    return this.primaryTuteeXrefInternal;
  }

  set primaryTuteeXref(value: string | null) {
    this.primaryTuteeXrefInternal = value;
    this.onTuteeChange();
  }

  get secondaryTuteeXref(): string | null {
    return this.secondaryTuteeXrefInternal;
  }

  set secondaryTuteeXref(value: string | null) {
    this.secondaryTuteeXrefInternal = value;
    this.onTuteeChange();
  }

  ////////////////////////////
  // Tutee List & Selection //
  ////////////////////////////

  /**
   * @returns selected time frame from component
   */
  get weekSelected(): TimeFrame | null {
    return this.$store.getters['cignition/getSelectedTimeFrame'];
  }

  /**
   * @returns full list of tutees for current user (from store)
   */
  get tutees(): Array<User> {
    return this.$store.state.cignition.tutees;
  }
  /**
   * @returns list of currently selected tutees xrefs
   */
  get tuteeXrefs(): Array<string> {
    let tuteeXrefs: Array<string> = [];
    if (this.primaryTuteeXref) {
      tuteeXrefs.push(this.primaryTuteeXref);
    }

    if (this.secondaryTuteeXref) {
      tuteeXrefs.push(this.secondaryTuteeXref);
    }

    return tuteeXrefs;
  }

  get noTuteeSelected(): boolean {
    return (
      this.primaryTuteeSelected == null && this.secondaryTuteeSelected == null
    );
  }
  /**
   * @returns User Obj for Primary Tutee
   * @returns null if 0 tutees selected
   */
  get primaryTuteeSelected(): User | null {
    const tutees = this.$store.getters['cignition/getSelectedTutees'];
    return tutees.length > 0 ? tutees[0] : null;
  }
  /**
   * @returns User Obj for Secondary Tutee
   * @returns null if 1 or 0 tutees selected
   */
  get secondaryTuteeSelected(): User | null {
    const tutees = this.$store.getters['cignition/getSelectedTutees'];
    return tutees.length > 1 ? tutees[1] : null;
  }
  // Labels for dropdowns
  get primaryTuteeLabel(): string {
    if (this.primaryTuteeSelected) {
      return 'Student 1';
    }
    return 'Select Student 1';
  }
  get secondaryTuteeLabel(): string {
    if (this.secondaryTuteeSelected) {
      return 'Student 2';
    }
    return 'Select Student 2';
  }
  // Initials
  get primaryTuteeInitials(): string {
    return this.primaryTuteeSelected
      ? this.primaryTuteeSelected.firstName.charAt(0) +
          this.primaryTuteeSelected.lastName.charAt(0)
      : '';
  }
  get secondaryTuteeInitials(): string {
    return this.secondaryTuteeSelected
      ? this.secondaryTuteeSelected.firstName.charAt(0) +
          this.secondaryTuteeSelected.lastName.charAt(0)
      : '';
  }

  ////////////////
  // Table Data //
  ////////////////
  get customTableLabels(): Array<CustomTableLabel> {
    const customLabels: Array<CustomTableLabel> = [];

    if (this.primaryTuteeSelected && this.primaryTuteeSelected.xref) {
      customLabels.push({
        label: this.primaryTuteeInitials,
        value: this.primaryTuteeSelected.xref,
        backgroundColor: this.primaryTuteeColor,
      });
    }

    if (this.secondaryTuteeSelected && this.secondaryTuteeSelected.xref) {
      customLabels.push({
        label: this.secondaryTuteeInitials,
        value: this.secondaryTuteeSelected.xref,
        backgroundColor: this.secondaryTuteeColor,
      });
    }

    return customLabels;
  }

  ////////////////
  // Graph Data //
  ////////////////

  get customChartLabels(): Array<CustomDataSetLabel> {
    const customLabels: Array<CustomDataSetLabel> = [];

    if (this.primaryTuteeSelected && this.primaryTuteeSelected.xref) {
      customLabels.push({
        xref: this.primaryTuteeSelected.xref,
        label: this.primaryTuteeSelected.displayName,
        backgroundColor: this.primaryTuteeColor,
      });
    }

    if (this.secondaryTuteeSelected && this.secondaryTuteeSelected.xref) {
      customLabels.push({
        xref: this.secondaryTuteeSelected.xref,
        label: this.secondaryTuteeSelected.displayName,
        backgroundColor: this.secondaryTuteeColor,
      });
    }

    return customLabels;
  }

  ////////////////////
  // Initialization //
  ////////////////////
  updateQueryParam(queryParams: Partial<CustomQueryParams>): void {
    // Update the URL
    this.$router.replace({
      name: 'tutoringDashboard',
      query: {
        ...this.$route.query,
        ...queryParams,
      },
    });
  }

  //to be called when tutees have changed
  onTuteeChange(): void {
    this.updateQueryParam({
      tutees: this.tuteeXrefs,
    });
    this.$store.commit('cignition/setSelectedTuteeXrefs', this.tuteeXrefs);
    if (!this.noTuteeSelected) {
      this.downloadData();
    }
  }

  // Watchers
  @Watch('weekSelected')
  onWeekChange(): void {
    if (!this.noTuteeSelected) {
      this.downloadData();
    }
  }
  // If user logs in/signs up, fetch their assignments
  @Watch('isAuthenticated')
  handleAuthChange(): void {
    if (!this.isAuthenticated) {
      // FIXME:
      // this.goToLoginPortal();
    }
  }

  // Download helpers
  initializeSelectedTutees(): void {
    const selectedXrefs = this.$store.state.cignition.selectedTuteeXrefs;

    // initialize internal xref params for the selectors. Do not navigate and do not put back into the store
    // The store already has the data from the CignitionParent.
    this.primaryTuteeXrefInternal =
      selectedXrefs.length > 0 ? selectedXrefs[0] : null;
    this.secondaryTuteeXrefInternal =
      selectedXrefs.length > 1 ? selectedXrefs[1] : null;

    if (!this.noTuteeSelected) {
      this.downloadData();
    }
  }
  download(promises: Array<Promise<void>>): Promise<void> {
    return Promise.all(promises)
      .then(() => {
        return;
      })
      .catch((error) => {
        // Any errors
        if (error.response.status === 403) {
          // FIXME: Clear data?
          this.unauthorized = true;
        }
        return Promise.reject(error);
      });
  }
  downloadSkillData(): Promise<void> {
    if (this.weekSelected) {
      this.isDownloadingSkillData = true;
      this.skillData = []; // Clear skill data

      const promise = getStudentAndPeerDataPerSkill(
        this.tuteeXrefs,
        this.weekSelected.startDateTime,
        this.weekSelected.endDateTime
      )
        .then((skillData) => {
          this.skillData = skillData;
          this.isDownloadingSkillData = false;
        })
        .catch((error) => {
          return Promise.reject(error);
        });

      return promise;
    }

    return Promise.reject();
  }
  downloadAssignmentData(): Promise<void> {
    if (this.weekSelected) {
      this.isDownloadingAssignmentData = true;
      this.assignmentData = []; // Clear table data

      const promise = getMyTuteesAssignments(
        this.tuteeXrefs,
        this.weekSelected.startDateTime,
        this.weekSelected.endDateTime
      )
        .then((assignmentData) => {
          this.assignmentData = assignmentData;
          this.isDownloadingAssignmentData = false;
        })
        .catch((error) => {
          return Promise.reject(error);
        });

      return promise;
    }

    return Promise.reject();
  }
  downloadData(): void {
    if (this.tuteeXrefs.length > 0 && this.weekSelected) {
      this.download([
        this.downloadSkillData(),
        this.downloadAssignmentData(),
      ]).catch(() => {
        this.isDownloadingSkillData = false;
        this.isDownloadingAssignmentData = false;
      });
    }
  }
  mounted(): void {
    this.initializeSelectedTutees();
  }
}
