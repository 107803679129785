import mixpanel from 'mixpanel-browser';

// production key (c394493de7e5543717c82fd343388893)
// dev key (06a9963a1a6e3ac8bfc9d06dd0dfe87c)

// production
// TODO change api_host to production nginx address
mixpanel.init('c394493de7e5543717c82fd343388893', {
  api_host: 'https://mxpnl.assistments.org',
});

enum AssignmentReportType {
  assignmentReport = 'Assignment Report',
  masteryReport = 'Mastery Report',
  studentDetaisReport = 'Student Details Report',
  ldoeTutorReport = 'LDOE Tutor Report',
}

enum EventType {
  reportViewed = 'Report Viewed',
  irClick = 'IRClick',
  qcCommentSelection = 'Selected QUICK-Comments Suggested Comment',
  qcScoreSelection = 'Selected QUICK-Comments Suggested Score',
  essayScoringSetting = 'Essay Scoring Page Setting',
}

export { mixpanel, AssignmentReportType, EventType };
