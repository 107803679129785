import { ActionTree } from 'vuex';
import { SearchState } from './types';
import { RootState } from '../types';
import {
  getProblemsBySkill,
  getSkillBuildersBySkill,
  ProblemsFilterAndPagingParams,
  searchForProblemsBySkill,
} from '@/api/pspr.api';
import { Problem, ProblemList } from '@/domain/Problem';
import { ProblemSet } from '@/domain/ProblemSet';
import { CancelToken } from 'axios';

export const actions: ActionTree<SearchState, RootState> = {
  requestProblemsBySkill(context, skillId: number): Promise<Problem[][]> {
    return getProblemsBySkill(skillId).then((groupedProblems) => {
      context.commit('setProblems', groupedProblems);
      return groupedProblems;
    });
  },
  requestSkillBuildersBySkill(context, skillId: number): Promise<ProblemSet[]> {
    return getSkillBuildersBySkill(skillId).then((skillBuilders) => {
      context.commit('setSkillBuilders', skillBuilders);
      return skillBuilders;
    });
  },
  searchProblemsBySkill(
    context,
    payload: {
      params: ProblemsFilterAndPagingParams;
      cancelToken: CancelToken;
    }
  ): Promise<ProblemList> {
    // If this is the first search, then reset before requesting problems
    if (!payload.params.nextPageToken) {
      context.commit('resetProblemSearch');
    }
    context.commit('setIsDownloadingProblems', true);
    return searchForProblemsBySkill(payload.params, payload.cancelToken)
      .then((result) => {
        context.commit('setProblems', result.problems);
        context.commit('setNextPageToken', result.nextPageToken);
        if (typeof result.numProblems === 'number' && result.numProblems >= 0) {
          context.commit('setNumProblems', result.numProblems);
        }
        return result;
      })
      .catch(() => {
        const errorResponse: ProblemList = {
          problems: [],
        };
        // If any errors are thrown, disable further redownloading
        context.commit('setShouldDownloadMoreProblems', false);

        return errorResponse;
      })
      .finally(() => {
        context.commit('setIsDownloadingProblems', false);
      });
  },
};
