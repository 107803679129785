import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LDOEMixins extends Vue {
  get firstTuteeColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.primary.darken2;
  }

  get secondTuteeColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.primary.base;
  }

  get thirdTuteeColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.base;
  }

  get fourthTuteeColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.darken2;
  }

  get labelColors(): Array<string> {
    // eslint-disable-next-line
    // @ts-ignore
    return [
      this.firstTuteeColor,
      this.secondTuteeColor,
      this.thirdTuteeColor,
      this.fourthTuteeColor,
    ];
  }

  get classAverageColor(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.$vuetify.theme.themes.light.neutral.darken3;
  }
}
