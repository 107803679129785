
import { Component, Vue } from 'vue-property-decorator';
import FolderBrowser from '@/components/FindProblems/FindProblemsPage/FolderBrowser.vue';
import FindProblemsTopBar from '@/components/FindProblems/FindProblemsPage/FindProblemsTopBar.vue';
import { FolderInfo } from '@/domain/FolderInfo';
import { getFolder, getFolderChildren } from '@/api/folders.api';

@Component({
  components: {
    FolderBrowser,
    FindProblemsTopBar,
  },
})
export default class FindProblemsPage extends Vue {
  //If we can figure out how to get reactive objects that we don't need to mark as Object | null
  // Then we can get away from this "as unknown as Object" garbage.
  rootFolder: FolderInfo = null as unknown as FolderInfo;
  initialized = false;

  init(): void {
    let outer = 86954;
    let path = this.$route.query.p as string;
    let ids: number[] = [];

    if (path && path.length > 0) {
      ids = path.split(',').map((id) => Number(id));
      outer = ids.shift() as number; //define a root folder
    }

    //Get the folder
    getFolder(outer).then((response) => {
      this.rootFolder = response;
      this.rootFolder.open = true;
      this.initOpenChildren(this.rootFolder, ids);
    });
  }

  initOpenChildren(currentFolder: FolderInfo, openIds: number[]): void {
    //gather children
    getFolderChildren(currentFolder.id).then((children) => {
      currentFolder.children = children;

      if (
        openIds &&
        openIds.length > 0 &&
        currentFolder.children &&
        currentFolder.children.length > 0
      ) {
        //IF we are meant to open more folders and we have children.
        let childId = openIds.shift();
        let found = false;

        for (let fi of currentFolder.children) {
          if (fi.id === childId) {
            fi.open = true;
            found = true;
            this.initOpenChildren(fi, openIds);
            break;
          }
        }

        if (!found) {
          this.initialized = true;
        }
      } else {
        this.initialized = true;
      }
    });
  }

  created(): void {
    this.init();
  }

  childFolderOpened(): void {
    (this.$refs.searchComp as FindProblemsTopBar).dense = true;
  }
}
