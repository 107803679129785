export interface Problem {
  id: number;
  xref: string;
  body: string;
  type: ProblemType;
  assistmentId: number;
  answers?: Array<Answer>;
  parentProblemSetIds?: Array<number>;
  position: number; // int 1-n determines partLetter
  curriculumXrefs?: Array<string>;
  attributionXrefs?: Array<string>;
  skillIds?: Array<number>;
  /* Custom Attributes */
  assistmentPosition?: number;
  partLetter: string | null;
}

export interface Answer {
  value: string;
  isCorrect: boolean;
}

export enum ProblemType {
  CHOOSE_ONE = 'CHOOSE_ONE',
  CHOOSE_N = 'CHOOSE_N',
  RANK = 'RANK',
  FILL_IN = 'FILL_IN',
  OLD_ALGEBRA = 'OLD_ALGEBRA',
  OPEN_RESPONSE = 'OPEN_RESPONSE',
  RACKET = 'RACKET',
  FILL_IN_IGNORE_CASE = 'FILL_IN_IGNORE_CASE',
  IFRAME = 'IFRAME',
  NUMERIC = 'NUMERIC',
  NUMERIC_EXPRESSION = 'NUMERIC_EXPRESSION',
  EXACT_FRACTION = 'EXACT_FRACTION',
  ALGEBRA = 'ALGEBRA',
}

export interface ProblemList {
  problems: Problem[][];
  nextPageToken?: string;
  numProblems?: number;
}

/**
 * These enum values are used on the back-end,
 * but the server sends the keys rather than values.
 * The above fix can be rolled back if the server
 * is switched to send enum values, rather than keys
 */
// export enum ProblemType {
// CHOOSE_ONE = 1,
// CHOOSE_N = 2,
// RANK = 3,
// FILL_IN = 4,
// OLD_ALGEBRA = 5,
// OPEN_RESPONSE = 8,
// RACKET = 10,
// FILL_IN_IGNORE_CASE = 11,
// IFRAME = 12,
// NUMERIC = 13,
// NUMERIC_EXPRESSION = 14,
// EXACT_FRACTION = 15,
// ALGEBRA = 17,
// }
