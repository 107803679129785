
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Problem } from '@/domain/Problem';
import { ProblemLog } from '@/domain/ReportData/AssignmentData';
import ProblemView from '@/components/base/ProblemView.vue';
import ScoreChip from './ScoreChip.vue';
import WirisMixins from '@/mixins/wiris';

@Component({
  components: {
    ProblemView,
    ScoreChip,
  },
})
export default class StudentAssignmentReportSideSheet extends Mixins(
  WirisMixins
) {
  @Prop({ default: true }) value: boolean;
  @Prop({ required: true }) problem: Problem;
  @Prop({ required: true }) problemLog: ProblemLog;
  @Prop({ required: true }) disableNext: boolean;
  @Prop({ required: true }) disablePrevious: boolean;

  get showDrawer(): boolean {
    return this.value;
  }

  set showDrawer(val: boolean) {
    this.$emit('input', val);
  }

  get title(): string {
    let title = 'Problem';

    // Problem number
    if (this.problem.assistmentPosition) {
      title += ' ' + this.problem.assistmentPosition;
    }

    if (this.problem.partLetter) {
      // A subproblem so include part letter
      return (title += ': ' + this.problem.partLetter);
    }

    return title;
  }

  get score(): number {
    const value =
      this.problemLog && this.problemLog.continuousScore
        ? this.problemLog.continuousScore
        : 0;

    return Math.round(value * 100);
  }

  get answer(): string {
    return this.problemLog.answerText;
  }

  updated(): void {
    this.onComponentMountOrUpdate();
  }

  mounted(): void {
    this.onComponentMountOrUpdate();
  }

  private attemptParsingWiris(): void {
    this.checkForWirisResponse(this.answer);
  }

  private attachClickEventListenersToImages(): void {
    const wrapper = document.getElementById('answer-output');

    if (!wrapper) {
      return;
    }

    const images = Array.from(wrapper.getElementsByTagName('img'));

    if (images.length === 0) {
      return;
    }

    images.forEach((image) => {
      const source = image.getAttribute('src');

      if (source) {
        image.addEventListener('click', () => this.openImageModal(source));
      }
    });
  }

  private openImageModal(source: string): void {
    this.$emit('openImageModal', source);
  }

  private onComponentMountOrUpdate(): void {
    this.attemptParsingWiris();
    this.attachClickEventListenersToImages();
  }
}
