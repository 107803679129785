
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Class } from '@/domain/Class';
import {
  AssignmentDefinition,
  AssignmentStatus,
  AssignmentSort,
} from '@/domain/Assignment';
import AssignmentView from '@/components/MyAssignments/AssignmentView.vue';
import NoAssignmentView from '@/components/MyAssignments/NoAssignmentView.vue';
import ScrollObserver from '@/components/base/ScrollObserver.vue';
import {
  AssignmentFilterAndSortParams,
  getAssignmentDefinitions,
} from '@/api/core/assignment.api';
import axios, { CancelTokenSource } from 'axios';
import dayjs from 'dayjs';
import { IncludeAttrSettings } from '@/domain/IncludeAttrSettings';

@Component({
  components: {
    AssignmentView,
    NoAssignmentView,
    ScrollObserver,
  },
})
export default class MyAssignmentsPage extends Vue {
  statusOptions = [
    { text: 'Upcoming', value: AssignmentStatus.UPCOMING },
    { text: 'Released', value: AssignmentStatus.RELEASED },
    { text: 'Past Due', value: AssignmentStatus.PAST_DUE },
  ];
  // FIXME: Confirm these sortBy field values
  sortByOptions = [
    {
      text: 'Due Date (Ascending)',
      value: { attribute: 'dueDate', sortBy: '+DUE_DATE' },
    },
    {
      text: 'Due Date (Descending)',
      value: { attribute: 'dueDate', sortBy: '-DUE_DATE' },
    },
    {
      text: 'Release Date (Ascending)',
      value: { attribute: 'releaseDate', sortBy: '+RELEASE_DATE' },
    },
    {
      text: 'Release Date (Descending)',
      value: { attribute: 'releaseDate', sortBy: '-RELEASE_DATE' },
    },
  ];

  isDownloadingAssignments = false;
  hasDownloadedAssignments = false;
  isDowloadingMore = false;
  assignments: AssignmentDefinition[] = [];

  // Paging
  limit = 20;
  nextPageToken: string | null = null;

  // Search
  source: CancelTokenSource | null = null;

  //////////////
  // Computed //
  //////////////

  /**
   * Store
   */
  get classes(): Array<Class> {
    return this.$store.state.classList.classes;
  }
  get classIds(): Array<string> {
    return this.classes.map((c) => c.id);
  }
  /**
   * Display & Rendering
   */
  get filters(): AssignmentFilterAndSortParams {
    const params: AssignmentFilterAndSortParams = {
      sortBy: this.selectedSort.sortBy,
      limit: this.limit,
      include: [IncludeAttrSettings.SETTINGS],
    };

    if (this.selectedClasses.length > 0) {
      params.courseXrefs = this.selectedClasses;
    }

    if (this.selectedStatuses.length > 0) {
      //We want to consider putting this in a better long term location. We want our statuses to match the api
      // release/due date filters
      let releaseDate = '';
      let dueDate = '';
      // let assignDate = null;
      let now = dayjs().valueOf();

      for (let status of this.selectedStatuses) {
        switch (status) {
          case AssignmentStatus.UPCOMING:
            if (releaseDate) {
              releaseDate += '[OR]';
            }

            releaseDate += `GT:${now}`;
            break;
          case AssignmentStatus.RELEASED:
            if (releaseDate) {
              releaseDate += '[OR]';
            }

            releaseDate += `LT:${now}`;
            break;
          case AssignmentStatus.PAST_DUE:
            dueDate += `LT:${now}`;
            break;
        }
      }

      if (releaseDate.length > 0) {
        params.releaseDate = releaseDate;
      }

      if (dueDate.length > 0) {
        params.dueDate = dueDate;
      }
    }

    return params;
  }

  /////////////
  // Methods //
  /////////////
  get selectedClasses(): Array<string> {
    if (this.$route.query.selectedClasses) {
      return (this.$route.query.selectedClasses as string).split(',');
    }
    return [];
  }

  set selectedClasses(newVal: Array<string>) {
    this.updateRouter({ selectedClasses: newVal.join(',') || undefined });
  }

  get selectedStatuses(): Array<AssignmentStatus> {
    if (this.$route.query.selectedStatuses) {
      return (
        (this.$route.query.selectedStatuses as string)
          .split(',')
          // Filter illegal statuses and cast array to AssignmentStatus[]
          .filter((status) => status in AssignmentStatus) as AssignmentStatus[]
      );
    }
    return [];
  }

  set selectedStatuses(newVal: Array<AssignmentStatus>) {
    this.updateRouter({ selectedStatuses: newVal.join(',') || undefined });
  }

  get selectedSort(): AssignmentSort {
    if (this.$route.query.sortAttribute && this.$route.query.sortDirection) {
      const { sortAttribute, sortDirection } = this.$route.query;
      if (sortAttribute === 'releaseDate' || sortAttribute === 'dueDate') {
        if (
          sortDirection === '+DUE_DATE' ||
          sortDirection === '-DUE_DATE' ||
          sortDirection === '+RELEASE_DATE' ||
          sortDirection === '-RELEASE_DATE'
        ) {
          return {
            attribute: sortAttribute,
            sortBy: sortDirection,
          };
        }
      }
    }
    return { attribute: 'releaseDate', sortBy: '-RELEASE_DATE' };
  }

  set selectedSort(newVal: AssignmentSort) {
    this.updateRouter({
      sortAttribute: newVal.attribute,
      sortDirection: newVal.sortBy,
    });
  }

  updateRouter(obj: {
    sortAttribute?: string;
    sortDirection?: string;
    selectedStatuses?: string;
    selectedClasses?: string;
  }): void {
    const query = { ...this.$route.query, ...obj };

    this.$router.replace({ query });
  }

  downloadAssignments(): void {
    // Loading state to indicate loading initial set assignments
    this.isDownloadingAssignments = true;
    this.hasDownloadedAssignments = false;

    if (this.source) {
      // Cancel prior requests with this cancel token
      this.source.cancel();
    }

    // Clear out prior results
    this.assignments = [];
    this.nextPageToken = null;

    // Use share cancel token for search and paging requests
    this.source = axios.CancelToken.source();

    getAssignmentDefinitions(this.filters, this.source?.token)
      .then((meta) => {
        this.assignments = meta.assignments;
        this.nextPageToken = meta.nextPageToken;
        this.hasDownloadedAssignments = true;
      })
      .finally(() => {
        this.isDownloadingAssignments = false;
      });
  }
  downloadMore(): void {
    if (!this.isDowloadingMore && this.nextPageToken) {
      this.isDowloadingMore = true;
      getAssignmentDefinitions(
        {
          ...this.filters,
          nextPageToken: this.nextPageToken,
        },
        this.source?.token
      )
        .then((meta) => {
          this.assignments.push(...meta.assignments);
          this.nextPageToken = meta.nextPageToken;
        })
        .finally(() => {
          this.isDowloadingMore = false;
        });
    }
  }
  addAssignments(): void {
    this.downloadAssignments();
  }
  removeAssignment(): void {
    this.downloadAssignments();
  }
  created(): void {
    // Should already be logged in to be on this page

    // Download classes if not already
    this.$store.dispatch('classList/requestClasses');

    // Download assignments
    this.downloadAssignments();
  }

  /////////////
  // Watcher //
  /////////////
  @Watch('$route.query')
  onQueryChange(): void {
    this.downloadAssignments();
  }
}
