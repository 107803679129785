
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActionType } from '@/domain/StudentProblemAction';

@Component({})
export default class ScoreChip extends Vue {
  @Prop({
    required: true,
  })
  score: number; // integer 0-100

  @Prop({
    default: () => [],
  })
  studentActions: ActionType[];

  @Prop({
    type: Boolean,
  })
  includesCorrectAnswer: boolean;

  @Prop({
    type: Boolean,
  })
  isStandardsReportChip: boolean;

  @Prop({
    default: false,
  })
  sawAnswer: boolean;

  @Prop({
    default: false,
  })
  isInTestMode: boolean;

  @Prop({
    default: false,
  })
  showZeroAsIncorrect: boolean;

  @Prop({
    type: Boolean,
  })
  round: boolean;

  ActionType = ActionType; // Make Enum values accessible in component logic
  get actionsToDisplay(): ActionType[] {
    const actions = this.studentActions.filter(
      (action) =>
        action == this.ActionType.STUDENT_RESPONSE_ACTION ||
        action == this.ActionType.HINT_REQUESTED_ACTION
    );

    // Removes the last element rather than displaying as having one extra
    // wrong answer which was actually their correct answer submission.
    if (actions.length <= 3 && this.includesCorrectAnswer) {
      return actions.slice(0, -1);
    }

    return actions.slice(0, 3);
  }

  get chipScore(): number {
    if (!this.score || (this.isInTestMode && this.score < 100)) {
      return 0;
    } else {
      return Math.round(this.score);
    }
  }

  /**
   * Set chip color based on score
   * @returns theme color name as string
   */
  get chipColor(): string {
    // Standards report color
    if (this.isStandardsReportChip) {
      return 'primary darken-2';
    }

    // Perfect Score
    if (this.chipScore >= 100) {
      return 'correct';
    }
    // Correct Eventually (partial credit)
    if (
      !this.sawAnswer &&
      !this.isInTestMode &&
      !(this.showZeroAsIncorrect && this.score === 0)
    ) {
      return 'correctEventually';
    }
    // Incorrect (answer requested or test mode and not right on first try)
    return 'incorrect';
  }
}
