import { GetterTree } from 'vuex';
import { InsightsHubState } from './types';
import { RootState } from '../types';
import { ModuleDefinition } from '@/domain/Curriculum';
import { User } from '@/domain/User';
import { CourseDefinition } from '@/domain/Class';
import { School } from '@/domain/School';
import { HybridFolderDefinition } from '@/domain/Folder';

export const getters: GetterTree<InsightsHubState, RootState> = {
  selectedGradeFolder(state): HybridFolderDefinition | null {
    return (
      state.gradeFolders.find(
        (gradeFolder) =>
          Number(gradeFolder.id) === Number(state.selectedGradeId)
      ) || null
    );
  },
  selectedModuleDefinition(state): ModuleDefinition | null {
    if (
      state.curriculumGrade &&
      state.curriculumGrade.modules &&
      state.selectedModuleId
    ) {
      return state.curriculumGrade.modules.get(state.selectedModuleId) || null;
    }
    return null;
  },
  xrefToGradeTeacherMap(state, getters): Map<string, User> {
    const res = new Map();
    const gradeFolder = getters.selectedGradeFolder;
    if (gradeFolder) {
      const gradeLevels = gradeFolder?.attributes?.gradeLevel ?? [];
      for (const teacher of state.menteeTeachers) {
        const teacherGradeLevels = teacher?.attributes?.gradeLevel ?? [];
        const inGradeLevel = teacherGradeLevels.some((grade) =>
          gradeLevels.includes(grade)
        );
        if (inGradeLevel) {
          res.set(teacher.xref, teacher);
        }
      }
    }

    return res;
  },
  xrefToCourseMap(state): Map<string, CourseDefinition> {
    const res = new Map();
    for (const course of state.menteeCourses) {
      res.set(course.courseXref, course);
    }
    return res;
  },
  xrefToSchoolMap(state): Map<string, School> {
    const res = new Map();
    for (const school of state.menteeSchools) {
      res.set(school.ncesId, school);
    }
    return res;
  },
  ownerXrefToCourseXrefsMap(state): Map<string, string[]> {
    const res = new Map();
    for (const course of state.menteeCourses) {
      const courseXrefs = res.get(course.ownerXref);
      if (courseXrefs) {
        courseXrefs.push(course.courseXref);
      } else {
        res.set(course.ownerXref, [course.courseXref]);
      }
    }

    return res;
  },
};
