
import {
  updateStudentProblemLog,
  UpdateStudentProblemLogPayload,
} from '@/api/core/assignment.api';
import { QuickComment } from '@/domain/QuickComment';
import { ProblemLog, StudentLog } from '@/domain/ReportData/AssignmentData';
import { User } from '@/domain/User';
import { appendGlobalHeaderOptions } from '@/utils/dataTables.utils';
import { isNaN, orderBy } from 'lodash';
import { shuffle } from 'lodash';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import EssayScoringDialog from './EssayScoringDialog.vue';
import WirisMixins from '@/mixins/wiris';
import { mixpanel, EventType } from '@/plugins/mixpanel';

enum UpdateTarget {
  COMMENT = 'Comment',
  SCORE = 'Score',
}

interface EssayScoringTableRow {
  studentName: string;
  studentXref: string;
  pLogId: number;
  continuousScore: number;
  teacherComment: string;
  customSortValue: number;
  updating: boolean;
  isScoreValid: boolean;
  quickComments: Array<string>;
  answerText?: string;
  suggestedScore?: number;
}

interface StudentName {
  firstName: string;
  lastName: string;
}

@Component({
  components: {
    EssayScoringDialog,
  },
})
export default class EssayScoringTable extends Mixins(WirisMixins) {
  @Prop({ required: true }) studentLogs: StudentLog[];
  @Prop({ required: true }) studentXrefToProblemLogMap: Map<string, ProblemLog>;
  @Prop({ required: true }) assignmentXref: string;
  @Prop({ required: true }) students: Array<User>;
  @Prop({ required: true }) problemId: number;
  @Prop({ required: true, default: [] }) quickComments: Array<QuickComment>;

  hideNames = false;

  showScoringDialog = false;
  availableScores: Array<number> = [0, 1, 2, 3, 4];

  teacherXref = this.$store.getters['auth/getCurrentUser'].xref;

  mounted(): void {
    this.attachClickEventListenersToImages();
    this.logReportSettings(
      this.assignmentXref,
      this.teacherXref,
      this.hideNames
    );
  }

  get studentXrefToQuickCommentMap(): Map<string, QuickComment> {
    const res = new Map<string, QuickComment>();
    if (this.quickComments.length > 0) {
      this.quickComments.forEach((element) => {
        res.set(element.userXref, element);
      });
    }
    return res;
  }

  get rows(): Array<EssayScoringTableRow> {
    const rows: Array<EssayScoringTableRow> = [];

    this.studentLogs.forEach((slog) => {
      const prLog = this.studentXrefToProblemLogMap.get(slog.studentXref);
      if (prLog) {
        this.checkForWirisResponse(prLog.answerText);

        rows.push(this.buildRow(slog, prLog));
      }
    });

    return this.hideNames
      ? shuffle(rows)
      : this.sortRowsByStudentsLastName(rows);
  }

  get hideNamesButtonText(): string {
    return this.hideNames ? 'Show Names' : 'Hide Names';
  }

  get headers(): Array<DataTableHeader> {
    return [...this.prependStaticHeaders.map(appendGlobalHeaderOptions)];
  }

  get prependStaticHeaders(): Array<DataTableHeader> {
    const buildStaticHeader = (
      text: string,
      value: string,
      sortable: boolean
    ): DataTableHeader => {
      const align = text === 'Student' ? 'start' : 'center';
      const cellClass = text === 'Response' ? ['markup-cell'] : undefined;

      const header: DataTableHeader = {
        text,
        value,
        align,
        cellClass,
        sortable,
        class: [
          'text-no-wrap',
          'sticky-row',
          'sticky-row-1',
          'text-subtitle-2',
        ],
      };

      if (header.text === 'Custom Sort') {
        header.sort = (a: number, b: number): number => (a > b ? 1 : -1);
      }

      return header;
    };

    return [
      buildStaticHeader('Student', 'studentName', true),
      buildStaticHeader('Response', 'answerText', false),
      buildStaticHeader('Score', 'continuousScore', true),
      buildStaticHeader('Teacher Comment', 'teacherComment', false),
      buildStaticHeader('Custom Sort', 'customSortValue', true),
    ];
  }

  get studentXrefToStudentNameMap(): Map<string, StudentName> {
    let res = new Map();
    this.students.forEach((student) => {
      res.set(student.xref, {
        firstName: student.firstName,
        lastName: student.lastName,
      });
    });
    return res;
  }

  private isUpdateInputInvalid(value: string | number): boolean {
    return value === null || isNaN(value);
  }

  logReportSettings(
    assignmentXref: string,
    teacherXref: string,
    anonymous: boolean
  ): void {
    mixpanel.track(EventType.essayScoringSetting, {
      distinct_id: teacherXref,
      assignmentXref: assignmentXref,
      anonymous: anonymous,
    });
  }

  update(
    updateTarget: UpdateTarget,
    assignmentXref: string,
    item: EssayScoringTableRow,
    value: string | number,
    isQCCommentClicked = false,
    qcCommentIndex: number | null = null
  ): void {
    /** README:
     * The reasoning behind this hard-null/hard-NaN check
     * is to avoid triggering an update if the value
     * hasn't been set for the update method.
     */
    const isInvalid = this.isUpdateInputInvalid(value);

    if (isInvalid) {
      return;
    }

    const teacherXref = this.teacherXref;
    const payload: UpdateStudentProblemLogPayload = {};

    if (updateTarget === UpdateTarget.COMMENT) {
      payload.teacherComment = value as string;

      // Break out if the values are the same
      if (item.teacherComment === payload.teacherComment) {
        return;
      }

      // Log comments selection for QUICK_Comments features
      let selectedCommentIndex = null;
      if (isQCCommentClicked) {
        selectedCommentIndex = qcCommentIndex as number;

        mixpanel.track(EventType.qcCommentSelection, {
          distinct_id: teacherXref,
          problemLogId: item.pLogId,
          assignmentXref: assignmentXref,
          studentXref: item.studentXref,
          selectedCommentIndex: selectedCommentIndex,
          selectedComment: payload.teacherComment,
        });
      }
    }

    if (updateTarget === UpdateTarget.SCORE) {
      payload.continuousScore = (value as number) / 4;

      mixpanel.track(EventType.qcScoreSelection, {
        distinct_id: teacherXref,
        problemLogId: item.pLogId,
        assignmentXref: assignmentXref,
        studentXref: item.studentXref,
        suggestedScore: item.suggestedScore,
        teacherScore: value,
      });

      // Update the quickcomments on score change
      item.quickComments =
        this.studentXrefToQuickCommentMap.get(item.studentXref)?.comments[
          payload.continuousScore * 4
        ] || [];

      // Revalidate the isScoreValid field of the row
      item.isScoreValid = this.isScoreValid(value as number);
    }

    item.updating = true;

    updateStudentProblemLog(
      assignmentXref,
      item.studentXref,
      item.pLogId,
      payload
    )
      .then(() => {
        this.$emit('update', {
          id: item.pLogId,
          ...payload,
        });
        item.updating = false;
        if (updateTarget === UpdateTarget.COMMENT) {
          item.teacherComment = value as string;
        }
        if (updateTarget === UpdateTarget.SCORE) {
          item.continuousScore = value as number;
        }
        this.$notify(`${updateTarget} updated!`);
      })
      .catch(() => {
        this.$notify('An error has occured. Please try again later.');
      });
  }

  getStudentName(studentXref: string): string {
    return this.students.find((student) => student.xref === studentXref)
      ?.displayName as string;
  }

  buildRow(
    studentLog: StudentLog,
    problemLog: ProblemLog
  ): EssayScoringTableRow {
    const continuousScore = problemLog.continuousScore * 4;
    const isScoreValid = this.isScoreValid(continuousScore);
    let quickComments: Array<string> = [];

    let suggestedScore;

    if (problemLog.answerText) {
      const qc = this.studentXrefToQuickCommentMap.get(studentLog.studentXref);
      let qcIndex: number;

      if (typeof continuousScore === 'number' && continuousScore >= 0) {
        qcIndex = continuousScore;
      } else {
        qcIndex = qc?.suggestedGrade as number;
      }

      quickComments = qc?.comments[qcIndex] || [];
      suggestedScore = qc?.suggestedGrade;
    }

    return {
      continuousScore,
      isScoreValid,
      customSortValue: 0,
      updating: false,
      studentXref: studentLog.studentXref,
      pLogId: problemLog.id,
      answerText: problemLog.answerText,
      teacherComment: problemLog.teacherComment,
      studentName: this.getStudentName(studentLog.studentXref),
      quickComments,
      suggestedScore,
    };
  }

  toggleNames(): void {
    this.hideNames = !this.hideNames;

    this.logReportSettings(
      this.assignmentXref,
      this.teacherXref,
      this.hideNames
    );
  }

  isScoreValid(score: number): boolean {
    // Early break if the score is 0 (falsy value) or empty
    if (!score || isNaN(score)) {
      return true;
    }

    return this.availableScores.includes(score);
  }

  sortRowsByStudentsLastName(
    rows: Array<EssayScoringTableRow>
  ): Array<EssayScoringTableRow> {
    const sortedRows = orderBy(
      rows,
      [
        (row) => {
          return this.studentXrefToStudentNameMap.get(row.studentXref)
            ?.lastName;
        },
        (row) => {
          return this.studentXrefToStudentNameMap.get(row.studentXref)
            ?.firstName;
        },
      ],
      ['asc', 'asc']
    );
    return sortedRows;
  }

  parseWiris(): void {
    this.$nextTick(() =>
      this.parseWirisResponsesFromDocument(this.documentContainsWiris)
    );
  }

  attachClickEventListenersToImages(): void {
    const wrappers = Array.from(document.getElementsByClassName('answer-text'));

    wrappers.forEach((wrapper) => {
      const images = wrapper.getElementsByTagName('img');

      if (images.length > 0) {
        Array.from(images).forEach((image) => {
          const source = image.getAttribute('src') as string;
          image.addEventListener('click', () =>
            this.$emit('openImageModal', source)
          );
        });
      }
    });
  }

  created(): void {
    this.hideNames =
      this.$store.state.auth.user.settings.anonymizeReportsByDefault || false;
  }
}
