import dayjs, { Dayjs } from 'dayjs';

export function getCurrentSchoolYear(): {
  startDateTime: Dayjs;
  endDateTime: Dayjs;
} {
  let startDate = dayjs();
  startDate.month(6);
  startDate = startDate.startOf('M');

  let endDate = startDate.subtract(1, 'millisecond');

  const currentMonth = dayjs().month();

  if (currentMonth > 5) {
    // This year
    endDate = endDate.add(1, 'year');
  } else {
    // Last Year
    startDate = startDate.subtract(1, 'year');
  }

  return { startDateTime: startDate, endDateTime: endDate };
}

export function getSchoolYears(yearsBack = 2): {
  startDateTime: Dayjs;
  endDateTime: Dayjs;
}[] {
  const results: {
    startDateTime: Dayjs;
    endDateTime: Dayjs;
  }[] = [];

  const currentSchoolYear: { startDateTime: Dayjs; endDateTime: Dayjs } =
    getCurrentSchoolYear();

  results.push(currentSchoolYear);

  let startDateTime = currentSchoolYear.startDateTime;
  let endDateTime = currentSchoolYear.endDateTime;

  while (yearsBack > 0) {
    // Prior school years
    startDateTime = startDateTime.subtract(1, 'year');
    endDateTime = endDateTime.subtract(1, 'year');

    results.push({ startDateTime, endDateTime });

    yearsBack--;
  }

  return results;
}
