
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class StandardsScoringDialog extends Vue {
  showDialog = false;

  /**
   * Returns max width for dialog based on current breakpoint
   */
  get maxWidth(): number {
    return this.$vuetify.breakpoint.lgAndUp ? 800 : 600;
  }
}
