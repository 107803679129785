import { Component, Vue } from 'vue-property-decorator';
import { AuthenticatedUser } from '@/domain/User';

@Component
export default class AuthMixins extends Vue {
  get isAuthenticated(): boolean {
    return this.$store.getters['auth/isAuthenticated'];
  }
  get getCurrentUser(): AuthenticatedUser {
    return this.$store.getters['auth/getCurrentUser'];
  }
  get isLtiUser(): boolean {
    return this.$store.getters['auth/isLtiUser'];
  }
}

Vue.mixin(AuthMixins);
