import { ActionType } from '@/domain/StudentProblemAction';

const hasSeenAnswer = (actions: Array<ActionType>): boolean => {
  return (
    actions.includes(ActionType.ANSWER_REQUESTED_ACTION) ||
    actions.includes(ActionType.EXPLANATION_REQUESTED_ACTION)
  );
};

export { hasSeenAnswer };
