
import { patchUserSetting } from '@/api/core/user.api';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class TestModeDialog extends Vue {
  @Prop({ required: true }) value: boolean;

  hideTestModeDialogInTheFuture = false;

  get showDialog(): boolean {
    return this.value;
  }

  set showDialog(value: boolean) {
    if (!value && this.hideTestModeDialogInTheFuture) {
      (async () => await patchUserSetting('hideTestModeDialog', true))();
    }
    this.$emit('input', value);
  }
}
