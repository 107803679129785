
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProblemView from '@/components/base/ProblemView.vue';
import StudentProblemView from '@/components/LDOE/base/StudentProblemView.vue';
import ScoreChip from '@/components/Report/ScoreChip.vue';
import { Problem } from '@/domain/Problem';
import {
  ProblemData,
  StudentDataPerAssignmentProblem,
} from '@/domain/ReportData/Cignition';
import {
  ActionType,
  StudentProblemAction,
} from '@/domain/StudentProblemAction';
import { hasSeenAnswer } from '@/utils/report.util';

export enum ProblemRowHeaderValue {
  CLASS_AVERAGE = 'classAverage',
}

export interface CustomProblemRowHeader {
  text: string;
  value: string;
  cols?: number;
}

export interface ProblemRow {
  problem: Problem;
  problemData: ProblemData;
  studentData: { [xref: string]: StudentDataPerAssignmentProblem };
  index: number;
}

@Component({
  components: {
    ProblemView,
    StudentProblemView,
    ScoreChip,
  },
})
export default class ProblemRowView extends Vue {
  @Prop({ required: true }) problemRow: ProblemRow;
  @Prop({ required: true }) customHeaders: Array<CustomProblemRowHeader>;
  @Prop({ default: null }) customHeaderCols: number | null;

  isExpanded = false;

  // Allows us access to the enum in the template.
  ProblemRowHeaderValue = ProblemRowHeaderValue;

  // Allows us access to the helper funciton in utils.

  /////////////
  // Methods //
  /////////////

  getScore(averageScore?: number): number | null {
    return averageScore != undefined ? Math.round(averageScore * 100) : null;
  }

  getDisplayScore(averageScore?: number): string {
    const score = this.getScore(averageScore);

    return score ? score + '%' : '-';
  }

  getStudentActions(
    studentData: StudentDataPerAssignmentProblem
  ): Array<ActionType> {
    return studentData.studentActions.map((action: StudentProblemAction) => {
      return action.type;
    });
  }
  getSawAnswer(studentData: StudentDataPerAssignmentProblem): boolean {
    const studentActions = this.getStudentActions(studentData);

    return hasSeenAnswer(studentActions);
  }
}
